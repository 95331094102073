import { Paper } from "@mui/material";
import React from "react";
import UserConcultation from "../../userDetails/UserConcultation";

export default function UserTab1({ user, token }) {
  return (
    <Paper sx={{ boxShadow: "none" }}>
      <UserConcultation
        user={user}
        token={token}
        getUrl="personnelConsultations/getByPersonnelId"
        createUrl="personnelConsultations/create"
        updateUrl="personnelConsultations/update"
      />
    </Paper>
  );
}
