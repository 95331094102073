import React from "react";

const Loader = ({ style, containerClassName, ...props }) => {
  return (
    <div className={`spinner ${containerClassName}`} {...props}>
      <div style={style} className="bounce1" />
      <div style={style} className="bounce2" />
      <div style={style} className="bounce3" />
    </div>
  );
};

export default Loader;
