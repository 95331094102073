/* eslint-disable no-undef */
import merge from "lodash/merge";
import ReactApexChart from "react-apexcharts";
// @mui
import { styled } from "@mui/material/styles";
import { CardHeader, Grid } from "@mui/material";
import BaseOptionChart from "./chart/BaseOptionChart";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 300;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: 0,
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    justifyContent: "space-around !important",
    position: "relative !important",
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    padding: 0,
  },
}));

// ----------------------------------------------------------------------

// const CHART_DATA1 = [32, 68];
const CHART_DATA2 = [0, 0, 0, 0, 0];
const CHART_DATA3 = [0, 0, 0, 0, 0];

export default function Donut({ userSummary }) {
  const gender = userSummary?.gender;
  const renderGender = [
    parseFloat(gender?.malePercentage),
    parseFloat(gender?.femalePercentage),
  ];

  const genderMale = gender?.maleCount;
  const genderFemale = gender?.femaleCount;

  // render Age Total
  const renderAgeTotal =
    userSummary && userSummary.ageTotalList
      ? userSummary.ageTotalList.map((ageItem) => {
          return parseFloat(ageItem?.percentage);
        })
      : [];

  const ten =
    userSummary && userSummary.ageTotalList
      ? userSummary?.ageTotalList[0]?.count
      : 0;

  const twenty =
    userSummary && userSummary.ageTotalList
      ? userSummary?.ageTotalList[1]?.count
      : 0;

  const thirty =
    userSummary && userSummary.ageTotalList
      ? userSummary?.ageTotalList[2]?.count
      : 0;

  const fourty =
    userSummary && userSummary.ageTotalList
      ? userSummary?.ageTotalList[3]?.count
      : 0;
  const fifty =
    userSummary && userSummary.ageTotalList
      ? userSummary?.ageTotalList[4]?.count
      : 0;

  // const labelAgeTotal =
  //   userSummary && userSummary.ageTotalList
  //     ? userSummary.ageTotalList.map((ageItem) => ageItem?.count)
  //     : [];

  // render Edu Level List
  const renderEduLevelList =
    userSummary && userSummary.eduLevelList
      ? userSummary.eduLevelList.map((eduItem) =>
          parseFloat(eduItem?.percentage)
        )
      : [];

  const eduTen =
    userSummary && userSummary.eduLevelList
      ? userSummary?.eduLevelList[0]?.total
      : 0;

  const eduTwenty =
    userSummary && userSummary.eduLevelList
      ? userSummary?.eduLevelList[1]?.total
      : 0;

  const eduThirty =
    userSummary && userSummary.eduLevelList
      ? userSummary?.eduLevelList[2]?.total
      : 0;

  const eduFourty =
    userSummary && userSummary.eduLevelList
      ? userSummary?.eduLevelList[3]?.total
      : 0;
  const eduFifty =
    userSummary && userSummary.eduLevelList
      ? userSummary?.eduLevelList[4]?.total
      : 0;

  // const labelEduList =
  //   userSummary && userSummary.eduLevelList
  //     ? userSummary.eduLevelList.map((eduItem) => eduItem?.name)
  //     : [];

  const customLegendFormatter = (seriesName, opts) => {
    return `
          <div class="legend-item-label">${seriesName}</div>
          <div class="legend-item-value">${
            (Math.round(opts.w.globals.series[opts.seriesIndex]) * 100) / 100
          }%</div>
      `;
  };
  // const customLegendFormatterEdu = (seriesName, opts) => {
  //   const roundedValue = (
  //     Math.round(opts.w.globals.series[opts.seriesIndex] * 100) / 100
  //   ).toFixed(2);
  //   return `
  //         <div class="legend-item-label">${seriesName}</div>
  //         <div class="legend-item-value">${roundedValue}%</div>
  //     `;
  // };

  const chartOptionsGender = merge(BaseOptionChart(), {
    colors: ["#5A6ACF", "#C7CEFF"],
    labels: ["남성", "여성"],
    stroke: { show: false },
    legend: {
      floating: true,
      horizontalAlign: "center",
      justifyContent: "space-around",
      formatter: customLegendFormatter,
    },
    tooltip: {
      labels: { show: false },
      fillSeriesColor: false,
      y: {
        title: {
          formatter: function (seriesName, opts) {
            return `
          <div class="legend-item-label-name">${seriesName}</div>
      
          <div class="legend-item-value-percent">${
            (Math.round(opts.w.globals.series[opts.seriesIndex]) * 100) / 100
          }%</div>
          <div class="legend-year-value">
          ${seriesName === "남성" ? genderMale + " 명" : genderFemale + " 명"}
          </div>
        `;
          },
        },
        formatter: function (val) {
          return null;
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
          labels: {
            value: {
              formatter: (val) => val,
            },
            total: {
              formatter: (w) => {
                const sum = w.globals.seriesTotals.reduce(
                  (a, b) => parseFloat(a) + parseFloat(b),
                  0
                );
                return Math.round(sum * 100) / 100 + "%";
              },
            },
          },
        },
      },
    },
  });

  const chartOptionsAge = merge(BaseOptionChart(), {
    colors: ["#5A6ACF", "#8593ED", "#5ACF97", "#AACF5A", "#EDE385"],
    labels: ["10대", "20대", "30대", "40대", "기타"],
    stroke: { show: false },
    legend: {
      floating: true,
      horizontalAlign: "center",
      justifyContent: "space-between",
      formatter: customLegendFormatter,
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        title: {
          formatter: function (seriesName, opts) {
            return `
          <div class="legend-item-label-name">${seriesName}</div>
          <div class="legend-item-value-percent">${
            (Math.round(opts.w.globals.series[opts.seriesIndex]) * 100) / 100
          }%</div>
          <div class="legend-year-value">
            ${
              seriesName === "10대"
                ? ten + " 명"
                : seriesName === "20대"
                ? twenty + " 명"
                : seriesName === "30대"
                ? thirty + " 명"
                : seriesName === "40대"
                ? fourty + " 명"
                : seriesName === "기타"
                ? fifty + " 명"
                : 0
            }
          </div>
        `;
          },
        },
        formatter: function (val) {
          return null;
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
          labels: {
            value: {
              formatter: (val) => val,
            },
            total: {
              formatter: (w) => {
                const sum = w.globals.seriesTotals.reduce(
                  (a, b) => parseFloat(a) + parseFloat(b),
                  0
                );

                return Math.round(sum * 100) / 100 + "%";
              },
            },
          },
        },
      },
    },
  });

  const chartOptionsEdu = merge(BaseOptionChart(), {
    colors: ["#5A6ACF", "#8593ED", "#5ACF97", "#AACF5A", "#EDE385"],
    labels: ["고졸", "전문대졸", "대졸", "대학원졸", "기타"],
    // labels: labelEduList,
    stroke: { show: false },
    legend: {
      floating: true,
      horizontalAlign: "center",
      justifyContent: "space-between",
      formatter: customLegendFormatter,
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        title: {
          formatter: function (seriesName, opts) {
            return `
          <div class="legend-item-label-name">${seriesName}</div>
          <div class="legend-item-value-percent">${
            opts.w.globals.series[opts.seriesIndex]
          }%</div>
          <div class="legend-year-value">
          ${
            seriesName === "고졸"
              ? eduTen + " 명"
              : seriesName === "전문대졸"
              ? eduTwenty + " 명"
              : seriesName === "대졸"
              ? eduThirty + " 명"
              : seriesName === "대학원졸"
              ? eduFourty + " 명"
              : seriesName === "기타"
              ? eduFifty + " 명"
              : 0
          }
        </div>
        `;
          },
        },
        formatter: function (val) {
          return null;
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          enable: true,
          size: "80%",
          labels: {
            value: {
              formatter: (val) => val,
            },
            total: {
              formatter: (w) => {
                const sum = w.globals.seriesTotals.reduce(
                  (a, b) => parseFloat(a) + parseFloat(b),
                  0
                );

                return Math.round(sum / 100) * 100 + "%";
              },
            },
          },
        },
      },
    },
  });

  return (
    <Grid container>
      <Grid item xs={4} sx={{ borderRight: "solid 1px #C8CBD9" }}>
        <CardHeader sx={{ p: 0 }} title="성별" />
        <ChartWrapperStyle dir="ltr">
          <ReactApexChart
            type="donut"
            series={renderGender}
            options={chartOptionsGender}
            height={200}
          />
        </ChartWrapperStyle>
      </Grid>
      <Grid item xs={4} sx={{ borderRight: "solid 1px #C8CBD9", px: 5 }}>
        <CardHeader sx={{ p: 0 }} title="연령별" />
        <ChartWrapperStyle dir="ltr">
          {renderAgeTotal.length > 0 ? (
            <ReactApexChart
              type="donut"
              series={renderAgeTotal}
              options={chartOptionsAge}
              height={200}
            />
          ) : (
            <ReactApexChart
              type="donut"
              series={CHART_DATA2}
              options={chartOptionsAge}
              height={200}
            />
          )}
        </ChartWrapperStyle>
      </Grid>
      <Grid item xs={4} sx={{ px: 5 }}>
        <CardHeader sx={{ p: 0 }} title="학력별" />
        <ChartWrapperStyle dir="ltr">
          {renderEduLevelList.length > 0 ? (
            <ReactApexChart
              type="donut"
              series={renderEduLevelList}
              options={chartOptionsEdu}
              height={200}
            />
          ) : (
            <ReactApexChart
              type="donut"
              series={CHART_DATA3}
              options={chartOptionsEdu}
              height={200}
            />
          )}
        </ChartWrapperStyle>
      </Grid>
    </Grid>
  );
}
