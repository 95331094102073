import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  TableHead,
  Grid,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import { pageSize } from "../../constants/constants";
import PayrollAddModal from "../../components/modals/PayrollAddModal";
import { getAllSalary } from "../../api/payroll";
import DataNotFound from "../../components/DataNotFound";

const initialQuery = {
  number: 1,
  size: 20,
};

const reverseArray = [
  {
    dateString: "2022-03",
  },
  {
    dateString: "2022-04",
  },
  {
    dateString: "2022-05",
  },
  {
    dateString: "2022-06",
  },
];

export default function PayrollManagement() {
  const methods = useForm();
  const { control } = methods;
  const { token } = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [modal, setModal] = useState({ isOpen: false });
  const [notFound, setNotFound] = useState(false);
  const [users, setUsers] = useState({
    content: reverseArray,
    additionalData: {},
    totalSalary: null,
  });

  const [query, setQuery] = useState(initialQuery);

  const handleChangePage = (event, value) => {
    setQuery({ ...query, number: value });
  };

  const getSalary = async () => {
    try {
      const data = await getAllSalary(query, token);
      if (data.data.length === 0 || !data.data) {
        setNotFound(true);
      } else {
        setUsers({
          content: data.data,
          additionalData: data.additionalData,
          totalSalary: data.totalSalary,
        });
        setNotFound(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSalary();
  }, [query]);

  return (
    <Paper
      sx={{
        width: "100%",
        boxShadow: "none",
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Grid fontWeight={700} fontSize={24} color="#5A5A5A">
          월별 지급 총계
        </Grid>
        <Box display="flex" flexDirection="column">
          <Button
            onClick={() => setModal({ isOpen: true })}
            size="small"
            sx={{
              fontSize: "13px",
              width: "124px",
              height: "30px",
              padding: "8px 26px",
              color: "#FFF",
              backgroundColor: "#7653DF",
              ml: "auto",
            }}
          >
            급여 등록 <AddIcon />
          </Button>
          <Button
            onClick={() => navigate("/payroll/search")}
            size="small"
            sx={{
              fontSize: "13px",
              width: "124px",
              height: "30px",
              padding: "8px 26px",
              color: "#FFF",
              backgroundColor: "#7653DF",
              ml: "auto",
              mt: "10px",
            }}
          >
            사원 검색
          </Button>
        </Box>
      </Box>

      <hr style={{ margin: "30px auto" }} />
      <Box
        sx={{
          display: "flex",
          mb: "15px",
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormInputDropdown
            control={control}
            name="size"
            sx={{ width: "90px" }}
            onChange={(e) => setQuery({ ...query, size: e.target.value })}
            options={pageSize}
            staticValue={query.size}
          />
        </Box>
      </Box>

      {notFound ? (
        <DataNotFound />
      ) : (
        <Box>
          {" "}
          <TableContainer
            className="table"
            sx={{
              borderTop: "1px solid #98A6AD",
            }}
          >
            <Table>
              <TableHead sx={{ background: "#fcfcfc" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    번호
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    급여 년월
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    지급횟수
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    지급 인원
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    지급 총액
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    공제 총액
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    차인 지급 총액
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.content.reverse()?.map((user, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      sx={{
                        "&:hover": {
                          color: "#5a5a5a !important",
                          backgroundColor: "rgba(142, 102, 255, 0.22)",
                        },
                        ".css-1d46hnn-MuiTableCell-root": {
                          padding: "1px",
                        },

                        height: "40px",
                      }}
                    >
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/payroll-management/${user.dateString}`}
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {query.size * (query.number - 1) + 1 + index}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/payroll-management/${user.dateString}`}
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.dateString}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/payroll-management/${user.dateString}`}
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.totalCount || 0} 회
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/payroll-management/${user.dateString}`}
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.employeeCount || 0} 명
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/payroll-management/${user.dateString}`}
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.sum?.toLocaleString() || 0} 원
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/payroll-management/${user.dateString}`}
                        sx={{
                          color: "#A91515",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.sub?.toLocaleString() || 0} 원
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/payroll-management/${user.dateString}`}
                        sx={{
                          color: "#1270B0",
                          fontSize: "12px",
                          fontWeight: "700",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {(user.sum - user.sub)?.toLocaleString()} 원
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
            <Pagination
              count={Math.ceil(users?.totalElements / query.size) || 0}
              onChange={handleChangePage}
              page={query.number}
              color="primary"
              variant="outlined"
              shape="rounded"
              sx={{
                m: "0 auto",
                "& .MuiPaginationItem-root.MuiPaginationItem-page, .MuiPaginationItem-previousNext":
                  { padding: "4px 20px !important" },
              }}
            />
          </Stack>
        </Box>
      )}

      <PayrollAddModal
        setModal={setModal}
        modal={{ ...modal, isOpen: modal.isOpen }}
        modalKey="isOpen"
      />
    </Paper>
  );
}
