import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Box } from "@mui/material";
import { Controller } from "react-hook-form";
import { uploadImageEditor } from "../../api/recruitment";
import { awsImagePath } from "../../utils/methods";

class UploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then((file) => {
      return new Promise(async (resolve, reject) => {
        const formData = new FormData();
        formData.append("file", file);

        try {
          const res = await uploadImageEditor(formData);
          resolve({ default: `${awsImagePath}/${res.data}` });
        } catch (err) {
          console.log("Editor error: ", err);
        }
      });
    });
  }
}

function uploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new UploadAdapter(loader);
  };
}

export default function FormInputCEditor({
  name = "",
  sx,
  control,
  setValue,
  data,
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <Box sx={sx}>
          <CKEditor
            editor={ClassicEditor}
            config={{
              language: "kr",
              extraPlugins: [uploadAdapterPlugin],
              enterMode: 2,
              shiftEnterMode: 1,
              heading: {
                options: [
                  {
                    model: "paragraph",
                    title: "fontsize",
                    class: "ck-heading_paragraph",
                  },
                  {
                    model: "heading1",
                    view: "font-8",
                    title: "8px",
                    class: "ck-heading_heading2",
                  },
                  {
                    model: "heading2",
                    view: "font-12",
                    title: "12px",
                    class: "ck-heading_heading2",
                  },
                  {
                    model: "heading3",
                    view: "font-16",
                    title: "16px",
                    class: "ck-heading_heading2",
                  },
                  {
                    model: "heading4",
                    view: "font-20",
                    title: "20px",
                    class: "ck-heading_heading2",
                  },
                  {
                    model: "heading5",
                    view: "font-24",
                    title: "24px",
                    class: "ck-heading_heading2",
                  },
                  {
                    model: "heading6",
                    view: "font-30",
                    title: "30px",
                    class: "ck-heading_heading2",
                  },
                  {
                    model: "heading7",
                    view: "font-40",
                    title: "40px",
                    class: "ck-heading_heading2",
                  },
                  {
                    model: "heading8",
                    view: "font-50",
                    title: "50px",
                    class: "ck-heading_heading2",
                  },
                ],
              },
            }}
            data={data ? data : ""}
            onChange={(event, editor) => {
              const data = editor.getData();
              setValue(name, data);
            }}
          />
        </Box>
      )}
    />
  );
}
