import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { FormInputTextArea } from "../../../components/form-components/FormInputTextArea";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createHhuntingMemo,
  getMemoByHHuntingId,
  updateMemoByHhuntingId,
} from "../../../api/customers";
import { toast } from "react-toastify";

export default function Tab1() {
  const { id } = useParams();
  const contentRef = useRef(null);
  const { handleSubmit, control, reset } = useForm();
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [memoList, setMemoList] = useState();
  const [clicked, setClicked] = useState(null);

  async function hhuntingReq() {
    try {
      const { data } = await getMemoByHHuntingId(id, user.token);
      setMemoList(data);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data.message);
    }
  }

  const submit = async (values) => {
    const data = {
      ...values,
      headHuntingId: id,
    };
    if (values.id) {
      try {
        await updateMemoByHhuntingId(JSON.stringify(data), user.token);
        toast.success("완료 되었습니다.");
        hhuntingReq();
      } catch (error) {
        console.log(error);
        toast.error(error.response?.data.message);
      }
    } else {
      try {
        await createHhuntingMemo(JSON.stringify(data), user.token);
        toast.success("완료 되었습니다.");
        hhuntingReq();
      } catch (error) {
        console.log(error);
        toast.error(error.response?.data.message);
      }
    }
  };

  const update = (user) => {
    reset(user);
  };

  useEffect(() => {
    hhuntingReq();
  }, [id]);

  return (
    <Paper sx={{ boxShadow: "none" }}>
      <Typography
        sx={{
          m: "40px 0 20px 0",
          fontSize: "16px",
          color: "#3A3A3A",
          fontWeight: 700,
        }}
      >
        상담 내역
      </Typography>
      <Table
        sx={{
          borderTop: "1px solid #98A6AD",
        }}
      >
        <TableHead sx={{ background: "#fcfcfc" }}>
          <TableRow>
            <TableCell
              sx={{
                whiteSpace: "nowrap",
                fontSize: "13px",
                fontWeight: "500",
              }}
              align="center"
            >
              일자
            </TableCell>
            <TableCell
              sx={{
                padding: "10px",
                fontWeight: "500",
                whiteSpace: "nowrap",
                fontSize: "13px",
              }}
              align="center"
            >
              관리자
            </TableCell>
            <TableCell
              sx={{
                padding: "10px",
                fontWeight: "500",
                whiteSpace: "nowrap",
                fontSize: "13px",
              }}
              align="center"
            >
              상담 내용
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {memoList
            ? memoList.map((user, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    onClick={() => update(user)}
                    onMouseEnter={() => setClicked(user.id)}
                    key={index}
                    sx={{
                      ".css-1d46hnn-MuiTableCell-root": {
                        padding: "1px",
                      },
                      textDecoration: "none",
                      fontSize: "12px",
                      height: "40px",
                    }}
                  >
                    <TableCell
                      component={Link}
                      id={labelId}
                      scope="row"
                      padding="none"
                      // to={`/user-details/${user.id}`}
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        width: "100px",
                        padding: "10px",
                        fontWeight: "500",
                      }}
                      align="center"
                    >
                      {user.createdDate}
                    </TableCell>
                    <TableCell
                      component={Link}
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        width: "200px",
                        padding: "10px",
                        fontWeight: "500",
                      }}
                      align="center"
                    >
                      {user.personInCharge}
                    </TableCell>
                    <TableCell
                      component={Link}
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        fontWeight: "500",
                      }}
                      align="center"
                    >
                      <div
                        ref={contentRef}
                        style={{
                          maxHeight: clicked === user.id ? "none" : "54px",
                          overflow: "hidden",
                          transition: "max-height 0.3s ease",
                          textAlign: "center",
                        }}
                      >
                        <span>{user.content}</span>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            : null}
        </TableBody>
      </Table>
      <Typography
        sx={{
          m: "40px 0 20px 0",
          fontSize: "16px",
          color: "#3A3A3A",
          fontWeight: 700,
        }}
      >
        상담 작성
      </Typography>
      <Box
        sx={{
          backgroundColor: "#FCFCFC",
          border: "1px solid #E7E7E7",
          padding: "30px",
          borderRadius: "5px",
          mb: "80px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            mb: "20px",
            alignItems: "center",
            width: "auto",
          }}
        >
          <Button
            onClick={handleSubmit(submit)}
            size="small"
            sx={{
              fontSize: "13px",
              fontWeight: 700,
              height: "30px",
              padding: "7px 35px",
              marginLeft: "auto",
              color: "#fff",
              background: "#7653DF",
            }}
          >
            저장
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "auto",
          }}
        >
          <Typography
            style={{ fontSize: "14px", fontWeight: "500", minWidth: "75px" }}
          >
            상담 내용
          </Typography>
          <FormInputTextArea
            style={{
              width: "100%",
              background: "#fff",
              borderRadius: "5px",
              border: "1px solid #E7E7E7",
            }}
            minRows={3}
            control={control}
            name="content"
          />
        </Box>
      </Box>
    </Paper>
  );
}
