import React, { useState, lazy, Suspense, useEffect } from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import BackButton from "../../components/BackButton";
import LinkButton from "../../components/LinkButton";
import UserChanger from "../../components/UserChanger";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getEmployeeDataById,
  getUserById,
  statusChanger,
} from "../../api/user";
import UserStatusChanger from "./UserStatusChanger";
import AcceptModal from "../../components/modals/AcceptModal";
import UserDataExelDownloadSwitcher from "../../components/buttons/UserDataExelDownloadSwitcher";
import {
  awsImagePath,
  formatKoreanPhoneNumber,
  getAge,
} from "../../utils/methods";
import SmsSendModal from "../../components/modals/SmsSendModal";
import DataNotFound from "../../components/DataNotFound";
import Loader from "../../components/loader";
import CustomModal from "../../components/modals/CustomModal";
import UserSalaryAddModal from "../../components/modals/UserSalaryAddModal";

const UserTab1 = lazy(() => import("./UserTab1"));
const UserTab2 = lazy(() => import("./UserTab2"));
const UserTab3 = lazy(() => import("./UserTab3"));
const UserTab4 = lazy(() => import("./UserTab4"));
const UserTab5 = lazy(() => import("./UserTab5"));

function UserDetail({ initialTab }) {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [employee, setEmployee] = useState();
  const isPanding =
    user && user.status !== "PRE_RETIRED" && user.status !== "RETIRED";
  const { token } = useSelector((state) => state.user.user);
  const [modal, setModal] = useState({ isOpen: false, isOpen1: false });
  const [salaryModal, setSalaryModal] = useState({
    isOpen: false,
    closed: false,
  });
  const [selectedTab, setSelectedTab] = useState(initialTab ?? 2);
  const [previewImage, setPreviewImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const smsSend = () => {
    setModal({ isOpen1: true, user: user });
  };

  const handleChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  const getUser = async () => {
    setIsLoading(true);
    try {
      const { data } = await getUserById(
        id,
        state ? state.url : undefined,
        token
      );
      if (data) {
        setUser(data);
        setPreviewImage(data.profilePicture);
      } else {
        setNotFound(true);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const getEmployee = async () => {
    try {
      const { data } = await getEmployeeDataById(id, token);
      setEmployee(data);
    } catch (e) {
      console.log(e);
      setEmployee();
    }
  };

  const changeStatus = async () => {
    const data = {
      applicantId: user.id,
      status: modal.status,
    };

    try {
      await statusChanger(data, token);
      navigate(-1);
    } catch (err) {
      toast.error(err.response?.data.message);
    }
  };

  const cancelWaiting = async () => {
    const data = {
      applicantId: user.id,
      status: "SEEKER",
    };

    try {
      await statusChanger(data, token);
      navigate(-1);
    } catch (err) {
      toast.error(err.response?.data.message);
    }

    // statusChanger(data, token)
    //   .then(() => navigate(-1))
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const statusSwitcher = (value) => {
    switch (value) {
      case "SEEKER":
        return "구직자";
        break;
      case "PENDING":
        return "사원 대기자";
        break;
      case "APPROVED":
        return "사원";
        break;
      case "PRE_RETIRED":
        return "퇴사 대기자";
        break;
      case "RETIRED":
        return "퇴사자";
        break;
      default:
        return value;
    }
  };

  const handleTab = (tab) => {
    switch (tab) {
      case 1:
        return <UserTab1 user={user} token={token} />;
      case 2:
        return <UserTab2 user={user} />;
      case 3:
        return <UserTab3 user={user} setUser={setUser} />;
      case 4:
        return isPanding ? (
          <UserTab4
            salaryModal={salaryModal}
            setSalaryModal={setSalaryModal}
            user={user}
            employee={employee}
            token={token}
          />
        ) : (
          <Box
            sx={{
              borderTop: "1px solid #E7E7E7",
              width: "100%",
            }}
          >
            <Typography marginTop={2} textAlign="center">
              입사정보가 없습니다.
            </Typography>
          </Box>
        );
      case 5:
        return <UserTab5 user={user} token={token} />;
      default:
        return <UserTab1 user={user} />;
    }
  };

  useEffect(() => {
    getUser();
    getEmployee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (salaryModal.isOpen === false && salaryModal.closed === true) {
      getUser();
      getEmployee();
    }
  }, [salaryModal.closed]);

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Loader style={{ backgroundColor: "red" }} />
      </Box>
    );
  } else if (notFound) {
    return <DataNotFound />;
  } else if (user) {
    return (
      <div
        style={{
          background: "white",
          borderRadius: "5px",
          paddingTop: "10px",
          minHeight: "700px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            className="back-button"
            onClick={() => navigate(-1)}
            style={{
              textDecoration: "none",
              color: "#3a3a3a",
              display: "flex",
              padding: 3,
              minWidth: "unset",
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                fill="#987EE7"
              />
              <path
                d="M12 8L8 12L12 16"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 12H8"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <Typography
              sx={{
                fontSize: "18px",
                ml: "10px",
                fontWeight: "770",
                lineHeight: "1",
              }}
            >
              회원 정보
            </Typography>
          </Button>
          <>
            <UserChanger user={user} />
            <LinkButton link="/talent/all-user" label="목록으로" />
          </>
        </Box>
        <div style={{ margin: "16px 0 20px 0" }} />
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box
            sx={{
              minWidth: "200px",
              maxWidth: "200px",
              marginTop: "50px",
              paddingRight: "12px",
              borderRight: "1px solid #E7E7E7",
            }}
          >
            <label htmlFor="imageupload" style={{ width: "100%" }}>
              <Box
                position="relative"
                sx={{
                  "&:hover": { cursor: "pointer" },
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "240px",
                    background: "#D9D9D9",
                    borderRadius: "5px",
                    position: "relative",
                    overflow: "hidden",
                    marginBottom: "40px",
                  }}
                >
                  <img
                    src={
                      `${awsImagePath}/${user.profilePicture}` ??
                      URL.createObjectURL(previewImage)
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt="uploader"
                  />
                </div>
              </Box>
              {/* <input
                    type="file"
                    id="imageupload"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleUploadImage}
                  /> */}
            </label>
            <Typography
              sx={{
                mb: "20px",
                fontWeight: 700,
                fontSize: "16px",
              }}
            >
              {user.name}
            </Typography>
            <Grid container rowSpacing={2} sx={{ wordBreak: "break-all" }}>
              <Grid
                item
                xs={4}
                color="#6B7280"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                  width: "100%",
                }}
              >
                생년월일
              </Grid>
              <Grid
                item
                xs={8}
                color="#3A3A3A"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                {getAge(user.birthday)}
              </Grid>
              <Grid
                item
                xs={4}
                color="#6B7280"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                성별
              </Grid>
              <Grid
                item
                xs={8}
                color="#3A3A3A"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                {user.gender}
              </Grid>
              <Grid
                item
                xs={4}
                color="#6B7280"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                휴대폰
              </Grid>
              <Grid
                item
                xs={8}
                color="#3A3A3A"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                {user.phoneNumber && formatKoreanPhoneNumber(user.phoneNumber)}
              </Grid>
              <Grid
                item
                xs={4}
                color="#6B7280"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                이메일
              </Grid>
              <Grid
                item
                xs={8}
                color="#3A3A3A"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                {user.email}
              </Grid>
            </Grid>
            <Grid
              container
              rowSpacing={2}
              mt="19px"
              sx={{ wordBreak: "break-all" }}
            >
              <Grid item xs={12} textAlign="center" mb="19px">
                {selectedTab !== 2 && (
                  <Grid
                    sx={{
                      fontSize: "13px",
                      height: "30px",
                      backgroundColor: "#8E66FF",
                      color: "#FFFFFF",
                      borderRadius: "25px!important",
                      width: "135px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {statusSwitcher(user?.status)}
                  </Grid>
                )}
              </Grid>
              {selectedTab !== 2 &&
              user.status !== "SEEKER" &&
              user.status !== "RETIRED" ? (
                <>
                  <Grid item xs={4} color="#6B7280">
                    업체명
                  </Grid>
                  <Grid item xs={8} color="#3A3A3A">
                    {user.customerName}
                  </Grid>
                  <Grid item xs={4} color="#6B7280">
                    사번
                  </Grid>
                  <Grid item xs={8} color="#3A3A3A">
                    {user.employeeNumber ?? "-"}
                  </Grid>
                  <Grid item xs={4} color="#6B7280">
                    담당자
                  </Grid>
                  <Grid item xs={8} color="#3A3A3A">
                    {employee?.personInCharge || ""}
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Box>
          <Box
            sx={{
              width: "100%",
              pl: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <Tabs
                sx={{
                  "& .MuiTab-root.MuiTab-textColorSecondary": {
                    borderRadius: "5px 5px 0px 0px",
                    color: "#987EE7",
                    background: "#EEEAFB",
                    mr: "5px",
                    minHeight: "30px !important",
                    p: "0",
                    fontWeight: "500",
                    fontSize: "13px",
                  },
                  "& .MuiTabs-scroller": {
                    alignItems: "flex-end",
                    display: "flex",
                  },
                  "& .MuiTab-root.MuiTab-textColorSecondary.Mui-selected": {
                    background: "#987EE7",
                    color: "#fff",
                  },
                  "& .MuiTabs-indicator": {
                    display: "none",
                    color: "#E1E1E1",
                  },
                }}
                value={selectedTab}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab value={1} label="구직 현황" />
                <Tab value={2} label="이력 정보" />
                <Tab value={3} label="계정 정보" />
                <Tab
                  value={4}
                  label="입사 정보"
                  disabled={user.status === "SEEKER"}
                />
                <Tab
                  value={5}
                  label="퇴사 정보"
                  disabled={user.status === "SEEKER"}
                />
              </Tabs>
              <Box display="flex">
                {user && user.status === "PENDING" ? (
                  <Button
                    sx={{
                      fontSize: "13px",
                      lineHeight: "15px",
                      height: "30px ",
                      marginRight: "6px",
                      padding: "7px 20px",
                      border: "1px solid #7653DF",
                      color: "#7653DF",
                      fontWeight: 500,
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => cancelWaiting()}
                  >
                    입사 취소
                  </Button>
                ) : null}
                <Box display="flex" flexDirection="column">
                  <UserStatusChanger
                    user={user}
                    setModal={setModal}
                    modal={modal}
                  />
                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      onClick={smsSend}
                      sx={{
                        fontSize: "12px",
                        lineHeight: "15px",
                        height: "30px",
                        color: "#fff",
                        padding: "7px 20px",
                        marginRight: selectedTab !== 2 && "6px",
                        background: "#6B7280",
                        width: selectedTab === 2 && "162px",
                      }}
                    >
                      SMS
                    </Button>
                    {selectedTab !== 2 && (
                      <UserDataExelDownloadSwitcher
                        user={user}
                        token={token}
                        active={selectedTab}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <div
              style={{
                margin: "5px 0 40px 0",
              }}
            />
            <Suspense fallback={"Loading..."}>
              {handleTab(selectedTab)}
            </Suspense>
          </Box>
        </Box>
        <AcceptModal
          setModal={setModal}
          modal={modal}
          handleClick={changeStatus}
          title="회원의 상태를 변경 하시겠습니까?"
        />
        <SmsSendModal
          setModal={setModal}
          modal={{ ...modal, isOpen: modal.isOpen1 }}
          modalKey="isOpen1"
        />

        <CustomModal
          modal={{ isOpen: salaryModal.isOpen }}
          style={{
            width: "100%",
            maxWidth: "1430px",
            height: "90vh",
            padding: "40px",
          }}
        >
          <UserSalaryAddModal
            modal={salaryModal}
            token={token}
            setModal={setSalaryModal}
            modalKey="isOpen"
          />
        </CustomModal>
      </div>
    );
  }
}

export default UserDetail;
