import React from "react";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { FormInputText } from "../form-components/FormInputText";
import { useForm } from "react-hook-form";
import { createCode, updateCodeById } from "../../api/personalCodeManagement";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const initial = {
  isOpen: false,
  isOpen2: false,
  isAgree: false,
};

export default function CompanyJobModal({
  users,
  setUsers,
  setModal,
  modal,
  token,
}) {
  const { control, handleSubmit } = useForm({ defaultValues: modal.content });

  const submit = async (values) => {
    const newValues = { ...values, customerId: modal.customerId };
    try {
      if (modal.content) {
        const { data } = await updateCodeById(
          modal.url,
          { ...modal.content.id, ...newValues },
          token
        );
        setUsers({
          ...users,
          content: users.content.map((item) =>
            item.id === data.id ? data : item
          ),
        });
      } else {
        const { data } = await createCode(modal.url, newValues, token);
        setUsers({
          ...users,
          totalElements: users.totalElements + 1,
          content: [data, ...users.content],
        });
      }
    } catch (error) {
      if (
        error.response.data.message.startsWith("could not execute statement")
      ) {
        toast.error("코드는 중복될 수 없습니다!");
      } else {
        toast.error(error.response?.data.message);
      }
    }
    setModal(initial);
  };

  const deleteCode = () => {
    setModal({ ...modal, isDelete: true, deleteID: modal.content.id });
  };

  return (
    <Modal open={modal.isOpen}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "#fff",
          borderRadius: "10px",
          border: "none",

          width: "650px",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding="30px"
        >
          <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
            신규 생성 / 수정
          </Typography>
          <Button sx={{ minWidth: "unset" }} onClick={() => setModal(initial)}>
            <img src="/icons/close.svg" alt="close" />
          </Button>
        </Box>
        <Box padding="30px 30px 40px" borderTop="1px solid #E7E7E7">
          <Grid container rowSpacing={1}>
            <Grid item xs={3} fontWeight={500}>
              코드
            </Grid>
            <Grid item xs={9}>
              <FormInputText
                control={control}
                name="code"
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
            <Grid item xs={3} fontWeight={500}>
              등록명
            </Grid>
            <Grid item xs={9}>
              <FormInputText
                control={control}
                name="name"
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
          </Grid>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            borderTop="1px solid #e7e7e7"
            pt="25px"
            marginTop="200px"
          >
            {modal.content && (
              <Button
                onClick={deleteCode}
                size="small"
                sx={{
                  fontSize: "13px",
                  mr: "auto",
                  height: "30px",
                  padding: "7px 26px",
                  color: "#7653DF",
                  border: "1px solid #7653DF",
                }}
              >
                삭제
              </Button>
            )}
            <Button
              size="small"
              sx={{
                fontSize: "13px",
                mr: "8px",
                height: "30px",
                padding: "7px 26px",
                color: "#fff",
                background: "#98A6AD",
              }}
              onClick={() => setModal(initial)}
            >
              취소
            </Button>
            <Button
              onClick={handleSubmit(submit)}
              size="small"
              sx={{
                fontSize: "13px",
                width: "auto",
                height: "30px",
                padding: "7px 46px",
                color: "#fff",
                background: "#7653DF",
              }}
            >
              저장하기
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
