import React, { useState } from "react";
import { Box, Typography, Button, Grid, TextField } from "@mui/material";
import BackButton from "../../components/BackButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  getApprovedUserById,
  sendToPreRetired,
  statusChanger,
} from "../../api/user";
import { useSelector } from "react-redux";
import { FormInputText } from "../../components/form-components/FormInputText";
import { useForm } from "react-hook-form";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import { FormInputDate } from "../../components/form-components/FormInputDate";
import CustomCheckbox from "../../components/CustomCheckbox";
import { reasonOfRetired } from "../../constants/constants";
import UserChanger from "../../components/UserChanger";
import LinkButton from "../../components/LinkButton";
import { toast } from "react-toastify";
import { formatKoreanPhoneNumber } from "../../utils/methods";

export default function SendToPreRetired() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.user.user);
  const { handleSubmit, control, setValue, watch } = useForm();
  const [user, setUser] = useState();

  const getUser = async () => {
    try {
      const { data } = await getApprovedUserById(id, token);
      setUser(data);
    } catch (error) {
      console.log(error);
    }
  };

  const submit = async (values) => {
    const newValues = {
      ...values,
      applicantId: +id,
      status: "PRE_RETIRED",
      personInCharge: user.manager,
    };

    try {
      await sendToPreRetired(newValues, token);
      navigate(`/employees/getByApplicantId/${id}`);
    } catch (err) {
      toast.warning(err.response?.data.message);
    }

    // sendToPreRetired(newValues, token)
    //   .then(() => navigate(`/employees/getByApplicantId/${id}`))
    //   .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUser();
  }, [id]);

  return (
    user && (
      <div
        style={{
          background: "white",
          borderRadius: "5px",
          paddingTop: "10px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <BackButton text="인원 정보" />
          <UserChanger user={user} url="send-to-pre-retired" />
          <LinkButton
            link={`/employees/getByApplicantId/${id}`}
            label="뒤로가기"
          />
        </Box>
        <hr style={{ margin: "16px 0 40px 0" }} />
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              minWidth: "200px",
              maxWidth: "200px",
              paddingRight: "12px",
              borderRight: "1px solid #E7E7E7",
            }}
          >
            <label htmlFor="imageupload" style={{ width: "100%" }}>
              <Box
                position="relative"
                sx={{
                  "&:hover": { cursor: "pointer" },
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "240px",
                    background: "#D9D9D9",
                    borderRadius: "5px",
                    position: "relative",
                    overflow: "hidden",
                    marginBottom: "40px",
                  }}
                >
                  {user.profilePicture ? (
                    <img
                      src={user.profilePicture}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      alt="uploader"
                    />
                  ) : null}
                </div>
              </Box>
            </label>
            <Typography
              sx={{
                mb: "20px",
                fontWeight: 700,
                fontSize: "16px",
              }}
            >
              {user.name}
            </Typography>
            <Grid container rowSpacing={2} sx={{ wordBreak: "break-all" }}>
              <Grid
                item
                xs={4}
                color="#6B7280"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                생년월일
              </Grid>
              <Grid
                item
                xs={8}
                color="#3A3A3A"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                {user.birthday}
              </Grid>
              <Grid
                item
                xs={4}
                color="#6B7280"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                성별
              </Grid>
              <Grid
                item
                xs={8}
                color="#3A3A3A"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                {user.gender}
              </Grid>
              <Grid
                item
                xs={4}
                color="#6B7280"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                휴대폰
              </Grid>
              <Grid
                item
                xs={8}
                color="#3A3A3A"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                {user.phoneNumber && formatKoreanPhoneNumber(user.phoneNumber)}
              </Grid>
              <Grid
                item
                xs={4}
                color="#6B7280"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                이메일
              </Grid>
              <Grid
                item
                xs={8}
                color="#3A3A3A"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                {user.email}
              </Grid>
            </Grid>
            <Grid
              container
              rowSpacing={2}
              mt="19px"
              sx={{ wordBreak: "break-all" }}
            >
              <Grid item xs={12} textAlign="center" mb="19px">
                <Button
                  component={Link}
                  to="/talent/employee"
                  sx={{
                    fontSize: "13px",
                    height: "30px",
                    padding: "8px 26px",
                    color: "#fff",
                    backgroundColor: "#7653DF",
                    borderRadius: "15px!important",
                    width: "100%",
                  }}
                >
                  사원
                </Button>
              </Grid>
              <Grid item xs={4} color="#6B7280">
                업체명
              </Grid>
              <Grid item xs={8} color="#3A3A3A">
                {user.customerName}
              </Grid>
              <Grid item xs={4} color="#6B7280">
                사번
              </Grid>
              <Grid item xs={8} color="#3A3A3A">
                {user.employeeNumber}
              </Grid>
              <Grid item xs={4} color="#6B7280">
                담당자
              </Grid>
              <Grid item xs={8} color="#3A3A3A">
                {user.manager}
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              width: "100%",
              pl: "20px",
            }}
          >
            <Typography whiteSpace="nowrap" mr="32px" mb="15px">
              <b style={{ fontSize: "15px" }}>퇴사 정보</b>
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <Grid container rowSpacing={1} alignItems="center">
                  <Grid item xs={2} fontWeight="500">
                    퇴사일
                  </Grid>
                  <Grid item xs={4}>
                    <FormInputDate
                      name="quitDate"
                      control={control}
                      placeholder="YYYY/MM/DD"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            width: "127px",
                            background: "#fff",
                            "& .MuiSvgIcon-root": {
                              fill: "#987EE7 !important",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} />
                  <Grid item xs={2} fontWeight="500">
                    퇴사 사유
                  </Grid>
                  <Grid item xs={4}>
                    <FormInputDropdown
                      control={control}
                      options={reasonOfRetired}
                      name="reason"
                      sx={{
                        maxWidth: "127px",
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} />
                  <Grid item xs={2} fontWeight="500">
                    사직원
                  </Grid>
                  <Grid item xs={1}>
                    <CustomCheckbox
                      setValue={setValue}
                      name="employee"
                      checked={watch("contractPeriod")}
                    />
                  </Grid>
                  <Grid item xs={2} fontWeight="500">
                    사직원 접수
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} pl="28px">
                <Grid container rowSpacing={1} alignItems="center">
                  <Grid item xs={3} fontWeight="500">
                    퇴사 담당자
                  </Grid>
                  <Grid item xs={9}>
                    <Box display="flex" alignItems="center">
                      <FormInputText
                        control={control}
                        name="manager"
                        staticValue={user.manager}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3} fontWeight="500">
                    퇴사 사유 메모
                  </Grid>
                  <Grid item xs={9}>
                    <FormInputText control={control} name="reasonComment" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <hr style={{ margin: "600px 0 30px 0" }} />
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Button
                size="small"
                sx={{
                  display: "block",
                  fontSize: "12px",
                  lineHeight: "15px",
                  mr: "8px",
                  padding: "7px 26px",
                  color: "#fff",
                  background: "#98A6AD",
                }}
              >
                취소
              </Button>
              <Button
                onClick={handleSubmit(submit)}
                size="small"
                sx={{
                  fontSize: "12px",
                  lineHeight: "15px",
                  padding: "7px 50px",
                  display: "block",
                  color: "#fff",
                  backgroundColor: "#7653DF",
                }}
              >
                보내기
              </Button>
            </Box>
          </Box>
        </Box>
      </div>
    )
  );
}
