import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import BackButton from "../components/BackButton";
import LinkButton from "../components/LinkButton";
import { FormInputText } from "../components/form-components/FormInputText";
import { adminRating, usageStatus } from "../constants/constants";
import { useSelector } from "react-redux";
import { FormInputRadio } from "../components/form-components/FormInputRadio";
import MultiCheckBoxList from "./MultiCheckBoxList";
import { deleteById, updateEmployee } from "../api/employee";
import { toast } from "react-toastify";

function EmployeeForm({ employee, menus }) {
  const navigation = useNavigate();
  const { token } = useSelector((state) => state.user.user);
  const { handleSubmit, control, setValue, register } = useForm({
    defaultValues: employee,
  });
  const [password, setPassword] = useState({ value: "" });

  const submit = async (values) => {
    values["menuList"] = state;
    delete values["menuNames"];
    values["password"] = password.value;
    try {
      await updateEmployee(values, token);
      toast.success("완료 되었습니다.");
      navigation("/admin-role/employee-list");
    } catch (error) {
      console.log(error);
    }
  };

  const { menuNames } = employee;
  const [state, setState] = useState(menuNames || []);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    const index = state.findIndex((item) => {
      return item.id === parseInt(name.slice(-1), 10);
    });
    if (index !== -1) {
      const updatedState = [...state];
      updatedState[index] = { ...updatedState[index], checked: checked };
      setState(updatedState);
    }
  };

  const deleteEmployee = async () => {
    const result = await deleteById(employee.id, token);
    navigation("/admin-role/employee-list");
  };
  const onChangedPassword = (name) => (event) => {
    setPassword({ ...password, [name]: event.target.value });
  };

  return (
    <div
      style={{
        background: "white",
        borderRadius: "5px",
        paddingTop: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <BackButton text="관리자 정보" />
        <LinkButton link="/talent/number-contacts" label="목록보기" />
        {menus?.map((item, index) => {
          <h1>{item.title} aaa</h1>;
        })}
      </Box>
      <hr style={{ margin: "16px 0px 20px 0" }} />
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            width: "100%",
            pl: "20px",
            marginTop: "50px",
          }}
        >
          <Typography mb="30px" fontSize="16px" fontWeight="700">
            관리자 정보 상세
          </Typography>
          <Grid container>
            <Grid item xs={6}>
              <Grid container rowSpacing={1} alignItems="center">
                <Grid item mb="4px" xs={2} fontWeight="500">
                  관리자 코드
                </Grid>
                <Grid item mb="4px" xs={10}>
                  <FormInputText
                    InputProps={{ maxLength: 3 }}
                    size="1"
                    control={control}
                    name="adminCode"
                    placeholder="관리자 코드"
                    disabled={true}
                  />
                </Grid>
                <Grid item mb="4px" xs={2} fontWeight="500">
                  성명
                </Grid>
                <Grid item mb="4px" xs={10}>
                  <FormInputText
                    control={control}
                    name="name"
                    placeholder="내용을 입력해 주세요"
                  />
                </Grid>

                <Grid item mb="4px" xs={2} fontWeight="500">
                  비밀번호
                </Grid>
                <Grid item xs={10} mb="4px">
                  <FormInputText
                    control={control}
                    // name="password"
                    type="password"
                    placeholder="비밀번호"
                    onChange={onChangedPassword("value")}
                    staticValue={password.value}
                  />
                </Grid>

                <Grid item xs={2} fontWeight="500">
                  부서
                </Grid>
                <Grid item mb="4px" xs={10}>
                  <FormInputText
                    control={control}
                    name="department"
                    placeholder="엔터테인먼트 팀"
                  />
                </Grid>

                <Grid item xs={2} fontWeight="500">
                  사용 상태
                </Grid>
                <Grid item mb="4px" xs={10}>
                  <FormInputRadio
                    name="usageStatusType"
                    sx={{
                      minHeight: "30px",
                      marginRight: "38px",
                      span: { fontWeight: 500 },
                    }}
                    control={control}
                    options={usageStatus}
                    row
                  />
                </Grid>

                <Grid item xs={2} fontWeight="500">
                  접근 권한
                </Grid>
                <Grid item mb="4px" xs={10} display="flex">
                  <MultiCheckBoxList
                    state={state}
                    handleChange={handleChange}
                    // setState={setState}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* ehniih bagana ni --------------------------------------------------------------------------- end duuusaj bnaa */}

            <Grid item xs={6} pl="35px">
              <Grid container rowSpacing={1} alignItems="center">
                <Grid item xs={2} fontWeight="500">
                  주민번호
                </Grid>
                <Grid item mb="4px" xs={10}>
                  <FormInputText
                    control={control}
                    name="socialInsuranceNumber"
                    placeholder="주민번호"
                  />
                </Grid>

                <Grid item mb="4px" xs={2} fontWeight="500">
                  연락처
                </Grid>
                <Grid item mb="4px" xs={10}>
                  <Typography color="red" fontSize={12}>
                    핸드폰 번호는 반드시 "-" 제외하고 입력하세요!
                  </Typography>
                  <FormInputText
                    required={true}
                    size="10"
                    type="number"
                    control={control}
                    name="phoneNumber"
                    placeholder="전화 번호를 입력해 주세요"
                  />
                </Grid>

                <Grid item mb="4px" xs={2} fontWeight="500">
                  이메일
                </Grid>
                <Grid item mb="4px" xs={10}>
                  <FormInputText
                    type="email"
                    control={control}
                    name="email"
                    placeholder="이매일을 입력해 주세요"
                  />
                </Grid>

                <Grid item mb="4px" xs={2} fontWeight="500">
                  직급
                </Grid>
                <Grid item mb="4px" xs={10}>
                  <FormInputText
                    control={control}
                    name="rank"
                    placeholder="사원"
                  />
                </Grid>

                <Grid item xs={2} fontWeight="500">
                  등급
                </Grid>
                <Grid item mb="4px" xs={10}>
                  <FormInputRadio
                    name="adminRating"
                    sx={{
                      minHeight: "30px",
                      marginRight: "38px",
                      span: { fontWeight: 500 },
                    }}
                    control={control}
                    options={adminRating}
                    row
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <hr style={{ margin: "16px 0 40px 0" }} />
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Button
          size="small"
          sx={{
            display: "block",
            fontSize: "12px",
            lineHeight: "15px",
            mr: "8px",
            padding: "7px 26px",
            color: "#fff",
            background: "red",
            float: "left",
          }}
          onClick={() => {
            deleteEmployee();
          }}
        >
          Delete
        </Button>
        <Button
          size="small"
          sx={{
            display: "block",
            fontSize: "12px",
            lineHeight: "15px",
            mr: "8px",
            padding: "7px 26px",
            color: "#fff",
            background: "#98A6AD",
          }}
          onClick={() => {
            navigation("/admin-role/employee-list");
          }}
        >
          취소
        </Button>
        <Button
          size="small"
          onClick={handleSubmit(submit)}
          sx={{
            fontSize: "12px",
            lineHeight: "15px",
            padding: "7px 50px",
            display: "block",
            color: "#fff",
            backgroundColor: "#7653DF",
          }}
        >
          저장
        </Button>
      </Box>
    </div>
  );
}

export default EmployeeForm;
