import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Button,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Table,
  TableContainer,
  Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { FormInputText } from "../../components/form-components/FormInputText";
import { useForm } from "react-hook-form";
import { createCategories, getJobCategories } from "../../api/recruitment";
import AcceptModal from "../../components/modals/AcceptModal";
import { toast } from "react-toastify";

function SetUpCareerInsights() {
  const { token } = useSelector((state) => state.user.user);
  const { handleSubmit, control, reset, watch } = useForm();
  const [list, setList] = useState();
  const [modal, setModal] = useState({
    isOpen: false,
  });

  const firstNumber = Math.ceil(list?.length / 2);

  const submit = async (values) => {
    const { categories } = values;
    try {
      await createCategories(categories, token);
      setModal({ isOpen: false });
      toast.success("저장이 완료 되었습니다.");
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      const { data } = await getJobCategories(token);
      setList(data);
      reset({ categories: data });
    } catch (error) {
      console.log(error);
    }
  };

  const saveCategories = () => {
    setModal({ ...modal, isOpen: true });
  };

  const addItemToList = () => {
    setList([...list, { id: "", title: "" }]);
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div
      style={{
        padding: "10px 0",
        position: "relative",
      }}
    >
      <Typography whiteSpace="nowrap" mb="20px">
        <b style={{ fontSize: "18px" }}>키워드 설정</b>
      </Typography>
      <Typography
        whiteSpace="nowrap"
        lineHeight="25px"
        fontSize="12px"
        color="#EB5757"
        fontWeight={500}
      >
        *키워드는 10글자 내로 작성하여주시기 바랍니다. <br />
        *키워드 수정 시 이전 설정한 공고에도 수정한 키워드로 일괄 적용 됩니다.
      </Typography>
      <hr style={{ margin: "40px 0" }} />
      {/* <TableContainer
        className="table"
        sx={{
          border: "1px solid #ddd",
          borderBottom: "none",
        }}
      >
        <Table>
          <TableHead sx={{ background: "#F5F5F5", height: "70px" }}>
            <TableRow>
              <TableCell
                sx={{
                  borderRight: "1px solid #ddd",
                  whiteSpace: "nowrap",
                  width: "70px",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                align="center"
              >
                번호
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #ddd",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                align="center"
              >
                제목
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #ddd",
                  whiteSpace: "nowrap",
                  width: "70px",
                  fontWeight: 500,
                }}
                align="center"
              >
                번호
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                align="center"
              >
                제목
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.map((category, index) => {
              const firstNumber = Math.ceil(list?.length / 2);
              const secondNumber = list?.length - firstNumber;
              console.log(firstNumber, secondNumber);
              return (
                <TableRow key={index}>
                  {index < firstNumber && (
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        padding: "12px",
                        borderRight: "1px solid #ddd",
                      }}
                      align="center"
                    >
                      {index + 1}
                    </TableCell>
                  )}
                  {index < firstNumber && (
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        padding: "12px",
                        borderRight: "1px solid #ddd",
                      }}
                      align="center"
                    >
                      <FormInputText
                        sx={{ width: "480px" }}
                        control={control}
                        name={`categories[${index}].title`}
                        placeholder="내용을 입력해 주세요"
                      />
                    </TableCell>
                  )}

                  {index + firstNumber < list.length && (
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        padding: "12px",
                        borderRight: "1px solid #ddd",
                      }}
                      align="center"
                    >
                      {index + 1 + firstNumber}
                    </TableCell>
                  )}

                  {index + firstNumber < list.length && (
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        padding: "12px 0 12px 12px",
                      }}
                      align="center"
                    >
                      <FormInputText
                        sx={{ width: "480px" }}
                        control={control}
                        name={`categories[${index + firstNumber}].title`}
                        placeholder="내용을 입력해 주세요"
                      />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer> */}
      <Box
        sx={{
          background: "#F5F5F5",
          height: "70px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          border: "1px solid #ddd",
        }}
      >
        <Typography
          sx={{
            borderRight: "1px solid #ddd",
            whiteSpace: "nowrap",
            minWidth: "50px",
            fontSize: "13px",
            height: "70px",
            fontWeight: 500,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          align="center"
        >
          번호
        </Typography>
        <Typography
          sx={{
            borderRight: "1px solid #ddd",
            fontSize: "13px",
            fontWeight: 500,
            height: "70px",
            width: "50%",
            minWidth: "480px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          align="center"
        >
          제목
        </Typography>
        <Typography
          sx={{
            borderRight: "1px solid #ddd",
            whiteSpace: "nowrap",
            fontWeight: 500,
            height: "70px",
            minWidth: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          align="center"
        >
          번호
        </Typography>
        <Typography
          sx={{
            fontSize: "13px",
            fontWeight: 500,
            height: "70px",
            width: "50%",
            minWidth: "480px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          align="center"
        >
          제목
        </Typography>
      </Box>
      {list && (
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            {list.slice(0, firstNumber).map((data, index) => (
              <Box
                key={index}
                sx={{
                  textDecoration: "none",
                  height: "53px",
                  border: "1px solid #ddd",
                  borderTop: "none",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography
                  sx={{
                    textDecoration: "none",
                    padding: "12px",
                    borderRight: "1px solid #ddd",
                    width: "50px",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {index + 1}
                </Typography>
                <FormInputText
                  sx={{ width: "480px", paddingInline: "12px" }}
                  control={control}
                  name={`categories[${index}].title`}
                  placeholder="내용을 입력해 주세요"
                />
              </Box>
            ))}
          </Box>
          <Box sx={{ width: "100%" }}>
            {list.slice(firstNumber, list.length).map((data, index) => (
              <Box
                key={index + firstNumber} // Adjust key to avoid collisions with the first box
                sx={{
                  textDecoration: "none",
                  height: "53px",
                  border: "1px solid #ddd",
                  borderTop: "none",
                  borderLeft: "none",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography
                  sx={{
                    textDecoration: "none",
                    padding: "12px",
                    borderRight: "1px solid #ddd",
                    width: "50px",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {index + 1 + firstNumber}
                </Typography>
                <FormInputText
                  sx={{ width: "480px", paddingInline: "12px" }}
                  control={control}
                  name={`categories[${index + firstNumber}].title`} // Adjust index for the second slice
                  placeholder="내용을 입력해 주세요"
                />
              </Box>
            ))}
          </Box>
        </Box>
      )}

      <hr style={{ margin: "40px 0 40px 0" }} />
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          onClick={addItemToList}
          size="small"
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "8px 26px",
            color: "#FFF",
            backgroundColor: "#7653DF",
          }}
        >
          <AddIcon />
        </Button>
      </Box>
      <hr style={{ margin: "100px 0 40px 0" }} />
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Button
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "7px 30px",
            color: "#fff",
            background: "#98A6AD",
            mr: "6px",
          }}
        >
          취소
        </Button>
        <Button
          onClick={saveCategories}
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "7px 46px",
            color: "#fff",
            background: "#7653DF",
          }}
        >
          저장
        </Button>
      </Box>
      {modal.isOpen && (
        <AcceptModal
          setModal={setModal}
          modal={{ ...modal, isOpen: modal.isOpen }}
          handleClick={handleSubmit(submit)}
          modalKey="isOpen"
          title="코드를 저장 하시겠습니까?"
        />
      )}
    </div>
  );
}

export default SetUpCareerInsights;
