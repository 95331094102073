import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FormInputText } from "../form-components/FormInputText";
import { useForm } from "react-hook-form";
import { getWorkDepartments } from "../../api/personalCodeManagement";
import { FormInputDropdown } from "../form-components/FormInputDropdown";
import { pageSize, searchType } from "../../constants/constants";
import { customerSearch, customersAllSearchNoPage } from "../../api/customers";

const initial = {
  isOpen: false,
};

const initialQuery = {
  number: 1,
  size: 60,
  order: "DESC",
  searchType: "name",
  keyword: "",
};

export default function Companies({ setModal, modal, token }) {
  const ref = useRef(null);
  const { control, handleSubmit } = useForm({ defaultValues: modal });
  const [users, setUsers] = useState();
  const [query, setQuery] = useState({
    ...initialQuery,
    keyword: modal.keyword,
  });

  const handleChangePage = (_, value) => {
    setQuery({ ...query, number: value });
  };

  const submit = (values) => {
    setQuery({ ...query, keyword: values.keyword });
  };

  const getList = async () => {
    try {
      const { data } = await customersAllSearchNoPage(query, token);
      if (data) {
        setUsers(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getList();
  }, [query]);

  return (
    <Box ref={ref} sx={{ width: "100%", maxHeight: "100%", minHeight: "100%" }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb="30px"
      >
        <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
          회사선택
        </Typography>
        <Button sx={{ minWidth: "unset" }} onClick={() => setModal(initial)}>
          <img src="/icons/close.svg" alt="close" />
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography style={{ minWidth: "95px", fontWeight: "500" }}>
          검색
        </Typography>
        {/* <FormInputDropdown
          control={control}
          name="searchType"
          sx={{ width: "111px", marginRight: "10px" }}
          options={searchType}
        /> */}
        <FormInputText
          control={control}
          sx={{ width: "280px", marginRight: "6px" }}
          name="keyword"
          placeholder="내용을 입력해 주세요"
        />
        <Button
          onClick={handleSubmit(submit)}
          size="small"
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "7px 18px",
            color: "#7653DF",
            background: "#EEEAFB",
          }}
        >
          검색
        </Button>
      </Box>
      <hr style={{ margin: "10px 0" }} />
      {/* <FormInputDropdown
        control={control}
        staticValue={query.size}
        onChange={(e) => setQuery({ ...query, size: e.target.value })}
        sx={{ width: "90px", display: "flex", margin: "0 0 10px auto" }}
        options={pageSize}
      /> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          maxHeight: ref?.current?.clientHeight - 190,
        }}
      >
        <TableContainer
          className="table"
          sx={{
            border: "1px solid #E7E7E7",
            borderTop: "1px solid #98A6AD",
          }}
        >
          <Table>
            <TableHead sx={{ background: "#fcfcfc" }}>
              <TableRow>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  코드
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  업체명
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users && !users.empty
                ? users.content.slice(0, 20).map((user, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        role="checkbox"
                        key={index}
                        sx={{
                          "&:hover": {
                            color: "#5a5a5a !important",
                            backgroundColor: "rgba(142, 102, 255, 0.22)",
                          },
                          height: "40px",
                        }}
                      >
                        <TableCell
                          id={labelId}
                          onClick={() =>
                            setModal({
                              ...modal,
                              customer: user,
                              customerId: user.id,
                            })
                          }
                          scope="row"
                          padding="none"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          {user.code}
                        </TableCell>
                        <TableCell
                          id={labelId}
                          onClick={() =>
                            setModal({
                              ...modal,
                              customer: user,
                              customerId: user.id,
                            })
                          }
                          scope="row"
                          padding="none"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          {user.name}
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer
          className="table"
          sx={{
            border: "1px solid #E7E7E7",
            borderTop: "1px solid #98A6AD",
          }}
        >
          <Table>
            <TableHead sx={{ background: "#fcfcfc" }}>
              <TableRow>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  코드
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  업체명
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.content.length > 20 &&
                users.content.slice(20, 40).map((user, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      sx={{
                        "&:hover": {
                          color: "#5a5a5a !important",
                          backgroundColor: "rgba(142, 102, 255, 0.22)",
                        },
                        ".css-1d46hnn-MuiTableCell-root": {
                          padding: "1px",
                        },

                        height: "40px",
                      }}
                    >
                      <TableCell
                        id={labelId}
                        onClick={() =>
                          setModal({
                            ...modal,
                            customer: user,
                            customerId: user.id,
                          })
                        }
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.code}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        onClick={() =>
                          setModal({
                            ...modal,
                            customer: user,
                            customerId: user.id,
                          })
                        }
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.name}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer
          className="table"
          sx={{
            border: "1px solid #E7E7E7",
            borderTop: "1px solid #98A6AD",
          }}
        >
          <Table>
            <TableHead sx={{ background: "#fcfcfc" }}>
              <TableRow>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  코드
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  업체명
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.content.length > 40 &&
                users.content.slice(40).map((user, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      sx={{
                        "&:hover": {
                          color: "#5a5a5a !important",
                          backgroundColor: "rgba(142, 102, 255, 0.22)",
                        },
                        ".css-1d46hnn-MuiTableCell-root": {
                          padding: "1px",
                        },

                        height: "40px",
                      }}
                    >
                      <TableCell
                        id={labelId}
                        onClick={() =>
                          setModal({
                            ...modal,
                            customer: user,
                            customerId: user.id,
                          })
                        }
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.code}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        onClick={() =>
                          setModal({
                            ...modal,
                            customer: user,
                            customerId: user.id,
                          })
                        }
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.name}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TableContainer
          className="table"
          sx={{
            border: "1px solid #E7E7E7",
            borderTop: "1px solid #98A6AD",
          }}
        >
          <Table>
            <TableHead sx={{ background: "#fcfcfc" }}>
              <TableRow>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  코드
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  업체명
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.content.length > 60 &&
                users.content.slice(60).map((user, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      sx={{
                        "&:hover": {
                          color: "#5a5a5a !important",
                          backgroundColor: "rgba(142, 102, 255, 0.22)",
                        },
                        ".css-1d46hnn-MuiTableCell-root": {
                          padding: "1px",
                        },

                        height: "40px",
                      }}
                    >
                      <TableCell
                        id={labelId}
                        onClick={() =>
                          setModal({
                            ...modal,
                            customer: user,
                            customerId: user.id,
                          })
                        }
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.code}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        onClick={() =>
                          setModal({
                            ...modal,
                            customer: user,
                            customerId: user.id,
                          })
                        }
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.name}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer> */}
      </Box>
      <Stack
        spacing={1}
        sx={{
          width: "100%",
          mt: "20px",
          position: "absolute",
          bottom: "40px",
          left: "0",
          right: "0",
        }}
      >
        <Pagination
          count={Math.ceil(users?.totalElements / query.size) || 0}
          onChange={handleChangePage}
          page={query.number}
          color="primary"
          variant="outlined"
          shape="rounded"
          sx={{
            m: "0 auto",
            "& .MuiPaginationItem-root.MuiPaginationItem-page, .MuiPaginationItem-previousNext":
              { padding: "4px 20px !important" },
          }}
        />
      </Stack>
    </Box>
  );
}
