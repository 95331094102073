import mainCaller from "./mainCaller";

export async function getAllCertificate(data, token) {
  return await mainCaller(`certificate`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

// export async function getCertificateById(id, token) {
//   return await mainCaller(`certificate/getById/${id}`, "GET", null, {
//     Authorization: `Bearer ${token}`,
//   });
// }

// export async function createCertificate(data, token) {
//   return await mainCaller(`certificate/create`, "POST", data, {
//     "Content-type": "multipart/form-data",
//     Authorization: `Bearer ${token}`,
//   });
// }

export async function updateCertificate(data, token) {
  return await mainCaller(`certificate/changeStatus`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

// export async function deleteCertificateById(id, token) {
//   return await mainCaller(`certificate/delete/${id}`, "DELETE", null, {
//     Authorization: `Bearer ${token}`,
//   });
// }
