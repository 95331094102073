import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

const BootstrapInput = styled(InputBase)(() => ({
  "& .MuiInputBase-input": {
    position: "relative",
    border: "1px solid #E0E0E0",
    "&:focus": {
      borderColor: "none",
    },
  },
}));

export const FormInputDropdown = ({
  name = "name",
  control = {},
  sx,
  options,
  staticValue = options[0].value,
  disabled,
  onChange: onChangeProp, // rename to avoid conflicts with internal onChange
  placeholder,
  disabledItem = <div />,
}) => {
  const generateSingleOptions = (value) => {
    return options.map((option) => (
      <MenuItem
        key={option.value}
        selected={option.value === value}
        value={option.value}
      >
        {option.label}
      </MenuItem>
    ));
  };

  return (
    <FormControl variant="standard" sx={sx} size={"small"}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          const handleChange = (e) => {
            onChange(e);
            if (onChangeProp) {
              onChangeProp(e);
            }
          };

          return (
            <Select
              displayEmpty
              sx={{ textAlign: "start" }}
              placeholder={placeholder}
              onChange={handleChange}
              value={value ?? staticValue}
              disabled={disabled}
              input={<BootstrapInput />}
              key={value}
              IconComponent={(props) => (
                <img
                  {...props}
                  style={{ padding: "2px 12px 0 12px", cursor: "pointer" }}
                  src="/icons/chevron-down.svg"
                  alt="chevron-down"
                />
              )}
            >
              {disabledItem}
              {generateSingleOptions(value)}
            </Select>
          );
        }}
      />
    </FormControl>
  );
};
