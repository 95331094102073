import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import MainLayout from "../layouts/Sidebar";
import Login from "../pages/Login";
import UserDetail from "../pages/userDetails/UserDetail";
import JobSeeker from "../pages/talent-management/JobSeeker";
import OrderStatus from "../pages/job-management/OrderStatus";
import AllJobs from "../pages/job-management/AllJobs";
import SetUpCareerInsights from "../pages/job-management/SetUpCareerInsights";
import CURecruitment from "../pages/job-management/CURecruitment";
import EmployeeWaitingList from "../pages/talent-management/EmployeeWaitingList";
import Employee from "../pages/talent-management/Employee";
import WaitingList from "../pages/talent-management/WaitingList";
import Deceased from "../pages/talent-management/Deceased";
import CertificateApplicant from "../pages/talent-management/CertificateApplicant";
import NumberOfContacts from "../pages/talent-management/NumberOfContacts";
import OrderDetails from "../pages/job-management/OrderStatus/OrderDetails";
import OrderCreate from "../pages/job-management/OrderStatus/OrderCreate";
import Business from "../pages/account-management/Business";
import Ledger from "../pages/account-management/Ledger";
import Manager from "../pages/account-management/Manager";
import BusinessDetails from "../pages/account-management/BusinessDetails";
import ManagerDetails from "../pages/account-management/ManagerDetails";
import LedgerDetails from "../pages/account-management/LedgerDetails";
import ContactDetails from "../pages/talent-management/NumberOfContactsDetails";
import UserCreate from "../pages/userDetails/UserCreate";
import ContactCreate from "../pages/talent-management/NumberOfContactsDetails/UserCreate";
import SendToPanding from "../pages/send-to-panding";
import PersonnelManagement from "../pages/inter-management/personel-code-management";
import InterEmployeeManagement from "../pages/inter-management/employee-management";
import PayrollManagement from "../pages/payroll-management";
import PayrollDetails from "../pages/payroll-management/PayrollDetails";
import Notification from "../pages/post-notice-management/Notification";
import NoticeDetails from "../pages/post-notice-management/NoticeDetails";
import Banner from "../pages/post-notice-management/Banner";
import Popup from "../pages/post-notice-management/PopUp";
import Message from "../pages/post-notice-management/message";
import ReferenceRoom from "../pages/post-notice-management/ReferenceRoom";
import BannerDetails from "../pages/post-notice-management/BannerDetails";
import PopUpDetails from "../pages/post-notice-management/PopUpDetails";
import HHRequest from "../pages/account-management/HHRequest";
import HHRequestDetails from "../pages/account-management/HHRequestDetails";
import SendToPreRetired from "../pages/send-to-pre-retired";
import EmployeeList from "../pages/admin-role/EmployeeList";
import EmployeeCreate from "../pages/admin-role/EmployeeCreate";
import InterCreate from "../pages/userDetails/InterCreate";
import EmployeeDetails from "../pages/admin-role/DetailEmployee";
import HomePage from "../pages/Home";
import NotificationDetail from "../pages/post-notice-management/NotificationDetail";
import SmsList from "../pages/sms-managment/SmsList";
import SmsSent from "../pages/sms-managment/SmsSent";
import PayrollSearchApplicant from "../pages/payroll-management/PayrollSearchApplicant";
import PayrollDetailsApplicant from "../pages/payroll-management/PayrollDetailsApplicant";
import SupportStatus from "../pages/job-management/SupportStatus";
import ReferenceDetail from "../pages/post-notice-management/ReferenceDetail";
import Statistic from "../pages/admin-role/Statistic";

function Routing() {
  const user = useSelector((state) => state.user);
  const [menus, setMenus] = useState(user.user.menus || []);

  useEffect(() => {
    setMenus(user.user.menus);
  }, [user.user]);

  const smsPages = (
    <>
      <Route
        path="/sms-list/"
        element={
          <MainLayout>
            <SmsList />
          </MainLayout>
        }
      />
      <Route
        path="/sms-sent"
        element={
          <MainLayout>
            <SmsSent />
          </MainLayout>
        }
      />
    </>
  );

  const talentPages = (
    <>
      <Route
        path="/user-details/:id"
        element={
          <MainLayout>
            <UserDetail />
          </MainLayout>
        }
      />
      <Route
        path="/inter-user-details/:id"
        element={
          <MainLayout>
            <UserDetail initialTab={3} />
          </MainLayout>
        }
      />
      <Route
        path="/user-create"
        element={
          <MainLayout>
            <UserCreate />
          </MainLayout>
        }
      />
      <Route
        path="/talent/all-user"
        element={
          <MainLayout>
            <JobSeeker />
          </MainLayout>
        }
      />
      <Route
        path="/talent/number-contacts"
        element={
          <MainLayout>
            <NumberOfContacts />
          </MainLayout>
        }
      />
      <Route
        path="/talent/number-contacts/create"
        element={
          <MainLayout>
            <ContactCreate />
          </MainLayout>
        }
      />
      <Route
        path="/talent/number-contacts/:id"
        element={
          <MainLayout>
            <ContactDetails />
          </MainLayout>
        }
      />
      <Route
        path="/talent/employee-waiting-list"
        element={
          <MainLayout>
            <EmployeeWaitingList />
          </MainLayout>
        }
      />
      <Route
        path="/talent/employee-waiting-list/:id"
        element={
          <MainLayout>
            <UserDetail initialTab={4} />
          </MainLayout>
        }
      />
      <Route
        path="/talent/employee"
        element={
          <MainLayout>
            <Employee />
          </MainLayout>
        }
      />
      <Route
        path="/employees/getByApplicantId/:id"
        element={
          <MainLayout>
            <UserDetail initialTab={4} />
          </MainLayout>
        }
      />
      <Route
        path="/talent/waiting-list"
        element={
          <MainLayout>
            <WaitingList />
          </MainLayout>
        }
      />
      <Route
        path="/send-to-pending/:id"
        element={
          <MainLayout>
            <SendToPanding />
          </MainLayout>
        }
      />
      <Route
        path="/send-to-pre-retired/:id"
        element={
          <MainLayout>
            <SendToPreRetired />
          </MainLayout>
        }
      />
      <Route
        path="/talent/waiting-list/:id"
        element={
          <MainLayout>
            <UserDetail initialTab={5} />
          </MainLayout>
        }
      />
      <Route
        path="/talent/deceased"
        element={
          <MainLayout>
            <Deceased />
          </MainLayout>
        }
      />
      <Route
        path="/talent/deceased/:id"
        element={
          <MainLayout>
            <UserDetail initialTab={5} />
          </MainLayout>
        }
      />
      <Route
        path="/talent/deceased/:id"
        element={
          <MainLayout>
            <UserDetail initialTab={5} />
          </MainLayout>
        }
      />
      <Route
        path="/talent/certificate-applicant"
        element={
          <MainLayout>
            <CertificateApplicant />
          </MainLayout>
        }
      />
    </>
  );

  const jobPages = (
    <>
      {/* {job-management} */}
      <Route
        path="/job-management/set-up-career"
        element={
          <MainLayout>
            <SetUpCareerInsights />
          </MainLayout>
        }
      />
      <Route
        path="/job-management/reqirement-create"
        element={
          <MainLayout>
            <CURecruitment />
          </MainLayout>
        }
      />
      <Route
        path="/job-management/reqirement-update/:id"
        element={
          <MainLayout>
            <CURecruitment />
          </MainLayout>
        }
      />
      <Route
        path="/job-management/support-status/:id"
        element={
          <MainLayout>
            <SupportStatus />
          </MainLayout>
        }
      />
      <Route
        path="/job-management/order-status"
        element={
          <MainLayout>
            <OrderStatus />
          </MainLayout>
        }
      />
      <Route
        path="/job-management/order-status/:slug"
        element={
          <MainLayout>
            <OrderDetails />
          </MainLayout>
        }
      />
      <Route
        path="/job-management/order-create/:slug"
        element={
          <MainLayout>
            <OrderCreate />
          </MainLayout>
        }
      />
      <Route
        path="/job-management/order-details/:id"
        element={
          <MainLayout>
            <OrderCreate />
          </MainLayout>
        }
      />
      <Route
        path="/job-management/all-jobs"
        element={
          <MainLayout>
            <AllJobs />
          </MainLayout>
        }
      />
    </>
  );
  const accountPages = (
    <>
      {/* {account} */}
      <Route
        path="/account-management/business-management"
        element={
          <MainLayout>
            <Business />
          </MainLayout>
        }
      />
      <Route
        path="/account-management/business-manager"
        element={
          <MainLayout>
            <Manager />
          </MainLayout>
        }
      />
      <Route
        path="/account-management/business-management-ledger"
        element={
          <MainLayout>
            <Ledger />
          </MainLayout>
        }
      />
      <Route
        path="/account-management/business-management/create"
        element={
          <MainLayout>
            <BusinessDetails />
          </MainLayout>
        }
      />
      <Route
        path="/account-management/business-management/:id"
        element={
          <MainLayout>
            <BusinessDetails />
          </MainLayout>
        }
      />
      <Route
        path="/account-management/business-management-ledger/:id"
        element={
          <MainLayout>
            <LedgerDetails />
          </MainLayout>
        }
      />
      <Route
        path="/account-management/business-manager/:id"
        element={
          <MainLayout>
            <ManagerDetails />
          </MainLayout>
        }
      />
      <Route
        path="/account-management/hh-request"
        element={
          <MainLayout>
            <HHRequest />
          </MainLayout>
        }
      />
      <Route
        path="/account-management/hh-request/:id"
        element={
          <MainLayout>
            <HHRequestDetails />
          </MainLayout>
        }
      />
    </>
  );
  const codeManagment = (
    <>
      <Route
        path="/personel-code-management"
        element={
          <MainLayout>
            <PersonnelManagement />
          </MainLayout>
        }
      />
      <Route
        path="/inter/employee-management"
        element={
          <MainLayout>
            <InterEmployeeManagement />
          </MainLayout>
        }
      />
      <Route
        path="/inter-create"
        element={
          <MainLayout>
            <InterCreate />
          </MainLayout>
        }
      />
    </>
  );
  const payrollPages = (
    <>
      <Route
        path="/payroll-management"
        element={
          <MainLayout>
            <PayrollManagement />
          </MainLayout>
        }
      />
      <Route
        path="/payroll-management/:date"
        element={
          <MainLayout>
            <PayrollDetails />
          </MainLayout>
        }
      />
      <Route
        path="/payroll-applicant/:name/:date"
        element={
          <MainLayout>
            <PayrollDetailsApplicant />
          </MainLayout>
        }
      />
      <Route
        path="/payroll/search"
        element={
          <MainLayout>
            <PayrollSearchApplicant />
          </MainLayout>
        }
      />
    </>
  );
  const postPages = (
    <>
      <Route
        path="/post-notice/notification"
        element={
          <MainLayout>
            <Notification />
          </MainLayout>
        }
      />
      <Route
        path="/notification-create"
        element={
          <MainLayout>
            <NotificationDetail />
          </MainLayout>
        }
      />
      <Route
        path="/notification-details/:id"
        element={
          <MainLayout>
            <NotificationDetail />
          </MainLayout>
        }
      />
      <Route
        path="/post-notice/banner"
        element={
          <MainLayout>
            <Banner />
          </MainLayout>
        }
      />
      <Route
        path="/banner-create"
        element={
          <MainLayout>
            <BannerDetails />
          </MainLayout>
        }
      />
      <Route
        path="/banner-details/:id"
        element={
          <MainLayout>
            <BannerDetails />
          </MainLayout>
        }
      />
      <Route
        path="/post-notice/popup"
        element={
          <MainLayout>
            <Popup />
          </MainLayout>
        }
      />
      <Route
        path="/popup-details/:id"
        element={
          <MainLayout>
            <PopUpDetails />
          </MainLayout>
        }
      />
      <Route
        path="/popup-create"
        element={
          <MainLayout>
            <PopUpDetails />
          </MainLayout>
        }
      />
      <Route
        path="/post-notice/message"
        element={
          <MainLayout>
            <Message />
          </MainLayout>
        }
      />
      <Route
        path="/post-notice/references"
        element={
          <MainLayout>
            <ReferenceRoom />
          </MainLayout>
        }
      />
      <Route
        path="/references-details/:id"
        element={
          <MainLayout>
            <ReferenceDetail />
          </MainLayout>
        }
      />
      <Route
        path="/notice-details/:id"
        element={
          <MainLayout>
            <NoticeDetails />
          </MainLayout>
        }
      />
    </>
  );
  const adminPages = (
    <>
      {/* Admin Role  */}
      <Route
        path="/admin-role/employee-list"
        element={
          <MainLayout>
            <EmployeeList />
          </MainLayout>
        }
      />
      <Route
        path="/employee-create"
        element={
          <MainLayout>
            <EmployeeCreate />
          </MainLayout>
        }
      />
      <Route
        path="/employee-details/:id"
        element={
          <MainLayout>
            <EmployeeDetails />
          </MainLayout>
        }
      />
    </>
  );
  const statisticPages = (
    <>
      <Route
        path="/admin-role/statistic"
        element={
          <MainLayout>
            <Statistic />
          </MainLayout>
        }
      />
      <Route
        path="/admin-role/employee-list"
        element={
          <MainLayout>
            <EmployeeList />
          </MainLayout>
        }
      />
      <Route
        path="/employee-create"
        element={
          <MainLayout>
            <EmployeeCreate />
          </MainLayout>
        }
      />
      <Route
        path="/employee-details/:id"
        element={
          <MainLayout>
            <EmployeeDetails />
          </MainLayout>
        }
      />
    </>
  );
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/home"
          element={
            <MainLayout>
              <HomePage />
            </MainLayout>
          }
        />
        <Route path="/" element={<Login />} />
        {menus?.some((item) => item.id === 1 && item.name === "관리자 현황") &&
          adminPages}
        {menus?.some((item) => item.id === 1 && item.name === "관리자 현황") &&
          smsPages}{" "}
        {/* end angilald oruulj ugnu */}
        {menus?.some((item) => item.id === 2 && item.name === "인재 관리") &&
          talentPages}
        {menus?.some(
          (item) => item.id === 3 && item.name === "채용정보 관리"
        ) && jobPages}
        {menus?.some((item) => item.id === 4 && item.name === "거래처 관리") &&
          accountPages}
        {menus?.some((item) => item.id === 5 && item.name === "내부 관리") &&
          codeManagment}
        {menus?.some((item) => item.id === 6 && item.name === "급여 관리") &&
          payrollPages}
        {menus?.some(
          (item) => item.id === 7 && item.name === "게시/공지 관리"
        ) && postPages}
        {menus?.some((item) => item.id === 8 && item.name === "통계") &&
          statisticPages}
      </Routes>
    </BrowserRouter>
  );
}

export default Routing;
