import {
  Box,
  Button,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { pageSize } from "../../../constants/constants";
import AddIcon from "@mui/icons-material/Add";
import { FormInputDropdown } from "../../../components/form-components/FormInputDropdown";
import { useForm } from "react-hook-form";
import CompanyJobModal from "../../../components/modals/CompanyJobCRUDModal";
import { useEffect } from "react";
import {
  deleteCodeById,
  getCodeList,
} from "../../../api/personalCodeManagement";
import AcceptModal from "../../../components/modals/AcceptModal";
import { FormInputText } from "../../../components/form-components/FormInputText";

const initialQuery = {
  number: 1,
  size: 60,
  order: "DESC",
  sort: "id",
  keyword: "",
};

export default function ManagementDepartment({ token }) {
  const { control, reset, handleSubmit } = useForm();
  const [query, setQuery] = useState(initialQuery);
  const [modal, setModal] = useState({ isOpen: false, isOpen1: false });
  const [users, setUsers] = useState();

  const handleChangePage = (_, value) => {
    setQuery({ ...query, number: value });
  };

  const getList = async () => {
    try {
      const { data } = await getCodeList("manDepartments", query, token);
      setUsers(data);
    } catch (error) {
      console.log(error);
    }
  };

  const updateCode = (user) => {
    setModal({
      isOpen: true,
      url: "manDepartments/update",
      content: user,
    });
  };

  const deleteById = async () => {
    try {
      await deleteCodeById("manDepartments/deleteById", modal.deleteID, token);
      setModal({ isOpen1: false });
      getList();
    } catch (error) {
      console.log(error);
      if (
        (error.response.data.message =
          "could not execute statement; SQL [n/a]; constraint [null]; nested exception is org.hibernate.exception.ConstraintViolationException: could not execute statement")
      ) {
        alert("Can't delete using data");
        setModal({ isOpen1: false });
      }
    }
  };

  // const deleteCode = async (id) => {
  //   try {
  //     await deleteCodeById("manDepartments/deleteById", id, token);
  //     setUsers({
  //       ...users,
  //       totalElements: users.totalElements - 1,
  //       content: users.content.filter((user) => user.id !== id),
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const setInitial = () => {
    reset(initialQuery);
    setQuery(initialQuery);
  };

  const submit = (values) => {
    setQuery({ ...query, ...values });
  };

  const deleteCode = async (id) => {
    setModal({ ...modal, isOpen1: true, deleteID: id });
  };

  useEffect(() => {
    getList();
  }, [query]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          mb: "16px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography style={{ minWidth: "95px", fontWeight: "500" }}>
          검색
        </Typography>
        <FormInputText
          sx={{ width: "280px", marginRight: "6px" }}
          placeholder="내용을 입력해 주세요"
          control={control}
          name="keyword"
        />
        <Button
          size="small"
          onClick={handleSubmit(submit)}
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "7px 18px",
            color: "#7653DF",
            background: "#EEEAFB",
          }}
        >
          검색
        </Button>
        <Box
          onClick={setInitial}
          component={Link}
          to="#"
          size="small"
          sx={{
            fontSize: "13px",
            color: "#6B7280",
            ml: "17px",
            "&:hover": {
              color: "#8E66FF",
            },
          }}
        >
          검색조건 초기화
        </Box>
        <Button
          onClick={() =>
            setModal({
              isOpen: true,
              url: "manDepartments/create",
            })
          }
          size="small"
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "8px 26px",
            color: "#FFF",
            backgroundColor: "#7653DF",
            ml: "auto",
          }}
        >
          신규 생성 <AddIcon />
        </Button>
      </Box>
      <hr style={{ margin: "30px 0" }} />
      {/* <FormInputDropdown
        control={control}
        sx={{ width: "90px", display: "flex", margin: "0 0 10px auto" }}
        options={pageSize}
        staticValue={query.size}
        onChange={(e) => setQuery({ ...query, size: e.target.value })}
      /> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TableContainer
          className="table"
          sx={{
            border: "1px solid #E7E7E7",
            borderTop: "1px solid #98A6AD",
          }}
        >
          <Table>
            <TableHead sx={{ background: "#fcfcfc" }}>
              <TableRow>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  번호
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  코드
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  관리부서 명
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  수정
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.content?.slice(0, 20).map((user, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      sx={{
                        "&:hover": {
                          color: "#5a5a5a !important",
                          backgroundColor: "rgba(142, 102, 255, 0.22)",
                        },
                        ".css-1d46hnn-MuiTableCell-root": {
                          padding: "1px",
                        },
                        height: "40px",
                      }}
                    >
                      <TableCell
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.code}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.name}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            onClick={() => updateCode(user)}
                            sx={{
                              padding: "0",
                              minWidth: "unset",
                              color: "#4B5CF7",
                            }}
                          >
                            수정
                          </Button>
                          <font color="#4B5CF7">ㅣ</font>
                          <Button
                            onClick={() => deleteCode(user.id)}
                            sx={{
                              padding: "0",
                              minWidth: "unset",
                              color: "#4B5CF7",
                            }}
                          >
                            삭제
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer
          className="table"
          sx={{
            marginX: "10px",
            border: "1px solid #E7E7E7",
            borderTop: "1px solid #98A6AD",
          }}
        >
          <Table>
            <TableHead sx={{ background: "#fcfcfc" }}>
              <TableRow>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  번호
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  코드
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  관리부서 명
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  수정
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.content.length > 20 &&
                users.content.slice(20, 40).map((user, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      sx={{
                        "&:hover": {
                          color: "#5a5a5a !important",
                          backgroundColor: "rgba(142, 102, 255, 0.22)",
                        },
                        ".css-1d46hnn-MuiTableCell-root": {
                          padding: "1px",
                        },

                        height: "40px",
                      }}
                    >
                      <TableCell
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {index + 21}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.code}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.name}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            onClick={() => updateCode(user)}
                            sx={{
                              padding: "0",
                              minWidth: "unset",
                              color: "#4B5CF7",
                            }}
                          >
                            수정
                          </Button>
                          <font color="#4B5CF7">ㅣ</font>
                          <Button
                            onClick={() => deleteCode(user.id)}
                            sx={{
                              padding: "0",
                              minWidth: "unset",
                              color: "#4B5CF7",
                            }}
                          >
                            삭제
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer
          className="table"
          sx={{
            border: "1px solid #E7E7E7",
            borderTop: "1px solid #98A6AD",
          }}
        >
          <Table>
            <TableHead sx={{ background: "#fcfcfc" }}>
              <TableRow>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  번호
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  코드
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  관리부서 명
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  수정
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.content.length > 40 &&
                users.content.slice(40).map((user, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      sx={{
                        "&:hover": {
                          color: "#5a5a5a !important",
                          backgroundColor: "rgba(142, 102, 255, 0.22)",
                        },
                        ".css-1d46hnn-MuiTableCell-root": {
                          padding: "1px",
                        },

                        height: "40px",
                      }}
                    >
                      <TableCell
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {index + 41}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.code}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.name}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            onClick={() => updateCode(user)}
                            sx={{
                              padding: "0",
                              minWidth: "unset",
                              color: "#4B5CF7",
                            }}
                          >
                            수정
                          </Button>
                          <font color="#4B5CF7">ㅣ</font>
                          <Button
                            onClick={() => deleteCode(user.id)}
                            sx={{
                              padding: "0",
                              minWidth: "unset",
                              color: "#4B5CF7",
                            }}
                          >
                            삭제
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {[0, 1, 2].map((tableIndex) => (
          <TableContainer
            key={tableIndex}
            className="table"
            sx={{
              border: "1px solid #E7E7E7",
              borderTop: "1px solid #98A6AD",
              marginX: tableIndex !== 1 ? "10px" : 0,
            }}
          >
            <Table>
              <TableHead sx={{ background: "#fcfcfc" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    번호
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    코드
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    관리부서 명
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    수정
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users &&
                  users.content
                    ?.filter((user, i) => i % 3 === tableIndex)
                    .map((user, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          role="checkbox"
                          key={index}
                          sx={{
                            "&:hover": {
                              color: "#5a5a5a !important",
                              backgroundColor: "rgba(142, 102, 255, 0.22)",
                            },
                            ".css-1d46hnn-MuiTableCell-root": {
                              padding: "1px",
                            },

                            height: "40px",
                          }}
                        >
                          <TableCell
                            id={labelId}
                            scope="row"
                            padding="none"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                            align="center"
                          >
                            {tableIndex + 1 + index * 3}
                          </TableCell>
                          <TableCell
                            id={labelId}
                            scope="row"
                            padding="none"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                            align="center"
                          >
                            {user.code}
                          </TableCell>
                          <TableCell
                            id={labelId}
                            scope="row"
                            padding="none"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                            align="center"
                          >
                            {user.name}
                          </TableCell>
                          <TableCell
                            id={labelId}
                            scope="row"
                            padding="none"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                            align="center"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                onClick={() => updateCode(user)}
                                sx={{
                                  padding: "0",
                                  minWidth: "unset",
                                  color: "#4B5CF7",
                                }}
                              >
                                수정
                              </Button>
                              <font color="#4B5CF7">ㅣ</font>
                              <Button
                                onClick={() => deleteCode(user.id)}
                                sx={{
                                  padding: "0",
                                  minWidth: "unset",
                                  color: "#4B5CF7",
                                }}
                              >
                                삭제
                              </Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        ))}
      </Box> */}
      {users && (
        <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
          <Pagination
            count={Math.ceil(users.totalElements / query.size) || 0}
            onChange={handleChangePage}
            page={query.number}
            color="primary"
            variant="outlined"
            shape="rounded"
            sx={{
              m: "0 auto",
              "& .css-1k2i9p6-MuiButtonBase-root-MuiPaginationItem-root": {
                height: "20px",
                minWidth: "20px",
                backgroundColor: "#CCCCCC",
                color: "#fff",
              },
            }}
          />
        </Stack>
      )}
      {modal.isOpen && (
        <CompanyJobModal
          setUsers={setUsers}
          users={users}
          modal={modal}
          setModal={setModal}
          token={token}
        />
      )}
      {modal.isOpen1 && (
        <AcceptModal
          setModal={setModal}
          modal={{ ...modal, isOpen: modal.isOpen1 }}
          handleClick={deleteById}
          modalKey="isOpen1"
          title="코드를 삭제 하시겠습니까?"
        />
      )}
    </Box>
  );
}
