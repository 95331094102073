import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormInputText } from "../form-components/FormInputText";
import { FormInputDropdown } from "../form-components/FormInputDropdown";
import { situation, subCompanySitution } from "../../constants/constants";
import { useForm } from "react-hook-form";
import {
  createChildren,
  deleteChildrenById,
  updateChildren,
} from "../../api/customers";
import { getCoordinatesByAddress } from "../../api/nominatium";
import { toast } from "react-toastify";
import AcceptModal from "./AcceptModal";

const initial = {
  isOpen: false,
};

export default function SubCompanyModal({
  customer,
  setCustomer,
  setModal,
  modal,
  open,
  token,
}) {
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: modal.content,
  });
  const [subModal, setSubModal] = useState({
    isOpen: false,
  });

  const submit = async (values) => {
    delete values["accessType"];
    let checkField = true;
    for (const property in values) {
      if (values[property] === undefined || values[property] === "") {
        checkField = false;
        toast.warning("모든 영역을 작성해 주세요!");
        break;
      }
    }

    if (checkField) {
      try {
        if (modal.content) {
          const { data } = await updateChildren(
            { id: modal.content.id, ...values },
            token
          );
          setCustomer({
            ...customer,
            subCustomers: customer.subCustomers.map((item) =>
              item.id === data.id ? data : item
            ),
          });
        } else {
          const { data } = await createChildren(
            { customerId: customer.id, ...values },
            token
          );
          setCustomer({
            ...customer,
            subCustomers: [data, ...customer.subCustomers],
          });
        }
      } catch (error) {
        console.log(error);
      }
      setModal(initial);
    }
  };

  const deleteSubCustomer = async () => {
    setSubModal({ isOpen: true });
  };

  const deleteById = async () => {
    try {
      if (modal.content.id) {
        await deleteChildrenById(modal.content.id, token);
        setCustomer({
          ...customer,
          subCustomers: customer.subCustomers.filter(
            (item) => item.id !== modal.content.id
          ),
        });
        setModal(initial);
        toast.success("삭제가 완료 되었습니다.");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data.message);
    }
  };

  const handleComplete = async (data) => {
    setValue(
      "address",
      `${data.userSelectedType === "R" ? data.roadAddress : data.jibunAddress}${
        data.buildingName ? `, ${data.buildingName}` : ""
      }`
    );
    setValue("zipCode", data.zonecode);
  };

  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  return (
    <>
      <Modal open={modal.isOpen}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#fff",
            borderRadius: "10px",
            border: "none",
            width: "650px",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            padding="30px"
          >
            <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
              사업장 목록
            </Typography>
            <Button
              sx={{ minWidth: "unset" }}
              onClick={() => setModal(initial)}
            >
              <img src="/icons/close.svg" alt="close" />
            </Button>
          </Box>
          <Box padding="30px 30px 40px" borderTop="1px solid #E7E7E7">
            <Grid container rowSpacing={1}>
              <Grid item xs={3} fontWeight={500}>
                현장 코드
              </Grid>
              <Grid item xs={9}>
                <FormInputText
                  control={control}
                  name="code"
                  placeholder="내용을 입력해 주세요"
                />
              </Grid>
              <Grid item xs={3} fontWeight={500}>
                현장 명
              </Grid>
              <Grid item xs={9}>
                <FormInputText
                  control={control}
                  name="name"
                  placeholder="내용을 입력해 주세요"
                />
              </Grid>
              <Grid item xs={3} fontWeight={500}>
                연락처
              </Grid>
              <Grid item xs={9}>
                <FormInputText
                  control={control}
                  name="phone"
                  placeholder="내용을 입력해 주세요"
                />
              </Grid>
              <Grid item xs={3} fontWeight={500}>
                우편번호
              </Grid>
              <Grid item xs={9}>
                <Box display="flex" alignItems="center">
                  <FormInputText
                    control={control}
                    sx={{ maxWidth: "180px" }}
                    name="zipCode"
                    placeholder=""
                  />
                  <Button
                    onClick={handleClick}
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "12px",
                      marginLeft: "10px",
                      lineHeight: "15px",
                      padding: "8px 26px",
                      color: "#7653DF",
                      backgroundColor: "#EEEAFB",
                    }}
                  >
                    검색
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={3} fontWeight={500}>
                주소
              </Grid>
              <Grid item xs={9}>
                <FormInputText
                  control={control}
                  name="address"
                  placeholder="상세 주소를 입력해 주세요"
                />
              </Grid>
              <Grid item xs={3} fontWeight={500}>
                상세주소
              </Grid>
              <Grid item xs={9}>
                <FormInputText
                  control={control}
                  name="daddress"
                  placeholder="내용을 입력해 주세요"
                />
              </Grid>
              <Grid item xs={3} fontWeight={500}>
                클라이언트 담당자
              </Grid>
              <Grid item xs={9}>
                <FormInputText
                  control={control}
                  sx={{ maxWidth: "180px" }}
                  name="client"
                  placeholder="내용을 입력해 주세요"
                />
              </Grid>
              <Grid item xs={3} fontWeight={500}>
                사원 관리 담당자
              </Grid>
              <Grid item xs={9}>
                <Box display="flex" alignItems="center">
                  <FormInputText
                    control={control}
                    sx={{ maxWidth: "180px" }}
                    name="manager"
                    placeholder=""
                  />
                  {/* <Button
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "12px",
                      marginLeft: "10px",
                      lineHeight: "15px",
                      padding: "8px 26px",
                      color: "#7653DF",
                      backgroundColor: "#EEEAFB",
                    }}
                  >
                    검색
                  </Button> */}
                </Box>
              </Grid>
              <Grid item xs={3} fontWeight={500}>
                상태
              </Grid>
              <Grid item xs={9}>
                <FormInputDropdown
                  control={control}
                  name="situation"
                  sx={{
                    width: "100%",
                  }}
                  options={subCompanySitution}
                />
              </Grid>
            </Grid>

            <hr style={{ margin: "30px 0 20px" }} />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              mt="25px"
            >
              {modal.content && (
                <Button
                  onClick={deleteSubCustomer}
                  size="small"
                  sx={{
                    fontSize: "13px",
                    mr: "auto",
                    height: "30px",
                    padding: "7px 26px",
                    color: "#7653DF",
                    border: "1px solid #7653DF",
                  }}
                >
                  삭제
                </Button>
              )}
              <Button
                size="small"
                sx={{
                  fontSize: "13px",
                  mr: "8px",
                  height: "30px",
                  padding: "7px 26px",
                  color: "#fff",
                  background: "#98A6AD",
                }}
                onClick={() => setModal(initial)}
              >
                취소
              </Button>
              <Button
                onClick={handleSubmit(submit)}
                size="small"
                sx={{
                  fontSize: "13px",
                  width: "auto",
                  height: "30px",
                  padding: "7px 46px",
                  color: "#fff",
                  background: "#7653DF",
                }}
              >
                저장하기
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {subModal.isOpen && (
        <AcceptModal
          handleClick={deleteById}
          modal={{ ...subModal }}
          modalKey="subModal"
          setModal={setSubModal}
          title="삭제 하시겠습니까?"
        />
      )}
    </>
  );
}
