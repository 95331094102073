import React from "react";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import Tab4 from "./Tab4";
import Tab5 from "./Tab5";

export default function MessageSwitcher({
  selectedTab,
  control,
  setValue,
  terms,
}) {
  switch (selectedTab) {
    case 1:
      return <Tab1 data={terms[0]} control={control} setValue={setValue} />;
    case 2:
      return <Tab2 data={terms[1]} control={control} setValue={setValue} />;
    case 3:
      return <Tab3 data={terms[2]} control={control} setValue={setValue} />;
    case 4:
      return <Tab4 data={terms[3]} control={control} setValue={setValue} />;
    case 5:
      return <Tab5 data={terms[4]} control={control} setValue={setValue} />;
    default:
      return <Tab1 data={terms[0]} control={control} setValue={setValue} />;
  }
}
