import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Fade, Paper } from "@mui/material";
import { useDispatch } from "react-redux";
import Manager from "./Manager";
import Talent from "./Talent";
import Account from "./Account";
import { handleRoute, handleSection } from "../../utils/methods";
import { logoutUser, setMenuData } from "../../actions/user";
import InterManagement from "./InterManagement";
import PayrollManagement from "./PayrollManagement";
import PostingNoticeManagement from "./PostingNoticeManagement";
import AdminRole from "./AdminRole";
import { useSelector } from "react-redux";
import StatisticMenu from "./StatisticMenu";
import MailMenu from "./MailMenu";

const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(9)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(15)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    width: `calc(100% - ${120}px)`,
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MainLayout(props) {
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [expanded, setExpanded] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuArray, setMenuArray] = React.useState(user.user?.menus || []);
  const handleAccordion = React.useRef(() => {});

  handleAccordion.current = (accordion) => {
    switch (accordion) {
      case "panel1":
        navigate("/admin-role/employee-list");
        break;
      case "panel2":
        navigate("/talent/all-user");
        break;
      case "panel3":
        navigate("/job-management/order-status");
        break;
      case "panel4":
        navigate("/account-management/business-management");
        break;
      case "panel5":
        navigate("/personel-code-management");
        break;
      case "panel6":
        navigate("/payroll-management");
        break;
      case "panel7":
        navigate("/post-notice/notification");
        break;
      case "panel8":
        navigate("/sms-list");
        break;

      default:
        return null;
    }
  };

  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  React.useEffect(() => {
    handleAccordion.current(expanded);
  }, [expanded]);

  React.useEffect(() => {
    dispatch(setMenuData(open));
  }, [open]);

  return (
    <Box
      sx={{ display: "flex", position: "relative", minHeight: "100vh" }}
      className="font-face-layout"
    >
      <div>
        <CssBaseline />
        <AppBar
          position="fixed"
          className="no-print"
          open={open}
          color="inherit"
          sx={{
            px: "30px",
            boxShadow: "none",
            borderBottom: "1px solid #E7E7E7",
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: "20px 0 14px 0!important",
              height: "100%",
              alignItems: "center",
            }}
          >
            <Button
              sx={{
                position: "absolute",
                left: "-40px",
                top: "68px",
                minWidth: "unset",
                width: "24px",
                height: "24px",
                padding: "3px",
                borderRadius: "50%!important",
                transition: "transform 0.5s",
                backgroundColor: "#37404A",
                transform: open ? "rotate(0deg)" : "rotate(180deg)",
                img: {
                  filter: "brightness(0) invert(0.8)",
                },
                ":hover": {
                  backgroundColor: "#37404A!important",
                  img: {
                    filter: "unset",
                  },
                },
              }}
              onClick={() => setOpen(!open)}
            >
              <img src="/images/iconSideLeft.svg" alt="arrow" />
            </Button>
            <Typography
              sx={{ fontSize: "20px", fontWeight: "500", color: "#3A3A3A" }}
            >
              {handleSection(location.pathname)}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              <div>
                <Button
                  sx={{
                    fontSize: "14px",
                    color: "#4A4A4A",
                    fontWeight: "500",
                  }}
                >
                  통합관리자님
                </Button>
                <Menu
                  sx={{ borderRadius: "8px", mt: 2 }}
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleClose}
                  PaperProps={{
                    sx: {
                      borderRadius: "12px",
                      width: "135px",
                      boxShadow: "0 0 20px #33333326",
                      padding: "8px",
                      ml: -3,
                    },
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                    sx: { color: "#707070" },
                  }}
                >
                  <MenuItem
                    sx={{
                      "&:hover": {
                        background: "transparent",
                      },
                    }}
                    onClick={handleClose}
                  >
                    프로필
                  </MenuItem>
                  <MenuItem
                    sx={{
                      "&:hover": {
                        background: "transparent",
                      },
                    }}
                    onClick={handleClose}
                  >
                    이용현황
                  </MenuItem>
                  <MenuItem
                    sx={{
                      "&:hover": {
                        background: "transparent",
                      },
                    }}
                    onClick={handleClose}
                  >
                    설정
                  </MenuItem>
                  <MenuItem
                    sx={{
                      "&:hover": {
                        background: "transparent",
                      },
                    }}
                    onClick={handleClose}
                  >
                    1:1문의
                  </MenuItem>
                  <MenuItem
                    sx={{
                      "&:hover": {
                        background: "transparent",
                      },
                    }}
                    onClick={handleClose}
                  >
                    사용가이드
                  </MenuItem>
                  <Divider sx={{ color: "#e1e1e1" }} />
                  <MenuItem
                    sx={{
                      "&:hover": {
                        background: "transparent",
                      },
                    }}
                    component={Link}
                    onClick={() => {
                      dispatch(logoutUser());
                      handleClose();
                    }}
                    to="/"
                  >
                    로그아웃
                  </MenuItem>
                </Menu>
              </div>

              <Avatar
                sx={{ background: "#212B36" }}
                onMouseOver={handleClick}
                style={{ width: "46px", height: "46px" }}
              >
                <img src="/images/person.svg" alt="user admin" />
              </Avatar>
            </Stack>
          </Toolbar>
        </AppBar>
        <Drawer
          className="no-print"
          transitionDuration={{ enter: 500, exit: 1000 }}
          variant="permanent"
          open={open}
          sx={{
            "& .MuiDrawer-paperAnchorLeft ": {
              background: "#212B36",
              color: "#fff",
              height: "100vh",
            },
          }}
        >
          <DrawerHeader
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "40px 0 50px 0",
            }}
            className="header"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ marginLeft: "40px" }}>
                <img src="/images/hlogoh.svg" alt="logo" />
              </div>
              <Fade in={open}>
                <Paper sx={{ background: "transparent", boxShadow: "none" }}>
                  <Typography
                    variant="text"
                    noWrap
                    component="div"
                    sx={{
                      pl: "12px",
                      color: "#8E66FF",
                      fontSize: "24px",
                      letterSpacing: "1px",
                    }}
                  >
                    MEDIA <b style={{ fontSize: "24px" }}>DB</b>
                  </Typography>
                </Paper>
              </Fade>
            </div>
          </DrawerHeader>
          <div
            style={{ background: "#212B36", color: "#fff", padding: "0 40px" }}
          >
            {menuArray.some(
              (item) => item.id === 1 && item.name === "관리자 현황"
            ) && (
              <AdminRole
                setExpanded={setExpanded}
                expanded={expanded}
                open={open}
                handleChange={handleChange}
              />
            )}
            {menuArray.some(
              (item) => item.id === 2 && item.name === "인재 관리"
            ) && (
              <Talent
                setExpanded={setExpanded}
                expanded={expanded}
                open={open}
                handleChange={handleChange}
              />
            )}
            {menuArray.some(
              (item) => item.id === 3 && item.name === "채용정보 관리"
            ) && (
              <Manager
                setExpanded={setExpanded}
                expanded={expanded}
                open={open}
                handleChange={handleChange}
              />
            )}
            {menuArray.some(
              (item) => item.id === 4 && item.name === "거래처 관리"
            ) && (
              <Account
                setExpanded={setExpanded}
                expanded={expanded}
                open={open}
                handleChange={handleChange}
              />
            )}
            {menuArray.some(
              (item) => item.id === 5 && item.name === "내부 관리"
            ) && (
              <InterManagement
                setExpanded={setExpanded}
                expanded={expanded}
                open={open}
                handleChange={handleChange}
              />
            )}
            {menuArray.some(
              (item) => item.id === 6 && item.name === "급여 관리"
            ) && (
              <PayrollManagement
                setExpanded={setExpanded}
                expanded={expanded}
                open={open}
                handleChange={handleChange}
              />
            )}
            {menuArray.some(
              (item) => item.id === 7 && item.name === "게시/공지 관리"
            ) && (
              <PostingNoticeManagement
                setExpanded={setExpanded}
                expanded={expanded}
                open={open}
                handleChange={handleChange}
              />
            )}
            <MailMenu
              setExpanded={setExpanded}
              expanded={expanded}
              open={open}
              handleChange={handleChange}
            />
            {menuArray.some(
              (item) => item.id === 8 && item.name === "통계"
            ) && (
              <StatisticMenu
                setExpanded={setExpanded}
                expanded={expanded}
                open={open}
                handleChange={handleChange}
              />
            )}
          </div>
        </Drawer>
      </div>
      <Box
        component="main"
        sx={{
          width: "100%",
          p: "90px 30px 2% 0",
          position: "relative",
          height: "auto",
          backgroundColor: "#F9F8FE",
        }}
      >
        <Typography
          variant="h6"
          noWrap
          className="no-print"
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#6B7280",
            lineHeight: "24px",
            fontSize: "12px",
            padding: "0 30px",
          }}
        >
          {handleRoute(location.pathname) === "대시보드"
            ? ""
            : handleRoute(location.pathname)}
        </Typography>
        <Box
          m="10px 0 0 30px"
          borderRadius="8px"
          overflow="hidden"
          backgroundColor="white"
          border="1px solid #E7E7E7"
          // width="1280px"
          padding="30px 40px"
        >
          {props.children}
        </Box>
      </Box>
    </Box>
  );
}
