import { Box, Button, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import BackButton from "../../components/BackButton";
import { useNavigate, useParams } from "react-router-dom";
import LinkButton from "../../components/LinkButton";
import { FormInputText } from "../../components/form-components/FormInputText";
import { useForm } from "react-hook-form";
import CustomCheckbox from "../../components/CustomCheckbox";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import AcceptModal from "../../components/modals/AcceptModal";
import {
    checkBannerDupplicate,
    createBanner,
    deleteBannerById,
    getBannerById,
    updateBanner,
} from "../../api/banner";
import { bannerStatus } from "../../constants/constants";
import { awsImagePath } from "../../utils/methods";
import { toast } from "react-toastify";
import Loader from "../../components/loader";

const positionOption = [
    {
        label: "선택",
        value: "",
    },
    {
        label: "메인 01",
        value: 1,
    },
    {
        label: "메인 02",
        value: 2,
    },
    {
        label: "메인 03",
        value: 3,
    },
];

export default function BannerDetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { token } = useSelector((state) => state.user.user);
    const [banner, setBanner] = useState();
    const { control, setValue, handleSubmit, watch, reset } = useForm({
        defaultValues: banner || {},
    });
    const [image, setImage] = useState();
    const [isUploading, setIsUploading] = useState(false);
    const [modal, setModal] = useState({
        isOpen: false,
        isOpen2: false,
        content: "",
    });
    const [error, setError] = useState(null);

    const [bannerData, setBannerData] = useState();

    const submit = async (values) => {
        const data = {
            position: values.position,
            status: values.status,
        };
        const isActive = await checkBannerDupplicate(data, token);
        if (isActive) {
        }
        const createValue = {
            ...values,
            image: image,
            isButtonShow: values.isButtonShow || false,
        };

        const updateValue = {
            ...values,
            image: image,
            isButtonShow: values.isButtonShow || false,
            id: id,
        };

        if (!values.status) {
            setError("Need status!");
        } else if (!values.position) {
            setError("Need position!");
        } else if (!values.adminTitle) {
            setError("Need 제목 title!");
        } else if (!values.bannerHeaderText) {
            setError("Need header text!");
        } else if (!values.bannerTitle) {
            setError("Need banner title!");
        } else if (!values.bannerTitleColor) {
            setError("Need title color!");
        } else if (!values.bannerHeaderColor) {
            setError("Need header color!");
        } else if (!id && !image) {
            setError("Need image!");
        } else {
            if (isActive) {
                setModal({ isOpen2: true });
                if (banner) setBannerData(updateValue);
                else setBannerData(createValue);
            } else {
                setIsUploading(true);
                try {
                    if (banner) { 
                        await updateBanner(updateValue, token); 
                        toast.success("갱신 완료되었니다.");
                    }
                    else{
                        await createBanner(createValue, token);
                        toast.success("저장 완료되었습니다.");
                    } 
                        
                    navigate("/post-notice/banner");
                } catch (error) {
                    toast.error(error.response?.data.message);
                }
                setIsUploading(false);
            }
        }
    };

    const getBanner = async () => {
        try {
            const { data } = await getBannerById(id, token);
            setBanner(data);
            reset(data);
        } catch (error) {
            console.log(error);
        }
    };

    const deleteById = async () => {
        try {
            await deleteBannerById(modal.deleteID, token);
            setModal({ isOpen: false });
            navigate("/post-notice/banner");
        } catch (error) {
            console.log(error);
        }
    };

    const handleDeleteBanner = async () => {
        setModal({ ...modal, isOpen: true, deleteID: id });
    };

    useEffect(() => {
        if (error) {
            toast.warning(error);
        }
    }, [error]);

    useEffect(() => {
        if (id) {
            getBanner();
        }
    }, [id]);

    const AddBanner = async () => {
        console.log("hell0=========")
        try {
            setIsUploading(true);
            if (banner){
                await updateBanner(bannerData, token);
                toast.success("갱신 완료되었니다.");
            }
            else{
                await createBanner(bannerData, token);
                toast.success("저장 완료되었습니다.");
            }

            
            setModal({ isOpen2: false });
            navigate("/post-notice/banner");
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data.message);
        }
        setIsUploading(false);
    };

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <BackButton text="배너 상세" />
                <LinkButton label="목록보기" link="/post-notice/banner" />
            </Box>
            <hr style={{ margin: "16px 0 30px 0" }} />
            <Grid container>
                <Grid item xs={6}>
                    <Grid container rowSpacing={1} alignItems="center">
                        <Grid item xs={2} fontWeight="500">
                            상태
                        </Grid>
                        <Grid item xs={10}>
                            <FormInputDropdown
                                control={control}
                                name="status"
                                sx={{
                                    maxWidth: "180px",
                                    width: "100%",
                                }}
                                options={bannerStatus}
                            />
                        </Grid>
                        <Grid item xs={2} fontWeight="500">
                            위치
                        </Grid>
                        <Grid item xs={10}>
                            <FormInputDropdown
                                control={control}
                                name="position"
                                sx={{
                                    maxWidth: "180px",
                                    width: "100%",
                                }}
                                options={positionOption}
                            />
                        </Grid>
                        <Grid item xs={2} fontWeight="500">
                            제목
                        </Grid>
                        <Grid item xs={10}>
                            <FormInputText
                                control={control}
                                name="adminTitle"
                                placeholder="내용을 입력해 주세요"
                            />
                        </Grid>
                        <Grid item xs={2} fontWeight="500">
                            랜딩 링크
                        </Grid>
                        <Grid item xs={10}>
                            <FormInputText
                                control={control}
                                name="landingUrl"
                                placeholder="내용을 입력해 주세요"
                            />
                        </Grid>
                        <Grid item xs={2} fontWeight="500">
                            이미지 업로드
                        </Grid>
                        <Grid item xs={10}>
                            <Box display="flex" alignItems="center">
                                {!image && banner ? (
                                    <img
                                        src={`${awsImagePath}/${banner.bannerImg}`}
                                        style={{
                                            width: "80%",
                                            objectFit: "cover",
                                        }}
                                        alt="uploader"
                                    />
                                ) : (
                                    <FormInputText
                                        control={control}
                                        staticValue={image?.name ?? ""}
                                        name="image"
                                    />
                                )}
                                <Button
                                    sx={{
                                        whiteSpace: "nowrap",
                                        fontSize: "12px",
                                        marginLeft: "10px",
                                        lineHeight: "15px",
                                        padding: "8px 26px",
                                        color: "#7653DF",
                                        position: "relative",
                                        backgroundColor: "#EEEAFB",
                                    }}
                                >
                                    업로드
                                    <input
                                        type="file"
                                        onChange={(e) =>
                                            setImage(e.target.files[0])
                                        }
                                        accept=".jpg, .png"
                                        style={{
                                            position: "absolute",
                                            width: "100%",
                                            height: "100%",
                                            opacity: "0",
                                        }}
                                    />
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={2} fontWeight="500">
                            이미지 삽입 글
                        </Grid>
                        <Grid item xs={2} fontWeight="500" textAlign="center">
                            헤더
                        </Grid>
                        <Grid item xs={8}>
                            <Box display="flex" alignItems="center">
                                <FormInputText
                                    control={control}
                                    name="bannerHeaderText"
                                    placeholder="내용을 입력해 주세요"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={2} fontWeight="500" textAlign="center">
                            제목
                        </Grid>
                        <Grid item xs={8}>
                            <Box display="flex" alignItems="center">
                                <FormInputText
                                    control={control}
                                    name="bannerTitle"
                                    placeholder="내용을 입력해 주세요"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2} fontWeight="500">
                            버튼
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            fontWeight="500"
                            textAlign="center"
                            display="flex"
                            alignItems="center"
                        >
                            <CustomCheckbox
                                checked={watch("isButtonShow")}
                                onChange={(e) =>
                                    setValue("isButtonShow", e.target.checked)
                                }
                            />
                            노출
                        </Grid>
                        <Grid item xs={8}>
                            <Box display="flex" alignItems="center">
                                <FormInputText
                                    control={control}
                                    name="buttonText"
                                    placeholder="내용을 입력해 주세요"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container rowSpacing={1} alignItems="center">
                        <Grid
                            item
                            xs={12}
                            sx={{ opacity: "0", pointerEvents: "none" }}
                        >
                            <FormInputDropdown
                                control={control}
                                options={bannerStatus}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{ opacity: "0", pointerEvents: "none" }}
                        >
                            <FormInputDropdown
                                control={control}
                                options={bannerStatus}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={10}
                            fontWeight="500"
                            fontSize="12px"
                            pl="8px"
                        >
                            사용자에게 노출 되지 않습니다.
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            sx={{ opacity: "0", pointerEvents: "none" }}
                        >
                            <FormInputDropdown
                                control={control}
                                options={bannerStatus}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={10}
                            fontWeight="500"
                            fontSize="12px"
                            pl="8px"
                            lineHeight="17.3px"
                        >
                            [이미지 업로드 안내]
                            <br /> 메인 01: 700*500px 사이즈를 권장합니다.
                            <br /> 메인 02, 03: 340*500px 사이즈를 권장합니다.
                            <br /> jpg/png 파일만 등록가능합니다.
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            sx={{ opacity: "0", pointerEvents: "none" }}
                        >
                            <FormInputDropdown
                                control={control}
                                options={bannerStatus}
                            />
                        </Grid>
                        <Grid item xs={2} pl="8px">
                            <FormInputText
                                control={control}
                                name="bannerHeaderColor"
                                type="color"
                            />
                        </Grid>
                        <Grid item xs={10} />
                        <Grid item xs={2} pl="8px">
                            <FormInputText
                                control={control}
                                name="bannerTitleColor"
                                type="color"
                            />
                        </Grid>
                        <Grid item xs={10} />
                    </Grid>
                </Grid>
            </Grid>
            <hr style={{ margin: "300px 0 20px" }} />
            <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                mt="25px"
            >
                {banner && (
                    <Button
                        onClick={() => handleDeleteBanner()}
                        size="small"
                        sx={{
                            fontSize: "13px",
                            width: "auto",
                            height: "30px",
                            marginRight: "auto",
                            border: "1px solid #7653DF",
                            padding: "8px 26px",
                        }}
                    >
                        삭제
                    </Button>
                )}
                <Button
                    onClick={() => navigate("/post-notice/banner")}
                    size="small"
                    sx={{
                        fontSize: "13px",
                        mr: "8px",
                        height: "30px",
                        padding: "7px 26px",
                        color: "#fff",
                        background: "#98A6AD",
                    }}
                >
                    취소
                </Button>
                {isUploading ? (
                    <Button>
                        <Loader style={{ backgroundColor: "red" }} />
                    </Button>
                ) : (
                    <Button
                        onClick={handleSubmit(submit)}
                        size="small"
                        sx={{
                            fontSize: "13px",
                            width: "auto",
                            height: "30px",
                            padding: "7px 46px",
                            color: "#fff",
                            background: "#7653DF",
                        }}
                    >
                        저장
                    </Button>
                )}
            </Box>
            {modal.isOpen && (
                <AcceptModal
                    setModal={setModal}
                    modal={{ ...modal, isOpen: modal.isOpen }}
                    handleClick={deleteById}
                    modalKey="isOpen"
                    title="삭제 하시겠습니까?"
                />
            )}

            {modal.isOpen2 && (
                <AcceptModal
                    buttonLabel="확인"
                    setModal={setModal}
                    modal={{ ...modal, isOpen: modal.isOpen2 }}
                    handleClick={AddBanner}
                    modalKey="isOpen2"
                    title={`중복 위치에 배너가 ${bannerData.position} 있습니다. 정말 게시할 겁니까?`}
                />
            )}
        </Box>
    );
}
