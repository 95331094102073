import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Modal, Typography, Grid } from "@mui/material";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { FormInputText } from "../form-components/FormInputText";
import { FormInputDropdown } from "../form-components/FormInputDropdown";
import { yearsNumber, months } from "../../constants/constants";
import * as XLSX from "xlsx"; // Import xlsx library
import { useNavigate } from "react-router-dom";
import Loader from "../loader";
import { createPayroll } from "../../api/payroll";
import { addZero } from "../../utils/methods";

const date = new Date();
const startYear = date.getFullYear();
const startMonth = date.getMonth() + 1;

export default function PayrollAddModal({
  modal,
  setModal,
  style = {},
  modalKey = "isOpen",
  //   handleClick = () => {},
}) {
  const close = () => {
    setModal((prevModal) => ({
      ...prevModal,
      [modalKey]: false,
    }));
  };

  const { handleSubmit, control, reset, setValue, watch } = useForm({});
  const { token } = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(null);

  const readExcelFile = async (file) => {
    const translationMapping = {
      계산식: "calculationFormula",
      금액: "amount",
      사원코드: "employeeNumber",
      사원명: "employeeName",
      "지급/공제항목명": "itemName",
      "지급/공제항목코드": "itemCode",
    };

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const jsonData = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheetName],
            {
              header: 1,
              defval: "",
              raw: false,
              range: 0,
            }
          );

          //header row
          const translatedHeaders = jsonData[0].map(
            (header) => translationMapping[header] || header
          );

          //data rows
          const translatedData = jsonData.slice(1).map((row) => {
            const translatedRow = {};
            row.forEach((cell, index) => {
              const fieldName = translatedHeaders[index];
              const fieldValue =
                fieldName === "amount"
                  ? parseInt(cell.replace(/,/g, ""), 10)
                  : cell;
              translatedRow[fieldName] = fieldValue;
            });
            return translatedRow;
          });

          resolve(translatedData);
        } catch (error) {
          reject(error);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const handleExcel = async (file, index) => {
    setUploadLoading(index);
    setValue(`data[${index}].name`, file.name);
    const jsonData = await readExcelFile(file);
    if (jsonData) {
      setValue(`data[${index}].file`, jsonData);
      setUploadLoading(null);
    }
  };

  const thisYear = new Date().getFullYear();

  const submit = async (values) => {
    const newValues = [];
    const newFile = [];
    values.data?.map((data) => {
      data.file?.map((item) => {
        const object = {
          ...item,
          companyName: data.companyName,
          dateString: `${data.year || thisYear}-${addZero(data.month) || "01"}`,
        };
        return newFile.push(object);
      });
      if (data.file && !data.companyName) {
        toast.warning("Enter company name");
      } else if (data.file) {
        return newValues.push({ file: newFile });
      }
    });
    if (newValues.length > 0) {
      setIsLoading(true);
      try {
        const res = await createPayroll(JSON.stringify(newValues), token);
        if (res) {
          toast.success(res.message);
          setIsLoading(false);
          close();
        }
      } catch (e) {
        console.log("error===", e);
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal open={modal.isOpen}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          padding: "20px 0px 40px",
          width: 650,
          bgcolor: "#fff",
          boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.10)",
          borderRadius: "10px",
          border: "none",
          ...style,
        }}
      >
        <Box paddingInline="25px" display="flex" justifyContent="space-between">
          <Typography
            sx={{
              fontSize: "18px",
              color: "#3A3A3A",
              fontWeight: "bold",
            }}
          >
            급여 일괄등록
          </Typography>
          <Button sx={{ minWidth: "unset" }} onClick={close}>
            <img src="/icons/close.svg" alt="close" />
          </Button>
        </Box>

        <div
          style={{
            width: "100%",
            height: 1,
            backgroundColor: "#E7E7E7",
            marginTop: 30,
          }}
        />

        <Box paddingInline="25px" marginTop={3}>
          <Box
            sx={{
              bgcolor: "#F1F1F1",
              padding: "10px",
              borderRadius: "15px",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid color="#5A5A5A" fontSize={13} item xs={2} fontWeight="500">
                업체명
              </Grid>
              <FormInputText
                name="data[0].companyName"
                placeholder="내용을 입력하여 주세요"
                control={control}
                sx={{ width: 180, height: 30, backgroundColor: "white" }}
              />
              <Grid color="#5A5A5A" fontSize={13} item xs={2} fontWeight="500">
                급여년월
              </Grid>
              <Box display="flex" alignItems="center">
                <FormInputDropdown
                  control={control}
                  name={`data[0].year`}
                  options={yearsNumber()}
                  sx={{
                    minWidth: "70px",
                  }}
                />
                <FormInputDropdown
                  control={control}
                  name={`data[0].month`}
                  options={months}
                  sx={{
                    minWidth: "60px",
                    marginLeft: "10px",
                  }}
                />
              </Box>
            </Box>
            <Box
              marginTop={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid
                whiteSpace="nowrap"
                color="#5A5A5A"
                fontSize={13}
                item
                xs={2}
                fontWeight="500"
              >
                첨부파일
              </Grid>
              <Button
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: "12px",
                  marginLeft: "10px",
                  lineHeight: "15px",
                  padding: "8px 26px",
                  color: "#7653DF",
                  position: "relative",
                  backgroundColor: "#EEEAFB",
                }}
              >
                업로드
                <input
                  type="file"
                  onChange={(e) => handleExcel(e.target.files[0], 0)}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    opacity: "0",
                  }}
                />
              </Button>
              {uploadLoading === 0 ? (
                <Box sx={{ width: 406 }}>
                  <Loader style={{ backgroundColor: "red" }} />
                </Box>
              ) : (
                <FormInputText
                  control={control}
                  sx={{ backgroundColor: "white", width: 406 }}
                  staticValue={watch("data[0]")?.name || ""}
                  name="data[0].file"
                />
              )}
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: "#F1F1F1",
              padding: "10px",
              borderRadius: "15px",
              marginTop: 2,
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid color="#5A5A5A" fontSize={13} item xs={2} fontWeight="500">
                업체명
              </Grid>
              <FormInputText
                name="data[1].companyName"
                placeholder="내용을 입력하여 주세요"
                control={control}
                sx={{ width: 180, height: 30, backgroundColor: "white" }}
              />
              <Grid color="#5A5A5A" fontSize={13} item xs={2} fontWeight="500">
                급여년월
              </Grid>
              <Box display="flex" alignItems="center">
                <FormInputDropdown
                  control={control}
                  name={`data[1].year`}
                  options={yearsNumber()}
                  sx={{
                    minWidth: "70px",
                  }}
                />
                <FormInputDropdown
                  control={control}
                  name={`data[1].month`}
                  options={months}
                  sx={{
                    minWidth: "60px",
                    marginLeft: "10px",
                  }}
                />
              </Box>
            </Box>
            <Box
              marginTop={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid
                whiteSpace="nowrap"
                color="#5A5A5A"
                fontSize={13}
                item
                xs={2}
                fontWeight="500"
              >
                첨부파일
              </Grid>
              <Button
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: "12px",
                  marginLeft: "10px",
                  lineHeight: "15px",
                  padding: "8px 26px",
                  color: "#7653DF",
                  position: "relative",
                  backgroundColor: "#EEEAFB",
                }}
              >
                업로드
                <input
                  type="file"
                  onChange={(e) => handleExcel(e.target.files[0], 1)}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    opacity: "0",
                  }}
                />
              </Button>
              {uploadLoading === 1 ? (
                <Box sx={{ width: 406 }}>
                  <Loader style={{ backgroundColor: "red" }} />
                </Box>
              ) : (
                <FormInputText
                  control={control}
                  sx={{ backgroundColor: "white", width: 406 }}
                  staticValue={watch("data[1]")?.name || ""}
                  name="data[1].file"
                />
              )}
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: "#F1F1F1",
              padding: "10px",
              borderRadius: "15px",
              marginTop: 2,
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid color="#5A5A5A" fontSize={13} item xs={2} fontWeight="500">
                업체명
              </Grid>
              <FormInputText
                name="data[2].companyName"
                placeholder="내용을 입력하여 주세요"
                control={control}
                sx={{ width: 180, height: 30, backgroundColor: "white" }}
              />
              <Grid color="#5A5A5A" fontSize={13} item xs={2} fontWeight="500">
                급여년월
              </Grid>
              <Box display="flex" alignItems="center">
                <FormInputDropdown
                  control={control}
                  name={`data[2].year`}
                  options={yearsNumber()}
                  sx={{
                    minWidth: "70px",
                  }}
                />
                <FormInputDropdown
                  control={control}
                  name={`data[2].month`}
                  options={months}
                  sx={{
                    minWidth: "60px",
                    marginLeft: "10px",
                  }}
                />
              </Box>
            </Box>
            <Box
              marginTop={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid
                whiteSpace="nowrap"
                color="#5A5A5A"
                fontSize={13}
                item
                xs={2}
                fontWeight="500"
              >
                첨부파일
              </Grid>
              <Button
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: "12px",
                  marginLeft: "10px",
                  lineHeight: "15px",
                  padding: "8px 26px",
                  color: "#7653DF",
                  position: "relative",
                  backgroundColor: "#EEEAFB",
                }}
              >
                업로드
                <input
                  type="file"
                  onChange={(e) => handleExcel(e.target.files[0], 2)}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    opacity: "0",
                  }}
                />
              </Button>
              {uploadLoading === 2 ? (
                <Box sx={{ width: 406 }}>
                  <Loader style={{ backgroundColor: "red" }} />
                </Box>
              ) : (
                <FormInputText
                  control={control}
                  sx={{ backgroundColor: "white", width: 406 }}
                  staticValue={watch("data[2]")?.name || ""}
                  name="data[2].file"
                />
              )}
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: "#F1F1F1",
              padding: "10px",
              borderRadius: "15px",
              marginTop: 2,
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid color="#5A5A5A" fontSize={13} item xs={2} fontWeight="500">
                업체명
              </Grid>
              <FormInputText
                name="data[3].companyName"
                placeholder="내용을 입력하여 주세요"
                control={control}
                sx={{ width: 180, height: 30, backgroundColor: "white" }}
              />
              <Grid color="#5A5A5A" fontSize={13} item xs={2} fontWeight="500">
                급여년월
              </Grid>
              <Box display="flex" alignItems="center">
                <FormInputDropdown
                  control={control}
                  name={`data[3].year`}
                  options={yearsNumber()}
                  sx={{
                    minWidth: "70px",
                  }}
                />
                <FormInputDropdown
                  control={control}
                  name={`data[3].month`}
                  options={months}
                  sx={{
                    minWidth: "60px",
                    marginLeft: "10px",
                  }}
                />
              </Box>
            </Box>
            <Box
              marginTop={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid
                whiteSpace="nowrap"
                color="#5A5A5A"
                fontSize={13}
                item
                xs={2}
                fontWeight="500"
              >
                첨부파일
              </Grid>
              <Button
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: "12px",
                  marginLeft: "10px",
                  lineHeight: "15px",
                  padding: "8px 26px",
                  color: "#7653DF",
                  position: "relative",
                  backgroundColor: "#EEEAFB",
                }}
              >
                업로드
                <input
                  type="file"
                  onChange={(e) => handleExcel(e.target.files[0], 3)}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    opacity: "0",
                  }}
                />
              </Button>
              {uploadLoading === 3 ? (
                <Box sx={{ width: 406 }}>
                  <Loader style={{ backgroundColor: "red" }} />
                </Box>
              ) : (
                <FormInputText
                  control={control}
                  sx={{ backgroundColor: "white", width: 406 }}
                  staticValue={watch("data[3]")?.name || ""}
                  name="data[3].file"
                />
              )}
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: "#F1F1F1",
              padding: "10px",
              borderRadius: "15px",
              marginTop: 2,
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid color="#5A5A5A" fontSize={13} item xs={2} fontWeight="500">
                업체명
              </Grid>
              <FormInputText
                name="data[4].companyName"
                placeholder="내용을 입력하여 주세요"
                control={control}
                sx={{ width: 180, height: 30, backgroundColor: "white" }}
              />
              <Grid color="#5A5A5A" fontSize={13} item xs={2} fontWeight="500">
                급여년월
              </Grid>
              <Box display="flex" alignItems="center">
                <FormInputDropdown
                  control={control}
                  name={`data[4].year`}
                  options={yearsNumber()}
                  sx={{
                    minWidth: "70px",
                  }}
                />
                <FormInputDropdown
                  control={control}
                  name={`data[4].month`}
                  options={months}
                  sx={{
                    minWidth: "60px",
                    marginLeft: "10px",
                  }}
                />
              </Box>
            </Box>
            <Box
              marginTop={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid
                whiteSpace="nowrap"
                color="#5A5A5A"
                fontSize={13}
                item
                xs={2}
                fontWeight="500"
              >
                첨부파일
              </Grid>
              <Button
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: "12px",
                  marginLeft: "10px",
                  lineHeight: "15px",
                  padding: "8px 26px",
                  color: "#7653DF",
                  position: "relative",
                  backgroundColor: "#EEEAFB",
                }}
              >
                업로드
                <input
                  type="file"
                  onChange={(e) => handleExcel(e.target.files[0], 4)}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    opacity: "0",
                  }}
                />
              </Button>
              {uploadLoading === 4 ? (
                <Box sx={{ width: 406 }}>
                  <Loader style={{ backgroundColor: "red" }} />
                </Box>
              ) : (
                <FormInputText
                  control={control}
                  sx={{ backgroundColor: "white", width: 406 }}
                  staticValue={watch("data[4]")?.name || ""}
                  name="data[4].file"
                />
              )}
            </Box>
          </Box>
        </Box>

        <div
          style={{
            width: "100%",
            height: 1,
            backgroundColor: "#E7E7E7",
            marginTop: 30,
          }}
        />

        <Box
          paddingInline="25px"
          display="flex"
          justifyContent="end"
          mt="50px"
          gap="10px"
        >
          <Button
            sx={{
              backgroundColor: "#98A6AD",
              color: "#FFFFFF",
              fontSize: "13px",
              borderRadius: "5px",
              width: "83px",
              height: "30px",
            }}
            onClick={close}
          >
            취소
          </Button>
          {isLoading ? (
            <Box
              sx={{ width: "123px", display: "flex", justifyContent: "center" }}
            >
              <Loader style={{ backgroundColor: "red" }} />
            </Box>
          ) : (
            <Button
              sx={{
                backgroundColor: "#7653DF",
                color: "#fff",
                fontSize: "13px",
                borderRadius: "5px",
                width: "123px",
                height: "30px",
              }}
              onClick={handleSubmit(submit)}
            >
              저장
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
