import React from "react";
import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  TableHead,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  exportDataToExcel,
  formatKoreanPhoneNumber,
  getAge,
} from "../../utils/methods";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import CustomCheckbox from "../../components/CustomCheckbox";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { pageSize } from "../../constants/constants";
import AcceptModal from "../../components/modals/AcceptModal";
import { FormInputText } from "../../components/form-components/FormInputText";
import { deleteHhuntingById, getAllHhunting } from "../../api/customers";

const initialQuery = {
  number: 1,
  size: 20,
  keyword: "",
};

const statusSwitcher = (value) => {
  switch (value) {
    case "ACTIVE":
      return "게시중";
    case "INACTIVE":
      return "숨김";
    default:
      return "";
  }
};

function HHRequest() {
  const { handleSubmit, control, reset } = useForm({
    defaultValues: initialQuery,
  });
  const { user } = useSelector((state) => state.user);
  const [query, setQuery] = useState(initialQuery);
  const [users, setUsers] = useState();
  const [modal, setModal] = useState({
    isOpen: false,
  });

  const handleChangePage = (_, value) => {
    setQuery({ ...query, number: value });
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    var tempUser = {};
    if (name === "allSelect") {
      tempUser = users.content.map((user) => {
        return { ...user, isChecked: checked };
      });
    } else {
      tempUser = users.content.map((user) =>
        user.createdDate + user.id === name
          ? { ...user, isChecked: checked }
          : user
      );
    }
    setUsers({ ...users, content: tempUser });
  };

  async function hhuntingList() {
    try {
      const { data } = await getAllHhunting(query, user.token);
      setUsers(data);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data.message);
    }
  }

  const donwloadExcel = () => {
    const checkedData = [];
    users?.content?.map((data) => {
      if (data.isChecked) {
        checkedData.push(data);
      }
      return data.isChecked;
    });

    if (checkedData.length === 0) {
      toast.warning("인원을 선택해 주세요!");
    } else {
      exportDataToExcel(checkedData, "헤드헌팅 의뢰");
    }
  };

  const deleteById = async () => {
    try {
      if (modal.deleteIds) {
        const deletePromises = modal.deleteIds.map((data) =>
          deleteHhuntingById(data, user.token)
        );

        await Promise.all(deletePromises);

        toast.success("삭제하고 싶은 것으로 선택해 주세요.");
        setModal({ isOpen: false });
        hhuntingList();
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data.message);
    }
  };

  const deleteUsers = async () => {
    const checkedData = [];
    users?.content?.map((data) => {
      if (data.isChecked) {
        checkedData.push(data.id);
      }
      return data.isChecked;
    });
    if (checkedData.length === 0) {
      toast.warning("인원을 선택해 주세요!");
    } else {
      setModal({ ...modal, isOpen: true, deleteIds: checkedData });
    }
  };

  const submit = async (values) => {
    setQuery({ ...query, ...values });
  };

  const setInitial = () => {
    reset(initialQuery);
    setQuery(initialQuery);
  };

  useEffect(() => {
    hhuntingList();
  }, [query]);

  return (
    <Paper
      sx={{
        width: "100%",
        boxShadow: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          mb: "16px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography style={{ minWidth: "95px", fontWeight: "500" }}>
          검색
        </Typography>
        <FormInputText
          control={control}
          sx={{ width: "280px", marginRight: "6px" }}
          name="keyword"
          placeholder="내용을 입력해 주세요"
        />
        <Button
          onClick={handleSubmit(submit)}
          size="small"
          sx={{
            fontSize: "12px",
            width: "auto",
            height: "30px",
            padding: "7px 18px",
            color: "#FFFFFF",
            background: "#8E66FF",
          }}
        >
          검색
        </Button>
        <Button
          component={Link}
          onClick={setInitial}
          size="small"
          sx={{
            fontSize: "13px",
            color: "#6B7280",
            ml: "17px",
            "&:hover": {
              color: "#8E66FF",
            },
          }}
        >
          검색조건 초기화
        </Button>
      </Box>
      <hr style={{ margin: "30px auto 20px auto", borderColor: "#E7E7E7" }} />

      {users && (
        <>
          <Box
            sx={{
              display: "flex",
              mb: "15px",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                onClick={deleteUsers}
                size="small"
                sx={{
                  fontSize: "12px",
                  mr: "6px",
                  height: "30px",
                  padding: "7px 20px",
                  color: "#fff",
                  background: "#6B7280",
                }}
              >
                삭제
              </Button>
              <Button
                onClick={() => donwloadExcel()}
                sx={{
                  fontSize: "12px",
                  mr: "12px",
                  height: "30px",
                  padding: "7px 20px",
                  color: "#fff",
                  background: "#6B7280",
                }}
              >
                엑셀 다운로드
              </Button>
              <CustomCheckbox
                name="allSelect"
                checked={
                  !users.content?.some((user) => user.isChecked !== true)
                }
                onChange={handleChange}
              />
              <Typography
                style={{
                  minWidth: "95px",
                  fontWeight: "500",
                  color: "#5A5A5A",
                }}
              >
                현재 해당되는 모든 회원 ( {users?.totalElements} 명) 선택
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormInputDropdown
                control={control}
                name="size"
                sx={{ width: "111px" }}
                onChange={(e) => setQuery({ ...query, size: e.target.value })}
                options={pageSize}
                staticValue={query.size}
              />
            </Box>
          </Box>
          <TableContainer
            className="table"
            sx={{
              mt: "10px",
              borderTop: "1px solid #98a6ad",
            }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead sx={{ background: "#FCFCFC" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",

                      textAlign: "center",
                    }}
                    align="center"
                  >
                    <CustomCheckbox
                      color="primary"
                      name="allSelect"
                      checked={
                        !users.content?.some((user) => user.isChecked !== true)
                      }
                      onChange={handleChange}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    번호
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    담당자명
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    회사명
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    직급
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    연락처
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    홈페이지
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    이메일
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    상태
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.content.map((user, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#EEEAFB",
                        },
                        ".css-1d46hnn-MuiTableCell-root": {
                          padding: "1px",
                        },
                        textDecoration: "none",
                        height: "40px",
                      }}
                    >
                      <TableCell
                        padding="checkbox"
                        sx={{
                          padding: "10px",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                        align="center"
                      >
                        <CustomCheckbox
                          name={user.createdDate + user.id}
                          checked={user?.isChecked || false}
                          onChange={handleChange}
                        />
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/account-management/hh-request/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {query.size * (query.number - 1) + index + 1}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/account-management/hh-request/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.personInCharge}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/account-management/hh-request/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.companyName}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/account-management/hh-request/${user.id}`}
                        sx={{
                          textDecoration: "none",

                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.position}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/account-management/hh-request/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.phoneNumber &&
                          formatKoreanPhoneNumber(user.phoneNumber)}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/account-management/hh-request/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.webAddress}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/account-management/hh-request/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.email}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/account-management/hh-request/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          padding: "10px",
                          color: "#2D9CDB",
                          "&:hover": {
                            color: "#8e66ff",
                          },
                        }}
                        align="center"
                      >
                        {statusSwitcher(user.status)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
            <Pagination
              count={Math.ceil(users?.totalElements / query.size) || 0}
              onChange={handleChangePage}
              page={query.number}
              color="primary"
              variant="outlined"
              shape="rounded"
              sx={{
                m: "0 auto",
                "& .MuiPaginationItem-root.MuiPaginationItem-page, .MuiPaginationItem-previousNext":
                  { padding: "4px 20px !important" },
              }}
            />
          </Stack>
        </>
      )}
      {modal.isOpen && (
        <AcceptModal
          handleClick={deleteById}
          modal={{ ...modal }}
          modalKey="isOpen"
          setModal={setModal}
          title="삭제 하시겠습니까?"
        />
      )}
    </Paper>
  );
}

export default HHRequest;
