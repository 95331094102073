import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import EmployeeForm from "../../components/EmployeeForm";
import { getEmployeeApi, getMenus } from "../../api/employee";

function EmployeeDetails() {
    const { id } = useParams();
    const { token } = useSelector((state) => state.user.user);
    const [menus, setMenus] = useState([]);
    const [employee, setEmployee] = useState();

    useEffect(() => {
        getMenuList();
        getEmployee();
    }, []);

    const getEmployee = async () => {
        console.log('orlo ------------------------> ' , id);
        const result = await getEmployeeApi(id, token);
        setEmployee(result.data);
    };

    const getMenuList = async () => {
        try {
            const result = await getMenus(token);
            const newMenus = result.map((item) => {
                const obj = { value: item.id, label: item.name };
                return obj;
            });
            setMenus(newMenus);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            {employee ? (
                <EmployeeForm employee={employee} menus={menus}></EmployeeForm>
            ) : (
                "loading.."
            )}
        </>
    );
}

export default EmployeeDetails;
