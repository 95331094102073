import { Box, Button } from "@mui/material";
import React from "react";
import { useState } from "react";
import { getDateRange } from "../utils/methods";

export default function FilterButtons({ setValue = () => {} }) {
  const [activeDay, setActiveDay] = useState(0);

  const handleChangeDate = (daysDecrease, index) => {
    setActiveDay(index);
    if (daysDecrease === false) {
      setValue("dateFrom", "");
      setValue("dateTo", "");
      return;
    }
    const { from, to } = getDateRange(daysDecrease);
    setValue("dateFrom", from);
    setValue("dateTo", to);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Button
        onClick={() => handleChangeDate(false, 1)}
        sx={{
          fontSize: "13px",
          width: "max-content",
          mr: "7px",
          height: "30px",
          color: "#fff",
          background: activeDay === 1 ? "#987EE7" : "#98A6AD",
        }}
      >
        전체
      </Button>
      <Button
        onClick={() => handleChangeDate(0, 2)}
        sx={{
          fontSize: "13px",
          width: "max-content",
          mr: "7px",
          height: "30px",
          color: "#fff",
          background: activeDay === 2 ? "#987EE7" : "#98A6AD",
        }}
      >
        오늘
      </Button>
      <Button
        onClick={() => handleChangeDate(7, 3)}
        sx={{
          fontSize: "13px",
          width: "max-content",
          mr: "7px",
          height: "30px",
          color: "#fff",
          background: activeDay === 3 ? "#987EE7" : "#98A6AD",
        }}
      >
        7일
      </Button>
      <Button
        onClick={() => handleChangeDate(15, 4)}
        sx={{
          fontSize: "13px",
          width: "max-content",
          mr: "7px",
          height: "30px",
          color: "#fff",
          background: activeDay === 4 ? "#987EE7" : "#98A6AD",
        }}
      >
        15일
      </Button>
      <Button
        onClick={() => handleChangeDate(30, 5)}
        sx={{
          fontSize: "13px",
          width: "max-content",
          mr: "7px",
          height: "30px",
          color: "#fff",
          background: activeDay === 5 ? "#987EE7" : "#98A6AD",
        }}
      >
        30일
      </Button>
    </Box>
  );
}
