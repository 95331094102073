import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// @Mui
import { Fade, Paper, Typography } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  background: "#212B36",
  color: "#fff",

  "& .css-yw020d-MuiAccordionSummary-expandIconWrapper": {
    color: "#fff",
    margin: 0,
  },
  "& .css-1l0yxov-MuiButtonBase-root-MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper.Mui-expanded":
    {
      color: "#7832dc",
    },

  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "",

    flexDirection: "reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  })
);

export default function StatisticMenu(props) {
  const { expanded, handleChange, open } = props;
  const [, setChildExp] = useState("panel1");

  useEffect(() => {
    if (expanded === "panel9") {
      setChildExp("panel1");
    }
  }, [expanded]);

  return (
    <Accordion
      expanded={expanded === "panel9"}
      onChange={handleChange("panel9")}
      sx={{
        "& .MuiButtonBase-root.MuiAccordionSummary-root ": {
          padding: "0",
          minWidth: "220px",

          "& .MuiAccordionSummary-content": { margin: "0" },
          "& .MuiAccordionSummary-expandIconWrapper": {
            color: expanded === "panel9" ? "#ffffff" : "#98A6AD",
          },
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel9-content"
        id="panel9-header"
        onClick={() => setChildExp("panel9")}
        component={Link}
        to="/admin-role/statistic"
      >
        <img
          src="/icons/chart.svg"
          style={{
            filter: expanded === "panel9" ? "brightness(0) invert(1)" : "none",
          }}
          alt="users"
        />
        <Fade in={open}>
          <Paper
            sx={{
              background: "transparent",
              boxShadow: "none",
              ml: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "15px",
                color: expanded === "panel9" ? "#ffffff" : "#98A6AD",
              }}
            >
              통계
            </Typography>
          </Paper>
        </Fade>
      </AccordionSummary>
    </Accordion>
  );
}
