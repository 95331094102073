import React from "react";
import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  TableHead,
  TextField,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { getAge } from "../../utils/methods";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import CustomCheckbox from "../../components/CustomCheckbox";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { pageSize } from "../../constants/constants";
import { FormInputText } from "../../components/form-components/FormInputText";
import { FormInputDate } from "../../components/form-components/FormInputDate";
import { deletePopupById, getAllPopup } from "../../api/popup";
import { toast } from "react-toastify";
import AcceptModal from "../../components/modals/AcceptModal";
import Loader from "../../components/loader";

const initialQuery = {
  number: 1,
  size: 20,
  order: "DESC",
  sort: "createdDate",
  dateTo: "",
  dateFrom: "",
  title: "",
};
const newDate = new Date();
const today = newDate.toISOString().split("T")[0];

export default function Popup() {
  const { handleSubmit, control, reset } = useForm({
    defaultValues: initialQuery,
  });
  const { user } = useSelector((state) => state.user);
  const [query, setQuery] = useState(initialQuery);
  const [popups, setPopups] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState({
    isOpen: false,
  });

  const handleChangePage = (_, value) => {
    setQuery({ ...query, number: value });
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    var tempUser = {};
    if (name === "allSelect") {
      tempUser = popups.content.map((user) => {
        return { ...user, isChecked: checked };
      });
    } else {
      tempUser = popups.content.map((user) =>
        user.title + user.id === name ? { ...user, isChecked: checked } : user
      );
    }
    setPopups({ ...popups, content: tempUser });
  };

  async function popupList() {
    try {
      setIsLoading(true);
      const { data } = await getAllPopup(query, user.token);
      if (data) {
        setIsLoading(false);
        setPopups(data);
      }
    } catch (error) {
      console.log(error.response?.data.message);
    }
  }

  const formatDate = (date) => {
    const newDate = new Date(date);
    const year = newDate.getUTCFullYear();
    const month = ("0" + (newDate.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + newDate.getUTCDate()).slice(-2);
    const formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  };

  const submit = async (values) => {
    setQuery({
      ...query,
      ...values,
      periodFrom: formatDate(values.periodFrom) || "",
      periodTo: formatDate(values.periodTo) || "",
    });
  };

  const setInitial = () => {
    reset(initialQuery);
    setQuery(initialQuery);
  };

  const deleteById = () => {
    try {
      modal.deleteIds?.map(async (data) => {
        await deletePopupById(data, user.token);
        popupList();
      });
      toast.success("삭제가 완료 되었습니다!");
      setModal({ isOpen: false });
    } catch (error) {
      console.log(error);
    }
  };

  const deletePopups = async () => {
    const checkedPopups = [];

    popups?.content?.map((data) => {
      if (data.isChecked) {
        checkedPopups.push(data.id);
      }
      return data.isChecked;
    });

    if (checkedPopups.length === 0) {
      toast.warning("삭제 하고 싶은 팝업을 선택해 주세요!");
    } else {
      setModal({ ...modal, isOpen: true, deleteIds: checkedPopups });
    }
  };

  useEffect(() => {
    popupList();
  }, [query]);

  return (
    <Paper
      sx={{
        width: "100%",
        boxShadow: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          mb: "12px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography
          style={{
            minWidth: "95px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#5a5a5a",
          }}
        >
          검색
        </Typography>
        <FormInputText
          sx={{ width: "280px", marginRight: "6px" }}
          control={control}
          name="title"
          placeholder="내용을 입력해 주세요"
        />
        {/* <Button
          size="small"
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "7px 18px",
            color: "#7653DF",
            background: "#EEEAFB",
          }}
        >
          검색
        </Button> */}
        <Link to="/popup-create" style={{ marginLeft: "auto" }}>
          <Button
            size="small"
            sx={{
              fontSize: "13px",
              width: "auto",
              height: "30px",
              padding: "8px 26px",
              color: "#FFF",
              backgroundColor: "#7653DF",
            }}
          >
            팝업 등록 <AddIcon />
          </Button>
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          mb: "12px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography
          style={{
            minWidth: "95px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#5a5a5a",
          }}
        >
          노출 기간
        </Typography>
        <FormInputDate
          name="periodFrom"
          control={control}
          placeholder="YYYY/MM/DD"
          sx={{
            ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
              fontSize: "16px",
            },
          }}
          renderInput={(params) => (
            <TextField
              color="info"
              size="small"
              variant="outlined"
              {...params}
              sx={{
                width: "127px",
                background: "#fff",
                ".MuiInputBase-root": {
                  paddingRight: "0px",
                },
                ".MuiIconButton-root": {
                  marginRight: "0px",
                },
                input: {
                  paddingRight: "0px!important",
                },
                "& .MuiSvgIcon-root": {
                  fill: "#987EE7 !important",
                },
              }}
            />
          )}
        />
        <Typography style={{ margin: "0 25px 0 10px" }}>부터</Typography>
        <FormInputDate
          name="periodTo"
          control={control}
          placeholder="YYYY/MM/DD"
          renderInput={(params) => (
            <TextField
              variant="outlined"
              {...params}
              sx={{
                width: "127px",
                background: "#fff",
                paddingRight: "0px!important",
                ".MuiInputBase-root": {
                  paddingRight: "0px",
                },
                ".MuiIconButton-root": {
                  marginRight: "0px",
                },
                input: {
                  paddingRight: "0px!important",
                },
                "& .MuiSvgIcon-root": {
                  fill: "#987EE7 !important",
                },
              }}
            />
          )}
        />
        <Typography style={{ margin: "0 12px 0 10px" }}>까지</Typography>
      </Box>
      <hr style={{ margin: "30px auto 20px auto", borderColor: "#E7E7E7" }} />
      <Box
        sx={{
          display: "flex",
          mb: "40px",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Button
          size="small"
          sx={{
            fontSize: "13px",
            width: "auto",
            mr: "30px",
            height: "30px",
            padding: "7px 30px",
            color: "#fff",
            background: "#987EE7",
          }}
          onClick={handleSubmit(submit)}
        >
          설정한 조건으로 검색
        </Button>
        <Box
          size="small"
          onClick={setInitial}
          sx={{
            textDecoration: "underline",
            fontSize: "14px",
            color: "#6B7280",
            fontWeight: 500,
            "&:hover": {
              color: "#8E66FF",
              cursor: "pointer",
            },
          }}
        >
          검색조건 초기화
        </Box>
      </Box>

      {isLoading ? (
        <Loader style={{ backgroundColor: "red" }} />
      ) : (
        popups && (
          <>
            <Box
              sx={{
                display: "flex",
                mb: "15px",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={deletePopups}
                  size="small"
                  sx={{
                    fontSize: "12px",
                    mr: "6px",
                    height: "30px",
                    padding: "7px 20px",
                    color: "#fff",
                    background: "#6B7280",
                  }}
                >
                  삭제
                </Button>
                {/* <Button
                sx={{
                  fontSize: "12px",
                  lineHeight: "15px",
                  height: "30px",
                  padding: "7px 26px",
                  whiteSpace: "nowrap",
                  color: "#fff",
                  background: "#6B7280",
                  marginLeft: "auto",
                }}
              >
                엑셀 다운로드
              </Button> */}
                <CustomCheckbox
                  name="allSelect"
                  checked={
                    !popups.content?.some((user) => user.isChecked !== true)
                  }
                  onChange={handleChange}
                />
                <Typography
                  style={{
                    minWidth: "95px",
                    fontWeight: "500",
                    color: "#5A5A5A",
                  }}
                >
                  현재 해당되는 모든 회원 ( {popups?.totalElements} 명) 선택
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormInputDropdown
                  control={control}
                  name="size"
                  sx={{ width: "111px" }}
                  onChange={(e) => setQuery({ ...query, size: e.target.value })}
                  options={pageSize}
                  staticValue={query.size}
                />
              </Box>
            </Box>
            <TableContainer
              className="table"
              sx={{
                mt: "10px",
                borderTop: "1px solid #98a6ad",
              }}
            >
              <Table size="small" aria-label="a dense table">
                <TableHead sx={{ background: "#FCFCFC" }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        color: "#5A5A5A",
                        fontSize: "13px",

                        textAlign: "center",
                      }}
                      align="center"
                    >
                      <CustomCheckbox
                        color="primary"
                        name="allSelect"
                        checked={
                          !popups.content?.some(
                            (user) => user.isChecked !== true
                          )
                        }
                        onChange={handleChange}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#5A5A5A",
                        fontSize: "13px",
                      }}
                      align="center"
                    >
                      번호
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#5A5A5A",
                        fontSize: "13px",
                      }}
                      align="center"
                    >
                      위치
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#5A5A5A",
                        fontSize: "13px",
                      }}
                      align="center"
                    >
                      제목
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#5A5A5A",
                        fontSize: "13px",
                      }}
                      align="center"
                    >
                      작성 일자
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#5A5A5A",
                        fontSize: "13px",
                      }}
                      align="center"
                    >
                      노출기간
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {popups.content.map((user, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        role="checkbox"
                        key={index}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#EEEAFB",
                          },
                          ".css-1d46hnn-MuiTableCell-root": {
                            padding: "1px",
                          },
                          textDecoration: "none",
                          height: "40px",
                        }}
                      >
                        <TableCell
                          padding="checkbox"
                          sx={{
                            padding: "10px",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                          align="center"
                        >
                          <CustomCheckbox
                            name={user.title + user.id}
                            checked={user?.isChecked || false}
                            onChange={handleChange}
                          />
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/popup-details/${user.id}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                          }}
                          align="center"
                        >
                          {query.size * (query.number - 1) + 1 + index}
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/popup-details/${user.id}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                          }}
                          align="center"
                        >
                          {user.locationUrl}
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/popup-details/${user.id}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                          }}
                          align="center"
                        >
                          {user.title}
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/popup-details/${user.id}`}
                          sx={{
                            textDecoration: "none",

                            padding: "10px",
                          }}
                          align="center"
                        >
                          {user.createdDate}
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/popup-details/${user.id}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                            color:
                              today > getAge(user.periodTo)
                                ? "black"
                                : "#0075C1",
                          }}
                          align="center"
                        >
                          {getAge(user.periodFrom) +
                            "~" +
                            getAge(user.periodTo)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
              <Pagination
                count={Math.ceil(popups.totalElements / query.size)}
                onChange={handleChangePage}
                page={query.number}
                color="primary"
                variant="outlined"
                shape="rounded"
                sx={{
                  m: "0 auto",
                  "& .css-1k2i9p6-MuiButtonBase-root-MuiPaginationItem-root": {
                    height: "20px",
                    minWidth: "20px",
                    backgroundColor: "#CCCCCC",
                    color: "#fff",
                  },
                }}
              />
            </Stack>
          </>
        )
      )}
      {modal.isOpen && (
        <AcceptModal
          handleClick={deleteById}
          modal={{ ...modal }}
          modalKey="isOpen"
          setModal={setModal}
          title="배너를 삭제 하시겠습니까?"
        />
      )}
    </Paper>
  );
}
