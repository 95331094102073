import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";

export default function AcceptModalSms({
  modal,
  setModal,
  title = <></>,
  content = <></>,
  style = {},
  modalKey = "isOpen",
  handleClick = () => {},
}) {
  const close = () => {
    setModal((prevModal) => ({
      ...prevModal,
      [modalKey]: false,
      //////this is user status, for user status changing in UserDetail page
      status: "",
    }));
  };

  return (
    <Modal open={modal.isOpen}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          padding: "70px 85px 60px",
          bgcolor: "#fff",
          boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.10)",
          borderRadius: "20px",
          border: "none",
          ...style,
        }}
      >
         <Typography sx={{ fontSize: "20px", color: "#EB5757", mb:'24px' }}>
          {title}
        </Typography>
        <Typography sx={{ fontSize: "18px", color: "#6B7280" }}>
          {content}
        </Typography>
        <Box display="flex" alignItems="center" mt="60px">
          <Button
            size="large"
            sx={{
              background: "#fff",
              color: "#212B36",
              fontSize: "14px",
              borderRadius: " 8px",
              border: "1px solid #E9EDF9",
              padding: "13px",
              mr: "20px",
              width: "190px",
              ":hover": { background: "#9775fa" },
            }}
            onClick={close}
          >
            취소
          </Button>
          <Button
            size="large"
            sx={{
              background: "#9775fa",
              color: "#fff",
              fontSize: "14px",
              borderRadius: " 8px",
              border: "1px solid transparent",
              padding: "13px",
              width: "190px",
              ":hover": { background: "#9775fa" },
            }}
            onClick={handleClick}
          >
            확인
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
