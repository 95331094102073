import React from "react";
import { Grid, Typography } from "@mui/material";

const HomePage = () => {
  return (
    <Grid
      sx={{
        height: "68vh",
        justifyContent: "center",
        alignItems: "center",
      }}
      container
    >
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 700,
        }}
      >
        WELCOME
      </Typography>
    </Grid>
  );
};

export default HomePage;
