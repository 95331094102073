import mainCaller from "./mainCaller";

export async function auth(data) {
  return await mainCaller(`signIn`, "POST", data, {});
}

export async function createApplicant(data, token) {
  return await mainCaller(`applicants/create`, "POST", data, {
    "Content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  });
}

export async function getUserById(id, url, token) {
  return await mainCaller(`${url ?? "applicants"}/${id}`, "GET", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getApprovedUserById(id, token) {
  return await mainCaller(`careerStage/getPartners/${id}`, "GET", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getAllEmployees(data, token) {
  return await mainCaller(`careerStage/getAllEmployees`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function updateUserById(data, token) {
  return await mainCaller(`applicants/update`, "POST", data, {
    "Content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  });
}

export async function createUser(url, data) {
  return await mainCaller(`${url ?? `signUp`}`, "POST", data, {
    "Content-type": "multipart/form-data",
  });
}

export async function getUsersList(data, token) {
  return await mainCaller(`applicants`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getEmployeeMangeUsers(data, token) {
  return await mainCaller(`employeeManagement`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getEmployeeList(data, token) {
  return await mainCaller(`careerStage`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getUserConcults(url, id, token) {
  return await mainCaller(`${url}/${id}`, "GET", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getUserJobAppls(id, token) {
  return await mainCaller(
    `jobApplications/getByEmployeeId/${id}`,
    "GET",
    null,
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export async function concultsUpdate(url, data, token) {
  return await mainCaller(url, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function concultsCreate(url, data, token) {
  return await mainCaller(url, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function concultsDelete(id, token) {
  return await mainCaller(
    `employeeConsultations/delete/${id}`,
    "DELETE",
    null,
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export async function getPersonnelList(data, token) {
  return await mainCaller(`personnel`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function createPersonnelUser(data, token) {
  return await mainCaller(`personnel/create`, "POST", data, {
    "Content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  });
}

export async function createEmployeeSalary(data, token) {
  return await mainCaller(`employeeSalaryDetail/create`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getEmployeeSalaryById(id, token) {
  return await mainCaller(`employeeSalaryDetail/getById/${id}`, "GET", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function updateEmployeeSalary(data, token) {
  return await mainCaller(`employeeSalaryDetail/update`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getPersonnelById(id, token) {
  return await mainCaller(`personnel/getById/${id}`, "GET", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function updatePersonnelById(data, token) {
  return await mainCaller(`personnel/update`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function updateWaiting(data, token) {
  return await mainCaller(`careerStage/update`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getEmployeeDataById(id, token) {
  return await mainCaller(`careerStage/getById/${id}`, "GET", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function deleteEmployeeDataById(data, token) {
  return await mainCaller(`careerStage/delete`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getWorkHistoriesById(id, token) {
  return await mainCaller(
    `careerStage/getAllByApplicantId/${id}`,
    "GET",
    null,
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export async function createWaiting(data, token) {
  return await mainCaller(`careerStage/create`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function statusChanger(data, token) {
  return await mainCaller(`applicants/changeStatus`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}
export async function sendToPreRetired(data, token) {
  return await mainCaller(`applicants/sendToPreRetired`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}
