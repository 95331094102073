import {
  Box,
  Button,
  Grid,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { FormInputDateSummery } from "../../components/form-components/FormInputDateSummery";
import AnalyticsWidgetSummary from "../../components/statistic/AnalyticsWidgetSummary";
import Donut from "../../components/statistic/Donut";
import MonthStatistics from "../../components/modals/MonthStatistics";
import Hourly from "../../components/statistic/Hourly";
import Yearly from "../../components/statistic/Yearly";
import Monthly from "../../components/statistic/Monthly";
import { getUserSummary } from "../../api/userSummary";
import { useSelector } from "react-redux";
import Loader from "../../components/loader";
import { toast } from "react-toastify";
import dayjs from "dayjs";

const initialQuery = {
  startDate: dayjs().format("YYYY-MM-DD 00:00"),
  endDate: dayjs().format("YYYY-MM-DD 23:59"),
};

function Statistic({ initialTab }) {
  const { user } = useSelector((state) => state.user);

  const [selectedTab, setSelectedTab] = useState(initialTab ?? 1);

  const [isLoading, setIsLoading] = useState(false);
  const [smsModal, setSmsModal] = useState({ isOpen: false });
  const [userSummary, setUserSummary] = useState();

  const handleChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  const { handleSubmit, control } = useForm({
    defaultValues: initialQuery,
  });

  useEffect(() => {
    UserSummaryApi(initialQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = async (values) => {
    // const from = new Date(values.startDate);
    // const to = new Date(values.endDate);
    // const dateFrom = values.startDate ? from.toISOString().split("T")[0] : "";
    // const dateTo = values.endDate ? to.toISOString().split("T")[0] : "";

    const body = {
      startDate: dayjs(values.startDate).format("YYYY-MM-DD 00:00"),
      endDate: dayjs(values.endDate).format("YYYY-MM-DD 23:59"),
    };

    try {
      UserSummaryApi(body);
    } catch (error) {
      toast.error(error.response?.data.message);
    }
  };

  const UserSummaryApi = async (body) => {
    setIsLoading(true);
    const result = await getUserSummary(body, user.token);
    setUserSummary(result);
    setIsLoading(false);
  };

  const smsSend = () => {
    setSmsModal({ isOpen: true });
  };

  const handleTab = (tab) => {
    switch (tab) {
      case 1:
        return <Hourly />;
      case 2:
        return <Monthly control={control} />;
      case 3:
        return <Yearly control={control} />;

      default:
        return <Hourly />;
    }
  };

  return (
    <>
      <Box sx={{ width: "100%", boxShadow: "none" }}>
        <MonthStatistics
          setModal={setSmsModal}
          modal={{ ...smsModal, isOpen: smsModal.isOpen }}
          modalKey="isOpen"
        />
        <Box
          alignItems="center"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Stack>
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: 600,
                color: "#05004E",
                lineHeight: "26px",
              }}
              mb="3px"
            >
              회원 현황
            </Typography>
            <Typography
              style={{
                fontSize: "13px",
                color: "#737791",
                lineHeight: "25px",
              }}
            >
              User Summery
            </Typography>
          </Stack>

          <Button
            onClick={smsSend}
            size="small"
            sx={{
              fontSize: "13px",
              width: "auto",
              height: "30px",
              padding: "7px 24px",
              color: "#FFF",
              backgroundColor: "#7653DF",
              fontWeight: 700,
            }}
          >
            월별통계
          </Button>
        </Box>
        <Box
          sx={{ display: "flex", alignItems: "center", width: "auto" }}
          my="45px"
        >
          <Typography
            style={{ minWidth: "95px", fontSize: "13px", color: "#5a5a5a" }}
          >
            검색기간
          </Typography>
          <FormInputDateSummery
            name="startDate"
            control={control}
            sx={{
              ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
                fontSize: "16px",
              },
            }}
            renderInput={(params) => (
              <TextField
                color="info"
                size="small"
                variant="outlined"
                {...params}
                sx={{
                  width: "127px",
                  background: "#fff",
                  ".MuiInputBase-root": {
                    paddingRight: "0px",
                  },
                  ".MuiIconButton-root": {
                    marginRight: "0px",
                  },
                  input: {
                    paddingRight: "0px!important",
                  },
                  "& .MuiSvgIcon-root": {
                    fill: "#987EE7 !important",
                  },
                }}
              />
            )}
          />
          <Typography style={{ margin: "0 25px 0 10px" }}>부터</Typography>
          <FormInputDateSummery
            name="endDate"
            control={control}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                {...params}
                sx={{
                  width: "127px",
                  background: "#fff",
                  paddingRight: "0px!important",
                  ".MuiInputBase-root": {
                    paddingRight: "0px",
                  },
                  ".MuiIconButton-root": {
                    marginRight: "0px",
                  },
                  input: {
                    paddingRight: "0px!important",
                  },
                  "& .MuiSvgIcon-root": {
                    fill: "#987EE7 !important",
                  },
                }}
              />
            )}
          />
          <Typography style={{ margin: "0 12px 0 10px" }}>까지</Typography>

          <Button
            size="small"
            onClick={handleSubmit(submit)}
            sx={{
              fontSize: "13px",
              width: "auto",
              height: "30px",
              padding: "7px 18px",
              color: "#7653DF",
              background: "#EEEAFB",
            }}
          >
            검색
          </Button>
        </Box>

        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Loader style={{ backgroundColor: "red" }} />
          </Box>
        ) : (
          <>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <AnalyticsWidgetSummary
                  title="회원가입"
                  total={userSummary?.totalJoinedMember}
                  icon={"/icons/person-add.svg"}
                  bgColor="#E2E8FF"
                  iconColor="#5A7DFA"
                />
              </Grid>
              <Grid item xs={2}>
                <AnalyticsWidgetSummary
                  title="구직신청"
                  total={userSummary?.applyForJobCount}
                  icon={"/icons/file-arrow-up.svg"}
                  bgColor="#E3FFE2"
                  iconColor="#5AFA60"
                />
              </Grid>
              <Grid item xs={2}>
                <AnalyticsWidgetSummary
                  title="진행중"
                  total={userSummary?.activeRecruitment}
                  icon={"/icons/caret-double.svg"}
                  bgColor="#FFF7E2"
                  iconColor="#FACD5A"
                />
              </Grid>
              <Grid item xs={2}>
                <AnalyticsWidgetSummary
                  title="대기자"
                  total={userSummary?.countPending}
                  icon={"/icons/counter-clockwise.svg"}
                  bgColor="#F1E2FF"
                  iconColor="#B45AFA"
                />
              </Grid>
              <Grid item xs={2}>
                <AnalyticsWidgetSummary
                  title="취업자"
                  total={userSummary?.totalEmployee}
                  icon={"/icons/building-office.svg"}
                  bgColor="#E2FFFD "
                  iconColor="#36F3FF"
                />
              </Grid>
              <Grid item xs={2}>
                <AnalyticsWidgetSummary
                  title="퇴사자"
                  total={userSummary?.countRetired}
                  icon={"/icons/arrow-square-out.svg"}
                  bgColor="#EFEFEF"
                  iconColor="#ABABAB"
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} lg={4} sx={{ mt: 7.5 }}>
              <Donut userSummary={userSummary} />
            </Grid>

            <Grid item xs={12} md={12} lg={12} sx={{ mt: 10 }}>
              <Typography
                sx={{ fontSize: "14px", fontWeight: 400, lineHeight: "22px" }}
                mb="29px"
              >
                지역별
              </Typography>

              <TableContainer>
                <Table>
                  <TableHead
                    sx={{
                      "& .MuiTableRow-root th:First-of-type": {
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                      },
                      "& .MuiTableRow-root th:last-child": {
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",
                      },
                    }}
                  >
                    <TableRow>
                      {userSummary?.regionTotalList?.map((headCell, ind) => (
                        <TableCell
                          key={ind}
                          align="center"
                          sx={{
                            width: "45px",
                            minWidth: "45px",
                            background: "rgba(118,	83,	223, 0.3)",
                            borderBottom: "none",
                          }}
                        >
                          {headCell.regionName}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:hover": {
                          color: "#5a5a5a !important",
                          backgroundColor: "rgba(142, 102, 255, 0.22)",
                        },
                        textDecoration: "none",
                        height: "40px",
                      }}
                    >
                      {userSummary?.regionTotalList?.map((headCell, ind) => (
                        <TableCell
                          key={ind}
                          component={Link}
                          scope="row"
                          padding="none"
                          // to={`/user-details/${user.applicantId}`}
                          sx={{ p: 2, fontSize: "13px" }}
                          align="center"
                        >
                          {headCell.count}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        )}
      </Box>

      <Box sx={{ mt: 8 }}>
        <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
          접속자 통계
        </Typography>
        <Tabs
          sx={{
            "& .MuiTab-root.MuiTab-textColorSecondary": {
              borderRadius: "5px",
              color: "#7653DF",
              border: "1px solid #7653DF",
              mr: 1.25,
              minHeight: "30px !important",
              p: "0",
              fontWeight: "500",
              fontSize: "13px",
            },
            "& .MuiTabs-scroller": {
              alignItems: "flex-end",
              display: "flex",
            },
            "& .MuiTab-root.MuiTab-textColorSecondary.Mui-selected": {
              background: "#987EE7",
              color: "#fff",
            },
            "& .MuiTabs-indicator": {
              display: "none",
              color: "#E1E1E1",
            },
          }}
          value={selectedTab}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="primary"
          aria-label="secondary tabs example"
        >
          <Tab value={1} label="시간별" />
          <Tab value={2} label="일별" />
          <Tab value={3} label="월별" />
        </Tabs>

        <Suspense fallback={"Loading..."}>{handleTab(selectedTab)}</Suspense>
      </Box>
    </>
  );
}

export default Statistic;
