import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";

export const FormInputSearch = ({
  name = "",
  control,
  handleChange: propHandle,
  onChange = () => {},
  setValue: setForm = () => {},
  staticValue,
  options = [],
  id = "search-box",
  placeholder,
  type = "text",
  size = "small",
  sx,
  arrowHide,
}) => {
  const [value, setValue] = useState(staticValue ?? null);

  function handleChange(_, selected) {
    setValue(selected);
    setForm(name, selected?.value);
  }

  useEffect(() => {
    setValue(staticValue);
  }, [staticValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState: { error } }, index) => (
        <Autocomplete
          key={index}
          id={id}
          disablePortal
          name={name}
          size={size}
          onInputChange={onChange}
          getOptionLabel={(option) =>
            option && option.label ? option.label : String(option)
          }
          isOptionEqualToValue={(option, value) => option.value === value}
          renderOption={(props, option, i) => (
            <li {...props} key={i}>
              {option.label}
            </li>
          )}
          sx={{
            width: "100%",
            "& .MuiAutocomplete-popupIndicator": {
              display: arrowHide ? "none" : "",
            }, // Hide indicator arrow
            ".MuiOutlinedInput-root.MuiInputBase-formControl": {
              padding: "0",
            },
            ...sx,
          }}
          value={value}
          options={options}
          onChange={propHandle ?? handleChange}
          renderInput={(params, ind) => (
            <TextField
              key={ind}
              onBlur={(e) => (e.target.value = "")}
              helpertext={error ? error.message : null}
              error={!!error}
              type={type}
              placeholder={placeholder}
              {...params}
            />
          )}
          disableClearable
        />
      )}
    />
  );
};
