import React, { useState, useEffect, useMemo } from "react";
import { Box, Typography, Button, Grid, TextField, Modal } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { FormInputText } from "../../../components/form-components/FormInputText";
import { FormInputDropdown } from "../../../components/form-components/FormInputDropdown";
import { FormInputTextArea } from "../../../components/form-components/FormInputTextArea";
import { FormInputDate } from "../../../components/form-components/FormInputDate";
import BackButton from "../../../components/BackButton";
import LinkButton from "../../../components/LinkButton";
import { FormInputSearch } from "../../../components/form-components/FormInputSearch";
import { debounce } from "../../../utils/methods";
import {
    getCodeList,
    occupationSearch,
} from "../../../api/personalCodeManagement";
import { createOrder, getOrderById, updateOrderById } from "../../../api/order";
import CustomModal from "../../../components/modals/CustomModal";
import CodeManager from "../../../components/modals/CodeManager";
import Companies from "../../../components/modals/Companies";

const chargerQuery = {
    number: 1,
    size: 20,
    order: "DESC",
    sort: "name",
    keyword: "",
};

function OrderCreate() {
    const { id } = useParams();
    
    const {slug} = useParams()
    const navigate = useNavigate();
    const { token } = useSelector((state) => state.user.user);
    const [occupation, setOccupation] = useState([]);
    const [order, setOrder] = useState();
    const [chargers, setChargers] = useState([]);
    const { control, handleSubmit, setValue, reset, watch } = useForm();
    const [modal, setModal] = useState({
        isOpen: false,
    });
    const [companyModal, setCompanyModal] = useState({
        isOpen: false,
    });

    const getOccupation = async (keyword) => {
        try {
            const { data } = await occupationSearch(keyword, token);
            setOccupation(data);
        } catch (error) {
            console.log(error);
        }
    };

  
    const submit = async (values) => {
        
      if (values.occupationId == null) {
            toast.warning("직종 선택은 필수입니다. ");
        } else if (values.customerId == null) {
            toast.warning("업체명 선택은 필수입니다.");
        } else {
            try {
                values.displayDate = slug
                if (id) {
                    await updateOrderById(values, token);
                } else {
                    await createOrder(values, token);
                }
                toast.success("완료 되었습니다.");
                navigate(-1);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const getOrder = async () => {
        try {
            const { data } = await getOrderById(id, token);
            reset(data);
            setOrder(data);
        } catch (error) {
            console.log(error);
        }
    };

    const getChargerList = async () => {
        try {
            const { data } = await getCodeList(
                "manDepartments",
                chargerQuery,
                token
            );
            const list = data.content;
            if (list) {
                const newChargersOption = list.map((item) => ({
                    label: item.name,
                    value: item.name,
                }));
                setChargers(newChargersOption);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const search = async (e) => {
        if (e && e.target.value) {
            const keyword = e.target.value;
            debounce(
                () => {
                    return getOccupation(keyword);
                },
                "search-box",
                1000
            );
        }
    };

    const searchOccu = () => {
        setModal({
            ...modal,
            isOpen: true,
            keyword: watch("occupationName"),
        });
    };

    const searchCompany = () => {
        setCompanyModal({
            ...modal,
            isOpen: true,
            keyword: watch("searchCompany"),
        });
    };

    useEffect(() => {
        if (companyModal.customerId) {
            setCompanyModal({ ...companyModal, isOpen: false });
        }
    }, [companyModal.customerId]);

    useEffect(() => {
        if (companyModal.customer) {
            setValue("customerId", companyModal.customerId);
            setOccupation([companyModal.customer]);
        }
    }, [companyModal.customer]);

    useEffect(() => {
        if (modal.occupation) {
            setValue("occupationId", modal.occupation.id);
            setOccupation([modal.occupation]);
        }
    }, [modal.occupation]);

    useMemo(() => {
        if (id) {
            getOrder();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        getChargerList();
    }, []);

    return (
        <div>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <BackButton text={id ? "오더 상세" : "오더 등록"} />
                <LinkButton
                    link="/job-management/order-status"
                    label="목록으로"
                />
            </Box>
            <div style={{ margin: "13px 0 40px 0" }} />
            <Grid container>
                <Grid item xs={6} pr="32px">
                    <Grid container rowSpacing={1} alignItems="center">
                        <Grid item xs={2} fontWeight="500">
                            업체명
                        </Grid>
                        <Grid item xs={10}>
                            <Box display="flex" alignItems="center">
                                <FormInputText
                                    control={control}
                                    name="searchCompany"
                                    staticValue={
                                        companyModal.customer
                                            ? companyModal.customer.name
                                            : order?.customerName
                                    }
                                />
                                <Button
                                    onClick={searchCompany}
                                    sx={{
                                        whiteSpace: "nowrap",
                                        fontSize: "12px",
                                        marginLeft: "10px",
                                        lineHeight: "14px",
                                        padding: "8px 26px",
                                        color: "#7653DF",
                                        backgroundColor: "#EEEAFB",
                                    }}
                                >
                                    검색
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={2} fontWeight="500">
                            부서명
                        </Grid>
                        <Grid item xs={10}>
                            <FormInputText
                                control={control}
                                name="department"
                                placeholder="내용을 입력해 주세요"
                            />
                        </Grid>

                        <Grid item xs={2} fontWeight="500">
                            프로그램
                        </Grid>
                        <Grid item xs={10}>
                            <FormInputText
                                control={control}
                                name="program"
                                placeholder="내용을 입력해 주세요"
                            />
                        </Grid>
                        <Grid item xs={2} fontWeight="500">
                            직종
                        </Grid>
                        <Grid item xs={10}>
                            <Box display="flex" alignItems="center">
                                <FormInputSearch
                                    arrowHide
                                    staticValue={
                                        modal.occupation
                                            ? modal.occupation.name
                                            : order?.occupationName
                                    }
                                    control={control}
                                    name="occupationId"
                                    placeholder="내용을 입력해 주세요"
                                    sx={{
                                        width: "100%",
                                        ".MuiOutlinedInput-root.MuiInputBase-formControl":
                                            {
                                                padding: "0",
                                            },
                                    }}
                                    setValue={(_, selected) =>
                                        setValue("occupationId", selected)
                                    }
                                    onChange={search}
                                    options={occupation.map((occupation) => ({
                                        label: occupation.name,
                                        value: occupation.id,
                                    }))}
                                />
                                <Button
                                    onClick={() => searchOccu()}
                                    sx={{
                                        whiteSpace: "nowrap",
                                        fontSize: "12px",
                                        marginLeft: "10px",
                                        lineHeight: "14px",
                                        padding: "8px 26px",
                                        color: "#7653DF",
                                        backgroundColor: "#EEEAFB",
                                    }}
                                >
                                    검색
                                </Button>
                            </Box>
                        </Grid>

                        <Grid item xs={2} fontWeight="500" mb="auto">
                            채용 조건
                        </Grid>
                        <Grid item xs={10}>
                            <FormInputTextArea
                                style={{
                                    width: "100%",
                                    background: "#fff",
                                    borderRadius: "8px",
                                    border: "1px solid #E0E0E0",
                                }}
                                placeholder="내용을 입력해 주세요"
                                minRows={3}
                                control={control}
                                name="recConditions"
                            />
                        </Grid>

                        <Grid item xs={2} fontWeight="500">
                            TO 수
                        </Grid>
                        <Grid item xs={10}>
                            <Box display="flex" alignItems="center">
                                <FormInputText
                                    type="number"
                                    control={control}
                                    sx={{ width: "130px" }}
                                    name="tobe"
                                    placeholder=""
                                />
                                <Typography ml="10px">명</Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={2} fontWeight="500">
                            오더 접수일
                        </Grid>
                        <Grid item xs={10}>
                            <Box display="flex" alignItems="center">
                                <FormInputDate
                                    name="receiptDate"
                                    control={control}
                                    placeholder="YYYY/MM/DD"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{
                                                width: "130px",
                                                background: "#fff",
                                                ".MuiInputBase-root": {
                                                    paddingRight: "0px",
                                                },
                                                ".MuiIconButton-root": {
                                                    margin: "0px",
                                                },
                                                input: {
                                                    paddingRight:
                                                        "0px!important",
                                                },
                                                "& .MuiSvgIcon-root": {
                                                    fill: "#987EE7 !important",
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={2} fontWeight="500">
                            서류 제출일
                        </Grid>
                        <Grid item xs={10}>
                            <Box display="flex" alignItems="center">
                                <FormInputDate
                                    name="submissionDate"
                                    control={control}
                                    placeholder="YYYY/MM/DD"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{
                                                width: "130px",
                                                background: "#fff",
                                                ".MuiInputBase-root": {
                                                    paddingRight: "0px",
                                                },
                                                ".MuiIconButton-root": {
                                                    margin: "0px",
                                                },
                                                input: {
                                                    paddingRight:
                                                        "0px!important",
                                                },
                                                "& .MuiSvgIcon-root": {
                                                    fill: "#987EE7 !important",
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={2} fontWeight="500">
                            매칭 예정일
                        </Grid>
                        <Grid item xs={10}>
                            <Box display="flex" alignItems="center">
                                <FormInputDate
                                    name="matchingDate"
                                    control={control}
                                    placeholder="YYYY/MM/DD"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{
                                                width: "130px",
                                                background: "#fff",
                                                ".MuiInputBase-root": {
                                                    paddingRight: "0px",
                                                },
                                                ".MuiIconButton-root": {
                                                    margin: "0px",
                                                },
                                                input: {
                                                    paddingRight:
                                                        "0px!important",
                                                },
                                                "& .MuiSvgIcon-root": {
                                                    fill: "#987EE7 !important",
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} pl="32px">
                    <Grid container rowSpacing={1} alignItems="center">
                        <Grid item xs={2} fontWeight="500" mb="auto">
                            결과
                        </Grid>
                        <Grid item xs={10}>
                            <FormInputTextArea
                                style={{
                                    width: "100%",
                                    background: "#fff",
                                    borderRadius: "8px",
                                    border: "1px solid #E0E0E0",
                                }}
                                placeholder="내용을 입력해 주세요"
                                minRows={3}
                                control={control}
                                name="result"
                            />
                        </Grid>
                        <Grid item xs={2} fontWeight="500">
                            면접 시간
                        </Grid>
                        <Grid item xs={10}>
                            <FormInputText
                                control={control}
                                name="interviewTime"
                                placeholder="내용을 입력해 주세요"
                            />
                        </Grid>
                        <Grid item xs={2} fontWeight="500" mb="auto">
                            인력 세팅 현황
                        </Grid>
                        <Grid item xs={10}>
                            <FormInputTextArea
                                style={{
                                    width: "100%",
                                    background: "#fff",
                                    borderRadius: "8px",
                                    border: "1px solid #E0E0E0",
                                }}
                                placeholder="내용을 입력해 주세요"
                                minRows={3}
                                control={control}
                                name="setStatus"
                            />
                        </Grid>
                        <Grid item xs={2} fontWeight="500">
                            담당 팀
                        </Grid>
                        {chargers.length > 0 && (
                            <Grid item xs={10}>
                                <FormInputDropdown
                                    control={control}
                                    name="teamInCharge"
                                    options={chargers}
                                    sx={{
                                        maxWidth: "180px",
                                        width: "100%",
                                    }}
                                />
                            </Grid>
                        )}
                        <Grid item xs={2} fontWeight="500">
                            담당자
                        </Grid>
                        <Grid item xs={10}>
                            <FormInputText
                                control={control}
                                name="manager"
                                placeholder="내용을 입력해 주세요"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div style={{ margin: "70px 0 20px" }} />
            <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                mt="25px"
            >
                <Button
                    onClick={() => navigate(-1)}
                    size="small"
                    sx={{
                        fontSize: "13px",
                        mr: "8px",
                        height: "30px",
                        padding: "7px 26px",
                        color: "#fff",
                        background: "#98A6AD",
                    }}
                >
                    취소
                </Button>
                <Button
                    onClick={handleSubmit(submit)}
                    size="small"
                    sx={{
                        fontSize: "13px",
                        width: "auto",
                        height: "30px",
                        padding: "7px 46px",
                        color: "#fff",
                        background: "#7653DF",
                    }}
                >
                    저장
                </Button>
            </Box>
            {modal.isOpen && (
                <CustomModal
                    modal={{ isOpen: modal.isOpen }}
                    style={{ width: "90%", height: "90%", padding: "30px" }}
                >
                    <CodeManager
                        modal={modal}
                        setModal={setModal}
                        contentKey="occupation"
                        apiUrl="occupation"
                        modalKey="isOpen"
                    />
                </CustomModal>
            )}
            {/* {modal.isOpen1 && (
        <CustomModal
          modal={{ isOpen: modal.isOpen1 }}
          style={{ width: "90%", height: "80vh", padding: "30px" }}
        >
          <CodeManager
            modal={modal}
            setModal={setModal}
            contentKey="managementDepartment"
            apiUrl="manDepartments"
            modalKey="isOpen1"
          />
        </CustomModal>
      )} */}
            <Modal open={companyModal.isOpen}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "90%",
                        height: "90%",
                        padding: "30px",
                        bgcolor: "#fff",
                        borderRadius: "10px",
                        border: "none",
                    }}
                >
                    <Companies
                        token={token}
                        modal={companyModal}
                        setModal={setCompanyModal}
                    />
                </Box>
            </Modal>
        </div>
    );
}

export default OrderCreate;
