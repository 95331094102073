import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  TableHead,
  Typography,
  Grid,
  TextField,
  Divider,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import {
  exportDataToExcel,
  getBirthYear,
  formatKoreanPhoneNumber,
} from "../../utils/methods";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import { FormInputText } from "../../components/form-components/FormInputText";
import CustomCheckbox from "../../components/CustomCheckbox";
import {
  getEmployeeDataById,
  getUserConcults,
  getUsersList,
} from "../../api/user";
import {
  careerPeriod,
  eduLevel,
  gender,
  level,
  order,
  pageSize,
  searchTypeName,
  selectRange,
  sort,
  years,
} from "../../constants/constants";
import { toast } from "react-toastify";
import SmsSendModal from "../../components/modals/SmsSendModal";
import Loader from "../../components/loader";
import DataNotFound from "../../components/DataNotFound";
import { FormInputDate } from "../../components/form-components/FormInputDate";
import CustomModal from "../../components/modals/CustomModal";
import CodeManager from "../../components/modals/CodeManager";

const initialQuery = {
  number: 1,
  size: 20,
  order: "DESC",
  sort: "updatedDate",
  gender: "",
  dateTo: "",
  keyword: "",
  eduLevel: "",
  dateFrom: "",
  searchType: "name",
  // status: "",
  extraSearch: {
    korean: "",
    msWord: "",
    msExcel: "",
    powerPoint: "",
    erp: "",
    pc: "", //Accounting, computerized accounting
    photoshop: "",
    illustrator: "",
    maya: "",
    nuke: "",
    threDMax: "",
    C4D: "",
    finalCut: "",
    edius: "",
    avid: "",
    premiere: "",
    after: "",
    shooting: "",
    filming: "",
    education: [],
    dateFrom: "",
    dateTo: "",
    occupation: "",
    experience: "",
    keyword: "",
  },
};

function JobSeeker() {
  const { handleSubmit, control, watch, reset } = useForm({
    defaultValues: initialQuery,
  });
  const { user } = useSelector((state) => state.user);
  const [query, setQuery] = useState(initialQuery);
  const [users, setUsers] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [usersData, setUsersData] = useState();
  const [smsModal, setSmsModal] = useState({ isOpen: false });
  const [isNoData, setIsNoData] = useState(false);
  const [openSearchInput, setOpenSearchInput] = useState(false);
  const [modal, setModal] = useState({ isOpen: false });

  const searchJob = () => {
    setModal({ ...modal, isOpen: true, keyword: watch("searchJob") });
  };

  const handleChangePage = (_, value) => {
    setQuery({ ...query, number: value });
  };

  const donwloadExcel = () => {
    const checkedData = [];
    usersData?.map((data) => {
      if (data.isChecked) {
        checkedData.push(data);
      }
      return data.isChecked;
    });

    if (checkedData.length === 0) {
      toast.warning("인원을 선택해 주세요!");
    } else {
      exportDataToExcel(checkedData, "구직자");
    }
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    var tempUser = {};
    if (name === "allSelect") {
      tempUser = usersData.map((user) => {
        return { ...user, isChecked: checked };
      });
    } else {
      tempUser = usersData.map((user) =>
        user.username + user.id === name
          ? { ...user, isChecked: checked }
          : user
      );
    }
    setUsersData(tempUser);
  };

  async function userList() {
    try {
      const { data } = await getUsersList(query, user.token);
      if (data) {
        setIsNoData(false);
        setUsers(data);
      } else {
        setIsNoData(true);
      }
    } catch (error) {
      toast.warning(error.response?.data.message);
    }
  }

  const submit = async (values) => {
    const eduFrom = values.extraSearch.education.eduLevelFrom ?? "";
    const eduFromId = eduLevel.filter((e) => e.value === eduFrom);

    const eduTo = values.extraSearch.education.eduLevelTo ?? "";
    const eduToId = eduLevel.filter((e) => e.value === eduTo);

    const from = new Date(values.extraSearch.dateFrom);
    const to = new Date(values.extraSearch.dateTo);
    const dateFrom = values.extraSearch.dateFrom
      ? from.toISOString().split("T")[0]
      : "";
    const dateTo = values.extraSearch.dateTo
      ? to.toISOString().split("T")[0]
      : "";

    let filteredArray = [];
    eduLevel.forEach((el) => {
      if (el.id >= eduFromId[0].id && el.id <= eduToId[0].id) {
        filteredArray.push(el.value);
      }
    });

    if (values.dateFrom && !values.dateTo) {
      toast.warning("연말을 입력해 주세요!");
    } else if (values.dateTo && !values.dateFrom) {
      toast.warning("시작 연도를 입력해 주세요!");
    } else if (
      (values.dateTo && values.dateTo) < (values.dateFrom && values.dateFrom)
    ) {
      toast.warning("연말이 시작 연도 보다 많아야 합니다!");
    } else {
      values.dateFrom = values.dateFrom ? `${values.dateFrom}-01-01` : "";
      values.dateTo = values.dateTo ? `${values.dateTo}-12-31` : "";
      setQuery({
        ...query,
        ...values,
        ...{
          extraSearch: {
            ...values.extraSearch,
            dateFrom: dateFrom,
            dateTo: dateTo,
            education: eduFrom && eduTo ? filteredArray : [],
          },
        },
      });
    }
  };

  const setInitial = () => {
    reset(initialQuery);
    setQuery(initialQuery);
  };

  const smsSend = () => {
    const checkedData = [];
    usersData?.map((data) => {
      if (data.isChecked) {
        checkedData.push(data);
      }
      return data.isChecked;
    });

    if (checkedData.length === 0) {
      toast.warning("인원을 선택해 주세요!");
    } else {
      setSmsModal({ isOpen: true, checkedUsers: checkedData });
    }
  };

  const fetchMemo = async (list) => {
    try {
      const { data: memo } = await getUserConcults(
        `employeeConsultations/getByEmployeeId`,
        list.id,
        user.token
      );

      const mergedData = { memo };

      // if (list.status === "APPROVED" && memo) {
      //   const { data: emp } = await getEmployeeDataById(list.id, user.token);
      //   mergedData.emp = emp;
      // }

      return mergedData;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!users) return;

      const mapUserData = async (userData) => {
        const { memo, emp } = await fetchMemo(userData);

        if (emp) {
          return {
            ...userData,
            bankSalary: emp.bankSalary || "",
            occupationName: emp.occupation?.name || "",
            personInCharge: emp.personInCharge || "",
          };
        }

        const lastMemoIndex = memo ? memo.length - 1 : -1;
        return {
          ...userData,
          memo: lastMemoIndex >= 0 ? memo[lastMemoIndex].contents : "",
        };
      };
      try {
        setIsLoading(true);
        const promises =
          users.content?.map(mapUserData) || users?.map(mapUserData);
        const data = await Promise.all(promises);
        setUsersData(data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    userList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <Paper
      sx={{
        width: "100%",
        boxShadow: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          mb: "12px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography
          style={{
            minWidth: "95px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#5a5a5a",
          }}
        >
          검색
        </Typography>
        <FormInputDropdown
          control={control}
          name="searchType"
          sx={{ width: "111px", marginRight: "10px" }}
          options={searchTypeName}
        />
        <FormInputText
          sx={{ width: "200px", marginRight: "6px" }}
          control={control}
          name="keyword"
          placeholder="내용을 입력해 주세요"
        />
        <Button
          size="small"
          onClick={handleSubmit(submit)}
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "7px 18px",
            color: "#7653DF",
            background: "#EEEAFB",
          }}
        >
          검색
        </Button>
        <Link to="/user-create" style={{ marginLeft: "auto" }}>
          <Button
            size="small"
            sx={{
              fontSize: "13px",
              width: "auto",
              height: "30px",
              padding: "8px 26px",
              color: "#FFF",
              backgroundColor: "#7653DF",
            }}
          >
            회원 등록 <AddIcon />
          </Button>
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          mb: "12px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography
          style={{
            minWidth: "95px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#5a5a5a",
          }}
        >
          범위 선택
        </Typography>
        <FormInputDropdown
          control={control}
          name="status"
          options={selectRange}
          sx={{ width: "111px", marginRight: "10px" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          mb: "12px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography
          style={{
            minWidth: "95px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#5a5a5a",
          }}
        >
          성별
        </Typography>
        <FormInputDropdown
          control={control}
          name="gender"
          options={gender}
          sx={{ width: "111px", marginRight: "10px" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          mb: "12px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography
          style={{
            minWidth: "95px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#5a5a5a",
          }}
        >
          출생년도
        </Typography>
        <FormInputDropdown
          control={control}
          name="dateFrom"
          sx={{ width: "111px", marginRight: "10px" }}
          options={years()}
        />
        <Typography
          style={{ minWidth: "38px", marginRight: "24px", color: "#7A7A7A" }}
        >
          부터
        </Typography>
        <FormInputDropdown
          control={control}
          name="dateTo"
          sx={{ width: "111px", marginRight: "10px" }}
          options={years()}
        />
        <Typography style={{ minWidth: "auto", color: "#7A7A7A" }}>
          까지
        </Typography>
      </Box>
      {/* <div style={{ margin: "30px auto 20px auto", borderColor: "#E7E7E7" }} /> */}

      {modal.isOpen && (
        <CustomModal
          modal={{ isOpen: modal.isOpen }}
          style={{ width: "90%", height: "80vh", padding: "30px" }}
        >
          <CodeManager
            modal={modal}
            setModal={setModal}
            contentKey="occupation"
            apiUrl="occupation"
            modalKey="isOpen"
          />
        </CustomModal>
      )}

      {!openSearchInput ? (
        <img
          onClick={() => setOpenSearchInput(!openSearchInput)}
          src="/icons/add.svg"
          alt="add"
          style={{ cursor: "pointer" }}
        />
      ) : (
        <Box sx={{ display: "flex" }}>
          <img
            onClick={() => setOpenSearchInput(!openSearchInput)}
            src="/icons/plus-square.svg"
            alt="plus"
            style={{ cursor: "pointer" }}
          />
          <Typography sx={{ color: "#5A5A5A", fontSize: "13px" }} ml="6px">
            상세 검색
          </Typography>
        </Box>
      )}

      <Divider sx={{ mt: 3, mb: 4 }} />

      {openSearchInput && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    minWidth: "95px",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#5a5a5a",
                  }}
                >
                  직종
                </Typography>
                <Box display="flex" alignItems="center" width="295px">
                  <FormInputText
                    control={control}
                    name="occupation"
                    staticValue={modal.occupation?.name}
                  />
                  <Button
                    onClick={searchJob}
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "12px",
                      marginLeft: "10px",
                      lineHeight: "15px",
                      padding: "8px 26px",
                      color: "#7653DF",
                      backgroundColor: "#EEEAFB",
                    }}
                  >
                    검색
                  </Button>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "auto",
                  mt: 2,
                }}
              >
                <Typography
                  style={{
                    minWidth: "95px",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#5a5a5a",
                  }}
                >
                  학력
                </Typography>
                <FormInputDropdown
                  control={control}
                  name={`extraSearch.education.eduLevelFrom`}
                  sx={{ width: "111px", marginRight: "10px" }}
                  options={eduLevel}
                />
                <Typography
                  style={{
                    minWidth: "38px",
                    marginRight: "24px",
                    color: "#7A7A7A",
                  }}
                >
                  부터
                </Typography>
                <FormInputDropdown
                  control={control}
                  name={`extraSearch.education.eduLevelTo`}
                  sx={{ width: "111px", marginRight: "10px" }}
                  options={eduLevel}
                />
                <Typography style={{ minWidth: "auto", color: "#7A7A7A" }}>
                  까지
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "auto",
                  mt: 2,
                }}
              >
                <Typography style={{ minWidth: "95px", fontWeight: 500 }}>
                  최근 업데이트
                </Typography>
                <FormInputDate
                  name={`extraSearch.dateFrom`}
                  control={control}
                  placeholder="YYYY/MM/DD"
                  sx={{
                    ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
                      fontSize: "16px",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      color="info"
                      size="small"
                      variant="outlined"
                      {...params}
                      sx={{
                        width: "127px",
                        background: "#fff",
                        ".MuiInputBase-root": {
                          paddingRight: "0px",
                        },
                        ".MuiIconButton-root": {
                          marginRight: "0px",
                        },
                        input: {
                          paddingRight: "0px!important",
                        },
                        "& .MuiSvgIcon-root": {
                          fill: "#987EE7 !important",
                        },
                      }}
                    />
                  )}
                />
                <Typography style={{ margin: "0 25px 0 10px" }}>
                  부터
                </Typography>
                <FormInputDate
                  name={`extraSearch.dateTo`}
                  control={control}
                  placeholder="YYYY/MM/DD"
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      {...params}
                      sx={{
                        width: "127px",
                        background: "#fff",
                        paddingRight: "0px!important",
                        ".MuiInputBase-root": {
                          paddingRight: "0px",
                        },
                        ".MuiIconButton-root": {
                          marginRight: "0px",
                        },
                        input: {
                          paddingRight: "0px!important",
                        },
                        "& .MuiSvgIcon-root": {
                          fill: "#987EE7 !important",
                        },
                      }}
                    />
                  )}
                />
                <Typography style={{ margin: "0 12px 0 10px" }}>
                  까지
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    minWidth: "95px",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#5a5a5a",
                  }}
                >
                  경력기간
                </Typography>
                <FormInputDropdown
                  sx={{ width: "295px" }}
                  control={control}
                  name={`extraSearch.career`}
                  options={careerPeriod}
                />
              </Box>
              <Box sx={{ display: "flex", mt: 2 }}>
                <Typography
                  style={{
                    minWidth: "95px",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#5a5a5a",
                  }}
                >
                  키워드
                </Typography>
                <FormInputText
                  sx={{ width: "295px", marginRight: "6px" }}
                  control={control}
                  name={`extraSearch.keyword`}
                  placeholder="내용을 입력해 주세요"
                />
              </Box>
              <Box sx={{ display: "flex", ml: 11.875, mt: 1 }}>
                <Typography sx={{ color: "#6B7280", fontSize: "12px" }}>
                  검색 범위 안내
                  <br />
                  <li>기본정보: 근무업체명/세부 수행 업무/기타 설명</li>
                  <li>
                    이력정보: 성장과정, 성격의 장/단점, 지원동기, 보유기술, 입사
                    가능 시기
                  </li>
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            marginTop={4}
            color="#5A5A5A"
          >
            <Typography whiteSpace="nowrap" fontWeight="600" width="9%">
              OA/문서작성
            </Typography>
            <Box>
              <Box display="flex">
                <Typography fontWeight="500" whiteSpace="nowrap" width="107px">
                  한글
                </Typography>
                <FormInputDropdown
                  sx={{ marginBottom: "10px", width: "150px" }}
                  control={control}
                  name={`extraSearch.korean`}
                  options={level}
                />
                <Typography
                  fontWeight="500"
                  whiteSpace="nowrap"
                  width="107px"
                  ml="25px"
                >
                  MS워드
                </Typography>
                <FormInputDropdown
                  sx={{ marginBottom: "10px", width: "150px" }}
                  control={control}
                  name={`extraSearch.msWord`}
                  options={level}
                />
              </Box>
              <Box display="flex">
                <Typography fontWeight="500" whiteSpace="nowrap" width="107px">
                  전산,ERP
                </Typography>
                <FormInputDropdown
                  sx={{ width: "150px" }}
                  control={control}
                  name={`extraSearch.erp`}
                  options={level}
                />
                <Typography
                  fontWeight="500"
                  whiteSpace="nowrap"
                  width="107px"
                  ml="25px"
                >
                  회계, 전산회계
                </Typography>
                <FormInputDropdown
                  sx={{ width: "150px" }}
                  control={control}
                  name={`extraSearch.pc`}
                  options={level}
                />
              </Box>
            </Box>
            <Typography
              fontWeight="500"
              whiteSpace="nowrap"
              mt="5px"
              width="107px"
            >
              MS엑셀
            </Typography>
            <FormInputDropdown
              sx={{ width: "150px" }}
              control={control}
              name={`extraSearch.msExcel`}
              options={level}
            />
            <Typography
              fontWeight="500"
              whiteSpace="nowrap"
              mt="5px"
              width="107px"
            >
              파워포인트
            </Typography>
            <FormInputDropdown
              sx={{ width: "150px" }}
              control={control}
              name={`extraSearch.powerPoint`}
              options={level}
            />
          </Box>

          <Divider sx={{ my: 2.5 }} />

          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            color="#5A5A5A"
          >
            <Typography
              whiteSpace="nowrap"
              mt="5px"
              fontWeight="700"
              width="10%"
            >
              영상디자인
            </Typography>
            <Box>
              <Box display="flex">
                <Typography
                  fontWeight="500"
                  whiteSpace="nowrap"
                  mt="5px"
                  width="107px"
                >
                  포토샵
                </Typography>
                <FormInputDropdown
                  sx={{ marginBottom: "10px", width: "150px" }}
                  control={control}
                  name={`extraSearch.photoshop`}
                  options={level}
                />
                <Typography
                  fontWeight="500"
                  whiteSpace="nowrap"
                  mt="5px"
                  width="107px"
                  ml="26px"
                >
                  일러스트
                </Typography>
                <FormInputDropdown
                  sx={{ marginBottom: "10px", width: "150px" }}
                  control={control}
                  name={`extraSearch.illustrator`}
                  options={level}
                />
              </Box>
              <Box display="flex">
                <Typography
                  fontWeight="500"
                  whiteSpace="nowrap"
                  mt="5px"
                  width="107px"
                >
                  3DMax
                </Typography>
                <FormInputDropdown
                  sx={{ width: "150px" }}
                  control={control}
                  name={`extraSearch.threDMax`}
                  options={level}
                />
                <Typography
                  fontWeight="500"
                  whiteSpace="nowrap"
                  mt="5px"
                  width="107px"
                  ml="26px"
                >
                  C4D
                </Typography>
                <FormInputDropdown
                  sx={{ width: "150px" }}
                  control={control}
                  name={`extraSearch.c4D`}
                  options={level}
                />
              </Box>
            </Box>
            <Typography
              fontWeight="500"
              whiteSpace="nowrap"
              mt="5px"
              width="107px"
              ml="26px"
            >
              Maya
            </Typography>
            <FormInputDropdown
              sx={{ width: "150px" }}
              control={control}
              name={`extraSearch.maya`}
              options={level}
            />
            <Typography
              fontWeight="500"
              whiteSpace="nowrap"
              mt="5px"
              width="107px"
              ml="26px"
            >
              Nuke
            </Typography>
            <FormInputDropdown
              sx={{ width: "150px" }}
              control={control}
              name={`extraSearch.nuke`}
              options={level}
            />
          </Box>

          <Divider sx={{ my: 2.5 }} />

          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            color="#5A5A5A"
          >
            <Typography
              whiteSpace="nowrap"
              mt="5px"
              fontWeight="600"
              width="10%"
            >
              영상 편집
            </Typography>
            <Box>
              <Box display="flex">
                <Typography
                  fontWeight="500"
                  whiteSpace="nowrap"
                  mt="5px"
                  width="107px"
                >
                  파이널컷
                </Typography>
                <FormInputDropdown
                  sx={{ marginBottom: "10px", width: "150px" }}
                  control={control}
                  name={`extraSearch.finalCut`}
                  options={level}
                />
                <Typography
                  fontWeight="500"
                  whiteSpace="nowrap"
                  mt="5px"
                  width="107px"
                  ml="26px"
                >
                  EDIUS
                </Typography>
                <FormInputDropdown
                  sx={{ marginBottom: "10px", width: "150px" }}
                  control={control}
                  name={`extraSearch.edius`}
                  options={level}
                />
              </Box>
              <Box display="flex">
                <Typography
                  fontWeight="500"
                  whiteSpace="nowrap"
                  mt="5px"
                  width="107px"
                >
                  애프터이펙트
                </Typography>
                <FormInputDropdown
                  sx={{ width: "150px" }}
                  control={control}
                  name={`extraSearch.after`}
                  options={level}
                />
              </Box>
            </Box>
            <Typography
              fontWeight="500"
              whiteSpace="nowrap"
              mt="5px"
              width="107px"
              ml="26px"
            >
              AVID
            </Typography>
            <FormInputDropdown
              sx={{ width: "150px" }}
              control={control}
              name={`extraSearch.avid`}
              options={level}
            />
            <Typography
              fontWeight="500"
              whiteSpace="nowrap"
              mt="5px"
              width="107px"
              ml="26px"
            >
              Premiere
            </Typography>
            <FormInputDropdown
              sx={{ width: "150px" }}
              control={control}
              name={`extraSearch.premiere`}
              options={level}
            />
          </Box>

          <Divider sx={{ my: 2.5 }} />

          <Box display="flex" color="#5A5A5A">
            <Typography
              whiteSpace="nowrap"
              mt="5px"
              fontWeight="600"
              width="13%"
            >
              영상 촬영
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <Typography
                fontWeight="500"
                whiteSpace="nowrap"
                mt="5px"
                width="107px"
              >
                촬영(6mm,DSLR)
              </Typography>
              <FormInputDropdown
                sx={{ marginBottom: "10px", width: "150px" }}
                control={control}
                name={`extraSearch.shooting`}
                options={level}
              />
              <Typography
                fontWeight="500"
                whiteSpace="nowrap"
                mt="5px"
                width="107px"
                ml="26px"
              >
                촬영(ENG,EFP)
              </Typography>
              <FormInputDropdown
                sx={{ marginBottom: "10px", width: "150px" }}
                control={control}
                name={`extraSearch.filming`}
                options={level}
              />
            </Box>
          </Box>
          <Divider sx={{ mt: 6.75, mb: 2.5 }} />
        </>
      )}

      <Box
        sx={{
          display: "flex",
          mb: "40px",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Button
          size="small"
          sx={{
            fontSize: "13px",
            width: "auto",
            mr: "30px",
            height: "30px",
            padding: "7px 30px",
            color: "#fff",
            background: "#987EE7",
          }}
          onClick={handleSubmit(submit)}
        >
          설정한 조건으로 검색
        </Button>
        <Box
          size="small"
          onClick={setInitial}
          sx={{
            textDecoration: "underline",
            fontSize: "14px",
            color: "#6B7280",
            fontWeight: 500,
            "&:hover": {
              color: "#8E66FF",
              cursor: "pointer",
            },
          }}
        >
          검색조건 초기화
        </Box>
      </Box>

      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Loader style={{ backgroundColor: "red" }} />
        </Box>
      ) : isNoData ? (
        <DataNotFound />
      ) : (
        users && (
          <>
            <Box
              sx={{
                display: "flex",
                mb: "15px",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={smsSend}
                  size="small"
                  sx={{
                    fontSize: "12px",
                    mr: "6px",
                    height: "30px",
                    padding: "7px 20px",
                    color: "#fff",
                    background: "#6B7280",
                  }}
                >
                  SMS
                </Button>
                <Button
                  onClick={() => donwloadExcel()}
                  sx={{
                    fontSize: "12px",
                    mr: "12px",
                    height: "30px",
                    padding: "7px 20px",
                    color: "#fff",
                    background: "#6B7280",
                  }}
                >
                  엑셀 다운로드
                </Button>
                <CustomCheckbox
                  name="allSelect"
                  checked={!usersData?.some((user) => user.isChecked !== true)}
                  onChange={handleChange}
                />
                <Typography
                  style={{
                    minWidth: "95px",
                    fontWeight: "500",
                    color: "#5A5A5A",
                  }}
                >
                  현재 해당되는 모든 회원 ( {users?.totalElements} 명) 선택
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormInputDropdown
                  control={control}
                  name="order"
                  onChange={(e) =>
                    setQuery({ ...query, order: e.target.value })
                  }
                  sx={{ width: "111px", marginRight: "10px" }}
                  options={order}
                  staticValue={query.order}
                />
                <FormInputDropdown
                  control={control}
                  name="sort"
                  onChange={(e) => setQuery({ ...query, sort: e.target.value })}
                  sx={{ width: "111px", marginRight: "10px" }}
                  options={sort}
                  staticValue={query.sort}
                />
                <FormInputDropdown
                  control={control}
                  name="size"
                  onChange={(e) => setQuery({ ...query, size: e.target.value })}
                  sx={{ width: "111px" }}
                  options={pageSize}
                  staticValue={query.size}
                />
              </Box>
            </Box>
            <TableContainer
              className="table"
              sx={{
                mt: "10px",
                height: `${window.innerHeight - 550}px`,
                position: "relative",
              }}
            >
              <Table
                sx={{
                  "& .MuiTableCell-root": {
                    border: "0.5px solid #b0aeae",
                  },
                }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead
                  sx={{
                    background: "#ebebeb",
                    position: "sticky",
                    top: -1,
                    zIndex: 10,
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        color: "#5A5A5A",
                        fontSize: "13px",
                        width: "",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      <CustomCheckbox
                        color="primary"
                        name="allSelect"
                        checked={
                          !usersData?.some((user) => user.isChecked !== true)
                        }
                        onChange={handleChange}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#000000",
                        fontSize: "13px",
                        width: "73px",
                      }}
                      align="center"
                    >
                      번호
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#000000",
                        fontSize: "13px",
                        width: "52px",
                      }}
                      align="center"
                    >
                      성명
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 9px 10px 2px",
                        whiteSpace: "nowrap",
                        color: "#000000",
                        fontSize: "13px",
                        width: "23px",
                      }}
                      align="center"
                    >
                      성별
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#000000",
                        fontSize: "13px",
                      }}
                      align="center"
                    >
                      출생년도
                    </TableCell>

                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#000000",
                        fontSize: "13px",
                      }}
                      align="center"
                    >
                      휴대폰
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#000000",
                        fontSize: "13px",
                      }}
                      align="center"
                    >
                      학력
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#000000",
                        fontSize: "13px",
                        maxWidth: "202px",
                      }}
                      align="center"
                    >
                      거주지
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#000000",
                        fontSize: "13px",
                      }}
                      align="center"
                    >
                      메모
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#000000",
                        fontSize: "13px",
                      }}
                      align="center"
                    >
                      관리자
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#000000",
                        fontSize: "13px",
                      }}
                      align="center"
                    >
                      최근 업데이트
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#000000",
                        fontSize: "13px",
                      }}
                      align="center"
                    >
                      구직 현황
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersData?.map((user, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        role="checkbox"
                        key={index}
                        sx={{
                          ":hover": {
                            backgroundColor: "#EEEAFB !important",
                          },
                          backgroundColor:
                            user.status === "PENDING" ||
                            user.status === "APPROVED"
                              ? "#f7ed94 !important"
                              : user.status === "PRE_RETIRED" ||
                                user.status === "RETIRED"
                              ? "#e6e3e3 !important"
                              : "",
                          ".css-1d46hnn-MuiTableCell-root": {
                            padding: "1px",
                          },
                          textDecoration: "none",
                          height: "40px",
                        }}
                      >
                        <TableCell
                          padding="checkbox"
                          sx={{
                            padding: "10px",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                          align="center"
                        >
                          <CustomCheckbox
                            name={user.username + user.id}
                            checked={user?.isChecked || false}
                            onChange={handleChange}
                          />
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/user-details/${user.id}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            color: "#1F2329",
                            maxWidth: "59px",
                            textOverflow: "ellipsis",
                          }}
                          align="center"
                        >
                          {query.size * (query.number - 1) + index + 1}
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/user-details/${user.id}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            color: "#1F2329",
                            maxWidth: "85px",
                            textOverflow: "ellipsis",
                          }}
                          align="center"
                        >
                          {user.name}
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/user-details/${user.id}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            color: "#1F2329",
                            maxWidth: "59px",
                            textOverflow: "ellipsis",
                          }}
                          align="center"
                        >
                          {user.gender}
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/user-details/${user.id}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            color: "#1F2329",
                            maxWidth: "75px",
                            textOverflow: "ellipsis",
                          }}
                          align="center"
                        >
                          {getBirthYear(user.birthday)}
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/user-details/${user.id}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            color: "#1F2329",
                            maxWidth: "128px",
                            textOverflow: "ellipsis",
                          }}
                          align="center"
                        >
                          {user.phoneNumber
                            ? formatKoreanPhoneNumber(user.phoneNumber)
                            : null}
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/user-details/${user.id}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            color: "#1F2329",
                            maxWidth: "86px",
                            textOverflow: "ellipsis",
                          }}
                          align="center"
                        >
                          {user.eduLevel}
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/user-details/${user.id}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            maxWidth: "202px",
                            textOverflow: "ellipsis",
                          }}
                          align="center"
                        >
                          {user.address}
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/user-details/${user.id}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                          }}
                          align="center"
                        >
                          <p
                            style={{
                              inlineSize: "480px",
                              overflowWrap: "break-word",
                            }}
                          >
                            {user.memo}
                          </p>
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/user-details/${user.id}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                            whiteSpace: "nowrap",
                            color: "#1F2329",
                            overflow: "hidden",
                            maxWidth: "75px",
                            textOverflow: "ellipsis",
                          }}
                          align="center"
                        >
                          {user.personInCharge}
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/user-details/${user.id}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            color: "#1F2329",
                            maxWidth: "107px",
                            textOverflow: "ellipsis",
                          }}
                          align="center"
                        >
                          {user.updatedDate }
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/user-details/${user.id}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                            color: "#2D9CDB",
                            "&:hover": {
                              color: "#8e66ff",
                            },
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            maxWidth: "107px",
                            textOverflow: "ellipsis",
                          }}
                          align="center"
                        >
                          구직 신청 중 ({user.jobRequestCount} 개)
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
              <Pagination
                count={Math.ceil(users.totalElements / query.size) || 0}
                onChange={handleChangePage}
                page={query.number}
                color="primary"
                variant="outlined"
                shape="rounded"
                sx={{
                  m: "0 auto",
                  "& .MuiPaginationItem-root.MuiPaginationItem-page, .MuiPaginationItem-previousNext":
                    { padding: "4px 20px !important" },
                }}
              />
            </Stack>
          </>
        )
      )}
      <SmsSendModal
        setModal={setSmsModal}
        modal={{ ...smsModal, isOpen: smsModal.isOpen }}
        modalKey="isOpen"
      />
    </Paper>
  );
}

export default JobSeeker;
