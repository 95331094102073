import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { FormControl, Grid } from "@mui/material";

const MultiCheckBoxList = ({ state, handleChange }) => {
    const checkBoxes = state?.map((item) => {
        return (
            <FormControlLabel
                key={item.id}
                control={
                    <Checkbox
                        checked={item.checked}
                        onChange={handleChange}
                        name={`checked_${item.id}`}
                    />
                }
                label={item.name}
            />
        );
    });

    return (
        <Box sx={{ flexGrow: 1 }} >
            <FormControl component="fieldset">
                <FormGroup>
                    <Grid container spacing={2}>
                        {checkBoxes}
                    </Grid>
                </FormGroup>
            </FormControl>
        </Box>
    );
};

export default MultiCheckBoxList;
