import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import BackButton from "../../components/BackButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import LinkButton from "../../components/LinkButton";
import { useForm } from "react-hook-form";
import CustomCheckbox from "../../components/CustomCheckbox";
import { order, pageSize, sort, testUsers } from "../../constants/constants";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import { getSalaryByDate } from "../../api/payroll";

const initialQuery = {
  number: 1,
  size: 20,
  order: "DESC",
  sort: "createdDate",
  dateString: "",
};

export default function PayrollDetails() {
  const { date } = useParams();
  const { control } = useForm();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.user.user);
  const [query, setQuery] = useState(initialQuery);
  const [users, setUsers] = useState({
    content: [],
    additionalData: {},
    totalSalary: {},
  });
  const [formatDate, setFormatDate] = useState({
    year: null,
    month: null,
  });

  const handleChangePage = (_, value) => {
    setQuery({ ...query, number: value });
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    var tempUser = {};
    if (name === "allSelect") {
      tempUser = users.content.map((user) => {
        return { ...user, isChecked: checked };
      });
    } else {
      tempUser = users.content.map((user) =>
        user.companyName + user.dateString === name
          ? { ...user, isChecked: checked }
          : user
      );
    }
    setUsers({ ...users, content: tempUser });
  };

  const getSalary = async () => {
    if (query.dateString) {
      try {
        const data = await getSalaryByDate(query, token);
        setUsers({
          content: data.data,
          additionalData: data.additionalData,
          totalSalary: data.totalSalary,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const decreaseMonth = () => {
    const currentYear = parseInt(formatDate.year, 10);
    const currentMonth = parseInt(formatDate.month, 10);

    let newMonth = currentMonth - 1;
    let newYear = currentYear;

    if (newMonth < 1) {
      newMonth = 12;
      newYear--;
    }

    // Update the state
    setFormatDate({
      year: newYear,
      month: newMonth < 10 ? `0${newMonth}` : `${newMonth}`,
    });
  };

  const increaseMonth = () => {
    const currentYear = parseInt(formatDate.year, 10);
    const currentMonth = parseInt(formatDate.month, 10);

    let newMonth = currentMonth + 1;
    let newYear = currentYear;

    if (newMonth > 12) {
      newMonth = 1;
      newYear++;
    }

    // Update the state
    setFormatDate({
      year: newYear,
      month: newMonth < 10 ? `0${newMonth}` : `${newMonth}`,
    });
  };

  useEffect(() => {
    const dateParts = date?.split("-");
    setFormatDate({ year: dateParts[0], month: dateParts[1] });
    setQuery({ ...query, dateString: date });
  }, [date]);

  useEffect(() => {
    getSalary();
  }, [query]);

  useEffect(() => {
    if (formatDate.month) {
      if (date !== `${formatDate.year}-${formatDate.month}`) {
        navigate(`/payroll-management/${formatDate.year}-${formatDate.month}`);
      }
    }
  }, [formatDate.month]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <BackButton
          onClick={() => navigate(`/payroll-management`)}
          text="월별 내역"
        />
        <LinkButton label="목록보기" link="/payroll-management" />
      </Box>
      <hr style={{ margin: "30px auto" }} />
      <Box display="flex" alignItems="center">
        <Button onClick={decreaseMonth}>
          <img src="/icons/arrow-left.png" alt="prev" />
        </Button>
        <Typography
          style={{
            margin: "0 6px",
            color: "#5A5A5A",
            fontSize: "24px",
            fontWeight: "700",
          }}
        >
          {formatDate.year + "년 " + formatDate.month + "월"}
        </Typography>
        <Button onClick={increaseMonth}>
          <img
            src="/icons/arrow-left.png"
            style={{ transform: "rotate(180deg)" }}
            alt="prev"
          />
        </Button>
      </Box>
      <Box
        sx={{
          border: "0.5px solid #828282",
          padding: "12px 0 19px",
          margin: "36px 0",
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "12px",
                    border: "none",
                    borderRight: "1px solid #828282",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  지급횟수
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "12px",
                    border: "none",
                    borderRight: "1px solid #828282",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  지급 인원
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "12px",
                    border: "none",
                    borderRight: "1px solid #828282",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  지급 총액
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "12px",
                    border: "none",
                    borderRight: "1px solid #828282",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  공제 총액
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "12px",
                    border: "none",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  차인 지급 총액
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    border: "none",
                    fontSize: "16px",
                    borderRight: "1px solid #828282",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  {users.additionalData?.allCount || 0} 회
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    border: "none",
                    fontSize: "16px",
                    borderRight: "1px solid #828282",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  {users.additionalData?.employeeCount || 0} 명
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    border: "none",
                    fontSize: "16px",
                    borderRight: "1px solid #828282",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  {users.totalSalary?.sum?.toLocaleString() || 0} 원
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    border: "none",
                    fontSize: "16px",
                    borderRight: "1px solid #828282",
                    textAlign: "center",
                    color: "#A91515",
                  }}
                  align="center"
                >
                  {users.totalSalary?.sub?.toLocaleString() || 0} 원
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    border: "none",
                    fontSize: "20px",
                    textAlign: "center",
                    fontWeight: "600",
                    color: "#1270B0",
                  }}
                  align="center"
                >
                  {(
                    users.totalSalary?.sum - users.totalSalary?.sub
                  )?.toLocaleString() || 0}{" "}
                  원
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box
        sx={{
          display: "flex",
          mb: "15px",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            size="small"
            sx={{
              fontSize: "12px",
              mr: "6px",
              height: "30px",
              padding: "7px 20px",
              color: "#fff",
              background: "#6B7280",
            }}
          >
            급여 일괄 승인 해제
          </Button>
          <Button
            sx={{
              fontSize: "12px",
              mr: "12px",
              height: "30px",
              padding: "7px 20px",
              color: "#fff",
              background: "#8E66FF",
            }}
          >
            급여 일괄 승인
          </Button>
          <CustomCheckbox
            name="allSelect"
            checked={!users.content?.some((user) => user.isChecked !== true)}
            onChange={handleChange}
          />
          <Typography
            style={{
              minWidth: "95px",
              fontWeight: "500",
              color: "#5A5A5A",
            }}
          >
            현재 해당되는 모든 회원 ( {users.content.length} 명) 선택
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormInputDropdown
            control={control}
            name="size"
            sx={{ width: "111px" }}
            onChange={(e) => setQuery({ ...query, size: e.target.value })}
            options={pageSize}
            staticValue={query.size}
          />
        </Box>
      </Box>
      <TableContainer
        className="table"
        sx={{
          mt: "10px",
          borderTop: "1px solid #98a6ad",
        }}
      >
        <Table size="small" aria-label="a dense table">
          <TableHead sx={{ background: "#FCFCFC" }}>
            <TableRow>
              <TableCell
                sx={{
                  whiteSpace: "nowrap",
                  color: "#5A5A5A",
                  fontSize: "13px",

                  textAlign: "center",
                }}
                align="center"
              >
                <CustomCheckbox
                  color="primary"
                  name="allSelect"
                  checked={
                    !users.content?.some((user) => user.isChecked !== true)
                  }
                  onChange={handleChange}
                />
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  color: "#5A5A5A",
                  fontSize: "13px",
                }}
                align="center"
              >
                번호
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  color: "#5A5A5A",
                  fontSize: "13px",
                }}
                align="center"
              >
                급여 년월
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  color: "#5A5A5A",
                  fontSize: "13px",
                }}
                align="center"
              >
                지급처
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  color: "#5A5A5A",
                  fontSize: "13px",
                }}
                align="center"
              >
                지급 인원
              </TableCell>

              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  color: "#5A5A5A",
                  fontSize: "13px",
                }}
                align="center"
              >
                지급 총액
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  color: "#5A5A5A",
                  fontSize: "13px",
                }}
                align="center"
              >
                공제 총액
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  color: "#5A5A5A",
                  fontSize: "13px",
                }}
                align="center"
              >
                차인 지급 총액
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  color: "#5A5A5A",
                  fontSize: "13px",
                }}
                align="center"
              >
                승인여부
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.content.map((user, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  role="checkbox"
                  key={index}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#EEEAFB",
                    },
                    ".css-1d46hnn-MuiTableCell-root": {
                      padding: "1px",
                    },
                    textDecoration: "none",
                    height: "40px",
                  }}
                >
                  <TableCell
                    padding="checkbox"
                    sx={{
                      padding: "10px",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    <CustomCheckbox
                      name={user.companyName + user.dateString}
                      checked={user?.isChecked || false}
                      onChange={handleChange}
                    />
                  </TableCell>
                  <TableCell
                    id={labelId}
                    component={Link}
                    to={`/payroll-applicant/${user.companyName}/${date}`}
                    scope="row"
                    padding="none"
                    sx={{
                      textDecoration: "none",
                      padding: "10px",
                    }}
                    align="center"
                  >
                    {query.size * (query.number - 1) + 1 + index}
                  </TableCell>
                  <TableCell
                    id={labelId}
                    component={Link}
                    to={`/payroll-applicant/${user.companyName}/${date}`}
                    scope="row"
                    padding="none"
                    sx={{
                      textDecoration: "none",
                      padding: "10px",
                    }}
                    align="center"
                  >
                    {user.dateString}
                  </TableCell>
                  <TableCell
                    id={labelId}
                    component={Link}
                    to={`/payroll-applicant/${user.companyName}/${date}`}
                    scope="row"
                    padding="none"
                    sx={{
                      textDecoration: "none",
                      padding: "10px",
                    }}
                    align="center"
                  >
                    {user.companyName}
                  </TableCell>
                  <TableCell
                    id={labelId}
                    component={Link}
                    to={`/payroll-applicant/${user.companyName}/${date}`}
                    scope="row"
                    padding="none"
                    sx={{
                      textDecoration: "none",

                      padding: "10px",
                    }}
                    align="center"
                  >
                    {user.employeeCount || 0}
                  </TableCell>
                  <TableCell
                    id={labelId}
                    component={Link}
                    to={`/payroll-applicant/${user.companyName}/${date}`}
                    scope="row"
                    padding="none"
                    sx={{
                      textDecoration: "none",
                      padding: "10px",
                    }}
                    align="center"
                  >
                    {user.sum?.toLocaleString() || 0} 원
                  </TableCell>
                  <TableCell
                    id={labelId}
                    component={Link}
                    to={`/payroll-applicant/${user.companyName}/${date}`}
                    scope="row"
                    padding="none"
                    sx={{
                      textDecoration: "none",
                      padding: "10px",
                      color: "#A91515",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    {user.sub?.toLocaleString() || 0} 원
                  </TableCell>
                  <TableCell
                    id={labelId}
                    component={Link}
                    to={`/payroll-applicant/${user.companyName}/${date}`}
                    scope="row"
                    padding="none"
                    sx={{
                      textDecoration: "none",
                      padding: "10px",
                      color: "#1270B0",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    {(user.sum - user.sub)?.toLocaleString()} 원
                  </TableCell>
                  <TableCell
                    id={labelId}
                    scope="row"
                    padding="none"
                    sx={{
                      textDecoration: "none",
                      padding: "10px",
                      fontWeight: "600",
                      color: `${user.status === "NO" ? "#A91515" : "#1270B0"}`,
                      cursor: "pointer",
                    }}
                    align="center"
                  >
                    {user.status === "NO" ? "N" : "Y"}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
        <Pagination
          count={Math.ceil(users?.additionalData?.totalCount / query.size) || 0}
          onChange={handleChangePage}
          page={query.number}
          color="primary"
          variant="outlined"
          shape="rounded"
          sx={{
            m: "0 auto",
            "& .css-1k2i9p6-MuiButtonBase-root-MuiPaginationItem-root": {
              height: "20px",
              minWidth: "20px",
              backgroundColor: "#CCCCCC",
              color: "#fff",
            },
          }}
        />
      </Stack>
    </Box>
  );
}
