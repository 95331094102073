import { Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function BackButton({ text = "", onClick }) {
  const navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };
  return (
    <Button
      className="back-button no-print"
      onClick={onClick ?? back}
      style={{
        textDecoration: "none",
        color: "#3a3a3a",
        display: "flex",
        padding: 3,
        minWidth: "unset",
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          fill="#987EE7"
        />
        <path
          d="M12 8L8 12L12 16"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 12H8"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <Typography
        sx={{
          fontSize: "18px",
          ml: "10px",
          fontWeight: "770",
          lineHeight: "1",
        }}
      >
        {text}
      </Typography>
    </Button>
  );
}
