import mainCaller from "./mainCaller";

export async function getOrderById(id, token) {
  return await mainCaller(`order/getById/${id}`, "GET", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getCurrentDayOrders(data, token) {
  return await mainCaller(`order`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function updateOrderById(data, token) {
  return await mainCaller(`order/update`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function deleteOrderById(id, token) {
  return await mainCaller(`order/deleteById/${id}`, "DELETE", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function createOrder(data, token) {
  return await mainCaller(`order/create`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function copyOrders(data, token) {
  return await mainCaller(`order/copyOrders`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getAllOrders(date, token) {
  return await mainCaller(`order/daysContainingOrder/${date}`, "GET", null, {
    Authorization: `Bearer ${token}`,
  });
}
