export const scriptUrl =
  "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";

const dateOptions = { month: "long", day: "numeric", weekday: "long" };

export const gender = [
  {
    label: "선택",
    value: "",
  },
  {
    label: "남자",
    value: "남자",
  },
  {
    label: "여자",
    value: "여자",
  },
  { label: "기타", value: "기타" },
];

export const genderIrrelevant = [
  {
    label: "무관",
    value: "",
  },
  {
    label: "남자",
    value: "남자",
  },
  {
    label: "여자",
    value: "여자",
  },
  { label: "기타", value: "기타" },
];

export const smsAgreement = [
  {
    label: "동의",
    value: 1,
  },
  {
    label: "미동의",
    value: 0,
  },
];

export const timer = {
  months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
};

export const dayFilter = [
  {
    label: "전체",
    active: true,
  },
  {
    label: "오늘",
    active: false,
  },
  {
    label: "7일",
    active: false,
  },
  {
    label: "15일",
    active: false,
  },
  {
    label: "30일",
    active: false,
  },
];

// export const range = [
//   {
//     label: "선택",
//     value: "",
//   },
//   {
//     label: "오늘",
//     value: "",
//   },
//   {
//     label: "7일",
//     value: "",
//   },

// ];

export const eduLevel = [
  {
    id: 1,
    label: "선택",
    value: "",
  },
  {
    id: 2,
    label: "고졸",
    value: "고졸",
    // value: "HighSchoolGraduate",
  },
  {
    id: 3,
    label: "초대중퇴",
    value: "초대중퇴",
    // value: "NotEndedShortUniversity",
  },
  {
    id: 4,
    label: "초대재",
    value: "초대재",
    // value: "ShortUniversityStudent",
  },
  {
    id: 5,
    label: "초대휴학",
    value: "초대휴학",
    // value: "LeaveOfAbsenceShortUniversity",
  },
  {
    id: 6,
    label: "초대졸",
    value: "초대졸",
    // value: "GraduatedShotUniversityStudent",
  },
  {
    id: 7,
    label: "대학교중퇴",
    value: "대학교중퇴",
    // value: "NotEndedUniversity",
  },
  {
    id: 8,
    label: "대학교재",
    value: "대학교재",
    // value: "UniversityStudent",
  },
  {
    id: 9,
    label: "대학교휴학",
    value: "대학교휴학",
    // value: "UniversityLeaveOfAbsence",
  },
  {
    id: 10,
    label: "대학교졸",
    value: "대학교졸",
    // value: "UniversityGraduate",
  },
  {
    id: 11,
    label: "대학원중퇴",
    value: "대학원중퇴",
    // value: "NotEndedMasterDegreeUniversity",
  },
  {
    id: 12,
    label: "대학원재",
    value: "대학원재",
    // value: "MasterDegreeStudent",
  },
  {
    id: 13,
    label: "대학원휴학",
    value: "대학원휴학",
    // value: "LeaveOfAbsenceFromMasterDegree",
  },
  {
    id: 14,
    label: "대학원졸",
    value: "대학원졸",
    // value: "MasterDegreeGraduateStudent",
  },
  {
    id: 15,
    label: "기타",
    value: "기타",
    // value: "MasterDegreeGraduateStudent",
  },
];
export const eduLevelIrrelevant = [
  {
    label: "무관",
    value: "",
  },
  {
    label: "고졸",
    value: "고졸",
    // value: "HighSchoolGraduate",
  },
  {
    label: "초대중퇴",
    value: "초대중퇴",
    // value: "NotEndedShortUniversity",
  },
  {
    label: "초대재",
    value: "초대재",
    // value: "ShortUniversityStudent",
  },
  {
    label: "초대휴학",
    value: "초대휴학",
    // value: "LeaveOfAbsenceShortUniversity",
  },
  {
    label: "초대졸",
    value: "초대졸",
    // value: "GraduatedShotUniversityStudent",
  },
  {
    label: "대학교중퇴",
    value: "대학교중퇴",
    // value: "NotEndedUniversity",
  },
  {
    label: "대학교재",
    value: "대학교재",
    // value: "UniversityStudent",
  },
  {
    label: "대학교휴학",
    value: "대학교휴학",
    // value: "UniversityLeaveOfAbsence",
  },
  {
    label: "대학교졸",
    value: "대학교졸",
    // value: "UniversityGraduate",
  },
  {
    label: "대학원중퇴",
    value: "대학원중퇴",
    // value: "NotEndedMasterDegreeUniversity",
  },
  {
    label: "대학원재",
    value: "대학원재",
    // value: "MasterDegreeStudent",
  },
  {
    label: "대학원휴학",
    value: "대학원휴학",
    // value: "LeaveOfAbsenceFromMasterDegree",
  },
  {
    label: "대학원졸",
    value: "대학원졸",
    // value: "MasterDegreeGraduateStudent",
  },
];

export const eduCategory = [
  {
    label: "선택",
    value: "",
  },
  {
    label: "고등학교",
    value: "고등학교",
  },
  {
    label: "대학(2년재)",
    value: "대학(2년재)",
  },
  {
    label: "대학교(4년재)",
    value: "대학교(4년재)",
  },
  {
    label: "대학원",
    value: "대학원",
  },
];

export const eduGraduation = [
  {
    label: "선택",
    value: "",
  },
  {
    label: "졸업 ",
    value: "졸업 ",
  },
  {
    label: "재학중",
    value: "재학중",
  },
  {
    label: "휴학",
    value: "휴학",
  },
  {
    label: "중퇴",
    value: "중퇴",
  },
  {
    label: "졸업예정",
    value: "졸업예정",
  },
  {
    label: "수료",
    value: "수료",
  },
];

export const location = [
  {
    label: "선택",
    value: "",
  },
  {
    label: "서울 ",
    value: "서울 ",
  },
  {
    label: "인천",
    value: "인천",
  },
  {
    label: "대전",
    value: "대전",
  },
  {
    label: "대구",
    value: "대구",
  },
  {
    label: "울산",
    value: "울산",
  },
  {
    label: "부산",
    value: "부산",
  },
  {
    label: "광주",
    value: "광주",
  },
  {
    label: "경기도",
    value: "경기도",
  },
  {
    label: "강원도",
    value: "강원도",
  },
  {
    label: "경상남도",
    value: "경상남도",
  },
  {
    label: "경상북도",
    value: "경상북도",
  },
  {
    label: "충청남도",
    value: "충청남도",
  },
  {
    label: "충청북도",
    value: "충청북도",
  },
  {
    label: "전라남도",
    value: "전라남도",
  },
  {
    label: "전라북도",
    value: "전라북도",
  },
  {
    label: "제주도",
    value: "제주도",
  },
];

export const employmentType = [
  {
    label: "선택",
    value: "",
  },
  {
    label: "정규직",
    value: "정규직",
  },
  {
    label: "계약직",
    value: "계약직",
  },
  {
    label: "도급직",
    value: "도급직",
  },
  {
    label: "파견직",
    value: "파견직",
  },
  {
    label: "아르바이트",
    value: "아르바이트",
  },
  {
    label: "프리랜서",
    value: "프리랜서",
  },
];

export const reasonOfRetired = [
  {
    label: "선택",
    value: "",
  },
  {
    label: "이직",
    value: "이직",
  },
  {
    label: "계약만료",
    value: "계약만료",
  },
  {
    label: "개인사정",
    value: "개인사정",
  },
  {
    label: "근무환경불만",
    value: "근무환경불만",
  },
  {
    label: "급여불만",
    value: "급여불만 ",
  },
  {
    label: "근무회사불만",
    value: "근무회사불만",
  },
  {
    label: "소속회사불만",
    value: "소속회사불만",
  },
  {
    label: "해고",
    value: "해고",
  },
  {
    label: "권고사직",
    value: "권고사직",
  },
  {
    label: "원거리출퇴근",
    value: "원거리출퇴근",
  },
  {
    label: "업무부담(과중)",
    value: "업무부담(과중)",
  },
  {
    label: "육아,가사",
    value: "육아,가사",
  },
  {
    label: "임신",
    value: "임신",
  },
  {
    label: "입사포기",
    value: "입사포기",
  },
  {
    label: "사용사업주변동(퇴직금연동)",
    value: "사용사업주변동(퇴직금연동)",
  },
  {
    label: "사용사업주변동(퇴직금비연동)",
    value: "사용사업주변동(퇴직금비연동)",
  },
  {
    label: "정규직전환 / 자체계약직변환",
    value: "정규직전환 / 자체계약직변환",
  },
];

export const situation = [
  {
    label: "선택",
    value: "",
  },
  {
    label: "엔트리 예정",
    value: "엔트리 예정",
  },
  {
    label: "엔트리 펑크",
    value: "엔트리 펑크",
  },
  {
    label: "엔트리 완료",
    value: "엔트리 완료",
  },
  {
    label: "서류 접수",
    value: "서류 접수",
  },
  {
    label: "매칭 예정",
    value: "매칭 예정",
  },
  {
    label: "매칭 펑크",
    value: "매칭 펑크",
  },

  {
    label: "매칭 불합격",
    value: "매칭 불합격",
  },
  {
    label: "입사포기",
    value: "입사포기",
  },
  {
    label: "결착",
    value: "결착",
  },
  {
    label: "타기업 취업",
    value: "타기업 취업",
  },
  {
    label: "추천 불가",
    value: "추천 불가",
  },
];

export const subCompanySitution = [
  {
    label: "선택",
    value: "",
  },
  {
    label: "거래종료",
    value: "거래종료",
  },
  {
    label: "거래중",
    value: "거래중",
  },
  {
    label: "거래정지",
    value: "거래정지",
  },
  {
    label: "신규거래",
    value: "신규거래",
  },
];

export const honorAndProtect = [
  { label: "선택", value: "" },
  { label: "비대상", value: "비대상" },
  { label: "대상", value: "대상" },
];

export const isExist = [
  { label: "있음", value: true },
  { label: "없음", value: false },
];

export const classification = [
  { label: "선택", value: "" },
  { label: "병역필", value: "병역필" },
  { label: "미필", value: "미필" },
  { label: "면제", value: "면제" },
  { label: "특례", value: "특례" },
  { label: "해당없음", value: "해당없음" },
];

export const level = [
  { label: "선택", value: "" },
  { label: "상", value: "상" },
  { label: "중", value: "중" },
  { label: "하", value: "하" },
];

export const county = [
  { label: "선택", value: "" },
  { label: "육군", value: "육군" },
  { label: "해군", value: "해군" },
  { label: "공군", value: "공군" },
  { label: "해병", value: "해병" },
  { label: "전경", value: "전경" },
  { label: "의경", value: "의경" },
  { label: "해경", value: "해경" },
  { label: "기타", value: "기타" },
  { label: "해당없음", value: "해당없음" },
];

export const languages = [
  { label: "선택", value: "" },
  { label: "영어", value: "영어" },
  { label: "일어", value: "일어" },
  { label: "중어", value: "중어" },
  { label: "독어", value: "독어" },
  { label: "러시아어", value: "러시아어" },
  { label: "스페인어", value: "스페인어" },
  { label: "베트남어", value: "베트남어" },
  { label: "이탈리아어", value: "이탈리아어" },
  { label: "기타", value: "기타" },
];

export const careerPeriod = [
  { label: "무관", value: "" },
  { label: "없음", value: "없음" },
  { label: "3개월 미만", value: "3개월 미만" },
  { label: "3개월 이상", value: "3개월 이상" },
  { label: "6개월 이상", value: "6개월 이상" },
  { label: "1년 이상", value: "1년 이상" },
  { label: "2년 이상", value: "2년 이상" },
  { label: "3년 이상", value: "3년 이상" },
  { label: "4년 이상", value: "4년 이상" },
  { label: "5년 이상", value: "5년 이상" },
  { label: "6년 이상", value: "6년 이상" },
  { label: "7년 이상", value: "7년 이상" },
  { label: "8년 이상", value: "8년 이상" },
  { label: "9년 이상", value: "9년 이상" },
];

export const searchType = [
  { label: "선택", value: "" },
  { label: "성명", value: "name" },
  { label: "휴대폰", value: "phoneNumber" },
  { label: "학교", value: "schoolName" },
  { label: "종교", value: "religion" },
  { label: "관리자", value: "manager" },
  { label: "희망직종", value: "desiredOccupation" },
];

export const searchTypeContact = [
  { label: "선택", value: "" },
  { label: "성명", value: "name" },
  { label: "휴대폰", value: "phoneNumber" },
  { label: "관리자", value: "manager" },
];

export const searchTypeName = [
  { label: "성명", value: "name" },
  { label: "휴대폰", value: "phoneNumber" },
  { label: "학교", value: "schoolName" },
  { label: "종교", value: "religion" },
  { label: "관리자", value: "manager" },
  { label: "희망직종", value: "desiredOccupation" },
];

export const searchType2 = [
  { label: "선택", value: "" },
  { label: "성명", value: "name" },
  { label: "주민번호", value: "social1" },
  { label: "사원번호", value: "employeeNumber" },
  { label: "입사일자", value: "createdDate" },
  { label: "업체명", value: "companyName" },
  { label: "연락처", value: "phoneNumber" },
  { label: "관리자", value: "manager" },
];

export const searchType3 = [
  { label: "선택", value: "" },
  { label: "성명", value: "name" },
  { label: "주민번호", value: "social1" },
  { label: "사원번호", value: "employeeNumber" },
  { label: "입사일자", value: "joiningDate" },
  { label: "업체명", value: "customerName" },
  { label: "연락처", value: "phoneNumber" },
  { label: "관리자", value: "manager" },
];

export const years = () => {
  const date = new Date();
  const startYear = date.getFullYear();
  const endYear = 1930;
  const years = Array.from(
    { length: startYear - endYear + 1 },
    (_, index) => startYear - index
  );
  const yearsOptions = years.map((year) => ({ label: year, value: year }));
  return [
    {
      label: "선택",
      value: "",
    },
    ...yearsOptions,
  ];
};

export const yearsNumber = () => {
  const date = new Date();
  const startYear = date.getFullYear();
  const endYear = 1930;
  const years = Array.from(
    { length: startYear - endYear + 1 },
    (_, index) => startYear - index
  );
  const yearsOptions = years.map((year) => ({ label: year, value: year }));
  return [...yearsOptions];
};

export const ages = () => {
  const startYear = 20;
  const endYear = 70;
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index
  );
  return years.map((year) => ({ label: year, value: year }));
};

export const months = [
  {
    label: "01",
    value: 1,
  },
  {
    label: "02",
    value: 2,
  },
  {
    label: "03",
    value: 3,
  },
  {
    label: "04",
    value: 4,
  },
  {
    label: "05",
    value: 5,
  },
  {
    label: "06",
    value: 6,
  },
  {
    label: "07",
    value: 7,
  },
  {
    label: "08",
    value: 8,
  },
  {
    label: "09",
    value: 9,
  },
  {
    label: "10",
    value: 10,
  },
  {
    label: "11",
    value: 11,
  },
  {
    label: "12",
    value: 12,
  },
];

export const workAreas = [
  { label: "전체", value: "" },
  { label: "서울 전지역", value: "서울 전지역" },
  { label: "서울 강북지역", value: "서울 강북지역" },
  { label: "서울 강남지역", value: "서울 강남지역" },
  { label: "서울 강동지역", value: "서울 강동지역" },
  { label: "서울 강서지역", value: "서울 강서지역" },
  { label: "인천 광역시", value: "인천 광역시" },
  { label: "대전 광역시", value: "대전 광역시" },
  { label: "대구 광역시", value: "대구 광역시" },
  { label: "울산 광역시", value: "울산 광역시" },
  { label: "부산 광역시", value: "부산 광역시" },
  { label: "광주 광역시", value: "광주 광역시" },
  { label: "경기 북부", value: "경기 북부" },
  { label: "경기 남부", value: "경기 남부" },
  { label: "경기 동부", value: "경기 동부" },
  { label: "경기 서부", value: "경기 서부" },
  { label: "강원도", value: "강원도" },
  { label: "겅상남도", value: "겅상남도" },
  { label: "경상북도", value: "경상북도" },
  { label: "충청남도", value: "충청남도" },
  { label: "충청북도", value: "충청북도" },
  { label: "전라남도", value: "전라남도" },
  { label: "전라북도", value: "전라북도" },
  { label: "희망직종", value: "희망직종" },
  { label: "제주도", value: "제주도" },
];

export const order = [
  { label: "정순", value: "DESC" },
  { label: "역순", value: "ASC" },
];

export const pageSize = [
  { label: "20개씩", value: 20 },
  { label: "50개씩", value: 50 },
  { label: "100개씩", value: 100 },
];

export const sort = [
  { label: "최근가입순", value: "createdDate" },
  { label: "구직현황수순", value: "jobRequestCount" },
];

export const toDay = new Date().toISOString().replace(/T.*$/, "");

export const toDayCountry = (country) =>
  new Date().toLocaleString(country, dateOptions);

export const bannerStatus = [
  {
    label: "선택",
    value: "",
  },
  {
    label: "게시중",
    value: "ACTIVE",
  },
  {
    label: "숨김",
    value: "INACTIVE",
  },
];

export const popUpSettings = [
  { label: "닫기", value: "ACTIVE" },
  { label: "오늘 하루 다시 보지 않기", value: "INACTIVE" },
  { label: "표시 안함", value: "INACTIVE1" },
];

export const testUsers = {
  content: [
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
    {
      id: 1,
      code: "J999",
      name: "표기영역표기영역표기영역표기영역",
    },
  ],
};

export const jobDisplayPlace = [
  { label: "선택", value: "" },
  { label: "스페셜 채용관", value: "스페셜 채용관" },
  { label: "전체 채용 정보", value: "전체 채용 정보" },
];

export const jobType = [
  {
    label: "정규직",
    value: "정규직",
  },
  {
    label: "파견직",
    value: "파견직",
  },
  {
    label: "도급직",
    value: "도급직",
  },
  {
    label: "위탁",
    value: "위탁",
  },
  {
    label: "아르바이트",
    value: "아르바이트",
  },
  {
    label: "프리랜서",
    value: "프리랜서",
  },
  {
    label: "계약직",
    value: "계약직",
  },
  {
    label: "기타",
    value: "기타",
  },
];

export const usageStatus = [
  {
    label: "사용",
    value: "사용",
  },
  {
    label: "사용 정지",
    value: "사용 정지",
  },
];

export const adminAccess = [
  {
    value: "관리자 현황",
    label: "관리자 현황",
  },

  { value: "인재 관리", label: "인재 관리" },

  { value: "채용정보 관리", label: "채용정보 관리" },

  { value: "거래처 관리", label: "거래처 관리" },

  { value: "내부 관리", label: "내부 관리" },

  { value: "급여 관리", label: "급여 관리" },

  { value: "게시/공지 관리", label: "게시/공지 관리" },

  { value: "통계", label: "통계" },
];

export const adminRating = [
  {
    label: "1급",
    value: "1급",
  },
  {
    label: "2급",
    value: "2급",
  },
  {
    label: "3급",
    value: "3급",
  },
];

export const memberGroup = [
  { label: "전체", value: "ALL" },
  { label: "구직자", value: "SEEKER" },
  { label: "사원 대기자", value: "PENDING" },
  { label: "사원", value: "APPROVED" },
  { label: "퇴사 대기자", value: "PRE_RETIRED" },
  { label: "퇴사자", value: "RETIRED" },
];

export const selectRange = [
  { label: "전체", value: "" },
  { label: "구직 대기자", value: "SEEKER" },
];
