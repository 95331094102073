import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FormInputText } from "../../components/form-components/FormInputText";

import { sendMessageToAllMember } from "../../api/employee";
import { FormInputTextArea } from "../../components/form-components/FormInputTextArea";
import SubCompanySelector from "../../components/modals/SubCompanySelector";
import { memberGroup } from "../../constants/constants";

import Loader from "../../components/loader";
import AcceptModalSms from "../../components/modals/AcceptModalSms";
import ToastModal from "../../components/modals/ToastModal";

// const checkEmploye = {
//   adminCode: "관리자 코드를 입력하세요",
//   name: "성명을 입력하세",
//   password: "비밀번호를  입력하세요",
//   department: "부서를 입력하세요",
//   usageStatusType: "사용 상태를 입력하세요",
//   socialInsuranceNumber: "주민번호를 입력하세요",
//   phoneNumber: "연락처를 입력하세요",
//   email: "이메일을 입력하세요",
//   rank: "접근 권한을 입력하세요",
//   adminRating: "등급을 입력하세요",
// };

function SmsSent() {
  const navigation = useNavigate();
  const { token } = useSelector((state) => state.user.user);
  const { handleSubmit, control, reset } = useForm({
    shouldUseNativeValidation: true,
  });

  const [modal, setModal] = useState({
    isOpen: false,
  });
  const [acceptModal, setAcceptModal] = useState({
    isOpen: false,
  });
  const [warningModal, setWarningModal] = useState({
    isOpen: false,
  });
  const [radioValue, setRadioValue] = useState(0);
  const [memberStatus, setMemberStatus] = useState("");
  const [employee, setEmployee] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setEmployee(modal.subCustomer ? modal.subCustomer.phone : null);
  }, [modal]);

  const onChangeRadioGroup = (values) => {
    setRadioValue(values.target.value);
  };

  const handleChangeSelect = (event) => {
    setMemberStatus(event.target.value);
  };

  const searchCompany = () => {
    setModal({ ...modal, isOpen: true });
  };

  const onClickAcceptModal = () => {
    setAcceptModal({ isOpen: true });
  };

  const submit = async (values) => {
    if (radioValue === 0) toast.warning("수신자 선택!");
    // eslint-disable-next-line eqeqeq
    else if (values == {}) toast.warning("talbar uudaa buglu");
    else if (!values.message)
      toast.warning("발송 내용 입력하여는지 확인해 주세요!");
    else if (!values.phoneNumber)
      toast.warning("발송번호 입력하여는지 확인해 주세요!");
    else if (!values.userPhoneNumber && radioValue === "1")
      toast.warning("수신자 번호 입력하여는지 확인해 주세요!");
    else if (memberStatus === "" && radioValue === "2") toast.warning("select");
    else {
      setAcceptModal({ isOpen: false });
      setIsLoading(true);
      let data = {};
      // eslint-disable-next-line default-case
      switch (radioValue) {
        case "1":
          data = {
            group: radioValue,
            userPhoneNumber: values.userPhoneNumber,
            adminPhoneNumber: values.phoneNumber,
            message: values.message,
          };
          break;
        case "2":
          data = {
            group: radioValue,
            adminPhoneNumber: values.phoneNumber,
            message: values.message,
            memberStatus: memberStatus,
          };
          break;
        case "3":
          data = {
            group: radioValue,
            adminPhoneNumber: values.phoneNumber,
            message: values.message,
            employeeNumber: employee,
          };
          break;
      }

      console.log("data: ", JSON.stringify(data));
      try {
        const result = await sendMessageToAllMember(data, token);
        if (result.body.statusCode === 200) {
          toast.success(result);
          setWarningModal({ isOpen: true });
          setIsLoading(false);
          reset();
        } else if (result.body.statusCode === 404) {
          toast.warning(result.body.message);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log("error: ", error);
        toast.warning(error.response?.data.message);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            background: "white",
            borderRadius: "5px",
            paddingTop: "10px",
            textAlign: "center",
          }}
        >
          <Loader style={{ backgroundColor: "red" }} />
        </div>
      ) : (
        <div
          style={{
            background: "white",
            borderRadius: "5px",
            paddingTop: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          ></Box>
          <Typography mb="8px" fontSize="18px" fontWeight="700">
            SMS 보내기
          </Typography>
          <hr style={{ margin: "16px 0px 20px 0" }} />
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                width: "100%",
                pl: "20px",
                marginTop: "50px",
              }}
            >
              <Grid container>
                <Grid item xs={8}>
                  <Typography mb="8px" fontSize="13px" fontWeight="500">
                    수신자 선택
                  </Typography>
                  <RadioGroup
                    key={"value"}
                    defaultValue={"value"}
                    onChange={onChangeRadioGroup}
                  >
                    {/* 1 */}
                    <Grid item mb="8px" container>
                      <Grid item mb="4px" xs={2}>
                        <FormControlLabel
                          sx={{
                            mt: "4px",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                          key={1}
                          value={"1"}
                          label={"번호 입력"}
                          control={
                            <Radio
                              sx={{
                                padding: "0px 9px 0 24px",
                              }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item mb="4px" xs={7}>
                        <Typography color="red" fontSize={12}>
                          핸드폰 번호는 반드시 "-" 제외하고 입력하세요!
                        </Typography>
                        <FormInputText
                          control={control}
                          name="userPhoneNumber"
                          placeholder="내용을 입력하여 주세요"
                          disabled={radioValue === "1" ? false : true}
                        />{" "}
                      </Grid>
                    </Grid>
                    {/* 2 */}
                    <Grid item mb="8px" container>
                      <Grid item mb="4px" xs={2}>
                        <FormControlLabel
                          sx={{
                            mt: "4px",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                          key={1}
                          value={"2"}
                          label={"회원 그룹"}
                          control={
                            <Radio
                              sx={{
                                padding: "0px 9px 0 24px",
                              }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item mb="4px" xs={7}>
                        <Select
                          defaultValue={""}
                          sx={{
                            fontSize: "13",
                            fontWeight: "500",
                            display: "flex",
                            margin: "0 0 0 auto",
                          }}
                          disabled={radioValue === "2" ? false : true}
                          onChange={handleChangeSelect}
                        >
                          {memberGroup.map((option) => (
                            <MenuItem value={option.value} key={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </Grid>
                    {/* 3 */}
                    <Grid item mb="8px" container>
                      <Grid item mb="4px" xs={2}>
                        <FormControlLabel
                          sx={{
                            mt: "4px",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                          key={1}
                          value={"3"}
                          label={"업체별 사원"}
                          control={
                            <Radio
                              sx={{
                                padding: "0px 9px 0 24px",
                              }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item mb="4px" xs={7}>
                        <Grid item mb="8px" container>
                          <Grid item mb="4px" xs={7}>
                            <FormInputText
                              control={control}
                              name="company"
                              placeholder="업체별 사원"
                              disabled={radioValue === "3" ? false : true}
                              staticValue={employee}
                            />
                          </Grid>
                          <Grid item mb="4px" xs={3}>
                            <Button
                              disabled={radioValue === "3" ? false : true}
                              onClick={searchCompany}
                              sx={{
                                whiteSpace: "nowrap",
                                fontSize: "13px",
                                fontWeight: "500",
                                marginLeft: "10px",
                                lineHeight: "15px",
                                padding: "8px 26px",
                                color: "#7653DF",
                                backgroundColor: "#EEEAFB",
                              }}
                            >
                              업체 선택
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* end */}
                  </RadioGroup>

                  <Grid item mb="8px" container>
                    <Grid item mb="4px" fontSize={13} fontWeight={500} xs={2}>
                      발송번호
                    </Grid>
                    <Grid item mb="4px" xs={7}>
                      <Typography color="red" fontSize={12}>
                        핸드폰 번호는 반드시 "-" 제외하고 입력하세요!
                      </Typography>
                      <FormInputText
                        control={control}
                        name="phoneNumber"
                        placeholder="발송번호"
                        // type="tel"
                        type="number"
                        InputProps={{ maxLength: 11 }}
                      />{" "}
                    </Grid>
                  </Grid>

                  <Grid item mb="8px" container>
                    <Grid item mb="4px" fontSize={13} fontWeight={500} xs={2}>
                      발송 내용
                    </Grid>
                    <Grid item mb="4px" xs={7}>
                      <FormInputTextArea
                        style={{
                          width: "100%",
                          background: "#fff",
                          borderRadius: "8px",
                          border: "1px solid #E0E0E0",
                        }}
                        placeholder="내용을 입력하여 주세요"
                        minRows={3}
                        control={control}
                        name="message"
                        maxLength={90}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item mb="8px" xs={2}></Grid>

                <Grid item xs={2} pl="35px">
                  <Grid container rowSpacing={1} alignItems="center"></Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <hr style={{ margin: "16px 0 40px 0" }} />
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Button
              size="small"
              sx={{
                display: "block",
                fontSize: "13px",
                fontWeight: "700",
                lineHeight: "15px",
                mr: "8px",
                padding: "7px 26px",
                color: "#fff",
                background: "#98A6AD",
              }}
              onClick={() => {
                navigation("/sms-list");
              }}
              // onClick={()=>setAcceptModal({isOpen:true})}
            >
              취소
            </Button>
            <Button
              size="small"
              onClick={onClickAcceptModal}
              sx={{
                fontSize: "13px",
                fontWeight: "700",
                lineHeight: "15px",
                padding: "7px 50px",
                display: "block",
                color: "#fff",
                backgroundColor: "#7653DF",
              }}
            >
              저장
            </Button>
          </Box>
          {/* <SubCompanySelector modal={modal} setModal={setModal} /> */}
          {modal.isOpen && (
            <SubCompanySelector modal={modal} setModal={setModal} />
          )}

          <AcceptModalSms
            setModal={setAcceptModal}
            modal={{ ...acceptModal, isOpen: acceptModal.isOpen }}
            handleClick={handleSubmit(submit)}
            modalKey="isOpen"
            title="메시지를 발송하시겠습니까?"
            content="발송한 문자는 취소할 수 없습니다."
          />
          <ToastModal
            setModal={setWarningModal}
            modal={{ ...warningModal, isOpen: warningModal.isOpen }}
            modalKey="isOpen"
            title="메시지를 발송하였습니다."
          />
        </div>
      )}{" "}
    </>
  );
}

export default SmsSent;
