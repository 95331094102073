import React from "react";
import { useSelector } from "react-redux";
import Companies from "./Companies";
import SubCompanies from "./SubCompanies";
import CustomModal from "./CustomModal";

export default function SubCompanySelector({ setModal, modal }) {
  const { token } = useSelector((state) => state.user.user);
  return (
    <CustomModal
      modal={modal}
      style={{ width: "90%", height: "90vh", padding: "30px" }}
    >
      {modal.customerId ? (
        <SubCompanies modal={modal} setModal={setModal} token={token} />
      ) : (
        <Companies modal={modal} setModal={setModal} token={token} />
      )}
    </CustomModal>
  );
}
