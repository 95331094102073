import React, { useState, useEffect } from "react";
import { Box, Fade, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { Link } from "react-router-dom";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  background: "#212B36",
  color: "#fff",

  "& .css-yw020d-MuiAccordionSummary-expandIconWrapper": {
    color: "#fff",
    // padding: 0,
    margin: 0,
  },
  "& .css-1l0yxov-MuiButtonBase-root-MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper.Mui-expanded":
    {
      color: "#7832dc",
    },

  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{
          fontSize: "0.9rem",
        }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "",

  flexDirection: "reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default function InterManagement(props) {
  const { expanded, handleChange, open } = props;
  const [childExp, setChildExp] = useState("panel1");

  const hChange = (panel) => (event, newExpanded) => {
    setChildExp(newExpanded ? panel : false);
  };

  useEffect(() => {
    if (expanded === "panel5") {
      setChildExp("panel1");
    }
  }, [expanded]);

  return (
    <Accordion
      expanded={expanded === "panel5"}
      onChange={handleChange("panel5")}
      sx={{
        "& .MuiButtonBase-root.MuiAccordionSummary-root ": {
          padding: "0",
          minWidth: "220px",

          "& .MuiAccordionSummary-content": { margin: "0" },
          "& .MuiAccordionSummary-expandIconWrapper": {
            color: expanded === "panel5" ? "#ffffff" : "#98A6AD",
          },
        },
      }}
    >
      <AccordionSummary aria-controls="panel5-content" id="panel5-header">
        <img
          src="/icons/inbox.svg"
          style={{
            filter: expanded === "panel5" ? "brightness(0) invert(1)" : "none",
          }}
          alt="users"
        />
        <Fade in={open}>
          <Paper
            sx={{
              background: "transparent",
              boxShadow: "none",
              ml: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "15px",
                color: expanded === "panel5" ? "#ffffff" : "#98A6AD",
              }}
            >
              내부 관리
            </Typography>
          </Paper>
        </Fade>
      </AccordionSummary>
      {open && (
        <AccordionDetails
          sx={{
            background: "",
            padding: "0!important",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "0",
            }}
          >
            <Box
              onClick={() => setChildExp("panel1")}
              component={Link}
              to="/personel-code-management"
              sx={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                padding: "6px 28px",
                borderRadius: "18px",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.10)",
                },
              }}
            >
              <Paper
                sx={{
                  background: "transparent",
                  boxShadow: "none",
                }}
              >
                <Typography
                  style={{
                    fontSize: "15px",
                    color: childExp === "panel1" ? "white" : "#98A6AD",
                  }}
                >
                  인사코드 관리
                </Typography>
              </Paper>
            </Box>
            <Box
              onClick={() => setChildExp("panel2")}
              component={Link}
              to="/inter/employee-management"
              sx={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                padding: "6px 28px",
                borderRadius: "18px",
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "rgba(255, 255, 255, 0.10)",
                },
              }}
            >
              <Paper
                sx={{
                  background: "transparent",
                  boxShadow: "none",
                }}
              >
                <Typography
                  style={{
                    fontSize: "15px",
                    color: childExp === "panel2" ? "white" : "#98A6AD",
                  }}
                >
                  사원 관리
                </Typography>
              </Paper>
            </Box>
          </Box>
        </AccordionDetails>
      )}
    </Accordion>
  );
}
