import React from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Controller } from "react-hook-form";

export const FormInputRadio = ({
  name,
  control,
  options,
  staticValue,
  row = false,
  sx,
}) => {
  const generateRadioOptions = () => {
    return options.map((singleOption) => (
      <FormControlLabel
        sx={sx}
        key={singleOption.value}
        value={singleOption.value}
        label={singleOption.label}
        control={<Radio sx={{ padding: "0px 9px" }} />}
      />
    ));
  };
  return (
    <FormControl component="fieldset">
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <RadioGroup
            key={value}
            defaultValue={staticValue ?? value}
            onChange={onChange}
            row={row}
          >
            {generateRadioOptions()}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};
