import React, { useState, useEffect } from "react";
import { Checkbox } from "@mui/material";

const CustomCheckbox = (props) => {
  const {
    checked: propsChecked,
    name,
    onChange,
    setValue = () => {},
    style,
  } = props;
  const [checked, setChecked] = useState(!!propsChecked);
  const handleChange = () => {
    setChecked((p) => !p);
    setValue(name, !checked);
  };

  useEffect(() => {
    setChecked(propsChecked);
  }, [propsChecked]);

  return (
    <div style={{ ...style, position: "relative", width: "37.5px" }}>
      <Checkbox
        name={name}
        checked={checked}
        onClick={onChange || handleChange}
        className="custom-checkbox"
        sx={{ color: "#914beb" }}
        style={{ opacity: 0, zIndex: 2 }}
        {...props}
      />
      <img
        src={checked ? "/icons/checked.svg" : "/icons/unchecked.svg"}
        alt="checkbox"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </div>
  );
};

export default CustomCheckbox;
