import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export const FormInputText = ({
  name = "",
  control,
  disabled,
  label,
  onChange,
  staticValue,
  placeholder,
  type = "text",
  rows,
  size = "small",
  maxRows,
  InputProps,
  multiline,
  required,
  sx,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleWheel = (event) => {
    event.preventDefault();
  };
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange: formOnChange, value },
        fieldState: { error },
      }) => (
        <TextField
          helpertext={error ? error.message : null}
          size={size}
          name={name}
          sx={sx}
          error={!!error}
          placeholder={placeholder}
          onChange={onChange ?? formOnChange}
          value={(staticValue ?? value) || ""}
          rows={rows}
          maxRows={maxRows}
          inputProps={InputProps}
          fullWidth
          multiline={multiline}
          type={showPassword ? "text" : type} // Toggle between "text" and "password"
          disabled={disabled}
          label={label}
          required={required}
          variant="outlined"
          InputProps={{
            endAdornment: type === "password" && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleTogglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOff sx={{ width: 15, height: 15 }} />
                  ) : (
                    <Visibility sx={{ width: 15, height: 15 }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onWheel={handleWheel}
        />
      )}
    />
  );
};
