import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  TableHead,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { order, pageSize } from "../../constants/constants";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import CustomCheckbox from "../../components/CustomCheckbox";
import {
  getApplicantsByJobId,
  getJobById,
  updateStatusByJobAppId,
} from "../../api/recruitment";
import {
  checkJobDedline,
  exportDataToExcel,
  formatKoreanPhoneNumber,
  getAge,
} from "../../utils/methods";
import SmsSendModal from "../../components/modals/SmsSendModal";
import BackButton from "../../components/BackButton";
import LinkButton from "../../components/LinkButton";
import DataNotFound from "../../components/DataNotFound";
import AcceptModal from "../../components/modals/AcceptModal";

const statusOptions = [
  { label: "선택", value: "" },
  {
    label: "대기",
    value: "REQUESTED",
  },
  {
    label: "서류합격",
    value: "PASSED",
  },
  {
    label: "1차 면졉합격",
    value: "FIRST_PASSED",
  },
  {
    label: "최종합격",
    value: "FINAL_PASSED",
  },
  {
    label: "불합격",
    value: "FAIL",
  },
  {
    label: "임시보관",
    value: "EDITING",
  },
];

const statusSwitcher = (value) => {
  switch (value) {
    case "REQUESTED":
      return "대기";
    case "PASSED":
      return "서류합격";
    case "FIRST_PASSED":
      return "1차 면졉합격";
    case "FINAL_PASSED":
      return "최종합격";
    case "FAIL":
      return "불합격";
    case "EDITING":
      return "임시보관";
    default:
      return "";
  }
};

const initialQuery = {
  number: 1,
  size: 20,
  order: "DESC",
  dateTo: "",
  keyword: "",
  dateFrom: "",
  searchType: "",
};

function SupportStatus() {
  const { id } = useParams();
  const { user } = useSelector((state) => state.user);
  const [query, setQuery] = useState(initialQuery);
  const [modal, setModal] = useState({
    isOpen: false,
  });
  const [applicants, setApplicants] = useState();
  const [company, setCompany] = useState();
  const [job, setJob] = useState();
  const [noData, setNoData] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const { handleSubmit, control, reset, setValue, watch } = useForm({
    defaultValues: initialQuery,
  });
  const [smsModal, setSmsModal] = useState({ isOpen: false });

  const handleChangePage = (_, value) => {
    setQuery({ ...query, number: value });
  };

  const downloadExcel = () => {
    const checkedData = [];
    applicants?.map((data) => {
      if (data.isChecked) {
        checkedData.push(data);
      }
      return data.isChecked;
    });

    if (checkedData.length === 0) {
      toast.warning("인원을 선택해 주세요!");
    } else {
      exportDataToExcel(checkedData, "지원 현황");
    }
  };

  const smsSend = () => {
    const checkedData = [];
    applicants?.map((data) => {
      if (data.isChecked) {
        checkedData.push(data);
      }
      return data.isChecked;
    });

    if (checkedData.length === 0) {
      toast.warning("인원을 선택해 주세요!");
    } else {
      setSmsModal({ isOpen: true, checkedUsers: checkedData });
    }
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    var tempUser = {};
    if (name === "allSelect") {
      tempUser = applicants?.map((user) => {
        return { ...user, isChecked: checked };
      });
    } else {
      tempUser = applicants?.map((user) =>
        user.name + user.id === name ? { ...user, isChecked: checked } : user
      );
    }
    setApplicants([...tempUser]);
  };

  async function userList() {
    const newQuery = { ...query, recruitmentId: id };
    try {
      const data = await getApplicantsByJobId(newQuery, user.token);
      if (data?.data.content?.length === 0) {
        setNoData(true);
      } else {
        setApplicants(data.data.content);
      }
      setCompany(data.additionalData);
    } catch (error) {
      console.log(error.response?.data.message);
    }
  }

  const getJob = async () => {
    try {
      const { data } = await getJobById(id, user.token, user?.id);
      setJob(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeById = async () => {
    try {
      if (modal.ids) {
        await Promise.all(
          modal.ids.map((data) =>
            updateStatusByJobAppId(
              JSON.stringify({ id: data, status: modal.status }),
              user.token
            )
          )
        );
      }
      setModal({ isOpen: false });
      toast.success("상태 병경이 완료 되었습니다.");
      userList();
    } catch (error) {
      console.log(error);
    }
  };

  const submit = async (values) => {
    const checkedData = [];
    applicants?.map((data) => {
      if (data.isChecked) {
        checkedData.push(data.id);
      }
      return data.isChecked;
    });

    if (checkedData.length === 0) {
      toast.warning("인원을 선택해 주세요!");
    } else if (!values.supportStatus) {
      toast.warning("인원을 선택해 주세요!");
    } else {
      setModal({
        isOpen: true,
        ids: checkedData,
        status: values.supportStatus,
      });
    }
  };

  useEffect(() => {
    userList();
    getJob();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, query]);

  return (
    <Paper
      sx={{
        width: "100%",
        boxShadow: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <BackButton text="지원 현황" />
        <LinkButton link="/job-management/all-jobs" label="목록보기" />
      </Box>

      <hr style={{ margin: "16px 0px 20px 0" }} />
      <Box
        display="flex"
        flexDirection="column"
        height={123}
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center" flexDirection="row">
          <Typography width="94px">고유번호</Typography>
          <Typography fontSize={18} fontWeight={700} color="#1F2329">
            {company?.code}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" flexDirection="row">
          <Typography width="94px">업체명</Typography>
          <Typography fontSize={18} fontWeight={700} color="#1F2329">
            {company?.name}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" flexDirection="row">
          <Typography width="94px">모집부문</Typography>
          <Typography fontSize={18} color="#1F2329">
            {job?.title}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" flexDirection="row">
          <Typography width="94px">관리자</Typography>
          <Typography fontSize={18} color="#1F2329">
            {company?.manager}
          </Typography>
        </Box>
      </Box>
      <hr style={{ margin: "16px 0px 20px 0" }} />
      <Box display="flex" alignItems="center" flexDirection="row">
        <Typography width="94px">상태 구분</Typography>
        <FormInputDropdown
          control={control}
          name="supportStatus1"
          sx={{ width: "111px" }}
          options={statusOptions}
        />
      </Box>
      <hr style={{ margin: "16px 0px 20px 0" }} />
      {applicants && (
        <Box
          sx={{
            display: "flex",
            mb: "15px",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              onClick={smsSend}
              sx={{
                fontSize: "13px",
                mr: "8px",
                height: "30px",
                padding: "7px 26px",
                color: "#fff",
                background: "#6B7280",
              }}
            >
              SMS
            </Button>
            <Button
              onClick={() => downloadExcel()}
              sx={{
                fontSize: "13px",
                mr: "8px",
                height: "30px",
                padding: "7px 26px",
                color: "#fff",
                background: "#6B7280",
                whiteSpace: "nowrap",
              }}
            >
              엑셀 다운로드
            </Button>
            <CustomCheckbox
              name="allSelect"
              checked={!applicants?.some((user) => user.isChecked !== true)}
              onChange={handleChange}
            />
            <Typography style={{ minWidth: "95px", fontWeight: 500 }}>
              현재 해당되는 모든 회원 ( {applicants?.length} 명) 선택
            </Typography>
            <Box ml={6} display="flex" alignItems="center" flexDirection="row">
              <Typography width="60px">상태 변경</Typography>
              <FormInputDropdown
                control={control}
                name="supportStatus"
                sx={{ width: "111px" }}
                options={statusOptions}
              />
              <Button
                onClick={handleSubmit(submit)}
                sx={{
                  backgroundColor: "#8E66FF",
                  color: "white",
                  width: "60px",
                  height: "30px",
                  borderRadius: "8px",
                  marginLeft: "10px",
                }}
              >
                저장
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormInputDropdown
              control={control}
              name="order"
              sx={{ width: "111px", marginRight: "10px" }}
              options={order}
              onChange={(e) => setQuery({ ...query, order: e.target.value })}
              staticValue={query.order}
            />
            <FormInputDropdown
              control={control}
              name="size"
              sx={{ width: "111px" }}
              onChange={(e) => setQuery({ ...query, size: e.target.value })}
              options={pageSize}
              staticValue={query.size}
            />
          </Box>
        </Box>
      )}
      <TableContainer
        className="table"
        sx={{
          borderTop: "1px solid #98A6AD",
        }}
      >
        <Table>
          <TableHead sx={{ background: "#fcfcfc" }}>
            <TableRow>
              <TableCell
                sx={{
                  whiteSpace: "nowrap",
                  fontWeight: 500,
                  textAlign: "center",
                }}
                align="center"
              >
                <CustomCheckbox
                  color="primary"
                  name="allSelect"
                  checked={!applicants?.some((user) => user.isChecked !== true)}
                  onChange={handleChange}
                />
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                align="center"
              >
                번호
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                align="center"
              >
                성명
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                align="center"
              >
                성별
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                align="center"
              >
                출생년도
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                align="center"
              >
                연락처
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                align="center"
              >
                최종 학력
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                align="center"
              >
                거주지
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                align="center"
              >
                지원 일자
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                align="center"
              >
                상태
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {applicants &&
              applicants.map((user, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    role="checkbox"
                    key={index}
                    sx={{
                      "&:hover": {
                        color: "#5a5a5a !important",
                        backgroundColor: "rgba(142, 102, 255, 0.22)",
                      },
                      textDecoration: "none",
                      height: "40px",
                    }}
                  >
                    <TableCell
                      padding="checkbox"
                      sx={{
                        padding: "10px",
                        fontWeight: 500,
                      }}
                      align="center"
                    >
                      <CustomCheckbox
                        name={user.name + user.id}
                        checked={user?.isChecked || false}
                        onChange={handleChange}
                      />
                    </TableCell>
                    <TableCell
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        fontWeight: "500",
                        padding: "10px",
                      }}
                      align="center"
                    >
                      {user.id}
                    </TableCell>
                    <TableCell
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        fontWeight: "500",
                        padding: "10px",
                      }}
                      align="center"
                    >
                      {user.name}
                    </TableCell>
                    <TableCell
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        fontWeight: "500",
                        padding: "10px",
                      }}
                      align="center"
                    >
                      {user.gender}
                    </TableCell>
                    <TableCell
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        fontWeight: "500",
                        padding: "10px",
                        whiteSpace: "nowrap",
                      }}
                      align="center"
                    >
                      {getAge(user.birthDay)}
                    </TableCell>
                    <TableCell
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        padding: "10px",
                        fontWeight: "500",
                        whiteSpace: "nowrap",
                      }}
                      align="center"
                    >
                      {user.phoneNumber &&
                        formatKoreanPhoneNumber(user.phoneNumber)}
                    </TableCell>
                    <TableCell
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        fontWeight: "500",
                        padding: "10px",
                        whiteSpace: "nowrap",
                      }}
                      align="center"
                    >
                      {user.education}
                    </TableCell>
                    <TableCell
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        fontWeight: "500",
                        padding: "10px",
                      }}
                      align="center"
                    >
                      {user.address}
                    </TableCell>
                    <TableCell
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        fontWeight: "500",
                        padding: "10px",
                        whiteSpace: "nowrap",
                      }}
                      align="center"
                    >
                      {getAge(user.applicationDate)}
                    </TableCell>
                    <TableCell
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        padding: "10px",
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "#2D9CDB",
                        "&:hover": {
                          color: "#8e66ff",
                        },
                        whiteSpace: "nowrap",
                      }}
                      align="center"
                    >
                      {statusSwitcher(user.status)}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {noData && <DataNotFound />}
      <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
        <Pagination
          count={Math.ceil(applicants?.totalElements / query.size) || 0}
          onChange={handleChangePage}
          page={query.number}
          color="primary"
          variant="outlined"
          shape="rounded"
          sx={{
            m: "0 auto",
            "& .MuiPaginationItem-root.MuiPaginationItem-page, .MuiPaginationItem-previousNext":
              { padding: "4px 20px !important" },
          }}
        />
      </Stack>
      <SmsSendModal
        setModal={setSmsModal}
        modal={{ ...smsModal, isOpen: smsModal.isOpen }}
        modalKey="isOpen"
      />
      {modal.isOpen && (
        <AcceptModal
          setModal={setModal}
          modal={{ ...modal, isOpen: modal.isOpen }}
          handleClick={changeById}
          modalKey="isOpen"
          title="원 상태를 병경하시겠습니까?"
        />
      )}
    </Paper>
  );
}

export default SupportStatus;
