import React from "react";
import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  TableHead,
  TextField,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import CustomCheckbox from "../../components/CustomCheckbox";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { pageSize } from "../../constants/constants";
import {
  deleteNotificationById,
  getAllNotification,
} from "../../api/notification";
import AcceptModal from "../../components/modals/AcceptModal";
import { toast } from "react-toastify";
import { FormInputText } from "../../components/form-components/FormInputText";

const initialQuery = {
  number: 1,
  size: 20,
  order: "DESC",
  sort: "createdDate",
  title: "",
};

export default function ReferenceRoom() {
  const { handleSubmit, control, reset } = useForm({
    defaultValues: initialQuery,
  });
  const { user } = useSelector((state) => state.user);
  const [query, setQuery] = useState(initialQuery);
  const [notifys, setNotifys] = useState();
  const [modal, setModal] = useState({
    isOpen: false,
  });

  const handleChangePage = (_, value) => {
    setQuery({ ...query, number: value });
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    var tempNotifys = {};
    if (name === "allSelect") {
      tempNotifys = notifys.content.map((notify) => {
        return { ...notify, isChecked: checked };
      });
    } else {
      tempNotifys = notifys.content.map((notify) =>
        notify.title + notify.id === name
          ? { ...notify, isChecked: checked }
          : notify
      );
    }
    setNotifys({ ...notifys, content: tempNotifys });
  };

  const statusSwitcher = (value) => {
    switch (value) {
      case "ACTIVE":
        return "게시중";
        break;
      case "INACTIVE":
        return "숨김";
        break;
    }
  };

  async function notifyList() {
    try {
      const { data } = await getAllNotification(query, user.token);
      setNotifys(data);
    } catch (error) {
      console.log(error.response?.data.message);
    }
  }

  const deleteById = () => {
    try {
      modal.deleteIds?.map(async (data) => {
        await deleteNotificationById(data, user.token);
        notifyList();
      });
      toast.success("삭제가 완료 되었습니다!");
      setModal({ isOpen: false });
    } catch (error) {
      console.log(error);
      setModal({ isOpen: false });
    }
  };

  const deleteNotifys = async () => {
    const checkedNotifys = [];

    notifys?.content?.map((data) => {
      if (data.isChecked) {
        checkedNotifys.push(data.id);
      }
      return data.isChecked;
    });

    if (checkedNotifys.length === 0) {
      toast.warning("삭제 하고 싶은 공지 선택해 주세요!");
    } else {
      setModal({ ...modal, isOpen: true, deleteIds: checkedNotifys });
    }
  };

  const submit = async (values) => {
    setQuery({ ...query, ...values });
  };

  const setInitial = () => {
    reset(initialQuery);
    setQuery(initialQuery);
  };

  useEffect(() => {
    notifyList();
  }, [query]);

  return (
    <Paper
      sx={{
        width: "100%",
        boxShadow: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          mb: "16px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography style={{ minWidth: "95px", fontWeight: "500" }}>
          검색
        </Typography>
        <FormInputText
          sx={{ width: "280px", marginRight: "6px" }}
          name="title"
          control={control}
          placeholder="내용을 입력해 주세요"
        />
        <Button
          onClick={handleSubmit(submit)}
          size="small"
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "7px 18px",
            color: "#7653DF",
            background: "#EEEAFB",
          }}
        >
          검색
        </Button>
        <Box
          size="small"
          onClick={setInitial}
          sx={{
            textDecoration: "underline",
            fontSize: "14px",
            color: "#6B7280",
            ml: "17px",
            fontWeight: 500,
            "&:hover": {
              color: "#8E66FF",
              cursor: "pointer",
            },
          }}
        >
          검색조건 초기화
        </Box>
        <Link to="/notification-create" style={{ marginLeft: "auto" }}>
          <Button
            size="small"
            sx={{
              fontSize: "13px",
              width: "auto",
              height: "30px",
              padding: "8px 26px",
              color: "#FFF",
              backgroundColor: "#7653DF",
              ml: "auto",
            }}
          >
            자료실 등록 <AddIcon />
          </Button>
        </Link>
      </Box>
      <hr style={{ margin: "30px 0" }} />

      {notifys && (
        <>
          <Box
            sx={{
              display: "flex",
              mb: "15px",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                onClick={deleteNotifys}
                size="small"
                sx={{
                  fontSize: "12px",
                  mr: "6px",
                  height: "30px",
                  padding: "7px 20px",
                  color: "#fff",
                  background: "#6B7280",
                }}
              >
                삭제
              </Button>
              {/* <Button
                sx={{
                  fontSize: "12px",
                  lineHeight: "15px",
                  height: "30px",
                  padding: "7px 26px",
                  whiteSpace: "nowrap",
                  color: "#fff",
                  background: "#6B7280",
                  marginLeft: "auto",
                }}
              >
                엑셀 다운로드
              </Button> */}
              <CustomCheckbox
                name="allSelect"
                checked={
                  !notifys.content?.some((user) => user.isChecked !== true)
                }
                onChange={handleChange}
              />
              <Typography
                style={{
                  minWidth: "95px",
                  fontWeight: "500",
                  color: "#5A5A5A",
                }}
              >
                현재 해당되는 모든 회원 ( {notifys?.totalElements} 명) 선택
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormInputDropdown
                control={control}
                name="size"
                sx={{ width: "111px" }}
                onChange={(e) => setQuery({ ...query, size: e.target.value })}
                options={pageSize}
                staticValue={query.size}
              />
            </Box>
          </Box>
          <TableContainer
            className="table"
            sx={{
              mt: "10px",
              borderTop: "1px solid #98a6ad",
            }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead sx={{ background: "#FCFCFC" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",

                      textAlign: "center",
                    }}
                    align="center"
                  >
                    <CustomCheckbox
                      color="primary"
                      name="allSelect"
                      checked={
                        !notifys.content?.some(
                          (user) => user.isChecked !== true
                        )
                      }
                      onChange={handleChange}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    번호
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    제목
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    파일
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    작성 일자
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    상태
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notifys.content.map((notify, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#EEEAFB",
                        },
                        ".css-1d46hnn-MuiTableCell-root": {
                          padding: "1px",
                        },
                        textDecoration: "none",
                        height: "40px",
                      }}
                    >
                      <TableCell
                        padding="checkbox"
                        sx={{
                          padding: "10px",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                        align="center"
                      >
                        <CustomCheckbox
                          name={notify.title + notify.id}
                          checked={notify?.isChecked || false}
                          onChange={handleChange}
                        />
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/references-details/${notify.id}`}
                        sx={{
                          textDecoration: "none",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {query.size * (query.number - 1) + 1 + index}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/references-details/${notify.id}`}
                        sx={{
                          textDecoration: "none",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {notify.title}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/references-details/${notify.id}`}
                        sx={{
                          textDecoration: "none",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {notify.attachmentName}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/references-details/${notify.id}`}
                        sx={{
                          textDecoration: "none",

                          padding: "10px",
                        }}
                        align="center"
                      >
                        {notify.createdDate}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/references-details/${notify.id}`}
                        sx={{
                          textDecoration: "none",
                          padding: "10px",
                          color: "#0075C1",
                        }}
                        align="center"
                      >
                        {statusSwitcher(notify.status)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
            <Pagination
              count={Math.ceil(notifys.totalElements / query.size)}
              onChange={handleChangePage}
              page={query.number}
              color="primary"
              variant="outlined"
              shape="rounded"
              sx={{
                m: "0 auto",
                "& .css-1k2i9p6-MuiButtonBase-root-MuiPaginationItem-root": {
                  height: "20px",
                  minWidth: "20px",
                  backgroundColor: "#CCCCCC",
                  color: "#fff",
                },
              }}
            />
          </Stack>
        </>
      )}
      {modal.isOpen && (
        <AcceptModal
          handleClick={deleteById}
          modal={{ ...modal }}
          modalKey="isOpen"
          setModal={setModal}
          title="선택한 항목을 삭제하시겠습니까?"
        />
      )}
    </Paper>
  );
}
