import React, { useEffect, useState } from "react";
import {
  Document,
  Font,
  PDFViewer,
  Page,
  Text,
  View,
} from "@react-pdf/renderer";
import { Box, Button, Modal } from "@mui/material";
import { getEmployeeSalary } from "../api/payroll";
import { formatLocaleDate } from "../utils/methods";

Font.register({
  family: "SpoqaHanSans",
  fontWeight: 300,
  src: "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf",
});

Font.register({
  family: "SpoqaHanSansBold",
  fontWeight: "bold",
  src: "https://static.gabia.com/libs/spoqa-han-sans/2.1.2/Subset/SpoqaHanSans/SpoqaHanSansBold.ttf",
});

const sumArrayCodes = [
  "P00",
  "P01",
  "H03",
  "P15",
  "P20",
  "P25",
  "P30",
  "P35",
  "Q01",
  "P45",
  "P50",
  "P55",
  "P75",
  "P80",
  "P81",
  "P82",
  "P83",
  "P84",
  "P85",
];

const subArrayCodes = [
  "S00",
  "S10",
  "S20",
  "S25",
  "S40",
  "S55",
  "S60",
  "S70",
  "S75",
  "S80",
  "S90",
  "S95",
  "SR1",
  "T00",
  "T10",
  "T30",
  "T40",
];

const ContentOfPdf = ({ data, handleRenderComplete }) => {
  const [sumArray, setSumArray] = useState([]);
  const [subArray, setSubArray] = useState([]);
  const [formatDate, setFormatDate] = useState({
    year: null,
    month: null,
  });

  const itemsPerRow = 2;
  const sumRows = Math.ceil(sumArray.length / itemsPerRow);
  const subRows = Math.ceil(subArray.length / itemsPerRow);

  const addItemToArray = (item) => {
    if (sumArrayCodes.includes(item.itemCode)) {
      setSumArray((prevSumArray) => [...prevSumArray, item]);
    } else if (subArrayCodes.includes(item.itemCode)) {
      setSubArray((prevSubArray) => [...prevSubArray, item]);
    }
  };

  useEffect(() => {
    setSubArray([]);
    setSumArray([]);
    if (data.content.length > 0) {
      data.content.map((data) => {
        addItemToArray(data);
      });
    }
    const dateParts = data.dateString?.split("-");
    setFormatDate({ year: dateParts[0], month: dateParts[1] });
  }, [data.content]);

  return (
    <Document onRender={handleRenderComplete}>
      <Page style={{ padding: "30px" }}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "1px solid gray",
          }}
        >
          <Text
            style={{
              color: "#0000FF",
              fontSize: "15",
              fontFamily: "SpoqaHanSans",
            }}
          >
            {formatDate.year + "년 " + formatDate.month + "월"}
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "5px",
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text
              style={{
                fontSize: "13px",
                textAlign: "center",
                fontFamily: "SpoqaHanSansBold",
              }}
            >
              근무지명:
            </Text>
            <Text
              style={{
                fontSize: "15px",
                textAlign: "center",
                fontFamily: "SpoqaHanSans",
              }}
            >
              &nbsp;{data?.user?.companyName}
            </Text>
          </View>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text
              style={{
                fontSize: "13px",
                textAlign: "center",
                fontFamily: "SpoqaHanSansBold",
              }}
            >
              성명:
            </Text>
            <Text
              style={{
                fontSize: "15px",
                textAlign: "center",
                fontFamily: "SpoqaHanSans",
              }}
            >
              &nbsp;{data?.user?.employeeName}
            </Text>
          </View>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text
              style={{
                fontSize: "13px",
                textAlign: "center",
                fontFamily: "SpoqaHanSansBold",
              }}
            >
              급여지급일:
            </Text>
            <Text
              style={{
                fontSize: "15px",
                textAlign: "center",
                fontFamily: "SpoqaHanSans",
              }}
            >
              &nbsp;{formatLocaleDate(data.user?.createdDate)}
            </Text>
          </View>
        </View>
        <View>
          <View
            style={{
              border: "1px solid #e0e0e0",
              backgroundColor: "#0047AB",
              padding: "6px",
              width: "100%",
              marginTop: 10,
            }}
          >
            <Text
              style={{
                fontSize: "10px",
                textAlign: "center",
                fontFamily: "SpoqaHanSansBold",
                color: "white",
                fontWeight: "bold",
              }}
            >
              지급내역
            </Text>
          </View>
          {Array.from({ length: sumRows }).map((_, rowIndex) => (
            <View
              key={rowIndex}
              style={{ flexDirection: "row", width: "100%" }}
            >
              {sumArray
                .slice(rowIndex * itemsPerRow, (rowIndex + 1) * itemsPerRow)
                .map((item, columnIndex) => {
                  const index = rowIndex * itemsPerRow + columnIndex;
                  const isOddIndex = index % 2 !== 0;

                  return (
                    <View
                      key={index}
                      style={{ flexDirection: "row", width: "50%" }}
                    >
                      <View
                        style={{
                          border: "1px solid #e0e0e0",
                          borderLeft: !isOddIndex
                            ? "1px solid #e0e0e0"
                            : "none",
                          borderRight: "none",
                          borderTop: "none",
                          padding: "6px",
                          width: "60%",
                          backgroundColor: "#F5F5F5",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: "10px",
                            textAlign: "center",
                            fontFamily: "SpoqaHanSans",
                          }}
                        >
                          {item.itemName}
                        </Text>
                      </View>
                      <View
                        style={{
                          border: "1px solid #e0e0e0",
                          borderTop: "none",
                          padding: "6px",
                          width: "40%",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: "10px",
                            textAlign: "center",
                            fontFamily: "SpoqaHanSans",
                          }}
                        >
                          {item.amount?.toLocaleString()}
                        </Text>
                      </View>
                    </View>
                  );
                })}
            </View>
          ))}
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                border: "1px solid #e0e0e0",
                borderRight: "none",
                padding: "6px",
                width: "80%",
                backgroundColor: "#F5F5F5",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  fontFamily: "SpoqaHanSans",
                }}
              >
                지급총계
              </Text>
            </View>
            <View
              style={{
                border: "1px solid #e0e0e0",
                padding: "6px",
                width: "20%",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  fontFamily: "SpoqaHanSans",
                }}
              >
                {data.user?.sum?.toLocaleString()}
              </Text>
            </View>
          </View>
        </View>
        <View>
          <View
            style={{
              border: "1px solid #e0e0e0",
              backgroundColor: "#0047AB",
              padding: "6px",
              width: "100%",
              marginTop: 10,
            }}
          >
            <Text
              style={{
                fontSize: "10px",
                textAlign: "center",
                fontFamily: "SpoqaHanSansBold",
                color: "white",
                fontWeight: "bold",
              }}
            >
              공제내역
            </Text>
          </View>
          {Array.from({ length: subRows }).map((_, rowIndex) => (
            <View
              key={rowIndex}
              style={{ flexDirection: "row", width: "100%" }}
            >
              {subArray
                .slice(rowIndex * itemsPerRow, (rowIndex + 1) * itemsPerRow)
                .map((item, columnIndex) => {
                  const index = rowIndex * itemsPerRow + columnIndex;
                  const isOddIndex = index % 2 !== 0;

                  return (
                    <View
                      key={index}
                      style={{ flexDirection: "row", width: "50%" }}
                    >
                      <View
                        style={{
                          border: "1px solid #e0e0e0",
                          borderLeft: !isOddIndex
                            ? "1px solid #e0e0e0"
                            : "none",
                          borderRight: "none",
                          borderTop: "none",
                          padding: "6px",
                          width: "60%",
                          backgroundColor: "#F5F5F5",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: "10px",
                            textAlign: "center",
                            fontFamily: "SpoqaHanSans",
                          }}
                        >
                          {item.itemName}
                        </Text>
                      </View>
                      <View
                        style={{
                          border: "1px solid #e0e0e0",
                          borderTop: "none",
                          padding: "6px",
                          width: "40%",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: "10px",
                            textAlign: "center",
                            fontFamily: "SpoqaHanSans",
                          }}
                        >
                          {item.amount?.toLocaleString()}
                        </Text>
                      </View>
                    </View>
                  );
                })}
            </View>
          ))}
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                border: "1px solid #e0e0e0",
                borderRight: "none",
                padding: "6px",
                width: "80%",
                backgroundColor: "#F5F5F5",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  fontFamily: "SpoqaHanSans",
                }}
              >
                공제총계
              </Text>
            </View>
            <View
              style={{
                border: "1px solid #e0e0e0",
                padding: "6px",
                width: "20%",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  fontFamily: "SpoqaHanSans",
                }}
              >
                {data.user?.sub?.toLocaleString()}
              </Text>
            </View>
          </View>
        </View>
        <View>
          <View
            style={{
              border: "1px solid #e0e0e0",
              backgroundColor: "#0047AB",
              padding: "6px",
              width: "100%",
              marginTop: 10,
            }}
          >
            <Text
              style={{
                fontSize: "10px",
                textAlign: "center",
                fontFamily: "SpoqaHanSansBold",
                color: "white",
                fontWeight: "bold",
              }}
            >
              지급액
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                border: "1px solid #e0e0e0",
                borderRight: "none",
                borderTop: "none",
                padding: "6px",
                width: "30%",
                backgroundColor: "#F5F5F5",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  fontFamily: "SpoqaHanSans",
                }}
              >
                지급총계
              </Text>
            </View>
            <View
              style={{
                border: "1px solid #e0e0e0",
                borderRight: "none",
                borderTop: "none",
                padding: "6px",
                width: "20%",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  fontFamily: "SpoqaHanSans",
                }}
              >
                {data.user?.sum?.toLocaleString()}
              </Text>
            </View>
            <View
              style={{
                border: "1px solid #e0e0e0",
                borderRight: "none",
                borderTop: "none",
                padding: "6px",
                width: "30%",
                backgroundColor: "#F5F5F5",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  fontFamily: "SpoqaHanSans",
                }}
              >
                공제총계
              </Text>
            </View>
            <View
              style={{
                border: "1px solid #e0e0e0",
                borderTop: "none",
                padding: "6px",
                width: "20%",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  fontFamily: "SpoqaHanSans",
                }}
              >
                {data.user?.sub?.toLocaleString()}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                border: "1px solid #e0e0e0",
                borderRight: "none",
                borderTop: "none",
                padding: "6px",
                width: "80%",
                backgroundColor: "#F5F5F5",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  fontFamily: "SpoqaHanSans",
                }}
              >
                차인지급액
              </Text>
            </View>
            <View
              style={{
                border: "1px solid #e0e0e0",
                borderTop: "none",
                padding: "6px",
                width: "20%",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  fontFamily: "SpoqaHanSans",
                }}
              >
                {(data.user?.sum - data.user?.sub)?.toLocaleString()}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            width: "100%",
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: "10px",
          }}
        >
          <Text
            style={{
              fontSize: "15px",
              textAlign: "center",
              fontFamily: "SpoqaHanSans",
            }}
          >
            공지사항
          </Text>
          <View style={{ border: "1px solid gray", width: "100%" }}>
            <Text
              style={{
                fontSize: "15px",
                textAlign: "center",
                fontFamily: "SpoqaHanSans",
                alignSelf: "flex-start",
                paddingVertical: "25px",
                color: "#0000FF",
                marginLeft: "10px",
              }}
            >
              귀하의 노고에 감사드립니다
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default function ApplicantPdfModal({
  modal,
  setModal,
  style = {},
  modalKey = "isOpen",
}) {
  const { user, companyName, dateString, token } = modal;
  const [userData, setUserData] = useState({
    content: [],
    dateString: dateString,
    user: user,
  });

  const close = () => {
    setModal((prevModal) => ({
      ...prevModal,
      [modalKey]: false,
    }));
  };

  const getSalary = async () => {
    const obj = {
      employeeNumber: user.employeeNumber,
      dateString: dateString,
      companyName: companyName,
    };
    try {
      const { data } = await getEmployeeSalary(JSON.stringify(obj), token);
      setUserData({
        ...userData,
        content: data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSalary();
  }, [user]);

  return (
    modal && (
      <Modal open={modal.isOpen}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            padding: "20px 30px",
            bgcolor: "#fff",
            boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.10)",
            borderRadius: "20px",
            border: "none",
            ...style,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button sx={{ minWidth: "unset" }} onClick={close}>
              <img src="/icons/close.svg" alt="close" />
            </Button>
          </Box>
          <PDFViewer style={{ width: "100%", height: "92%", marginTop: 20 }}>
            <ContentOfPdf data={userData} />
          </PDFViewer>
        </Box>
      </Modal>
    )
  );
}
