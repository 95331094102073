import React from "react";
import { Box, Button, Paper, TableHead, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getAllEmployee } from "../../api/employee";
import { formatKoreanPhoneNumber } from "../../utils/methods";

function EmployeeList() {
  const { user } = useSelector((state) => state.user);
  const [allData, setAllData] = useState([]);
  const label = ["1급 관리자", "2급 관리자", "3급 관리자"];

  useEffect(() => {
    userList();
  }, []);

  async function userList() {
    try {
      const result = await getAllEmployee(user.token);
      setAllData(result);
    } catch (error) {
      console.log(error.response?.data.message);
    }
  }

  return (
    <Paper
      sx={{
        width: "100%",
        // height:"250px",
        boxShadow: "none",
      }}
    >
      <>
        <Box
          sx={{
            display: "flex",
            mb: "15px",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Link to="/employee-create" style={{ marginLeft: "auto" }}>
            <Button
              size="small"
              sx={{
                fontSize: "13px",
                width: "auto",
                height: "30px",
                padding: "8px 26px",
                color: "#FFF",
                backgroundColor: "#7653DF",
              }}
            >
              관리자 등록 <AddIcon />
            </Button>
          </Link>
        </Box>

        {allData?.map((item, i) => {
          return (
            <div key={Math.random() * 100}>
              <Typography
                fontSize="18px"
                fontWeight="600"
                key={Math.random() * 100}
              >
                {i === 0 ? label[0] : ""}
                {i === 1 ? label[1] : ""}
                {i === 2 ? label[2] : ""}
              </Typography>
              <TableContainer
                key={i}
                className="table"
                sx={{
                  mt: "10px",
                  marginBottom: "24px",
                  borderTop: "1px solid #98a6ad",
                }}
              >
                <Table
                  size="small"
                  aria-label="a dense table"
                  key={Math.random() * 100}
                >
                  <TableHead sx={{ background: "#FCFCFC" }}>
                    <TableRow>
                      <TableCell
                        sx={{
                          whiteSpace: "nowrap",
                          color: "#5A5A5A",
                          fontSize: "13px",
                          width: "",
                          textAlign: "center",
                        }}
                        align="center"
                      ></TableCell>
                      <TableCell
                        sx={{
                          padding: "10px",
                          whiteSpace: "nowrap",
                          color: "#5A5A5A",
                          fontSize: "13px",
                          width: "73px",
                        }}
                        align="center"
                      >
                        번호
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "10px",
                          whiteSpace: "nowrap",
                          color: "#5A5A5A",
                          fontSize: "13px",
                          width: "52px",
                        }}
                        align="center"
                      >
                        성명
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "10px 9px 10px 2px",
                          whiteSpace: "nowrap",
                          color: "#5A5A5A",
                          fontSize: "13px",
                          width: "23px",
                        }}
                        align="center"
                      >
                        코드
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "10px",
                          whiteSpace: "nowrap",
                          color: "#5A5A5A",
                          fontSize: "13px",
                        }}
                        align="center"
                      >
                        부서
                      </TableCell>

                      <TableCell
                        sx={{
                          padding: "10px",
                          whiteSpace: "nowrap",
                          color: "#5A5A5A",
                          fontSize: "13px",
                        }}
                        align="center"
                      >
                        직급
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "10px",
                          whiteSpace: "nowrap",
                          color: "#5A5A5A",
                          fontSize: "13px",
                        }}
                        align="center"
                      >
                        이메일
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "10px",
                          whiteSpace: "nowrap",
                          color: "#5A5A5A",
                          fontSize: "13px",
                        }}
                        align="center"
                      >
                        연락처
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "10px",
                          whiteSpace: "nowrap",
                          color: "#5A5A5A",
                          fontSize: "13px",
                        }}
                        align="center"
                      >
                        상태
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "10px",
                          whiteSpace: "nowrap",
                          color: "#5A5A5A",
                          fontSize: "13px",
                        }}
                        align="center"
                      >
                        등급 구분
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "10px",
                          whiteSpace: "nowrap",
                          color: "#5A5A5A",
                          fontSize: "13px",
                        }}
                        align="center"
                      >
                        접근 권한
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody key={Math.random() * 100}>
                    {item?.map((user, index) => {
                      const labelId = `enhanced-table-checkbox-${
                        Math.random() * 100
                      }`;
                      return (
                        <TableRow
                          role="checkbox"
                          key={index}
                          sx={{
                            "&:hover": {
                              backgroundColor: "#EEEAFB",
                            },
                            ".css-1d46hnn-MuiTableCell-root": {
                              padding: "1px",
                            },
                            textDecoration: "none",
                            height: "40px",
                          }}
                        >
                          <TableCell
                            padding="checkbox"
                            sx={{
                              padding: "10px",
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                            align="center"
                          ></TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/employee-details/${user.id}`}
                            sx={{
                              textDecoration: "none",
                              padding: "10px",
                            }}
                            align="center"
                          >
                            {user.id}
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/employee-details/${user.id}`}
                            sx={{
                              textDecoration: "none",
                              padding: "10px",
                            }}
                            align="center"
                          >
                            <span>{user.name}</span>
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/employee-details/${user.id}`}
                            sx={{
                              textDecoration: "none",
                              padding: "10px",
                            }}
                            align="center"
                          >
                            <span>{user.adminCode}</span>
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/employee-details/${user.id}`}
                            sx={{
                              textDecoration: "none",
                              padding: "10px",
                            }}
                            align="center"
                          >
                            {user.department}
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/employee-details/${user.id}`}
                            sx={{
                              textDecoration: "none",
                              padding: "10px",
                            }}
                            align="center"
                          >
                            {user.rank}
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/employee-details/${user.id}`}
                            sx={{
                              textDecoration: "none",
                              padding: "10px",
                            }}
                            align="center"
                          >
                            {user.email}
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/employee-details/${user.id}`}
                            sx={{
                              textDecoration: "none",
                              padding: "10px",
                            }}
                            align="center"
                          >
                            {user.phoneNumber &&
                              formatKoreanPhoneNumber(user.phoneNumber)}
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/employee-details/${user.id}`}
                            sx={{
                              textDecoration: "none",

                              padding: "10px",
                            }}
                            align="center"
                          >
                            {user.usageStatusType}
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/employee-details/${user.id}`}
                            sx={{
                              textDecoration: "none",

                              padding: "10px",
                            }}
                            align="center"
                          >
                            {user.adminRating}
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/employee-details/${user.id}`}
                            sx={{
                              textDecoration: "none",

                              padding: "10px",
                            }}
                            align="center"
                          >
                            {user.rank}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          );
        })}
      </>
    </Paper>
  );
}

export default EmployeeList;
