import { Box, Tab, Tabs, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import MessageSwitcher from "./MessageSwitcher";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { getAllTerms, updateTerms } from "../../../api/terms";
import { toast } from "react-toastify";
import Loader from "../../../components/loader";

export default function Message({ initialTab }) {
  const { handleSubmit, control, setValue, watch, reset } = useForm();
  const [selectedTab, setSelectedTab] = useState(initialTab ?? 1);
  const [isSaving, setIsSaving] = useState(false);
  const [terms, setTerms] = useState();
  const [selectedTerm, setSelectedTerm] = useState();
  const { token } = useSelector((state) => state.user.user);

  const getTerms = async () => {
    try {
      const data = await getAllTerms(token);
      setTerms(data.data);
      reset(data);
    } catch (error) {
      console.log(error);
    }
  };

  const submit = async (values) => {
    const newValues = {
      id: selectedTerm?.id,
      content: values.content,
    };
    try {
      setIsSaving(true);
      const data = await updateTerms(newValues, token);
      if (data?.statusCode === 200) {
        toast.success("완료 되었습니다.");
        setIsSaving(false);
        getTerms();
      }
    } catch (error) {
      console.log(error);
      setIsSaving(false);
      toast.error(error.response?.data.message);
    }
  };

  const handleChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    getTerms();
  }, []);

  useEffect(() => {
    if (terms && selectedTab) {
      setSelectedTerm(terms[selectedTab - 1]);
    }
  }, [selectedTab, terms]);

  if (!terms) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Loader style={{ backgroundColor: "red" }} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        pl: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Tabs
          sx={{
            "& .MuiTab-root.MuiTab-textColorSecondary": {
              borderRadius: "5px 5px 0px 0px",
              color: "#987EE7",
              background: "#EEEAFB",
              mr: "5px",
              minHeight: "30px !important",
              p: "7px 20px",
              fontWeight: "500",
              fontSize: "13px",
            },
            "& .MuiTabs-scroller": {
              alignItems: "flex-end",
              display: "flex",
            },
            "& .MuiTab-root.MuiTab-textColorSecondary.Mui-selected": {
              background: "#987EE7",
              color: "#fff",
            },
            "& .MuiTabs-indicator": {
              display: "none",
              color: "#E1E1E1",
            },
          }}
          value={selectedTab}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value={1} label="이용약관" />
          <Tab value={2} label="개인정보 수집 및 이용(필수)" />
          <Tab value={3} label="개인정보 수집 및 이용(선택)" />
          <Tab value={4} label="개인정보 제3자 제공 (필수)" />
          <Tab value={5} label="개인정보 제3자 제공 (선택)" />
        </Tabs>
      </Box>
      <hr
        style={{
          margin: "0px 0 40px 0",
        }}
      />
      <MessageSwitcher
        terms={terms}
        control={control}
        setValue={setValue}
        selectedTab={selectedTab}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        {isSaving ? (
          <Loader style={{ backgroundColor: "red" }} />
        ) : (
          <Button
            onClick={handleSubmit(submit)}
            sx={{
              fontSize: "13px",
              width: "auto",
              height: "30px",
              padding: "7px 46px",
              color: "#fff",
              background: "#7653DF",
              marginTop: 10,
            }}
          >
            저장하기
          </Button>
        )}
      </Box>
    </Box>
  );
}
