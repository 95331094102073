import {
  Box,
  Button,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import BackButton from "../../components/BackButton";
import LinkButton from "../../components/LinkButton";
import { FormInputText } from "../../components/form-components/FormInputText";
import CustomCheckbox from "../../components/CustomCheckbox";
import { pageSize } from "../../constants/constants";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import { getAllEmployeeSalary } from "../../api/payroll";
import { formatLocaleDate } from "../../utils/methods";

const initialQuery = {
  number: 1,
  size: 20,
  order: "DESC",
  sort: "createdDate",
  employeeName: "",
};

export default function PayrollSearchApplicant() {
  const { control, handleSubmit, reset } = useForm();
  const { token } = useSelector((state) => state.user.user);
  const [query, setQuery] = useState(initialQuery);
  const [users, setUsers] = useState();

  const handleChangePage = (_, value) => {
    setQuery({ ...query, number: value });
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    var tempUser = {};
    if (name === "allSelect") {
      tempUser = users?.content.map((user) => {
        return { ...user, isChecked: checked };
      });
    } else {
      tempUser = users?.content.map((user) =>
        user.employeeName + user.employeeNumber === name
          ? { ...user, isChecked: checked }
          : user
      );
    }
    setUsers({ ...users, content: tempUser });
  };

  const setInitial = () => {
    reset(initialQuery);
    setQuery(initialQuery);
  };

  const submit = async (values) => {
    setQuery({ ...query, ...values });
  };

  const getSalary = async () => {
    try {
      const data = await getAllEmployeeSalary(query, token);
      setUsers({
        content: data?.data,
        additionalData: data.additionalData,
        totalSalary: data.totalSalary,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSalary();
  }, [query]);

  if (!users) {
    return <Box></Box>;
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <BackButton text="사원 급여" />
        <LinkButton label="목록보기" link="/payroll-management" />
      </Box>
      <hr style={{ margin: "16px 0 30px 0" }} />
      <Box
        sx={{
          display: "flex",
          mb: "16px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography style={{ minWidth: "95px", fontWeight: 500 }}>
          사원 검색
        </Typography>
        <FormInputText
          sx={{ width: "280px", marginRight: "6px" }}
          control={control}
          name="employeeName"
          placeholder="내용을 입력해 주세요"
        />
        <Button
          onClick={handleSubmit(submit)}
          size="small"
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "7px 18px",
            color: "#7653DF",
            background: "#EEEAFB",
          }}
        >
          검색
        </Button>
        <Box
          size="small"
          onClick={setInitial}
          sx={{
            textDecoration: "underline",
            fontSize: "14px",
            color: "#6B7280",
            fontWeight: 500,
            "&:hover": {
              color: "#8E66FF",
              cursor: "pointer",
            },
          }}
        >
          검색조건 초기화
        </Box>
      </Box>
      <hr style={{ margin: "30px auto" }} />
      <Box
        sx={{
          display: "flex",
          mb: "15px",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            size="small"
            sx={{
              fontSize: "12px",
              mr: "6px",
              height: "30px",
              padding: "7px 20px",
              color: "#fff",
              background: "#6B7280",
            }}
          >
            급여 일괄 승인 해제
          </Button>
          <Button
            sx={{
              fontSize: "12px",
              mr: "12px",
              height: "30px",
              padding: "7px 20px",
              color: "#fff",
              background: "#8E66FF",
            }}
          >
            급여 일괄 승인
          </Button>
          <CustomCheckbox
            name="allSelect"
            checked={!users?.content?.some((user) => user.isChecked !== true)}
            onChange={handleChange}
          />
          <Typography
            style={{
              minWidth: "95px",
              fontWeight: "500",
              color: "#5A5A5A",
            }}
          >
            현재 해당되는 모든 회원 ( {users?.additionalData?.totalCount} 명)
            선택
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormInputDropdown
            control={control}
            name="size"
            sx={{ width: "111px" }}
            onChange={(e) => setQuery({ ...query, size: e.target.value })}
            options={pageSize}
            staticValue={query.size}
          />
        </Box>
      </Box>
      <TableContainer
        className="table"
        sx={{
          mt: "10px",
          borderTop: "1px solid #98a6ad",
        }}
      >
        <Table size="small" aria-label="a dense table">
          <TableHead sx={{ background: "#FCFCFC" }}>
            <TableRow>
              <TableCell
                sx={{
                  whiteSpace: "nowrap",
                  color: "#5A5A5A",
                  fontSize: "13px",

                  textAlign: "center",
                }}
                align="center"
              >
                <CustomCheckbox
                  color="primary"
                  name="allSelect"
                  checked={
                    !users.content?.some((user) => user.isChecked !== true)
                  }
                  onChange={handleChange}
                />
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  color: "#5A5A5A",
                  fontSize: "13px",
                }}
                align="center"
              >
                번호
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  color: "#5A5A5A",
                  fontSize: "13px",
                }}
                align="center"
              >
                성명
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  color: "#5A5A5A",
                  fontSize: "13px",
                }}
                align="center"
              >
                주민번호
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  color: "#5A5A5A",
                  fontSize: "13px",
                }}
                align="center"
              >
                업체명
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  color: "#5A5A5A",
                  fontSize: "13px",
                }}
                align="center"
              >
                지급일
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  color: "#5A5A5A",
                  fontSize: "13px",
                }}
                align="center"
              >
                지급 총액
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  color: "#5A5A5A",
                  fontSize: "13px",
                }}
                align="center"
              >
                공제 총액
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  color: "#5A5A5A",
                  fontSize: "13px",
                }}
                align="center"
              >
                차인 지급 총액
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  color: "#5A5A5A",
                  fontSize: "13px",
                }}
                align="center"
              >
                승인여부
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.content?.map((user, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  role="checkbox"
                  key={index}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#EEEAFB",
                    },
                    ".css-1d46hnn-MuiTableCell-root": {
                      padding: "1px",
                    },
                    textDecoration: "none",
                    height: "40px",
                  }}
                >
                  <TableCell
                    padding="checkbox"
                    sx={{
                      padding: "10px",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    <CustomCheckbox
                      name={user.employeeName + user.employeeNumber}
                      checked={user?.isChecked || false}
                      onChange={handleChange}
                    />
                  </TableCell>
                  <TableCell
                    id={labelId}
                    scope="row"
                    padding="none"
                    sx={{
                      textDecoration: "none",
                      padding: "10px",
                    }}
                    align="center"
                  >
                    {query.size * (query.number - 1) + 1 + index}
                  </TableCell>
                  <TableCell
                    id={labelId}
                    scope="row"
                    padding="none"
                    sx={{
                      textDecoration: "none",
                      padding: "10px",
                    }}
                    align="center"
                  >
                    {user.employeeName}
                  </TableCell>
                  <TableCell
                    id={labelId}
                    scope="row"
                    padding="none"
                    sx={{
                      textDecoration: "none",
                      padding: "10px",
                    }}
                    align="center"
                  >
                    {user.employeeNumber}
                  </TableCell>
                  <TableCell
                    id={labelId}
                    scope="row"
                    padding="none"
                    sx={{
                      textDecoration: "none",

                      padding: "10px",
                    }}
                    align="center"
                  >
                    {user.companyName}
                  </TableCell>
                  <TableCell
                    id={labelId}
                    scope="row"
                    padding="none"
                    sx={{
                      textDecoration: "none",
                      padding: "10px",
                      whiteSpace: "nowrap",
                    }}
                    align="center"
                  >
                    {formatLocaleDate(user.createdDate)}
                  </TableCell>
                  <TableCell
                    id={labelId}
                    scope="row"
                    padding="none"
                    sx={{
                      textDecoration: "none",
                      padding: "10px",
                    }}
                    align="center"
                  >
                    {user.sum?.toLocaleString() || 0} 원
                  </TableCell>
                  <TableCell
                    id={labelId}
                    scope="row"
                    padding="none"
                    sx={{
                      textDecoration: "none",
                      padding: "10px",
                      color: "#A91515",
                    }}
                    align="center"
                  >
                    {user.sub?.toLocaleString() || 0} 원
                  </TableCell>
                  <TableCell
                    id={labelId}
                    scope="row"
                    padding="none"
                    sx={{
                      textDecoration: "none",
                      padding: "10px",
                      color: "#1270B0",
                      fontWeight: "700",
                    }}
                    align="center"
                  >
                    {(user.sum - user.sub)?.toLocaleString() || 0} 원
                  </TableCell>
                  <TableCell
                    id={labelId}
                    scope="row"
                    padding="none"
                    sx={{
                      textDecoration: "none",
                      padding: "10px",
                      fontWeight: "600",
                      color: `${user.status === "NO" ? "#A91515" : "#1270B0"}`,
                      cursor: "pointer",
                    }}
                    align="center"
                  >
                    {user.status === "NO" ? "N" : "Y"}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
        <Pagination
          count={Math.ceil(users.additionalData.totalCount / query.size)}
          onChange={handleChangePage}
          page={query.number}
          color="primary"
          variant="outlined"
          shape="rounded"
          sx={{
            m: "0 auto",
            "& .css-1k2i9p6-MuiButtonBase-root-MuiPaginationItem-root": {
              height: "20px",
              minWidth: "20px",
              backgroundColor: "#CCCCCC",
              color: "#fff",
            },
          }}
        />
      </Stack>
    </Box>
  );
}
