import {
  Box,
  Button,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { pageSize, searchType } from "../../../constants/constants";
import { FormInputDropdown } from "../../../components/form-components/FormInputDropdown";
import { useForm } from "react-hook-form";
import { getWorkDepartments } from "../../../api/personalCodeManagement";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { FormInputText } from "../../../components/form-components/FormInputText";

const initialQuery = {
  number: 1,
  size: 60,
  searchType: "",
  keyword: "",
};

export default function WorkDepartment({ setSelectedTab }) {
  const { control, handleSubmit } = useForm();
  const { token } = useSelector((state) => state.user.user);
  const [query, setQuery] = useState(initialQuery);
  const [users, setUsers] = useState();

  const handleChangePage = (_, value) => {
    setQuery({ ...query, number: value });
  };

  const getList = async () => {
    try {
      const { data } = await getWorkDepartments(query, token);
      setUsers(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeTab = (id) => {
    setSelectedTab({ tab: 6, id: id });
  };

  const submit = (values) => {
    setQuery({ ...query, ...values });
  };

  useEffect(() => {
    getList();
  }, [query]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          mb: "16px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography style={{ minWidth: "95px", fontWeight: "500" }}>
          검색
        </Typography>
        <FormInputDropdown
          control={control}
          name="searchType"
          sx={{ width: "111px", marginRight: "10px" }}
          options={searchType}
        />
        <FormInputText
          control={control}
          sx={{ width: "280px", marginRight: "6px" }}
          name="keyword"
          placeholder="내용을 입력해 주세요"
        />
        <Button
          onClick={handleSubmit(submit)}
          size="small"
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "7px 18px",
            color: "#7653DF",
            background: "#EEEAFB",
          }}
        >
          검색
        </Button>
      </Box>
      <hr style={{ margin: "30px 0" }} />
      {/* <FormInputDropdown
        control={control}
        staticValue={query.size}
        onChange={(e) => setQuery({ ...query, size: e.target.value })}
        sx={{ width: "90px", display: "flex", margin: "0 0 10px auto" }}
        options={pageSize}
      /> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TableContainer
          className="table"
          sx={{
            border: "1px solid #E7E7E7",
            borderTop: "1px solid #98A6AD",
          }}
        >
          <Table>
            <TableHead sx={{ background: "#fcfcfc" }}>
              <TableRow>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  코드
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  업체명
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users && !users.empty
                ? users.content.slice(0, 20).map((user, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        role="checkbox"
                        key={index}
                        sx={{
                          "&:hover": {
                            color: "#5a5a5a !important",
                            backgroundColor: "rgba(142, 102, 255, 0.22)",
                          },
                          height: "40px",
                        }}
                      >
                        <TableCell
                          id={labelId}
                          scope="row"
                          padding="none"
                          onClick={() => handleChangeTab(user.id)}
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          {user.code}
                        </TableCell>
                        <TableCell
                          id={labelId}
                          onClick={() => handleChangeTab(user.id)}
                          scope="row"
                          padding="none"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          {user.name}
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer
          className="table"
          sx={{
            border: "1px solid #E7E7E7",
            borderTop: "1px solid #98A6AD",
          }}
        >
          <Table>
            <TableHead sx={{ background: "#fcfcfc" }}>
              <TableRow>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  코드
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  업체명
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.content.length > 20 &&
                users.content.slice(20, 40).map((user, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      sx={{
                        "&:hover": {
                          color: "#5a5a5a !important",
                          backgroundColor: "rgba(142, 102, 255, 0.22)",
                        },
                        ".css-1d46hnn-MuiTableCell-root": {
                          padding: "1px",
                        },

                        height: "40px",
                      }}
                    >
                      <TableCell
                        id={labelId}
                        scope="row"
                        onClick={() => handleChangeTab(user.id)}
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.code}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        scope="row"
                        onClick={() => handleChangeTab(user.id)}
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.name}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer
          className="table"
          sx={{
            border: "1px solid #E7E7E7",
            borderTop: "1px solid #98A6AD",
          }}
        >
          <Table>
            <TableHead sx={{ background: "#fcfcfc" }}>
              <TableRow>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  코드
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  업체명
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.content.length > 40 &&
                users.content.slice(40).map((user, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      sx={{
                        "&:hover": {
                          color: "#5a5a5a !important",
                          backgroundColor: "rgba(142, 102, 255, 0.22)",
                        },
                        ".css-1d46hnn-MuiTableCell-root": {
                          padding: "1px",
                        },

                        height: "40px",
                      }}
                    >
                      <TableCell
                        id={labelId}
                        scope="row"
                        padding="none"
                        onClick={() => handleChangeTab(user.id)}
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.code}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        scope="row"
                        padding="none"
                        onClick={() => handleChangeTab(user.id)}
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.name}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TableContainer
          className="table"
          sx={{
            border: "1px solid #E7E7E7",
            borderTop: "1px solid #98A6AD",
          }}
        >
          <Table>
            <TableHead sx={{ background: "#fcfcfc" }}>
              <TableRow>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  코드
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  업체명
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.content.length > 60 &&
                users.content.slice(60).map((user, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      sx={{
                        "&:hover": {
                          color: "#5a5a5a !important",
                          backgroundColor: "rgba(142, 102, 255, 0.22)",
                        },
                        ".css-1d46hnn-MuiTableCell-root": {
                          padding: "1px",
                        },

                        height: "40px",
                      }}
                    >
                      <TableCell
                        id={labelId}
                        scope="row"
                        padding="none"
                        onClick={() => handleChangeTab(user.id)}
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.code}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        scope="row"
                        padding="none"
                        onClick={() => handleChangeTab(user.id)}
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.name}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer> */}
      </Box>
      <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
        <Pagination
          count={Math.ceil(users?.totalElements / query.size) || 0}
          onChange={handleChangePage}
          page={query.number}
          color="primary"
          variant="outlined"
          shape="rounded"
          sx={{
            m: "0 auto",
            "& .css-1k2i9p6-MuiButtonBase-root-MuiPaginationItem-root": {
              height: "20px",
              minWidth: "20px",
              backgroundColor: "#CCCCCC",
              color: "#fff",
            },
          }}
        />
      </Stack>
    </Box>
  );
}
