import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import BackButton from "../../components/BackButton";
import { useNavigate, useParams } from "react-router-dom";
import LinkButton from "../../components/LinkButton";
import { FormInputText } from "../../components/form-components/FormInputText";
import { useForm } from "react-hook-form";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import AcceptModal from "../../components/modals/AcceptModal";
import { bannerStatus } from "../../constants/constants";
import { toast } from "react-toastify";
import {
  createNotification,
  deleteNotificationById,
  getNotificationById,
  updateNotification,
} from "../../api/notification";
import FormInputCEditor from "../../components/form-components/FormInputCEditor";
import Loader from "../../components/loader";

export default function ReferenceDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.user.user);
  const [notify, setNotify] = useState();
  const { control, setValue, handleSubmit, watch, reset } = useForm({
    defaultValues: notify || {},
  });
  const [attachment, setAttachment] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [modal, setModal] = useState({
    isOpen: false,
  });
  const [error, setError] = useState(null);

  const submit = async (values) => {
    const createValue = {
      ...values,
      attachment: attachment,
    };

    const updateValue = {
      ...values,
      attachment: attachment,
      id: id,
    };
    if (!values.status) {
      setError("신고현황을 적어주세요!");
    } else if (!values.title) {
      setError("공지사항 제목을 적어주세요!");
    } else if (!values.notificationDetail) {
      setError("공지 내용 작성해 주세요!");
    } else {
      setIsUploading(true);
      try {
        if (notify) {
          await updateNotification(updateValue, token);
        } else {
          await createNotification(createValue, token);
        }
        toast.success("완료 되었습니다.");
        navigate("/post-notice/notification");
      } catch (error) {
        console.log(error);
        alert(error.response?.data.message);
      }

      setIsUploading(false);
    }
  };

  const getNotify = async () => {
    try {
      const { data } = await getNotificationById(id, token);
      setNotify(data);
      reset(data);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteById = async () => {
    try {
      await deleteNotificationById(modal.deleteID, token);
      setModal({ isOpen: false });
      navigate("/post-notice/notification");
      toast.success("삭제가 완료 되었습니다!");
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteNotify = async () => {
    setModal({ ...modal, isOpen: true, deleteID: id });
  };

  useEffect(() => {
    if (error) {
      toast.warning(error);
    }
  }, [error]);

  useEffect(() => {
    if (id) {
      getNotify();
    }
  }, [id]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <BackButton text="자료실 상세" />
        <LinkButton label="목록보기" link="/post-notice/references" />
      </Box>
      <hr style={{ margin: "16px 0 30px 0" }} />
      <Grid container>
        <Grid item xs={12}>
          <Grid container rowSpacing={1} alignItems="center">
            <Grid item xs={1} fontWeight="500">
              상태
            </Grid>
            <Grid item xs={11}>
              <FormInputDropdown
                control={control}
                name="status"
                sx={{
                  maxWidth: "200px",
                  width: "100%",
                }}
                options={bannerStatus}
              />
            </Grid>
            <Grid item xs={1} fontWeight="500">
              제목
            </Grid>
            <Grid item xs={11}>
              <FormInputText
                control={control}
                name="title"
                sx={{
                  maxWidth: "427px",
                  width: "100%",
                }}
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
            <Grid item xs={1} fontWeight="500">
              파일 첨부
            </Grid>
            <Grid item xs={11}>
              <Box display="flex" alignItems="center">
                <FormInputText
                  control={control}
                  staticValue={attachment?.name || notify?.attachmentName}
                  name="attachment"
                  placeholder={"파일명표기영역.hwp"}
                  sx={{
                    maxWidth: "297px",
                    width: "100%",
                  }}
                />
                <Button
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "12px",
                    marginLeft: "10px",
                    lineHeight: "15px",
                    padding: "8px 26px",
                    color: "#7653DF",
                    position: "relative",
                    backgroundColor: "#EEEAFB",
                  }}
                >
                  업로드
                  <input
                    type="file"
                    onChange={(e) => setAttachment(e.target.files[0])}
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      opacity: "0",
                    }}
                  />
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                height: "521px",
              }}
              height={"100%"}
              fontWeight="500"
            >
              내용
            </Grid>
            <Grid item xs={11}>
              <Box
                sx={{
                  border: "1px solid #E7E7E7",
                  borderRadius: "5px",
                  overflow: "hidden",
                }}
              >
                <FormInputCEditor
                  setValue={setValue}
                  control={control}
                  data={notify?.notificationDetail}
                  sx={{
                    ".ck-content": {
                      height: "475px",
                      border: "none",
                      borderTop: "1px solid #E0E0E0",
                    },
                    ".ck-toolbar, .ck-focused": {
                      border: "none",
                      borderTop: "1px solid #E0E0E0",
                    },
                  }}
                  name="notificationDetail"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <hr style={{ margin: "300px 0 20px" }} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        mt="25px"
      >
        {notify && (
          <Button
            onClick={() => handleDeleteNotify()}
            size="small"
            sx={{
              fontSize: "13px",
              width: "auto",
              height: "30px",
              marginRight: "auto",
              border: "1px solid #7653DF",
              padding: "8px 26px",
            }}
          >
            삭제
          </Button>
        )}
        <Button
          onClick={() => navigate("/post-notice/references")}
          size="small"
          sx={{
            fontSize: "13px",
            mr: "8px",
            height: "30px",
            padding: "7px 26px",
            color: "#fff",
            background: "#98A6AD",
          }}
        >
          취소
        </Button>
        {isUploading ? (
          <Button>
            <Loader style={{ backgroundColor: "red" }} />
          </Button>
        ) : (
          <Button
            onClick={handleSubmit(submit)}
            size="small"
            sx={{
              fontSize: "13px",
              width: "auto",
              height: "30px",
              padding: "7px 46px",
              color: "#fff",
              background: "#7653DF",
            }}
          >
            저장
          </Button>
        )}
      </Box>
      {modal.isOpen && (
        <AcceptModal
          setModal={setModal}
          modal={{ ...modal, isOpen: modal.isOpen }}
          handleClick={deleteById}
          modalKey="isOpen"
          title="삭제 하시겠습니까?"
        />
      )}
    </Box>
  );
}
