import mainCaller from "./mainCaller";

export async function getUserResumesById(id, token) {
  return await mainCaller(`resume/getByEmployeeId/${id}`, "GET", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getResumeById(id, token) {
  return await mainCaller(`resume/getById/${id}`, "GET", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function updateResumeById(data, token) {
  return await mainCaller(`resume/update`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function deleteResumeById(id, token) {
  return await mainCaller(`resume/deleteById/${id}`, "DELETE", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function createResume(data, token) {
  return await mainCaller(`resume/create`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function copyResume(data, token) {
  return await mainCaller(`resume/copy`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function sendResume(data, token) {
  return await mainCaller(`resume/sendEmail`, "POST", data, {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  });
}
