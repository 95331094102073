import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  TableHead,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  exportDataToExcel,
  formatKoreanPhoneNumber,
  getAge,
} from "../../utils/methods";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import { FormInputText } from "../../components/form-components/FormInputText";
import CustomCheckbox from "../../components/CustomCheckbox";
import { useSelector } from "react-redux";
import { getEmployeeList } from "../../api/user";
import {
  employmentType,
  order,
  pageSize,
  searchType3,
} from "../../constants/constants";
import SmsSendModal from "../../components/modals/SmsSendModal";
import DataNotFound from "../../components/DataNotFound";
import Loader from "../../components/loader";

const initialQuery = {
  number: 1,
  size: 20,
  order: "DESC",
  sort: "registration",
  searchType: "",
  keyword: "",
  employmentType: "",
  status: "APPROVED",
};

function Employee() {
  const { user } = useSelector((state) => state.user);
  const [query, setQuery] = useState(initialQuery);
  const [users, setUsers] = useState();
  const { handleSubmit, control, reset } = useForm({
    defaultValues: initialQuery,
  });
  const [smsModal, setSmsModal] = useState({ isOpen: false });
  const [isNoData, setIsNoData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePage = (_, value) => {
    setQuery({ ...query, number: value });
  };

  const donwloadExcel = () => {
    const checkedData = [];
    users?.content?.map((data) => {
      if (data.isChecked) {
        delete data.occupationId;
        delete data.quitDate;
        delete data.reason;
        delete data.workerSalary;
        delete data.subCustomerId;
        delete data.customerId;
        checkedData.push({
          ...data,
          occupation: data.occupation?.name,
          subCustomer: data.subCustomer?.name,
          customer: data.customer?.name,
        });
      }
    });

    if (checkedData.length === 0) {
      toast.warning("인원을 선택해 주세요!");
    } else {
      exportDataToExcel(checkedData, "사원");
    }
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    var tempUser = {};
    if (name === "allSelect") {
      tempUser = users.content.map((user) => {
        return { ...user, isChecked: checked };
      });
    } else {
      tempUser = users.content.map((user) =>
        user.name + user.applicantId === name
          ? { ...user, isChecked: checked }
          : user
      );
    }
    setUsers({ ...users, content: tempUser });
  };

  async function userList() {
    try {
      setIsLoading(true);
      const { data } = await getEmployeeList(query, user.token);
      setIsLoading(false);
      if (data.totalElements > 0) {
        setIsNoData(false);
        setUsers(data);
      } else {
        setIsNoData(true);
      }
    } catch (error) {
      console.log(error.response?.data.message);
    }
  }

  const submit = async (values) => {
    setQuery({ ...query, ...values });
  };

  const setInitial = () => {
    reset(initialQuery);
    setQuery(initialQuery);
  };

  const smsSend = () => {
    const checkedData = [];
    users?.content?.map((data) => {
      if (data.isChecked) {
        checkedData.push(data);
      }
      return data.isChecked;
    });

    if (checkedData.length === 0) {
      toast.warning("인원을 선택해 주세요!");
    } else {
      setSmsModal({ isOpen: true, checkedUsers: checkedData });
    }
  };

  useEffect(() => {
    userList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <Paper
      sx={{
        width: "100%",

        boxShadow: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          mb: "6px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography style={{ minWidth: "95px", fontWeight: 500 }}>
          검색
        </Typography>
        <FormInputDropdown
          control={control}
          name="searchType"
          sx={{ width: "180px", marginRight: "6px" }}
          options={searchType3}
        />
        <FormInputText
          sx={{ width: "180px", marginRight: "6px" }}
          control={control}
          name="keyword"
          placeholder="내용을 입력해 주세요"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          mb: "6px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography style={{ minWidth: "95px", fontWeight: 500 }}>
          취업형태
        </Typography>
        <FormInputDropdown
          control={control}
          name="employmentType"
          options={employmentType}
          sx={{ width: "180px", marginRight: "10px" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          mb: "6px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography style={{ minWidth: "95px", fontWeight: 500 }}>
          사업장 별 검색
        </Typography>
        <Button
          size="small"
          sx={{
            fontSize: "13px",
            width: "180px",
            mr: "6px",
            height: "30px",
            padding: "7px 46px",
            color: "#7653DF",
            background: "#EEEAFB",
          }}
        >
          사업장 선택
        </Button>
        <FormInputText
          sx={{ width: "180px", marginRight: "6px" }}
          control={control}
          name="adminCode"
          placeholder="내용을 입력해 주세요"
        />
      </Box>
      <div style={{ margin: "30px auto 20px auto" }} />
      <Box
        sx={{
          display: "flex",
          mb: "40px",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Button
          size="small"
          onClick={handleSubmit(submit)}
          sx={{
            fontSize: "13px",
            width: "auto",
            mr: "20px",
            height: "30px",
            padding: "7px 46px",
            color: "#fff",
            background: "#987EE7",
          }}
        >
          설정한 조건으로 검색
        </Button>
        <Button
          component={Link}
          onClick={setInitial}
          size="small"
          sx={{
            fontSize: "13px",
            color: "#6B7280",
            fontWeight: "500",
            textDecoration: "underline",
            "&:hover": {
              color: "#8E66FF",
            },
          }}
        >
          검색조건 초기화
        </Button>
      </Box>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Loader style={{ backgroundColor: "red" }} />
        </Box>
      ) : isNoData ? (
        <DataNotFound title="검색된 사원이 없습니다!" />
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              mb: "10px",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box display="flex" alignItems="center">
              <Button
                onClick={smsSend}
                sx={{
                  fontSize: "12px",
                  lineHeight: "15px",
                  height: "30px",
                  color: "#fff",
                  padding: "7px 20px",
                  marginRight: "6px",
                  background: "#6B7280",
                }}
              >
                SMS
              </Button>
              <Button
                onClick={() => donwloadExcel()}
                sx={{
                  fontSize: "12px",
                  lineHeight: "15px",
                  height: "30px",
                  padding: "7px 26px",
                  whiteSpace: "nowrap",
                  color: "#fff",
                  background: "#6B7280",
                  marginLeft: "auto",
                }}
              >
                엑셀 다운로드
              </Button>
              <CustomCheckbox
                name="allSelect"
                checked={
                  !users?.content.some((user) => user.isChecked !== true)
                }
                onChange={handleChange}
              />
              <Typography style={{ minWidth: "95px", fontWeight: "500" }}>
                현재 해당되는 모든 회원 ( {users?.totalElements} 명) 선택
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormInputDropdown
                control={control}
                name="order"
                sx={{ width: "75px", marginRight: "6px" }}
                onChange={(e) => setQuery({ ...query, order: e.target.value })}
                options={order}
                staticValue={query.order}
              />
              <FormInputDropdown
                control={control}
                name="size"
                sx={{ width: "90px" }}
                onChange={(e) => setQuery({ ...query, size: e.target.value })}
                options={pageSize}
                staticValue={query.size}
              />
            </Box>
          </Box>
          <TableContainer
            sx={{
              height: `${window.innerHeight - 470}px`,
              position: "relative",
            }}
            className="table"
          >
            <Table
              sx={{
                borderTop: "1px solid #98A6AD",
              }}
            >
              <TableHead
                sx={{
                  background: "#ebebeb",
                  position: "sticky",
                  top: -1,
                  zIndex: 10,
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                    align="center"
                  >
                    <CustomCheckbox
                      color="primary"
                      name="allSelect"
                      checked={
                        !users?.content.some((user) => user.isChecked !== true)
                      }
                      onChange={handleChange}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    번호
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    성명
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    주민번호
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    연락처
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    업체명
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    입사일자
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    사원번호
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    취업형태
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    관리자
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users
                  ? users.content.map((user, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          role="checkbox"
                          key={index}
                          sx={{
                            "&:hover": {
                              color: "#5a5a5a !important",
                              backgroundColor: "rgba(142, 102, 255, 0.22)",
                            },
                            textDecoration: "none",
                            height: "40px",
                          }}
                        >
                          <TableCell
                            padding="checkbox"
                            sx={{
                              padding: "10px",
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                            align="center"
                          >
                            <CustomCheckbox
                              name={user.name + user.applicantId}
                              checked={user?.isChecked || false}
                              onChange={handleChange}
                            />
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/employees/getByApplicantId/${user.applicantId}`}
                            sx={{
                              textDecoration: "none",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "10px",
                            }}
                            align="center"
                          >
                            {query.size * (query.number - 1) + index + 1}
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/employees/getByApplicantId/${user.applicantId}`}
                            sx={{
                              textDecoration: "none",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "10px",
                            }}
                            align="center"
                          >
                            {user.name}
                          </TableCell>

                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/employees/getByApplicantId/${user.applicantId}`}
                            sx={{
                              textDecoration: "none",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "10px",
                            }}
                            align="center"
                          >
                            <span>
                              {user.social1}
                              {user.social2 ? ` - ${user.social2}` : ""}
                            </span>
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/employees/getByApplicantId/${user.applicantId}`}
                            sx={{
                              textDecoration: "none",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "10px",
                            }}
                            align="center"
                          >
                            {user.phoneNumber &&
                              formatKoreanPhoneNumber(user.phoneNumber)}
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/employees/getByApplicantId/${user.applicantId}`}
                            sx={{
                              textDecoration: "none",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "10px",
                            }}
                            align="center"
                          >
                            {user.customerName}
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/employees/getByApplicantId/${user.applicantId}`}
                            sx={{
                              textDecoration: "none",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "10px",
                            }}
                            align="center"
                          >
                            {getAge(user.joiningDate)}
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/employees/getByApplicantId/${user.applicantId}`}
                            sx={{
                              textDecoration: "none",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "10px",
                            }}
                            align="center"
                          >
                            {user.employeeNumber}
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/employees/getByApplicantId/${user.applicantId}`}
                            sx={{
                              textDecoration: "none",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "10px",
                            }}
                            align="center"
                          >
                            {user.employmentType}
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/employees/getByApplicantId/${user.applicantId}`}
                            sx={{
                              textDecoration: "none",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "10px",
                            }}
                            align="center"
                          >
                            {user.personInCharge}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
            <Pagination
              count={Math.ceil(users?.totalElements / query.size) || 0}
              onChange={handleChangePage}
              page={query.number}
              color="primary"
              variant="outlined"
              shape="rounded"
              sx={{
                m: "0 auto",
                "& .MuiPaginationItem-root.MuiPaginationItem-page, .MuiPaginationItem-previousNext":
                  { padding: "4px 20px !important" },
              }}
            />
          </Stack>
        </Box>
      )}
      <SmsSendModal
        setModal={setSmsModal}
        modal={{ ...smsModal, isOpen: smsModal.isOpen }}
        modalKey="isOpen"
      />
    </Paper>
  );
}

export default Employee;
