import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const style = {
  fontSize: "13px",
  lineHeight: "15px",
  height: "30px ",
  marginBottom: "20px",
  textDecoration: "none",
  borderRadius: "5px",
  padding: "7px 35px",
  color: "#fff",
  fontWeight: 700,
  backgroundColor: "#8E66FF",
};

export default function UserStatusChanger({ user, setModal, modalKey }) {
  const changeStatus = (status) => {
    setModal({
      isOpen: true,
      [modalKey]: true,
      status,
    });
  };

  switch (user.status) {
    case "SEEKER":
      return (
        <Button
          component={Link}
          to={`/send-to-pending/${user.id}`}
          style={style}
        >
          사원 대기자로 보내기
        </Button>
      );
    case "PENDING":
      return (
        <Button onClick={() => changeStatus("APPROVED")} style={style}>
          사원으로 보내기
        </Button>
      );
    case "APPROVED":
      return (
        <Button
          component={Link}
          to={`/send-to-pre-retired/${user.id}`}
          style={style}
        >
          퇴사 대기자
        </Button>
      );

    case "PRE_RETIRED":
      return (
        <Button onClick={() => changeStatus("RETIRED")} style={style}>
          퇴사자로 보내기
        </Button>
      );
    case "RETIRED":
      return (
        <Button
          component={Link}
          to={`/send-to-pending/${user.id}`}
          style={style}
        >
          사원 대기자로 보내기
        </Button>
      );
    case "REJECTED":
      return "Rejected";
    default:
      return (
        <Button
          component={Link}
          to={`/send-to-pending/${user.id}`}
          disabled={user.status === "PENDING"}
          style={style}
        >
          사원 대기자로 보내기
        </Button>
      );
  }
}
