import { Box, Typography } from "@mui/material";

const DataNotFound = ({ title }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "30px",
        borderTop: "1px solid #E7E7E7",
      }}
    >
      <Typography marginTop={2} variant="body1">
        {title ? title : "검색된 데이터가 없습니다!"}
      </Typography>
    </Box>
  );
};

export default DataNotFound;
