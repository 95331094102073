import React, { useState } from "react";
import { Box, Typography, Button, Grid, TextField } from "@mui/material";
import BackButton from "../../components/BackButton";
import LinkButton from "../../components/LinkButton";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  createWaiting,
  deleteEmployeeDataById,
  getEmployeeDataById,
  getUserById,
  statusChanger,
  updateWaiting,
} from "../../api/user";
import { useSelector } from "react-redux";
import { FormInputText } from "../../components/form-components/FormInputText";
import { useForm } from "react-hook-form";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import { FormInputDate } from "../../components/form-components/FormInputDate";
import CustomCheckbox from "../../components/CustomCheckbox";
import { FormInputRadio } from "../../components/form-components/FormInputRadio";
import { eduLevel, employmentType, gender } from "../../constants/constants";
import SubCompanySelector from "../../components/modals/SubCompanySelector";
import CustomModal from "../../components/modals/CustomModal";
import CodeManager from "../../components/modals/CodeManager";
import { toast } from "react-toastify";
import { awsImagePath, formatKoreanPhoneNumber } from "../../utils/methods";
import UserSalaryAddModal from "../../components/modals/UserSalaryAddModal";
import AcceptModal from "../../components/modals/AcceptModal";

const options = [
  {
    label: "있음",
    value: "있음",
  },
  {
    label: "없음",
    value: "없음",
  },
];
const newDate = new Date();

export default function SendToPending() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState();
  const { token } = useSelector((state) => state.user.user);
  const { handleSubmit, control, setValue, watch, reset } = useForm();
  const [user, setUser] = useState();
  const [tab, setTab] = useState(1);
  const [modal, setModal] = useState({
    isOpen: false,
    isOpen1: false,
    isOpen2: false,
    isOpen3: false,
    isOpen4: false,
    isOpen5: false,
  });
  const [salaryModal, setSalaryModal] = useState({
    isOpen: false,
    closed: false,
  });

  const getUser = async () => {
    try {
      const { data } = await getUserById(id, undefined, token);
      setUser(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getEmployee = async () => {
    try {
      const { data } = await getEmployeeDataById(id, token);
      setEmployee(data);
      if (data) {
        setTab(2);
      }
    } catch (e) {
      console.log(e);
      setEmployee();
    }
  };

  const searchCompany = () => {
    setModal({ ...modal, isOpen: true, keyword: watch("searchCompany") });
  };
  const searchDispatch = () => {
    setModal({ ...modal, isOpen1: true, keyword: watch("searchDispatch") });
  };
  const searchInd = () => {
    setModal({ ...modal, isOpen2: true, keyword: watch("searchInd") });
  };
  const searchJob = () => {
    setModal({ ...modal, isOpen3: true, keyword: watch("searchJob") });
  };
  const searchManage = () => {
    setModal({ ...modal, isOpen4: true, keyword: watch("searchManagement") });
  };

  const handleCancelWaiting = async () => {
    const value = {
      applicantId: user.id,
      careerStageId: employee?.id,
    };
    if (employee?.id) {
      try {
        await deleteEmployeeDataById(value, token);
        setModal({ isOpen5: false });
        window.location.reload();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleDelete = async () => {
    setModal({ isOpen5: true });
  };

  const submit = async (values) => {
    const newValues = {
      ...values,
      applicantId: +id,
      joiningDate: values.joiningDate ? values.joiningDate : newDate,
      dateFrom: values.dateFrom ? values.dateFrom : newDate,
      dateUntil: values.dateUntil ? values.dateUntil : newDate,
      customerId: modal.customer?.id,
      occupationId: modal.occupation?.id,
      subCustomerId: modal.subCustomer?.id,
      dispatchCodeId: modal.dispatchCode?.id,
      managementDepartmentId: modal.managementDepartment?.id || 0,
      departmentId: modal.department?.id,
      status: "PENDING",
    };

    const employeeValue = {
      ...values,
      applicantId: +id,
      id: employee?.id,
      joiningDate: values.joiningDate ? values.joiningDate : newDate,
      dateFrom: values.dateFrom ? values.dateFrom : newDate,
      dateUntil: values.dateUntil ? values.dateUntil : newDate,
      customerId: employee?.customer?.id,
      occupationId: employee?.occupation?.id,
      subCustomerId: employee?.subCustomer?.id,
      dispatchCodeId: employee?.dispatchCode?.id,
      managementDepartmentId: modal.managementDepartment?.id || 0,
      departmentId: employee?.department?.id,
      status: "PENDING",
    };

    try {
      if (employee?.id) {
        await updateWaiting(employeeValue, token);
      } else {
        await createWaiting(newValues, token);
      }
      setTab(2);
      getEmployee();
      if (tab === 2) {
        navigate("/talent/employee-waiting-list");
      }
    } catch (err) {
      toast.warning(err.response?.data.message);
    }
  };

  useEffect(() => {
    getUser();
    getEmployee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    getEmployee();
  }, [id, salaryModal.closed]);

  return (
    user && (
      <div
        style={{
          background: "white",
          borderRadius: "5px",
          paddingTop: "10px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <BackButton text="사원 대기자로 보내기" />
          <LinkButton link="/talent/all-user" label="뒤로가기" />
        </Box>
        <div style={{ margin: "16px 0 40px 0" }} />
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              minWidth: "200px",
              maxWidth: "200px",
              paddingRight: "12px",
              borderRight: "1px solid #E7E7E7",
            }}
          >
            <label htmlFor="imageupload" style={{ width: "100%" }}>
              <Box
                position="relative"
                sx={{
                  "&:hover": { cursor: "pointer" },
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "240px",
                    background: "#D9D9D9",
                    borderRadius: "5px",
                    position: "relative",
                    overflow: "hidden",
                    marginBottom: "40px",
                  }}
                >
                  {user.profilePicture ? (
                    <img
                      src={`${awsImagePath}/${user.profilePicture}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      alt="uploader"
                    />
                  ) : null}
                </div>
              </Box>
            </label>
            <Typography
              sx={{
                mb: "20px",
                fontWeight: 700,
                fontSize: "16px",
              }}
            >
              {user.name}
            </Typography>
            <Grid container rowSpacing={2} sx={{ wordBreak: "break-all" }}>
              <Grid
                item
                xs={4}
                color="#6B7280"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                생년월일
              </Grid>
              <Grid
                item
                xs={8}
                color="#3A3A3A"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                {user.birthday}
              </Grid>
              <Grid
                item
                xs={4}
                color="#6B7280"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                성별
              </Grid>
              <Grid
                item
                xs={8}
                color="#3A3A3A"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                {user.gender}
              </Grid>
              <Grid
                item
                xs={4}
                color="#6B7280"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                휴대폰
              </Grid>
              <Grid
                item
                xs={8}
                color="#3A3A3A"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                {user.phoneNumber && formatKoreanPhoneNumber(user.phoneNumber)}
              </Grid>
              <Grid
                item
                xs={4}
                color="#6B7280"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                이메일
              </Grid>
              <Grid
                item
                xs={8}
                color="#3A3A3A"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                {user.email}
              </Grid>
            </Grid>
          </Box>
          {tab === 1 && (
            <Box
              sx={{
                width: "100%",
                pl: "20px",
              }}
            >
              <Typography whiteSpace="nowrap" mr="32px" mb="15px">
                <b style={{ fontSize: "15px" }}>1. 기본 정보</b>
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  <Grid container rowSpacing={1} alignItems="center">
                    <Grid item xs={2} fontWeight="500">
                      이름(한글)
                    </Grid>
                    <Grid item xs={10}>
                      <FormInputText
                        disabled
                        control={control}
                        staticValue={user.name}
                      />
                    </Grid>
                    <Grid item xs={2} fontWeight="500">
                      성별
                    </Grid>
                    <Grid item xs={4}>
                      <FormInputDropdown
                        control={control}
                        staticValue={user.gender}
                        disabled
                        sx={{
                          maxWidth: "180px",
                          width: "100%",
                        }}
                        options={gender}
                      />
                    </Grid>
                    <Grid item xs={2} fontWeight="500" textAlign="center">
                      최종학력
                    </Grid>
                    <Grid item xs={4}>
                      <FormInputDropdown
                        control={control}
                        disabled
                        staticValue={user.eduLevel}
                        sx={{
                          maxWidth: "180px",
                          width: "100%",
                        }}
                        options={eduLevel}
                      />
                    </Grid>
                    <Grid item xs={2} fontWeight="500">
                      주소
                    </Grid>
                    <Grid item xs={10}>
                      <FormInputText
                        control={control}
                        staticValue={user.address}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={10}>
                      <FormInputText
                        control={control}
                        staticValue={user.addressDetails}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} pl="28px">
                  <Grid container rowSpacing={1} alignItems="center">
                    <Grid item xs={2} fontWeight="500">
                      생년월일
                    </Grid>
                    <Grid item xs={10}>
                      <FormInputText
                        control={control}
                        staticValue={user.birthday}
                        disabled
                        placeholder="1998.01.27"
                      />
                    </Grid>
                    <Grid item xs={2} fontWeight="500">
                      휴대폰
                    </Grid>
                    <Grid item xs={10}>
                      <Typography color="red" fontSize={12}>
                        핸드폰 번호는 반드시 "-" 제외하고 입력하세요!
                      </Typography>
                      <FormInputText
                        control={control}
                        staticValue={user.phoneNumber}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={2} fontWeight="500">
                      이메일
                    </Grid>
                    <Grid item xs={10}>
                      <FormInputText
                        control={control}
                        staticValue={user.email}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt="30px">
                <Grid item xs={6}>
                  <Typography color="red" fontSize={12}>
                    주민 번호는 반드시 "-" 제외하고 입력하세요!
                  </Typography>
                  <Grid container rowSpacing={1} alignItems="center">
                    <Grid item xs={2} fontWeight="500">
                      주민번호
                    </Grid>
                    <Grid item xs={4}>
                      <FormInputText
                        control={control}
                        name="social1"
                        type="tel"
                        placeholder="내용을 입력해 주세요"
                        InputProps={{ maxLength: 6 }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      fontWeight="500"
                      px="20px"
                      textAlign="center"
                    >
                      ㅡ
                    </Grid>
                    <Grid item xs={4}>
                      <FormInputText
                        control={control}
                        name="social2"
                        type="tel"
                        placeholder="내용을 입력해 주세요"
                        InputProps={{ maxLength: 7 }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} pl="28px">
                  <Grid container rowSpacing={1} alignItems="center">
                    <Grid item xs={2} fontWeight="500">
                      등록일
                    </Grid>
                    <Grid item xs={10}>
                      <FormInputText
                        control={control}
                        staticValue={user.createdDate.split("T")[0]}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div style={{ margin: "40px 0 30px 0" }} />
              <Typography whiteSpace="nowrap" mr="32px" mb="15px">
                <b style={{ fontSize: "15px" }}>2. 근무 업체 정보</b>
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  <Grid container rowSpacing={1} alignItems="center">
                    <Grid item xs={2} fontWeight="500">
                      업체명
                    </Grid>
                    <Grid item xs={10}>
                      <Box display="flex" alignItems="center">
                        <FormInputText
                          control={control}
                          name="searchCompany"
                          staticValue={modal.customer?.name}
                        />
                        <Button
                          onClick={searchCompany}
                          sx={{
                            whiteSpace: "nowrap",
                            fontSize: "12px",
                            marginLeft: "10px",
                            lineHeight: "14px",
                            padding: "8px 26px",
                            color: "#7653DF",
                            backgroundColor: "#EEEAFB",
                          }}
                        >
                          검색
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={2} fontWeight="500">
                      사업장명
                    </Grid>
                    <Grid item xs={10}>
                      <Box display="flex" alignItems="center">
                        <FormInputText
                          control={control}
                          staticValue={modal.subCustomer?.name}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={2} fontWeight="500">
                      근무 부서
                    </Grid>
                    <Grid item xs={10}>
                      <Box display="flex" alignItems="center">
                        <FormInputText
                          control={control}
                          name="searchInd"
                          staticValue={modal.department?.name}
                        />
                        <Button
                          onClick={searchInd}
                          sx={{
                            whiteSpace: "nowrap",
                            fontSize: "12px",
                            marginLeft: "10px",
                            lineHeight: "15px",
                            padding: "8px 26px",
                            color: "#7653DF",
                            backgroundColor: "#EEEAFB",
                          }}
                        >
                          검색
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={2} fontWeight="500">
                      직무
                    </Grid>
                    <Grid item xs={10}>
                      <Box display="flex" alignItems="center">
                        <FormInputText
                          control={control}
                          name="searchJob"
                          staticValue={modal.occupation?.name}
                        />
                        <Button
                          onClick={searchJob}
                          sx={{
                            whiteSpace: "nowrap",
                            fontSize: "12px",
                            marginLeft: "10px",
                            lineHeight: "15px",
                            padding: "8px 26px",
                            color: "#7653DF",
                            backgroundColor: "#EEEAFB",
                          }}
                        >
                          검색
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={2} fontWeight="500">
                      세부 수행 업무
                    </Grid>
                    <Grid item xs={10}>
                      <Box display="flex" alignItems="center">
                        <FormInputText
                          control={control}
                          name="detailedTasks"
                          placeholder="영상 편집(편집기자)"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} pl="28px">
                  <Grid container rowSpacing={1} alignItems="center">
                    <Grid item xs={2} fontWeight="500">
                      업체코드
                    </Grid>
                    <Grid item xs={10}>
                      <FormInputText
                        control={control}
                        staticValue={modal.customer?.code}
                        placeholder="99999999"
                      />
                    </Grid>
                    <Grid item xs={2} fontWeight="500">
                      사업장 코드
                    </Grid>
                    <Grid item xs={10}>
                      <Box display="flex" alignItems="center">
                        <FormInputText
                          control={control}
                          staticValue={modal.subCustomer?.code}
                          placeholder="내용을 입력해 주세요"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={2} fontWeight="500">
                      취업 형태
                    </Grid>
                    <Grid item xs={10}>
                      <FormInputDropdown
                        control={control}
                        name="eploymentType"
                        sx={{
                          maxWidth: "166px",
                          width: "100%",
                        }}
                        options={employmentType}
                      />
                    </Grid>
                    <Grid item xs={2} fontWeight="500">
                      파견 코드
                    </Grid>
                    <Grid item xs={10}>
                      <Box display="flex" alignItems="center">
                        <FormInputText
                          control={control}
                          staticValue={modal.dispatchCode?.name}
                          name="searchDispatch"
                        />
                        <Button
                          onClick={searchDispatch}
                          sx={{
                            whiteSpace: "nowrap",
                            fontSize: "12px",
                            marginLeft: "10px",
                            lineHeight: "15px",
                            padding: "8px 26px",
                            color: "#7653DF",
                            backgroundColor: "#EEEAFB",
                          }}
                        >
                          검색
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div style={{ margin: "40px 0 30px 0" }} />
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Button
                  onClick={() => navigate(-1)}
                  size="small"
                  sx={{
                    display: "block",
                    fontSize: "12px",
                    lineHeight: "15px",
                    mr: "8px",
                    padding: "7px 26px",
                    color: "#fff",
                    background: "#98A6AD",
                  }}
                >
                  취소
                </Button>
                <Button
                  onClick={handleSubmit(submit)}
                  size="small"
                  sx={{
                    fontSize: "12px",
                    lineHeight: "15px",
                    padding: "7px 50px",
                    display: "block",
                    color: "#fff",
                    backgroundColor: "#7653DF",
                  }}
                >
                  다음
                </Button>
              </Box>
            </Box>
          )}
          {tab === 2 && (
            <Box
              sx={{
                width: "100%",
                pl: "20px",
              }}
            >
              <Typography whiteSpace="nowrap" mr="32px" mb="15px">
                <b style={{ fontSize: "15px" }}>3. 입사 정보</b>
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  <Grid container rowSpacing={1} alignItems="center">
                    <Grid item xs={2} fontWeight="500">
                      입사일
                    </Grid>
                    <Grid item xs={4}>
                      <FormInputDate
                        name="joiningDate"
                        color="info"
                        control={control}
                        placeholder="YYYY/MM/DD"
                        sx={{
                          mb: "20px",
                          ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
                            fontSize: "16px",
                          },
                        }}
                        size="small"
                        variant="outlined"
                        val
                        renderInput={(params) => (
                          <TextField
                            color="info"
                            size="small"
                            variant="outlined"
                            {...params}
                            sx={{
                              width: "127px",
                              background: "#fff",
                              "& .MuiSvgIcon-root": {
                                fill: "#987EE7 !important",
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={2} fontWeight="500">
                      근무 부서
                    </Grid>
                    <Grid item xs={3}>
                      <FormInputDate
                        name="dateFrom"
                        color="info"
                        control={control}
                        disabled={watch("contractPeriod")}
                        placeholder="YYYY/MM/DD"
                        sx={{
                          mb: "20px",
                          ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
                            fontSize: "16px",
                          },
                        }}
                        size="small"
                        variant="outlined"
                        renderInput={(params) => (
                          <TextField
                            color="info"
                            size="small"
                            variant="outlined"
                            {...params}
                            sx={{
                              width: "127px",
                              background: "#fff",
                              "& .MuiSvgIcon-root": {
                                fill: "#987EE7 !important",
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2} pl="20px" fontWeight="500">
                      부터
                    </Grid>
                    <Grid item xs={3}>
                      <FormInputDate
                        name="dateUntil"
                        color="info"
                        disabled={watch("contractPeriod")}
                        control={control}
                        placeholder="YYYY/MM/DD"
                        sx={{
                          mb: "20px",
                          ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
                            fontSize: "16px",
                          },
                        }}
                        size="small"
                        variant="outlined"
                        renderInput={(params) => (
                          <TextField
                            color="info"
                            size="small"
                            variant="outlined"
                            {...params}
                            sx={{
                              width: "127px",
                              background: "#fff",
                              "& .MuiSvgIcon-root": {
                                fill: "#987EE7 !important",
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item pl="15px" xs={2}>
                      <Typography>까지</Typography>
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={10}>
                      <Box display="flex" alignItems="center">
                        <CustomCheckbox
                          setValue={setValue}
                          name="contractPeriod"
                        />
                        <Typography whiteSpace="nowrap" fontWeight="500">
                          계약 기간 없음 (정규직)
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={2} fontWeight="500">
                      근로자 급여
                    </Grid>
                    <Grid item xs={10}>
                      <Box display="flex" alignItems="center">
                        <FormInputText
                          control={control}
                          disabled
                          staticValue={`${employee?.workerSalary
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${
                            employee?.totalTax
                              ? `(${employee?.totalTax
                                  ?.toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`
                              : ""
                          }`}
                        />
                        <Button
                          onClick={() =>
                            setSalaryModal({
                              isOpen: true,
                              user: employee,
                              closed: false,
                            })
                          }
                          sx={{
                            whiteSpace: "nowrap",
                            fontSize: "12px",
                            marginLeft: "10px",
                            lineHeight: "15px",
                            padding: "8px 26px",
                            color: "#7653DF",
                            backgroundColor: "#EEEAFB",
                          }}
                        >
                          검색
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={2} fontWeight="500">
                      4대보험
                    </Grid>
                    <Grid item xs={10}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <CustomCheckbox
                            setValue={setValue}
                            name="pensionApplication"
                          />
                          <Typography whiteSpace="nowrap" fontWeight={500}>
                            국민연금 접수
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <CustomCheckbox
                            setValue={setValue}
                            name="healthInsurance"
                          />
                          <Typography whiteSpace="nowrap" fontWeight={500}>
                            건강보험 접수
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <CustomCheckbox
                            setValue={setValue}
                            name="employmentInsurance"
                          />
                          <Typography whiteSpace="nowrap" fontWeight={500}>
                            고용보험 접수
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={2} fontWeight="500">
                      급여 은행
                    </Grid>
                    <Grid item xs={10}>
                      <Box display="flex" alignItems="center">
                        <FormInputText
                          staticValue={employee?.bankSalary}
                          control={control}
                          name="bankSalary"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} pl="28px">
                  <Grid container rowSpacing={1} alignItems="center">
                    <Grid item xs={3} fontWeight="500">
                      건강보혐가족등재여부
                    </Grid>
                    <Grid item xs={9}>
                      <FormInputRadio
                        name="statusOfHealth"
                        sx={{ marginLeft: "50px" }}
                        control={control}
                        options={options}
                        row
                      />
                    </Grid>
                    <Grid item xs={12} py="36px" />
                    <Grid item xs={12} py="36px" />
                    <Grid item xs={12} py="36px" />
                    <Grid item xs={12} pl="40px" mt="auto">
                      <Box display="flex" alignItems="center">
                        <CustomCheckbox
                          setValue={setValue}
                          name="accidentInsurance"
                        />
                        <Typography whiteSpace="nowrap" fontWeight={500}>
                          산재보험 접수
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={2} fontWeight="500">
                      계좌번호
                    </Grid>
                    <Grid item xs={10}>
                      <Box display="flex" alignItems="center">
                        <FormInputText
                          control={control}
                          staticValue={employee?.accountNumber}
                          name="accountNumber"
                          type="number"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div style={{ margin: "40px 0 30px 0" }} />
              <Typography whiteSpace="nowrap" mr="32px" mb="15px">
                <b style={{ fontSize: "15px" }}>4. 관리자 정보</b>
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  <Grid container rowSpacing={1} alignItems="center">
                    <Grid item xs={2} fontWeight="500">
                      관리 담당자
                    </Grid>
                    <Grid item xs={10}>
                      <Box display="flex" alignItems="center">
                        <FormInputText
                          control={control}
                          staticValue={employee?.personInCharge}
                          name="personInCharge"
                          placeholder="관리부서자동표기영역"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} pl="28px">
                  <Grid container rowSpacing={1} alignItems="center">
                    <Grid item xs={2} fontWeight="500">
                      관리 부서
                    </Grid>
                    <Grid item xs={10}>
                      <Box display="flex" alignItems="center">
                        <FormInputText
                          control={control}
                          staticValue={
                            modal.managementDepartment?.name ||
                            employee?.managementDepartment?.name
                          }
                          name="searchManagement"
                          placeholder="관리팀자동표기영역"
                        />
                        <Button
                          onClick={searchManage}
                          sx={{
                            whiteSpace: "nowrap",
                            fontSize: "12px",
                            marginLeft: "10px",
                            lineHeight: "15px",
                            padding: "8px 26px",
                            color: "#7653DF",
                            backgroundColor: "#EEEAFB",
                          }}
                        >
                          검색
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div style={{ margin: "40px 0 30px 0" }} />
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Button
                  onClick={() => handleDelete()}
                  size="small"
                  sx={{
                    display: "block",
                    fontSize: "12px",
                    lineHeight: "15px",
                    mr: "8px",
                    padding: "7px 26px",
                    color: "#fff",
                    background: "#98A6AD",
                  }}
                >
                  취소
                </Button>
                <Button
                  onClick={handleSubmit(submit)}
                  size="small"
                  sx={{
                    fontSize: "12px",
                    lineHeight: "15px",
                    padding: "7px 50px",
                    display: "block",
                    color: "#fff",
                    backgroundColor: "#7653DF",
                  }}
                >
                  보내기
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        {modal.isOpen && (
          <SubCompanySelector modal={modal} setModal={setModal} />
        )}
        {modal.isOpen1 && (
          <CustomModal
            modal={{ isOpen: modal.isOpen1 }}
            style={{ width: "90%", height: "80vh", padding: "30px" }}
          >
            <CodeManager
              modal={modal}
              setModal={setModal}
              contentKey="dispatchCode"
              apiUrl="dispatchCode"
              modalKey="isOpen1"
            />
          </CustomModal>
        )}
        {modal.isOpen2 && (
          <CustomModal
            modal={{ isOpen: modal.isOpen2 }}
            style={{ width: "90%", height: "80vh", padding: "30px" }}
          >
            <CodeManager
              modal={modal}
              setModal={setModal}
              contentKey="department"
              apiUrl="departments"
              modalKey="isOpen2"
            />
          </CustomModal>
        )}
        {modal.isOpen3 && (
          <CustomModal
            modal={{ isOpen: modal.isOpen3 }}
            style={{ width: "90%", height: "80vh", padding: "30px" }}
          >
            <CodeManager
              modal={modal}
              setModal={setModal}
              contentKey="occupation"
              apiUrl="occupation"
              modalKey="isOpen3"
            />
          </CustomModal>
        )}
        {modal.isOpen4 && (
          <CustomModal
            modal={{ isOpen: modal.isOpen4 }}
            style={{ width: "90%", height: "80vh", padding: "30px" }}
          >
            <CodeManager
              modal={modal}
              setModal={setModal}
              contentKey="managementDepartment"
              apiUrl="manDepartments"
              modalKey="isOpen4"
            />
          </CustomModal>
        )}
        {modal.isOpen5 && (
          <AcceptModal
            handleClick={handleCancelWaiting}
            modal={{ ...modal, isOpen: modal.isOpen5 }}
            modalKey="isOpen5"
            setModal={setModal}
            title="취소하시겠습니까?"
            desc="취소 시 이전 작성 내용은 저장되지 않습니다!"
          />
        )}
        <CustomModal
          modal={{ isOpen: salaryModal.isOpen }}
          style={{
            width: "100%",
            maxWidth: "1430px",
            height: "90vh",
            padding: "40px",
          }}
        >
          <UserSalaryAddModal
            modal={salaryModal}
            token={token}
            setModal={setSalaryModal}
            modalKey="isOpen"
          />
        </CustomModal>
      </div>
    )
  );
}
