import React, { useState } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import UserTab1 from "./UserTab1";
import UserTab2 from "./UserTab2";
import { getPersonnelById } from "../../../api/user";
import BackButton from "../../../components/BackButton";
import UserChanger from "../../../components/UserChanger";
import LinkButton from "../../../components/LinkButton";
import SmsSendModal from "../../../components/modals/SmsSendModal";
import { formatKoreanPhoneNumber } from "../../../utils/methods";

function UserDetail({ initialTab }) {
  const { id } = useParams();
  const { token } = useSelector((state) => state.user.user);
  const [selectedTab, setSelectedTab] = useState(initialTab ?? 1);
  const [previewImage, setPreviewImage] = useState();
  const [user, setUser] = useState();
  const [modal, setModal] = useState({
    isOpen: false,
  });

  const handleUploadImage = (e) => {
    setPreviewImage(e.target.files[0]);
  };

  const handleChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  const getUser = async () => {
    try {
      const { data } = await getPersonnelById(id, token);
      setUser(data);
      setPreviewImage(data.profilePicture);
    } catch (error) {
      console.log(error);
    }
  };

  const smsSend = () => {
    setModal({ isOpen: true, user: user });
  };

  useEffect(() => {
    getUser();
  }, [id]);

  const handleTab = (tab) => {
    switch (tab) {
      case 1:
        return <UserTab1 user={user} token={token} />;
      case 2:
        return <UserTab2 user={user} token={token} />;
      default:
        return <UserTab1 user={user} token={token} />;
    }
  };

  return (
    user && (
      <div
        style={{
          background: "white",
          borderRadius: "5px",
          paddingTop: "10px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <BackButton text="콘택트 인원 정보" />
          <>
            <UserChanger url="talent/number-contacts" user={user} />
            <LinkButton link="/talent/number-contacts" label="목록보기" />
          </>
        </Box>
        <hr style={{ margin: "16px 0 20px 0" }} />
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              minWidth: "200px",
              maxWidth: "200px",
              marginTop: "18px",
              paddingRight: "12px",
              borderRight: "1px solid #E7E7E7",
            }}
          >
            {/* <label htmlFor="imageupload" style={{ width: "100%" }}>
              <Box
                position="relative"
                sx={{
                  "&:hover": { cursor: "pointer" },
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "240px",
                    background: "#D9D9D9",
                    borderRadius: "5px",
                    position: "relative",
                    overflow: "hidden",
                    marginBottom: "40px",
                  }}
                >
                  {previewImage ? (
                    <img
                      src={
                        user.profilePicture ?? URL.createObjectURL(previewImage)
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      alt="uploader"
                    />
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#987EE7",
                        color: "#fff",
                        width: "50px",
                        height: "50px",
                        bottom: "7px",
                        right: "7px",
                        borderRadius: "50%",
                      }}
                    >
                      <PhotoCameraOutlinedIcon
                        sx={{ fontSize: "35px", color: "#fff" }}
                      />
                    </div>
                  )}
                </div>
              </Box>
              <input
                type="file"
                id="imageupload"
                style={{ display: "none" }}
                onChange={handleUploadImage}
              />
            </label> */}
            <Typography
              sx={{
                mb: "20px",
                fontWeight: 700,
                fontSize: "16px",
              }}
            >
              {user.name}
            </Typography>
            <Grid container rowSpacing={2} sx={{ wordBreak: "break-all" }}>
              <Grid
                item
                xs={4}
                color="#6B7280"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                생년월일
              </Grid>
              <Grid
                item
                xs={8}
                color="#3A3A3A"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                {user.birthday}
              </Grid>
              <Grid
                item
                xs={4}
                color="#6B7280"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                성별
              </Grid>
              <Grid
                item
                xs={8}
                color="#3A3A3A"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                {user.gender}
              </Grid>
              <Grid
                item
                xs={4}
                color="#6B7280"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                휴대폰
              </Grid>
              <Grid
                item
                xs={8}
                color="#3A3A3A"
                style={{
                  paddingTop: "10px",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                {user.phoneNumber && formatKoreanPhoneNumber(user.phoneNumber)}
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              width: "100%",
              pl: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <Tabs
                sx={{
                  "& .MuiTab-root.MuiTab-textColorSecondary": {
                    borderRadius: "5px 5px 0px 0px",
                    color: "#987EE7",
                    background: "#EEEAFB",
                    mr: "5px",
                    minHeight: "30px !important",
                    p: "0",
                    fontWeight: "500",
                    fontSize: "13px",
                  },
                  "& .MuiTabs-scroller": {
                    alignItems: "flex-end",
                    display: "flex",
                  },
                  "& .MuiTab-root.MuiTab-textColorSecondary.Mui-selected": {
                    background: "#987EE7",
                    color: "#fff",
                  },
                  "& .MuiTabs-indicator": {
                    display: "none",
                    color: "#E1E1E1",
                  },
                }}
                value={selectedTab}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab value={1} label="상담 내역" />
                <Tab value={2} label="개인 정보" />
              </Tabs>
              <Box display="flex" flexDirection="column">
                <Button
                  onClick={smsSend}
                  sx={{
                    fontSize: "12px",
                    lineHeight: "15px",
                    height: "30px",
                    color: "#fff",
                    padding: "7px 20px",
                    marginRight: "6px",
                    background: "#6B7280",
                  }}
                >
                  SMS
                </Button>
              </Box>
            </Box>
            <hr
              style={{
                margin: "5px 0 40px 0",
              }}
            />
            {handleTab(selectedTab)}
          </Box>
        </Box>
        <SmsSendModal
          setModal={setModal}
          modal={{ ...modal, isOpen: modal.isOpen }}
          modalKey="isOpen"
        />
      </div>
    )
  );
}

export default UserDetail;
