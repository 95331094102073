import {
  Box,
  Button,
  Divider,
  Modal,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserSummaryDetail } from "../../api/userSummary";
import Loader from "../loader";

const TABLE_HEAD = [
  { id: "id", label: "연월", minWidth: 120 },
  { id: "name", label: "회원가입", minWidth: 120 },
  { id: "phoneNumber", label: "구직신청", minWidth: 120 },
  { id: "birthday", label: "진행중", minWidth: 120 },
  { id: "status", label: "대기자", minWidth: 120 },
  { id: "country", label: "취업자", minWidth: 120 },
  { id: "country1", label: "퇴사자", minWidth: 120 },
];

const initialQuery = {
  number: 1,
  size: 12,
  order: "DESC",
  sort: "",
};

export default function MonthStatistics({
  modal,
  setModal,
  style = {},
  modalKey = "isOpen",
}) {
  const { user } = useSelector((state) => state.user);
  const [query, setQuery] = useState(initialQuery);
  const [isLoading, setIsLoading] = useState(false);
  const [userSummaryDetail, setUserSummaryDetail] = useState();

  const handleChangePage = (event, value) => {
    setQuery({ ...query, number: value });
  };

  const close = () => {
    setModal((prevModal) => ({
      ...prevModal,
      [modalKey]: false,
      status: "",
    }));
  };

  useEffect(() => {
    summaryDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const summaryDetail = async () => {
    try {
      setIsLoading(true);
      const result = await getUserSummaryDetail(query, user.token);
      setUserSummaryDetail(result);
      setIsLoading(false);
    } catch (error) {
      toast.error(error.response?.data.message);
    }
  };

  return (
    <Modal open={modal.isOpen}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          padding: "20px 40px 40px",
          width: "auto",
          bgcolor: "#fff",
          boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.10)",
          borderRadius: "10px",
          border: "none",
          ...style,
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography
            sx={{
              fontSize: "18px",
              color: "#3A3A3A",
              fontWeight: 700,
            }}
          >
            {"월별 회원 통계"}
          </Typography>
          <Button sx={{ minWidth: "unset" }} onClick={close}>
            <img src="/icons/close.svg" alt="close" />
          </Button>
        </Box>

        <Divider sx={{ my: 3.5 }} />

        <TableContainer>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: 600,
                height: 300,
              }}
            >
              <Loader style={{ backgroundColor: "red" }} />
            </Box>
          ) : (
            <Table>
              <TableHead
                sx={{
                  "& .MuiTableRow-root th:First-of-type": {
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    padding: "10px 40px",
                  },
                  "& .MuiTableRow-root th:last-child": {
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                    padding: "10px 40px",
                  },
                }}
              >
                <TableRow>
                  {TABLE_HEAD?.map((headCell, inx) => (
                    <TableCell
                      key={inx}
                      align={headCell.align || "center"}
                      sx={{
                        width: headCell.width,
                        minWidth: headCell.minWidth,
                        background: "rgba(118,	83,	223, 0.3)",
                        border: "none",
                        padding: "10px 40px",
                      }}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {userSummaryDetail &&
                  userSummaryDetail?.content?.map((bodyCell, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:hover": {
                          color: "#5a5a5a !important",
                          backgroundColor: "rgba(142, 102, 255, 0.22)",
                        },
                        textDecoration: "none",
                        height: "40px",
                      }}
                    >
                      <TableCell
                        component={Link}
                        scope="row"
                        padding="none"
                        // to={`/user-details/${user.applicantId}`}
                        sx={{ p: 2, fontSize: "13px" }}
                        align="center"
                      >
                        {bodyCell.monthName}
                      </TableCell>
                      <TableCell
                        component={Link}
                        scope="row"
                        padding="none"
                        sx={{ p: 2, fontSize: "13px" }}
                        align="center"
                      >
                        {bodyCell.joinedMembers}
                      </TableCell>
                      <TableCell
                        component={Link}
                        scope="row"
                        padding="none"
                        sx={{ p: 2, fontSize: "13px" }}
                        align="center"
                      >
                        {bodyCell.applyForJobs}
                      </TableCell>
                      <TableCell
                        component={Link}
                        scope="row"
                        padding="none"
                        sx={{ p: 2, fontSize: "13px" }}
                        align="center"
                      >
                        {bodyCell.recruitmentByMonths}
                      </TableCell>
                      <TableCell
                        component={Link}
                        scope="row"
                        padding="none"
                        sx={{ p: 2, fontSize: "13px" }}
                        align="center"
                      >
                        {bodyCell.waitingEmployeeMonth}
                      </TableCell>
                      <TableCell
                        component={Link}
                        scope="row"
                        padding="none"
                        sx={{ p: 2, fontSize: "13px" }}
                        align="center"
                      >
                        {bodyCell.totalWorkerByMonth}
                      </TableCell>
                      <TableCell
                        component={Link}
                        scope="row"
                        padding="none"
                        sx={{ p: 2, fontSize: "13px" }}
                        align="center"
                      >
                        {bodyCell.retiredByMonth}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
          <Pagination
            count={
              Math.ceil(userSummaryDetail?.totalElements / query.size) || 0
            }
            onChange={handleChangePage}
            page={query.number}
            color="primary"
            variant="outlined"
            shape="rounded"
            sx={{
              m: "0 auto",
              "& .css-1k2i9p6-MuiButtonBase-root-MuiPaginationItem-root": {
                height: "20px",
                minWidth: "20px",
                backgroundColor: "#CCCCCC",
                color: "#fff",
              },
            }}
          />
        </Stack>
      </Box>
    </Modal>
  );
}
