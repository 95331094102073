import React from "react";
import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  TableHead,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import { FormInputText } from "../../components/form-components/FormInputText";
import { useEffect } from "react";
import { getAllCustomers } from "../../api/customers";
import { useSelector } from "react-redux";
import { pageSize } from "../../constants/constants";
import { formatKoreanPhoneNumber } from "../../utils/methods";

const initialQuery = {
  number: 1,
  size: 20,
  companyName: "",
};

function Business() {
  const { token } = useSelector((state) => state.user.user);
  const { control, handleSubmit, reset } = useForm();
  const [users, setUsers] = useState();
  const [query, setQuery] = useState(initialQuery);

  const handleChangePage = (_, value) => {
    setQuery({ ...query, number: value });
  };

  const submit = async (values) => {
    setQuery({ ...query, ...values });
  };

  const setInitial = () => {
    reset(initialQuery);
    setQuery(initialQuery);
  };
  const getCustomers = async () => {
    try {
      const { data } = await getAllCustomers(query, token);
      setUsers(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCustomers();
  }, [query]);

  return (
    <Paper
      sx={{
        width: "100%",
        boxShadow: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          mb: "16px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography style={{ minWidth: "95px", fontWeight: 500 }}>
          업체명
        </Typography>
        <FormInputText
          sx={{ width: "280px", marginRight: "6px" }}
          control={control}
          name="companyName"
          placeholder="내용을 입력해 주세요"
        />
        <Button
          size="small"
          onClick={handleSubmit(submit)}
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "7px 18px",
            color: "#7653DF",
            background: "#EEEAFB",
          }}
        >
          검색
        </Button>
        <Button
          size="small"
          onClick={setInitial}
          sx={{
            textDecoration: "underline",
            color: "#6B7280",
            ml: "17px",
            "&:hover": {
              color: "#8E66FF",
            },
          }}
        >
          검색조건 초기화
        </Button>
        <Button
          component={Link}
          to="/account-management/business-management/create"
          size="small"
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "8px 26px",
            color: "#FFF",
            backgroundColor: "#7653DF",
            ml: "auto",
          }}
        >
          업체 등록 <AddIcon />
        </Button>
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          mb: "16px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography style={{ minWidth: "95px", fontWeight: 500 }}>
          상태
        </Typography>
        <FormInputDropdown
          staticValue={query.situation}
          control={control}
          onChange={(e) => setQuery({ ...query, situation: e.target.value })}
          sx={{ width: "350px", marginRight: "10px" }}
          options={situation}
        />
      </Box> */}
      <hr style={{ margin: "30px auto" }} />
      <Box
        sx={{
          display: "flex",
          mb: "15px",
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormInputDropdown
            control={control}
            options={pageSize}
            staticValue={query.size}
            onChange={(e) => setQuery({ ...query, size: e.target.value })}
            sx={{ width: "90px" }}
          />
        </Box>
      </Box>
      <TableContainer
        className="table"
        sx={{
          borderTop: "1px solid #98A6AD",
        }}
      >
        <Table>
          <TableHead sx={{ background: "#fcfcfc" }}>
            <TableRow>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                }}
                align="center"
              >
                번호
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                }}
                align="center"
              >
                코드
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                }}
                align="center"
              >
                업체명
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                }}
                align="center"
              >
                본사주소
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                }}
                align="center"
              >
                대표이사
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                }}
                align="center"
              >
                사용사업관리 책임자
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                }}
                align="center"
              >
                연락처
              </TableCell>
              {/* <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                }}
                align="center"
              >
                상태
              </TableCell> */}
              <TableCell
                sx={{
                  padding: "10px",

                  whiteSpace: "nowrap",
                }}
                align="center"
              >
                사업장 수
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                }}
                align="center"
              >
                취업자 수
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users && !users.empty
              ? users.content.map((user, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      sx={{
                        "&:hover": {
                          color: "#5a5a5a !important",
                          backgroundColor: "rgba(142, 102, 255, 0.22)",
                        },
                        ".css-1d46hnn-MuiTableCell-root": {
                          padding: "1px",
                        },

                        height: "40px",
                      }}
                    >
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/account-management/business-management/${user.id}`}
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.id}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/account-management/business-management/${user.id}`}
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.code}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/account-management/business-management/${user.id}`}
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.name}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/account-management/business-management/${user.id}`}
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.address}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/account-management/business-management/${user.id}`}
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.ceo}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/account-management/business-management/${user.id}`}
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.manager}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/account-management/business-management/${user.id}`}
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.phone && formatKoreanPhoneNumber(user.phone)}
                      </TableCell>
                      {/* <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/account-management/business-management/${user.id}`}
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.situation}
                      </TableCell> */}
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/account-management/business-management/${user.id}`}
                        sx={{
                          padding: "10px",
                          textDecoration: "underline",
                          color: "#2D9CDB",
                        }}
                        align="center"
                      >
                        {user.numbOfBusiness}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/account-management/business-management/${user.id}`}
                        sx={{
                          padding: "10px",
                          textDecoration: "underline",
                          color: "#2D9CDB",
                        }}
                        align="center"
                      >
                        {user.numbOfEmployees ?? 0}명
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
        <Pagination
          count={Math.ceil(users?.totalElements / query.size) || 0}
          onChange={handleChangePage}
          page={query.number}
          color="primary"
          variant="outlined"
          shape="rounded"
          sx={{
            m: "0 auto",
            "& .css-1k2i9p6-MuiButtonBase-root-MuiPaginationItem-root": {
              height: "20px",
              minWidth: "20px",
              backgroundColor: "#CCCCCC",
              color: "#fff",
            },
          }}
        />
      </Stack>
    </Paper>
  );
}

export default Business;
