import mainCaller from "./mainCaller";

export async function getAllBanner(data, token) {
  return await mainCaller(`banner`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getBannerById(id, token) {
  return await mainCaller(`banner/getById/${id}`, "GET", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function createBanner(data, token) {
  return await mainCaller(`banner/create`, "POST", data, {
    "Content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  });
}

export async function updateBanner(data, token) {
  return await mainCaller(`banner/update`, "POST", data, {
    "Content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  });
}

export async function deleteBannerById(id, token) {
  return await mainCaller(`banner/delete/${id}`, "DELETE", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function checkBannerDupplicate(data, token) {
  return await mainCaller(`banner/isActive`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}