import mainCaller from "./mainCaller";

export async function getCodeById(url, id, token) {
    return await mainCaller(`${url}/${id}`, "GET", null, {
        Authorization: `Bearer ${token}`,
    })
}


export async function updateCodeById(url, data, token) {
    return await mainCaller(`${url}`, "POST", data, {
        Authorization: `Bearer ${token}`,
    })
}

export async function deleteCodeById(url, id, token) {
    return await mainCaller(`${url}/${id}`, "DELETE", null, {
        Authorization: `Bearer ${token}`,
    })
}

export async function createCode(url, data, token) {
    return await mainCaller(`${url}`, "POST", data, {
        Authorization: `Bearer ${token}`,
    })
}

export async function getCodeList(url, data, token) {
    return await mainCaller(`${url}`, "POST", data, {
        Authorization: `Bearer ${token}`,
    })
}

export async function getWorkDepartments(data, token) {
    return await mainCaller(`customers`, "POST", data, {
        Authorization: `Bearer ${token}`,
    })
}

export async function searchCode(url, keyword, token) {
    return await mainCaller(`${url}/${keyword}`, "GET", null, {
        Authorization: `Bearer ${token}`,
    })
}

export async function occupationSearch(keyword, token) {
    return await mainCaller(`occupation/findByKeyword/${keyword}`, "GET", null, {
        Authorization: `Bearer ${token}`,
    })
}
