import mainCaller from "./mainCaller";

export async function getAllTerms(token) {
  return await mainCaller(`terms`, "GET", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getTermsByTitle(data, token) {
  return await mainCaller(`terms/getByTitle`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function updateTerms(data, token) {
  return await mainCaller(`terms/update`, "POST", data, {
    "Content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  });
}
