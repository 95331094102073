import mainCaller from "./mainCaller";

export async function getAllNotification(data, token) {
  return await mainCaller(`notification`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getNotificationById(id, token) {
  return await mainCaller(`notification/getById/${id}`, "GET", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function createNotification(data, token) {
  return await mainCaller(`notification/create`, "POST", data, {
    "Content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  });
}

export async function updateNotification(data, token) {
  return await mainCaller(`notification/update`, "POST", data, {
    "Content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  });
}

export async function deleteNotificationById(id, token) {
  return await mainCaller(`notification/delete/${id}`, "DELETE", null, {
    Authorization: `Bearer ${token}`,
  });
}
