import mainCaller from "./mainCaller";

export async function createPayroll(data, token) {
  return await mainCaller(`salary/create`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getAllSalary(data, token) {
  return await mainCaller(`salary/getAllSumByMonths`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getSalaryByDate(data, token) {
  return await mainCaller(`salary/getAllCompanyByMonths`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getSalaryByCompany(data, token) {
  return await mainCaller(`salary/getByCompany`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getEmployeeSalary(data, token) {
  return await mainCaller(`salary/getEmployeeSalaryDetail`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getAllEmployeeSalary(data, token) {
  return await mainCaller(`salary/getAllEmployee`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function changeEmployeeSalaryStatus(data, token) {
  return await mainCaller(`salary/changeStatus`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}
