// @mui
import { styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "start",
  padding: "16px",
  display: "grid",

  justifyContent: "flex-start",
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: "34px",
  height: "34px",
  justifyContent: "center",
  marginBottom: 13,
}));

// ----------------------------------------------------------------------

export default function AnalyticsWidgetSummary({
  title,
  total,
  icon,
  bgColor,
  iconColor,
}) {
  return (
    <RootStyle sx={{ bgcolor: bgColor, borderRadius: "10px" }}>
      <IconWrapperStyle sx={{ bgcolor: iconColor }}>
        <img src={icon} alt="person" width={21} height={21} />
      </IconWrapperStyle>
      <Typography
        sx={{
          color: "#151D48",
          fontWeight: 600,
          lineHeight: "26px",
          fontSize: "20px",
        }}
      >
        {total} 명
      </Typography>
      <Typography
        sx={{
          color: "#425166",
          mt: 0.8,
          fontSize: "13px",
          fontWeight: 500,
          lineHeight: "20px",
        }}
      >
        {title}
      </Typography>
    </RootStyle>
  );
}
