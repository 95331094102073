import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { getSubCustomers } from "../../api/customers";
import BackButton from "../BackButton";

const initial = {
  isOpen: false,
};

export default function SubCompanies({ setModal, modal, token }) {
  const [users, setUsers] = useState();
  const ref = useRef(null);

  const getList = async () => {
    try {
      const { data } = await getSubCustomers(
        { number: 1, size: 100, customerId: modal.customerId },
        token
      );
      setUsers(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <Box
      ref={ref}
      sx={{
        width: "100%",
        maxHeight: "100%",
        minHeight: "100%",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb="30px"
      >
        <BackButton
          text="사업장 선택"
          onClick={() => setModal({ ...modal, customerId: "" })}
        />
        <Button sx={{ minWidth: "unset" }} onClick={() => setModal(initial)}>
          <img src="/icons/close.svg" alt="close" />
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          maxHeight: ref?.current?.clientHeight - 150,
        }}
      >
        <TableContainer
          className="table"
          sx={{
            border: "1px solid #E7E7E7",
            borderTop: "1px solid #98A6AD",
          }}
        >
          <Table>
            <TableHead sx={{ background: "#fcfcfc" }}>
              <TableRow>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  번호
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  현장 코드
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  현장명
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  주소
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  클라이언트담당자
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  관리 담당자
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  연락처
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  상태
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  취업자 수
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.content.map((user, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      sx={{
                        "&:hover": {
                          color: "#5a5a5a !important",
                          backgroundColor: "rgba(142, 102, 255, 0.22)",
                        },
                        height: "40px",
                      }}
                    >
                      <TableCell
                        id={labelId}
                        onClick={() =>
                          setModal({
                            ...modal,
                            isOpen: false,
                            subCustomer: user,
                          })
                        }
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.id}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        onClick={() =>
                          setModal({
                            ...modal,
                            isOpen: false,
                            subCustomer: user,
                          })
                        }
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.code}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        onClick={() =>
                          setModal({
                            ...modal,
                            isOpen: false,
                            subCustomer: user,
                          })
                        }
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.name}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        onClick={() =>
                          setModal({
                            ...modal,
                            isOpen: false,
                            subCustomer: user,
                          })
                        }
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.address}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        onClick={() =>
                          setModal({
                            ...modal,
                            isOpen: false,
                            subCustomer: user,
                          })
                        }
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.client}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        onClick={() =>
                          setModal({
                            ...modal,
                            isOpen: false,
                            subCustomer: user,
                          })
                        }
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.manager}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        onClick={() =>
                          setModal({
                            ...modal,
                            isOpen: false,
                            subCustomer: user,
                          })
                        }
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.phone}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        onClick={() =>
                          setModal({
                            ...modal,
                            isOpen: false,
                            subCustomer: user,
                          })
                        }
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.situation}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        onClick={() =>
                          setModal({
                            ...modal,
                            isOpen: false,
                            subCustomer: user,
                          })
                        }
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.numbOfEmployees ?? 0}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
