import mainCaller from "./mainCaller";

export async function createEmployee(data, token) {
  return await mainCaller(`employee/create`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getAllEmployee(token) {
  return await mainCaller(
    `employee/getAll`,
    "GET",
    {},
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export async function getMenus(token) {
  return await mainCaller(
    `employee/menus`,
    "GET",
    {},
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export async function getEmployeeApi(id, token) {
  return await mainCaller(
    `employee/${id}`,
    "GET",
    {},
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export async function updateEmployee(data, token) {
  return await mainCaller("employee/update", "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function deleteById(id, token) {
  return await mainCaller(
    `employee/deleteById/${id}`,
    "DELETE",
    {},
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export async function sendMessageToAllMember(data, token) {
  return await mainCaller("sms/member/smsSent", "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}
export async function getAllSms(data, token) {
  return await mainCaller("sms/histories", "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function sendSms(data, token) {
  return await mainCaller("sms/checked/sms", "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function sendMail(data, token) {
  return await mainCaller("email/send", "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function sendEmailWithAttachment(data, token) {
  return await mainCaller("email/sendEmailWithAttachment", "POST", data, {
    "Content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  });
}
