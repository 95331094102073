import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import BackButton from "../../components/BackButton";
import LinkButton from "../../components/LinkButton";
import { FormInputText } from "../../components/form-components/FormInputText";
import CustomCheckbox from "../../components/CustomCheckbox";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import { popUpSettings } from "../../constants/constants";
import { FormInputRadio } from "../../components/form-components/FormInputRadio";
import {
  createPopup,
  deletePopupById,
  getPopupById,
  updatePopup,
} from "../../api/popup";
import AcceptModal from "../../components/modals/AcceptModal";
import { FormInputDate } from "../../components/form-components/FormInputDate";
import { awsImagePath } from "../../utils/methods";
import Loader from "../../components/loader";

const options = [
  {
    label: "정규직",
    value: "1",
  },
  {
    label: "파견직",
    value: "2",
  },
  {
    label: "도급직",
    value: "3",
  },
  {
    label: "위탁",
    value: "4",
  },
  {
    label: "아르바이트",
    value: "5",
  },
  {
    label: "프리랜서",
    value: "6",
  },
  {
    label: "계약직",
    value: "7",
  },
  {
    label: "기타",
    value: "8",
  },
];

export default function PopUpDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [popup, setPopup] = useState();
  const { control, setValue, reset, handleSubmit, watch } = useForm({
    defaultValues: popup || {},
  });
  const { token } = useSelector((state) => state.user.user);
  const [modal, setModal] = useState({
    isOpen: false,
  });
  const [image, setImage] = useState();
  const [isUploading, setIsUploading] = useState(false);

  const formatDate = (date) => {
    const newDate = new Date(date);
    const year = newDate.getUTCFullYear();
    const month = ("0" + (newDate.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + newDate.getUTCDate()).slice(-2);
    const formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  };

  const submit = async (values) => {
    delete values.name;

    const createValue = {
      ...values,
      periodFrom: formatDate(values.periodFrom),
      periodTo: formatDate(values.periodTo),
      image: image,
    };

    const updateValue = {
      ...values,
      periodFrom: formatDate(values.periodFrom),
      periodTo: formatDate(values.periodTo),
      image: image,
      id: id,
    };

    if (!values.locationUrl) {
      toast.warning("모든 영역을 작성해 주세요!");
    } else if (!values.title) {
      toast.warning("모든 영역을 작성해 주세요!");
    } else if (!values.status) {
      toast.warning("모든 영역을 작성해 주세요!");
    } else if (!values.landingUrl) {
      toast.warning("모든 영역을 작성해 주세요!");
    } else if (!values.periodFrom) {
      toast.warning("모든 영역을 작성해 주세요!");
    } else if (!values.periodTo) {
      toast.warning("모든 영역을 작성해 주세요!");
    } else if (!values.imageWidth) {
      toast.warning("모든 영역을 작성해 주세요!");
    } else if (!id && !image) {
      toast.warning("모든 영역을 작성해 주세요!");
    } else {
      setIsUploading(true);
      try {
        if (popup && id) {
          await updatePopup(updateValue, token);
        } else {
          await createPopup(createValue, token);
        }
        toast.success("완료 되었습니다.");
        navigate("/post-notice/popup");
      } catch (error) {
        console.log(error);
        alert(error.response?.data.message);
      }

      setIsUploading(false);
    }
  };

  const checked = (isChecked, type) => {
    setValue("isToNewWindow", isChecked);
    return;
  };

  const getPopup = async () => {
    try {
      const { data } = await getPopupById(id, token);
      setPopup(data);
      reset(data);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteById = async () => {
    try {
      await deletePopupById(modal.deleteID, token);
      setModal({ isOpen: false });
      navigate("/post-notice/popup");
      toast.success("삭제가 완료 되었습니다!");
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteNotify = async () => {
    setModal({ ...modal, isOpen: true, deleteID: id });
  };

  useEffect(() => {
    if (id) {
      getPopup();
    }
  }, [id]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <BackButton text="팝업 상세" />
        <LinkButton label="목록보기" link="/post-notice/popup" />
      </Box>
      <hr style={{ margin: "16px 0 30px 0" }} />
      <Grid container>
        <Grid item xs={6}>
          <Grid container rowSpacing={1} alignItems="center">
            <Grid item xs={2} fontWeight="500">
              노출 기간
            </Grid>
            <Grid item xs={10}>
              <Box display="flex" alignItems="center">
                <FormInputDate
                  name="periodFrom"
                  control={control}
                  placeholder="YYYY/MM/DD"
                  sx={{
                    ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
                      fontSize: "16px",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      color="info"
                      size="small"
                      variant="outlined"
                      {...params}
                      sx={{
                        width: "127px",
                        background: "#fff",
                        ".MuiInputBase-root": {
                          paddingRight: "0px",
                        },
                        ".MuiIconButton-root": {
                          marginRight: "0px",
                        },
                        input: {
                          paddingRight: "0px!important",
                        },
                        "& .MuiSvgIcon-root": {
                          fill: "#987EE7 !important",
                        },
                      }}
                    />
                  )}
                />
                <Typography style={{ margin: "0 25px 0 10px" }}>
                  부터
                </Typography>
                <FormInputDate
                  name="periodTo"
                  control={control}
                  placeholder="YYYY/MM/DD"
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      {...params}
                      sx={{
                        width: "127px",
                        background: "#fff",
                        paddingRight: "0px!important",
                        ".MuiInputBase-root": {
                          paddingRight: "0px",
                        },
                        ".MuiIconButton-root": {
                          marginRight: "0px",
                        },
                        input: {
                          paddingRight: "0px!important",
                        },
                        "& .MuiSvgIcon-root": {
                          fill: "#987EE7 !important",
                        },
                      }}
                    />
                  )}
                />
                <Typography style={{ margin: "0 12px 0 10px" }}>
                  까지
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} fontWeight="500">
              노출 위치
            </Grid>
            <Grid item xs={10}>
              <FormInputText
                control={control}
                name="locationUrl"
                placeholder="https://"
              />
            </Grid>
            <Grid item xs={2} fontWeight="500">
              제목
            </Grid>
            <Grid item xs={10}>
              <FormInputText
                control={control}
                name="title"
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
            <Grid item xs={2} fontWeight="500">
              닫기 설정
            </Grid>
            <Grid item xs={10}>
              <FormInputRadio
                name="status"
                sx={{ marginRight: "40px", height: "30px" }}
                control={control}
                options={popUpSettings}
                row
              />
            </Grid>
            <Grid item xs={2} fontWeight="500">
              이미지 업로드
            </Grid>
            <Grid item xs={10}>
              <Box display="flex" alignItems="center">
                {!image && popup ? (
                  <img
                    src={`${awsImagePath}/${popup.imagePath}`}
                    style={{
                      width: "80%",
                      objectFit: "cover",
                    }}
                    alt="uploader"
                  />
                ) : (
                  <FormInputText
                    control={control}
                    staticValue={image?.name ?? ""}
                    name="image"
                  />
                )}
                <Button
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "12px",
                    marginLeft: "10px",
                    lineHeight: "15px",
                    padding: "8px 26px",
                    color: "#7653DF",
                    position: "relative",
                    backgroundColor: "#EEEAFB",
                  }}
                >
                  업로드
                  <input
                    type="file"
                    onChange={(e) => setImage(e.target.files[0])}
                    accept=".jpg, .png"
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      opacity: "0",
                    }}
                  />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={2} fontWeight="500">
              이미지 링크
            </Grid>
            <Grid item xs={10}>
              <Box display="flex" alignItems="center">
                <FormInputText
                  control={control}
                  name="landingUrl"
                  placeholder="https://"
                />
              </Box>
            </Grid>
            <Grid item xs={2} fontWeight="500">
              이미지 너비
            </Grid>
            <Grid item xs={2}>
              <Box display="flex" alignItems="center">
                <FormInputText
                  control={control}
                  type="number"
                  sx={{ maxWidth: "113px" }}
                  name="imageWidth"
                />
              </Box>
            </Grid>
            <Grid item xs={8} fontWeight="500" pl="10px">
              px
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container rowSpacing={1} alignItems="center">
            <Grid item xs={12} sx={{ opacity: "0", pointerEvents: "none" }}>
              <FormInputDropdown control={control} options={options} />
            </Grid>
            <Grid item xs={12} sx={{ opacity: "0", pointerEvents: "none" }}>
              <FormInputDropdown control={control} options={options} />
            </Grid>
            <Grid item xs={10} fontWeight="500" fontSize="12px" pl="8px">
              사용자에게 노출 되지 않습니다.
            </Grid>
            <Grid item xs={2} sx={{ opacity: "0", pointerEvents: "none" }}>
              <FormInputDropdown control={control} options={options} />
            </Grid>
            <Grid
              item
              xs={10}
              fontWeight="500"
              fontSize="12px"
              pl="8px"
              lineHeight="17.3px"
            >
              [이미지 업로드 안내]
              <br /> 메인 01: 700*500px 사이즈를 권장합니다.
              <br /> 메인 02, 03: 340*500px 사이즈를 권장합니다.
              <br /> jpg/png 파일만 등록가능합니다.
            </Grid>
            <Grid item xs={2} sx={{ opacity: "0", pointerEvents: "none" }}>
              <FormInputDropdown control={control} options={options} />
            </Grid>
            <Grid
              item
              xs={10}
              pl="8px"
              fontWeight="500"
              textAlign="center"
              display="flex"
              alignItems="center"
            >
              <CustomCheckbox
                name="isToNewWindow"
                checked={watch("isToNewWindow")}
                onChange={(e) => checked(e.target.checked, "isToNewWindow")}
              />
              새창에서 열기
            </Grid>
            <Grid item xs={2} />
          </Grid>
        </Grid>
      </Grid>
      <hr style={{ margin: "300px 0 20px" }} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        mt="25px"
      >
        {popup && (
          <Button
            onClick={() => handleDeleteNotify()}
            size="small"
            sx={{
              fontSize: "13px",
              width: "auto",
              height: "30px",
              marginRight: "auto",
              border: "1px solid #7653DF",
              padding: "8px 26px",
            }}
          >
            삭제
          </Button>
        )}
        <Button
          onClick={() => navigate("/post-notice/popup")}
          size="small"
          sx={{
            fontSize: "13px",
            mr: "8px",
            height: "30px",
            padding: "7px 26px",
            color: "#fff",
            background: "#98A6AD",
          }}
        >
          취소
        </Button>
        {isUploading ? (
          <Button>
            <Loader style={{ backgroundColor: "red" }} />
          </Button>
        ) : (
          <Button
            onClick={handleSubmit(submit)}
            size="small"
            sx={{
              fontSize: "13px",
              width: "auto",
              height: "30px",
              padding: "7px 46px",
              color: "#fff",
              background: "#7653DF",
            }}
          >
            저장
          </Button>
        )}
      </Box>
      {modal.isOpen && (
        <AcceptModal
          setModal={setModal}
          modal={{ ...modal, isOpen: modal.isOpen }}
          handleClick={deleteById}
          modalKey="isOpen"
          title="삭제 하시겠습니까?"
        />
      )}
    </Box>
  );
}
