import mainCaller from "./mainCaller";

export async function getAllJobs(data, token) {
  return await mainCaller(`recruitment`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function updateJobById(data, token) {
  return await mainCaller(`recruitment/update`, "POST", data, {
    Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
  });
}

export async function getJobById(id, token, applicantId) {
  return await mainCaller(
    `recruitment/getById/${id}/${applicantId}`,
    "GET",
    null,
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export async function getApplicantsByJobId(data, token) {
  return await mainCaller(`jobApplications/getAllByRecruitment`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function updateStatusByJobAppId(data, token) {
  return await mainCaller(`jobApplications/changeStatus`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getJobCategories(token) {
  return await mainCaller(`jobCategories`, "GET", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function deleteJobById(id, token) {
  return await mainCaller(`recruitment/deleteById/${id}`, "DELETE", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function createJob(data, token) {
  return await mainCaller(`recruitment/create`, "POST", data, {
    "Content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  });
}

export async function createCategories(data, token) {
  return await mainCaller(`jobCategories`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function uploadImageEditor(data) {
  return await mainCaller(`recruitment/upload`, "POST", data, {
    "Content-type": "multipart/form-data",
  });
}

export async function copyRecruitment(data, token) {
  return await mainCaller(`recruitment/copy`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}
