import React, { useEffect } from "react";
import { Box, Button, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import AnimationPage from "./AnimationPage";
import { useForm } from "react-hook-form";
import { FormInputText } from "../components/form-components/FormInputText";
import { setUserData } from "../actions/user";
import { auth } from "../api/user";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const Login = () => {
  const data = useSelector((state) => state.user.user);
  const methods = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleSubmit, control } = methods;
  const [loader, setLoader] = useState(false);

  const submit = async (values) => {
    let checkField = true;

    for (const property in values) {
      if (values[property] === undefined || values[property] === "")
        checkField = false;
    }

    if (checkField) {
      setLoader(true);
      try {
        const user = await auth(values);
        dispatch(setUserData(user.data));
        navigate("/home");
      } catch (error) {
        console.log("error: ", error);
        toast.warning(error.response.data.message);
        setLoader(false);
      }
    } else toast.warning("모든 영역을 작성해 주세요!");
  };

  useEffect(() => {
    if (data?.id) {
      navigate("/home");
    }
  }, []);

  return (
    <AnimationPage>
      <Box sx={{ flexGrow: 1, height: "100vh" }}>
        <Grid container sx={{ height: "100%" }} alignItems={"center"}>
          <Grid item xs={6}>
            <Box width="557px" margin="0 auto">
              <img
                style={{ width: "100%" }}
                src="/images/image & shapes.png"
                alt="login"
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                width: "490px",
                padding: "60px",
                height: "552px",
                margin: "0 auto",
                borderRadius: "8px",
                boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.07)",
              }}
            >
              <img
                src="/images/vlogo.svg"
                alt="logo"
                style={{ display: "block", margin: "0 auto" }}
              />
              <Box
                textAlign="center"
                sx={{
                  margin: "42px auto 90px auto",
                }}
              >
                <FormInputText
                  control={control}
                  name="username"
                  placeholder="User name"
                  color="primary"
                  sx={{
                    input: {
                      padding: "15px 25px !important",
                      fontSize: "15px !important",
                      lineHeight: "20px !important",
                    },
                  }}
                />
                <FormInputText
                  control={control}
                  name="password"
                  type="password"
                  placeholder="Password"
                  sx={{
                    margin: "30px 0",
                    input: {
                      padding: "15px 25px !important",
                      fontSize: "15px !important",
                      lineHeight: "20px !important",
                    },
                  }}
                />
                <FormInputText
                  control={control}
                  name="code"
                  placeholder="Code"
                  sx={{
                    input: {
                      padding: "15px 25px !important",
                      fontSize: "15px !important",
                      lineHeight: "20px !important",
                    },
                  }}
                />
                <Button
                  sx={{
                    width: "100%",
                    padding: "15px",
                    backgroundColor: "#7653DF",
                    borderRadius: "5px",
                    marginTop: "30px",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: " 20px",
                  }}
                  disabled={loader}
                  variant="contained"
                  onClick={handleSubmit(submit)}
                >
                  로그인
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </AnimationPage>
  );
};

export default Login;
