import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  TableHead,
  TextField,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useForm } from "react-hook-form";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { FormInputDropdown } from "../../../components/form-components/FormInputDropdown";
import { FormInputText } from "../../../components/form-components/FormInputText";
import { FormInputDate } from "../../../components/form-components/FormInputDate";
import CustomCheckbox from "../../../components/CustomCheckbox";
import {
  dayFilter,
  searchType2,
  pageSize,
  order,
} from "../../../constants/constants";
import {
  getPreviousDay,
  getTomorrow,
  exportDataToExcel,
  getAge,
} from "../../../utils/methods";
import BackButton from "../../../components/BackButton";
import {
  copyOrders,
  deleteOrderById,
  getCurrentDayOrders,
} from "../../../api/order";
import AcceptModal from "../../../components/modals/AcceptModal";
import { getCodeList } from "../../../api/personalCodeManagement";

const searchOptions = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "업체명",
    value: "customer.name",
  },
  {
    label: "부서",
    value: "department",
  },
  {
    label: "프로그램",
    value: "program",
  },
  {
    label: "직종",
    value: "occupation.name",
  },
  {
    label: "담당자",
    value: "manager",
  },
];

const sortOption = [
  {
    label: "오더 접수일 순",
    value: "receiptDate",
  },
  {
    label: "서류 제출일 순",
    value: "submissionDate",
  },
  {
    label: "매칭 예정일 순",
    value: "matchingDate",
  },
];

const newDate = new Date();
const nextWeek = new Date(newDate);
const nextHalfMonth = new Date(newDate);
const nextMotth = new Date(newDate);
nextWeek.setDate(newDate.getDate() + 7);
nextHalfMonth.setDate(newDate.getDate() + 15);
nextMotth.setDate(newDate.getDate() + 30);

const today = newDate.toISOString().split("T")[0];
const week = nextWeek.toISOString().split("T")[0];
const halfMonth = nextHalfMonth.toISOString().split("T")[0];
const month = nextMotth.toISOString().split("T")[0];

const initialQuery = {
  number: 1,
  size: 20,
  order: "DESC",
  sort: "receiptDate",
  searchType: "",
  keyword: "",
  displayDate: "",
  receiptDateFrom: "",
  receiptDateTo: "",
  submissionDateFrom: "",
  submissionDateTo: "",
  matchingDateFrom: "",
  matchingDateTo: "",
  teamInCharge: "",
};

const codeQuery = {
  number: 1,
  size: 20,
  order: "DESC",
  sort: "name",
  keyword: "",
};

function OrderDetails() {
  const { slug } = useParams();
  const contentRef = useRef(null);
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.user.user);
  const [currentDate, setCurrentDate] = useState(new Date(slug));
  const [filter, setFilter] = useState({
    receiptDate: dayFilter,
    submissionDate: dayFilter,
    matchingDate: dayFilter,
  });
  const [modal, setModal] = useState({
    isOpen: false,
  });
  const [query, setQuery] = useState(initialQuery);
  const [orders, setOrders] = useState();
  const [occupationOptions, setOccupationOptions] = useState([]);
  const { handleSubmit, control, reset } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePage = (_, value) => {
    setQuery({ ...query, number: value });
  };

  const handleButtonClick = (type, index) => {
    setFilter((prevFilter) => {
      const updatedFilter = {
        ...prevFilter,
        [type]: prevFilter[type].map((day, i) => ({
          ...day,
          active: i === index ? !day.active : false,
        })),
      };

      return updatedFilter;
    });
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    var tempUser = {};
    if (name === "allSelect") {
      tempUser = orders.content.map((user) => {
        return { ...user, isChecked: checked };
      });
    } else {
      tempUser = orders.content.map((user) => {
        return JSON.stringify(user.id) === name
          ? { ...user, isChecked: checked }
          : user;
      });
    }
    setOrders({ ...orders, content: tempUser });
  };

  async function orderList() {
    const newQuery = { ...query, displayDate: slug };
    try {
      setIsLoading(true);
      const { data } = await getCurrentDayOrders(newQuery, token);
      data.content?.map((data) => {
        data.isChecked = false;
        return data.isChecked;
      });
      setOrders(data);
      setIsLoading(false);
    } catch (error) {
      toast.error(error.response?.data.message);
    }
  }

  const getOccupationList = async () => {
    try {
      const { data } = await getCodeList("occupation", codeQuery, token);
      const list = data.content;
      if (list) {
        const newChargersOption = list.map((item) => ({
          label: item.name,
          value: item.name,
        }));
        setOccupationOptions([
          { label: "전체", value: "" },
          ...newChargersOption,
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submit = async (values) => {
    if (
      query.submissionDateFrom ||
      query.matchingDateFrom ||
      query.receiptDateFrom
    ) {
      setQuery({
        ...query,
        ...values,
        searchType: values.occupationName
          ? "occupation.name"
          : values.searchType,
        keyword: values.occupationName
          ? values.occupationName
          : values.searchType,
        receiptDateFrom: query.receiptDateFrom,
        receiptDateTo: query.receiptDateTo,
        submissionDateFrom: query.submissionDateFrom,
        submissionDateTo: query.submissionDateTo,
        matchingDateFrom: query.matchingDateFrom,
        matchingDateTo: query.matchingDateTo,
      });
    } else {
      setQuery({
        ...query,
        ...values,
        searchType: values.occupationName
          ? "occupation.name"
          : values.searchType,
        keyword: values.occupationName
          ? values.occupationName
          : values.searchType,
      });
    }
  };

  const setInitial = () => {
    reset(initialQuery);
    setQuery(initialQuery);
  };

  const download = () => {
    const checkedData = [];
    orders?.content?.map((data) => {
      if (data.isChecked) {
        checkedData.push(data);
      }
      return data.isChecked;
    });
    if (checkedData.length === 0) {
      toast.warning("인원을 선택해 주세요!");
    } else {
      exportDataToExcel(checkedData, "오더");
    }
  };

  const copyPrevOrders = async () => {
    try {
      const checkedData = [];
      orders?.content?.map((data) => {
        if (data.isChecked) {
          checkedData.push(data.id);
        }
        return data.isChecked;
      });
      if (checkedData.length === 0) {
        toast.error("일괄을 선택해주세요!");
      } else {
        await copyOrders(checkedData, token);
        orderList();
        toast.success("일괄 복사 완료 되었습니다.");
      }
    } catch (err) {
      toast.warning(err.response?.data.message);
    }
  };

  const deleteById = () => {
    try {
      modal.deleteIds?.map(async (data) => {
        await deleteOrderById(data, token);
        orderList();
      });
      toast.success("일괄 삭제 완료 되었습니다.");
      setModal({ isOpen: false });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteOrders = async () => {
    const checkedOrderIds = [];
    orders?.content?.map((data) => {
      if (data.isChecked) {
        checkedOrderIds.push(data.id);
      }
      return data.isChecked;
    });
    if (checkedOrderIds.length === 0) {
      toast.warning("일괄을 선택해 주세요!");
    } else {
      setModal({ ...modal, isOpen: true, deleteIds: checkedOrderIds });
    }
  };

  useEffect(() => {
    orderList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, slug]);

  useEffect(() => {
    const calendarDate = currentDate.toISOString().split("T")[0];
    navigate(`/job-management/order-status/${calendarDate}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  useEffect(() => {
    filter.receiptDate.map((data, index) => {
      if (data.active) {
        // eslint-disable-next-line default-case
        switch (index) {
          case 0:
            setQuery({
              ...query,
              receiptDateFrom: "",
              receiptDateTo: "",
            });
            break;
          case 1:
            setQuery({
              ...query,
              receiptDateFrom: today,
              receiptDateTo: today,
            });
            break;
          case 2:
            setQuery({
              ...query,
              receiptDateFrom: today,
              receiptDateTo: week,
            });
            break;
          case 3:
            setQuery({
              ...query,
              receiptDateFrom: today,
              receiptDateTo: halfMonth,
            });
            break;
          case 4:
            setQuery({
              ...query,
              receiptDateFrom: today,
              receiptDateTo: month,
            });
            break;
        }
      }
      return data.active;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.receiptDate]);

  useEffect(() => {
    filter.submissionDate.map((data, index) => {
      if (data.active) {
        // eslint-disable-next-line default-case
        switch (index) {
          case 0:
            setQuery({
              ...query,
              submissionDateFrom: "",
              submissionDateTo: "",
            });
            break;
          case 1:
            setQuery({
              ...query,
              submissionDateFrom: today,
              submissionDateTo: today,
            });
            break;
          case 2:
            setQuery({
              ...query,
              submissionDateFrom: today,
              submissionDateTo: week,
            });
            break;
          case 3:
            setQuery({
              ...query,
              submissionDateFrom: today,
              submissionDateTo: halfMonth,
            });
            break;
          case 4:
            setQuery({
              ...query,
              submissionDateFrom: today,
              submissionDateTo: month,
            });
            break;
        }
      }
      return data.active;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.submissionDate]);

  useEffect(() => {
    filter.matchingDate.map((data, index) => {
      if (data.active) {
        // eslint-disable-next-line default-case
        switch (index) {
          case 0:
            setQuery({
              ...query,
              matchingDateFrom: "",
              matchingDateTo: "",
            });
            break;
          case 1:
            setQuery({
              ...query,
              matchingDateFrom: today,
              matchingDateTo: today,
            });
            break;
          case 2:
            setQuery({
              ...query,
              matchingDateFrom: today,
              matchingDateTo: week,
            });
            break;
          case 3:
            setQuery({
              ...query,
              matchingDateFrom: today,
              matchingDateTo: halfMonth,
            });
            break;
          case 4:
            setQuery({
              ...query,
              submissionDateFrom: today,
              submissionDateTo: month,
            });
            break;
        }
      }
      return data.active;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.matchingDate]);

  useEffect(() => {
    getOccupationList();
  }, [slug]);


  // const goCreatePage = () => {
  //   console.log("sluc"); 
  //   navigate(`/job-management/order-status/${calendarDate}`);
  // }

  return (
    <Paper
      sx={{
        width: "100%",
        boxShadow: "none",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: "20px",
          alignItems: "center",
          width: "100%",
        }}
      >
        <BackButton onClick={() => navigate("/job-management/order-status")} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            sx={{
              minWidth: "unset",
              padding: 0,
              img: {
                transition: "0.3s",
              },
              ":hover": {
                backgroundColor: "transparent!important",
                img: {
                  filter: "invert(1) brightness(0)",
                },
              },
            }}
            onClick={() => {
              setCurrentDate(getPreviousDay(currentDate));
              setInitial();
            }}
          >
            <img src="/icons/back-bordered.svg" alt="back" />
          </Button>
          <Typography
            fontSize={22}
            fontWeight="700"
            margin="0 60px"
            color="#3A3A3A"
          >
            {currentDate.toLocaleString("default", {
              weekday: "long",
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </Typography>
          <Button
            sx={{
              minWidth: "unset",
              padding: 0,
              transform: "rotate(180deg)",
              img: {
                transition: "0.3s",
              },
              ":hover": {
                backgroundColor: "transparent!important",
                img: {
                  filter: "invert(1) brightness(0)",
                },
              },
            }}
            onClick={() => {
              setCurrentDate(getTomorrow(currentDate));
              setInitial();
            }}
          >
            <img src="/icons/back-bordered.svg" alt="back" />
          </Button>
          <Button
            sx={{
              ml: "30px",
              padding: "7px 12px",
              minWidth: "unset",
              color: "#fff",
              backgroundColor: "#987EE7",
            }}
            onClick={() => {
              setCurrentDate(newDate);
              setInitial();
            }}
          >
            오늘
          </Button>
        </Box>
        <Link
          to="/job-management/order-status"
          style={{ color: "#987EE7", textDecoration: "none" }}
        >
          <CalendarMonthIcon sx={{ mr: "6px", fontSize: "22px" }} />
          캘린더로
        </Link>
      </Box>
      <div
        style={{ margin: "16.5px auto 24px auto", borderColor: "#828282" }}
      />
      <Box
        sx={{
          display: "flex",
          mb: "12px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography style={{ minWidth: "80px", fontWeight: 500 }}>
          검색
        </Typography>
        <FormInputDropdown
          control={control}
          name="searchType"
          sx={{ width: "127px", marginRight: "10px" }}
          options={searchOptions}
        />
        <FormInputText
          sx={{ width: "200px", marginRight: "6px" }}
          control={control}
          name="keyword"
          placeholder="내용을 입력해 주세요"
        />
        <Button
          onClick={handleSubmit(submit)}
          size="small"
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "7px 18px",
            color: "#7653DF",
            background: "#EEEAFB",
          }}
        >
          검색
        </Button>
        <Link
          to={{
            pathname:`/job-management/order-create/${slug}`,
            
          }}
          size="small"
          style={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "7px 18px",
            textDecoration: "none",
            display: "inline-block",
            marginLeft: "auto",
            borderRadius: "10px",
            lineHeight: "18px",
            color: "#fff",
            background: "#987EE7",
          }}
        >
          오더 등록
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: "12px",
        }}
      >
        {/* <Typography style={{ minWidth: "80px", fontWeight: 500 }}>
          업체
        </Typography>
        <FormInputDropdown
          control={control}
          name="customerName"
          sx={{ width: "140px", marginRight: "28px" }}
          options={searchOptions}
        /> */}
        <Typography style={{ minWidth: "80px", fontWeight: 500 }}>
          직종
        </Typography>
        {occupationOptions.length > 0 && (
          <FormInputDropdown
            control={control}
            name="occupationName"
            sx={{ width: "127px", marginRight: "28px" }}
            options={occupationOptions}
          />
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          mb: "12px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography style={{ minWidth: "80px", fontWeight: 500 }}>
          오더 접수일
        </Typography>
        <FormInputDate
          name="receiptDateFrom"
          control={control}
          placeholder="YYYY/MM/DD"
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                width: "127px",
                background: "#fff",
                ".MuiInputBase-root": {
                  paddingRight: "0px",
                },
                ".MuiIconButton-root": {
                  marginRight: "0px",
                },
                input: {
                  paddingRight: "0px!important",
                },
                "& .MuiSvgIcon-root": {
                  fill: "#987EE7 !important",
                },
              }}
            />
          )}
        />
        <Typography style={{ margin: "0 10px 0 6px", fontWeight: 500 }}>
          부터
        </Typography>
        <FormInputDate
          name="receiptDateTo"
          control={control}
          placeholder="YYYY/MM/DD"
          renderInput={(params) => (
            <TextField
              variant="outlined"
              {...params}
              sx={{
                width: "127px",
                background: "#fff",
                paddingRight: "0px!important",
                ".MuiInputBase-root": {
                  paddingRight: "0px",
                },
                ".MuiIconButton-root": {
                  marginRight: "0px",
                },
                input: {
                  paddingRight: "0px!important",
                },
                "& .MuiSvgIcon-root": {
                  fill: "#987EE7 !important",
                },
              }}
            />
          )}
        />
        <Typography style={{ margin: "0 20px 0 6px", fontWeight: 500 }}>
          까지
        </Typography>
        {filter.receiptDate.map((day, index) => (
          <Button
            key={index}
            sx={{
              fontSize: "13px",
              minWidth: "unset",
              padding: "7px 12px",
              mr: "6px",
              height: "30px",
              color: "#fff",
              background: day.active ? "#987EE7" : "#98A6AD",
            }}
            onClick={() => handleButtonClick("receiptDate", index)}
          >
            {day.label}
          </Button>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          mb: "12px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography style={{ minWidth: "80px", fontWeight: 500 }}>
          서류 제출일
        </Typography>
        <FormInputDate
          name="submissionDateFrom"
          control={control}
          placeholder="YYYY/MM/DD"
          sx={{}}
          renderInput={(params) => (
            <TextField
              color="info"
              size="small"
              variant="outlined"
              {...params}
              sx={{
                width: "127px",
                background: "#fff",
                ".MuiInputBase-root": {
                  paddingRight: "0px",
                },
                ".MuiIconButton-root": {
                  marginRight: "0px",
                },
                input: {
                  paddingRight: "0px!important",
                },
                "& .MuiSvgIcon-root": {
                  fill: "#987EE7 !important",
                },
              }}
            />
          )}
        />
        <Typography style={{ margin: "0 10px 0 6px", fontWeight: 500 }}>
          부터
        </Typography>
        <FormInputDate
          name="submissionDateTo"
          control={control}
          placeholder="YYYY/MM/DD"
          renderInput={(params) => (
            <TextField
              variant="outlined"
              {...params}
              sx={{
                width: "127px",
                background: "#fff",
                paddingRight: "0px!important",
                ".MuiInputBase-root": {
                  paddingRight: "0px",
                },
                ".MuiIconButton-root": {
                  marginRight: "0px",
                },
                input: {
                  paddingRight: "0px!important",
                },
                "& .MuiSvgIcon-root": {
                  fill: "#987EE7 !important",
                },
              }}
            />
          )}
        />
        <Typography style={{ margin: "0 20px 0 6px", fontWeight: 500 }}>
          까지
        </Typography>
        {filter.submissionDate.map((day, index) => (
          <Button
            key={index}
            sx={{
              fontSize: "13px",
              minWidth: "unset",
              padding: "7px 12px",
              mr: "6px",
              height: "30px",
              color: "#fff",
              background: day.active ? "#987EE7" : "#98A6AD",
            }}
            onClick={() => handleButtonClick("submissionDate", index)}
          >
            {day.label}
          </Button>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          mb: "12px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography style={{ minWidth: "80px", fontWeight: 500 }}>
          매칭 예정일
        </Typography>
        <FormInputDate
          name="matchingDateFrom"
          control={control}
          placeholder="YYYY/MM/DD"
          sx={{}}
          renderInput={(params) => (
            <TextField
              color="info"
              size="small"
              variant="outlined"
              {...params}
              sx={{
                width: "127px",
                background: "#fff",
                ".MuiInputBase-root": {
                  paddingRight: "0px",
                },
                ".MuiIconButton-root": {
                  marginRight: "0px",
                },
                input: {
                  paddingRight: "0px!important",
                },
                "& .MuiSvgIcon-root": {
                  fill: "#987EE7 !important",
                },
              }}
            />
          )}
        />
        <Typography style={{ margin: "0 10px 0 6px", fontWeight: 500 }}>
          부터
        </Typography>
        <FormInputDate
          name="matchingDateTo"
          control={control}
          placeholder="YYYY/MM/DD"
          renderInput={(params) => (
            <TextField
              variant="outlined"
              {...params}
              sx={{
                width: "127px",
                background: "#fff",
                paddingRight: "0px!important",
                ".MuiInputBase-root": {
                  paddingRight: "0px",
                },
                ".MuiIconButton-root": {
                  marginRight: "0px",
                },
                input: {
                  paddingRight: "0px!important",
                },
                "& .MuiSvgIcon-root": {
                  fill: "#987EE7 !important",
                },
              }}
            />
          )}
        />
        <Typography style={{ margin: "0 20px 0 6px", fontWeight: 500 }}>
          까지
        </Typography>
        {filter.matchingDate.map((day, index) => (
          <Button
            key={index}
            sx={{
              fontSize: "13px",
              minWidth: "unset",
              padding: "7px 12px",
              mr: "6px",
              height: "30px",
              color: "#fff",
              background: day.active ? "#987EE7" : "#98A6AD",
            }}
            onClick={() => handleButtonClick("matchingDate", index)}
          >
            {day.label}
          </Button>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography style={{ minWidth: "80px", fontWeight: 500 }}>
          관리부서
        </Typography>
        <FormInputDropdown
          control={control}
          name="teamInCharge"
          sx={{ width: "140px", marginRight: "28px" }}
          options={searchOptions}
        />
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "28px",
            borderLeft: "1px solid #E7E7E7",
          }}
        >
          <Typography style={{ marginRight: "20px", fontWeight: 500 }}>
            담당자
          </Typography>
          <FormInputDropdown
            control={control}
            name="manager"
            sx={{ width: "140px", marginRight: "10px" }}
            options={searchOptions}
          />
        </Box> */}
      </Box>
      <div style={{ margin: "30px auto 20px auto", borderColor: "#828282" }} />
      <Box
        sx={{
          display: "flex",
          mb: "50px",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Button
          size="small"
          onClick={handleSubmit(submit)}
          sx={{
            fontSize: "13px",
            width: "auto",
            mr: "20px",
            height: "30px",
            padding: "7px 46px",
            color: "#fff",
            background: "#987EE7",
          }}
        >
          설정한 조건으로 검색
        </Button>
        <Box
          component={Link}
          onClick={setInitial}
          size="small"
          sx={{
            fontSize: "13px",
            color: "#6B7280",
            "&:hover": {
              color: "#987EE7",
            },
          }}
        >
          검색조건 초기화
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          mb: "10px",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => copyPrevOrders()}
            sx={{
              fontSize: "13px",
              mr: "8px",
              height: "30px",
              padding: "7px 26px",
              color: "#fff",
              background: "#987EE7",
            }}
          >
            일괄 복사
          </Button>
          <Button
            onClick={deleteOrders}
            sx={{
              fontSize: "13px",
              mr: "8px",
              height: "30px",
              padding: "7px 26px",
              color: "#fff",
              background: "#987EE7",
            }}
          >
            일괄 삭제
          </Button>
          <Button
            onClick={() => download()}
            sx={{
              fontSize: "13px",
              mr: "8px",
              height: "30px",
              padding: "7px 26px",
              color: "#fff",
              background: "#6B7280",
            }}
          >
            엑셀 다운로드
          </Button>
          <CustomCheckbox
            name="allSelect"
            checked={!orders?.content?.some((user) => user.isChecked !== true)}
            onChange={handleChange}
          />
          <Typography style={{ minWidth: "80px", fontWeight: 500 }}>
            현재 해당되는 모든 회원 ( {orders?.totalElements}명) 선택
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormInputDropdown
            control={control}
            name="order"
            sx={{ width: "80px", marginRight: "6px" }}
            options={order}
          />
          <FormInputDropdown
            control={control}
            name="sort"
            sx={{ width: "120px", marginRight: "6px" }}
            options={sortOption}
            staticValue={query.sort}
            onChange={(e) => setQuery({ ...query, sort: e.target.value })}
          />
          <FormInputDropdown
            control={control}
            name="size"
            sx={{ width: "90px" }}
            onChange={(e) => setQuery({ ...query, size: e.target.value })}
            options={pageSize}
            staticValue={query.size}
          />
        </Box>
      </Box>
      <TableContainer
        className="table"
        sx={{
          borderTop: "1px solid #98A6AD",
        }}
      >
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead sx={{ background: "#fcfcfc" }}>
            <TableRow>
              <TableCell
                sx={{
                  whiteSpace: "nowrap",
                  textAlign: "center",
                }}
                align="center"
              >
                <CustomCheckbox
                  color="primary"
                  name="allSelect"
                  checked={
                    !orders?.content?.some((user) => user.isChecked !== true)
                  }
                  onChange={handleChange}
                />
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
                align="center"
              >
                고유번호
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
                align="center"
              >
                업체명
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
                align="center"
              >
                부서명
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
                align="center"
              >
                프로그램
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
                align="center"
              >
                직종
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
                align="center"
              >
                채용 조건 (업무,급여,근무시간,근무지,우대사항 등)
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
                align="center"
              >
                TO 수
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: "500",
                  backgroundColor: "#FAE73C",
                  minWidth: 100,
                }}
                align="center"
              >
                오더 접수일
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  minWidth: 100,
                  fontSize: "13px",
                  backgroundColor: "#FAE73C",
                }}
                align="center"
              >
                서류 제출일
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  minWidth: 100,
                  fontSize: "13px",
                  backgroundColor: "#FAE73C",
                }}
                align="center"
              >
                매칭 예정일
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  backgroundColor: "#FEC269",
                }}
                align="center"
              >
                결과
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                }}
                align="center"
              >
                면접 시간
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                }}
                align="center"
              >
                인력 셋팅 현황
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                }}
                align="center"
              >
                담당 팀
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
                align="center"
              >
                담당자
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders && !orders.empty
              ? orders.content.map((user, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      sx={{
                        "&:hover": {
                          color: "#5a5a5a !important",
                          backgroundColor: "rgba(142, 102, 255, 0.22)",
                        },
                        textDecoration: "none",
                        height: "40px",
                      }}
                    >
                      <TableCell
                        padding="checkbox"
                        sx={{
                          padding: "10px",
                        }}
                        align="center"
                      >
                        <CustomCheckbox
                          name={`${user?.id}`}
                          checked={user?.isChecked || false}
                          onChange={handleChange}
                        />
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/job-management/order-details/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.id}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/job-management/order-details/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          padding: "10px",
                          whiteSpace: "nowrap",
                        }}
                        align="center"
                      >
                        {user.customerName}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/job-management/order-details/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          padding: "10px",
                          whiteSpace: "nowrap",
                        }}
                        align="center"
                      >
                        {user.department}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/job-management/order-details/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          padding: "10px",
                          whiteSpace: "nowrap",
                        }}
                        align="center"
                      >
                        {user.program}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/job-management/order-details/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          padding: "10px",
                          whiteSpace: "nowrap",
                        }}
                        align="center"
                      >
                        {user.occupationName}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/job-management/order-details/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          padding: "10px",
                          maxWidth: "340px",
                          minWidth: "340px",
                        }}
                        align="center"
                      >
                        {user.recConditions}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/job-management/order-details/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.tobe}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/job-management/order-details/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {getAge(user.receiptDate)}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/job-management/order-details/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {getAge(user.submissionDate)}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/job-management/order-details/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {getAge(user.matchingDate)}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/job-management/order-details/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          padding: "10px",
                          maxWidth: "180px",
                          minWidth: "180px",
                        }}
                        align="center"
                      >
                        {user.result}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/job-management/order-details/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.interviewTime}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/job-management/order-details/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          padding: "10px",
                          maxWidth: "350px",
                          minWidth: "350px",
                        }}
                        align="center"
                      >
                        <div
                          ref={contentRef}
                          style={{
                            maxHeight: "54px",
                            overflow: "hidden",
                            transition: "max-height 0.3s ease",
                            textAlign: "center",
                          }}
                        >
                          {user.setStatus}
                        </div>
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/job-management/order-details/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.teamInCharge}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/job-management/order-details/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          padding: "10px",
                          whiteSpace: "nowrap",
                        }}
                        align="center"
                      >
                        {user.manager}
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      {orders && (
        <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
          <Pagination
            count={Math.ceil(orders?.totalElements / query.size) || 0}
            onChange={handleChangePage}
            page={query.number}
            color="primary"
            variant="outlined"
            shape="rounded"
            sx={{
              m: "0 auto",
              "& .MuiPaginationItem-root.MuiPaginationItem-page, .MuiPaginationItem-previousNext":
                { padding: "4px 20px !important" },
            }}
          />
        </Stack>
      )}
      {modal.isOpen && (
        <AcceptModal
          handleClick={deleteById}
          modal={{ ...modal }}
          modalKey="isOpen"
          setModal={setModal}
          title="주문을 삭제 하시겠습니까?"
        />
      )}
    </Paper>
  );
}

export default OrderDetails;
