import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { useState } from "react";
import CodeSwitcher from "./CodeSwitcher";

export default function PersonnelManagement({ initialTab }) {
  const [selectedTab, setSelectedTab] = useState({
    tab: initialTab ?? 1,
  });

  const handleChange = (_, newValue) => {
    setSelectedTab({ tab: newValue });
  };

  return (
    <Box
      sx={{
        width: "100%",
        pl: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Tabs
          sx={{
            "& .MuiTab-root.MuiTab-textColorSecondary": {
              borderRadius: "5px 5px 0px 0px",
              color: "#987EE7",
              background: "#EEEAFB",
              mr: "5px",
              minHeight: "30px !important",
              p: "7px 20px",
              fontWeight: "500",
              fontSize: "13px",
            },
            "& .MuiTabs-scroller": {
              alignItems: "flex-end",
              display: "flex",
            },
            "& .MuiTab-root.MuiTab-textColorSecondary.Mui-selected": {
              background: "#987EE7",
              color: "#fff",
            },
            "& .MuiTabs-indicator": {
              display: "none",
              color: "#E1E1E1",
            },
          }}
          value={selectedTab.tab}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value={1} label="파견코드 관리" />
          <Tab value={2} label="거래처 업종 관리" />
          <Tab value={3} label="직종 관리" />
          <Tab value={4} label="근무부서 관리" />
          <Tab value={5} label="관리부서 관리" />
          {/* <Tab value={6} label="업무부서 상세" /> */}
        </Tabs>
      </Box>
      <hr
        style={{
          margin: "0px 0 40px 0",
        }}
      />
      <CodeSwitcher selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
    </Box>
  );
}
