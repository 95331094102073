import axios from "axios";

export const getMainUrl = () => {
  // return `http://146.120.18.92:9191`;
  // return `http://112.175.18.143:9090`;
  // return `http://192.168.0.150:9090`;
    return `https://papi.mediadb.co.kr`;
  // return `http://192.168.0.84:8080`; //buyanaa ah
  // return `http://192.168.0.236:8080`; //gantumur
  // return `http://192.168.0.173:8080`; //me
};

async function mainCaller(path, method = "GET", data = null, headers = {}) {
  const options = {
    url: `${getMainUrl()}/${path}`,
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      ...headers,
    },
    method,
    withCredentials: false,
  };
  if (data) {
    options.data = data;
  }

  const res = await axios(options).catch((err) => {
    // if (err.response.status === 402) {
    // } else if (err.response.status === 401) {
    //   dispatch(logoutUser());
    //   window.location.replace("/");
    // }
    if (err.response.status === 401) {
      localStorage.removeItem("media-user");
      window.location = "/";
    }
    throw err;
  });
  return res.data;
}

export async function pilaAuthUser(password, username) {
  const { data } = await axios
    .post(
      getMainUrl() + "/signIn",
      {
        username: username,
        password: password,
      },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    )
    .catch((err) => {
      console.log(err);
    });

  return data.data;
}

export default mainCaller;
