import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteOutline";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { FormInputText } from "../../components/form-components/FormInputText";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import { FormInputRadio } from "../../components/form-components/FormInputRadio";
import { FormInputTextArea } from "../../components/form-components/FormInputTextArea";
import CustomCheckbox from "../../components/CustomCheckbox";
import { FormInputDate } from "../../components/form-components/FormInputDate";
import FormInputCEditor from "../../components/form-components/FormInputCEditor";
import BackButton from "../../components/BackButton";
import LinkButton from "../../components/LinkButton";
import { FormInputSearch } from "../../components/form-components/FormInputSearch";
import { occupationSearch } from "../../api/personalCodeManagement";
import {
  careerPeriod,
  eduLevelIrrelevant,
  genderIrrelevant,
  jobDisplayPlace,
  jobType,
  scriptUrl,
  workAreas,
} from "../../constants/constants";
import { getCoordinatesByAddress } from "../../api/nominatium";
import {
  copyRecruitment,
  createJob,
  deleteJobById,
  getJobById,
  getJobCategories,
  updateJobById,
} from "../../api/recruitment";
import { debounce, formatLocaleDate } from "../../utils/methods";
import { getAllCustomers } from "../../api/customers";
import CustomModal from "../../components/modals/CustomModal";
import CodeManager from "../../components/modals/CodeManager";
import AcceptModal from "../../components/modals/AcceptModal";
import { awsImagePath } from "../../utils/methods";
import Loader from "../../components/loader";

const initialQuery = {
  number: 1,
  size: 20,
  companyName: "",
  situation: "",
};

function CURecruitment() {
  const { id } = useParams();
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const open = useDaumPostcodePopup(scriptUrl);
  const { token } = useSelector((state) => state.user.user);
  const [modal, setModal] = useState({
    isOpen: false,
    isOpen1: false,
  });
  const [job, setJob] = useState();
  const { handleSubmit, control, setValue, watch, reset } = useForm({
    defaultValues: job,
  });
  const [occupation, setOccupation] = useState([]);
  const [images, setImages] = useState({ img1: "", img2: "" });
  const [categories, setCategories] = useState([{ label: "선택", value: "" }]);
  const [isOldCompany, setIsOldCompany] = useState(true);
  const [query, setQuery] = useState(initialQuery);
  const [companies, setCompanies] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState(null);

  const handleUploadImage1 = (e) => {
    const file = e.target.files[0];

    const image = new Image();

    image.onload = function () {
      // if (this.width !== 380 || this.height !== 380) {
      //   toast.warning("로고 : 380 * 380px 사이즈, 1:1 비율을 권장합니다!");
      // } else {
      //   setImages({ ...images, img1: file });
      // }
      setImages({ ...images, img1: file });
    };

    // Set the source of the Image object to the selected file
    image.src = URL.createObjectURL(file);
  };

  const handleUploadImage2 = (e) => {
    const file = e.target.files[0];

    const image = new Image();

    image.onload = function () {
      // if (this.width !== 760 || this.height !== 380) {
      //   toast.warning("썸네일 : 760 * 380px 사이즈, 2:1 비율을 권장합니다!");
      // } else {
      //   setImages({ ...images, img2: file });
      // }
      setImages({ ...images, img2: file });
    };

    image.src = URL.createObjectURL(file);
  };

  const handleFile = async (data) => {
    setFile(data);
  };

  const deleteImage1 = (e) => {
    e.preventDefault();
    setImages({ ...images, img1: "" });
  };

  const deleteImage2 = (e) => {
    e.preventDefault();
    setImages({ ...images, img2: "" });
  };

  const handleComplete = async (data) => {
    setValue(
      "businessAddress",
      `${data.userSelectedType === "R" ? data.roadAddress : data.jibunAddress}${
        data.buildingName ? `, ${data.buildingName}` : ""
      }`
    );
  };

  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  const getOccupation = async (keyword) => {
    try {
      const { data } = await occupationSearch(keyword, token);
      setOccupation(data);
    } catch (error) {
      console.log(error);
    }
  };

  const checked = (isChecked, type) => {
    if (type === "always") {
      setValue("always", isChecked);
      setValue("periodFrom", "");
      setValue("periodTo", "");
      return;
    } else if (type === "submission") {
      setValue("submission", isChecked);
      setValue("submissionDate", "");
      return;
    } else {
      setValue("activation", isChecked);
      setValue("interviewDate", "");
      return;
    }
  };

  const submit = async (values) => {
    let basicInfoValues = [
      values.type,
      values.occupationId,
      // values.eduHistoryFrom,
      // values.eduHistoryTo,
      values.workingDepartment,
      // values.career,
      values.workingArea,
      values.numberOfPeople,
      values.salaryConditions,
      values.time,
    ];

    let scheduleValues = [
      !values.always && values.periodFrom,
      !values.always && values.periodTo,
      values.submission && values.submissionDate,
      values.activation && values.interviewDate,
    ];

    let companyInfoValues = [
      values.isSignedCompany ? values.companyId : values.companyName,
      values.homePage,
      values.companyIndustry,
      values.businessAddress,
      values.aboutUs,
      images.img1,
      images.img2,
    ];

    let recruiterInfoValues = [
      values.managerName,
      values.managerEmail,
      values.managerInquiryCall,
      values.workingDepartment,
    ];

    let isBasicInfoEmpty = basicInfoValues.some(
      (value) => value === null || value === ""
    );

    let isScheduleInfoEmpty = scheduleValues.some(
      (value) => value === null || value === ""
    );

    let isCompanyInfoEmpty = companyInfoValues.some(
      (value) => value === null || value === ""
    );

    let isRecruiterInfoEmpty = recruiterInfoValues.some(
      (value) => value === null || value === ""
    );

    console.log("values --> " , JSON.stringify(values))


    // else if (isScheduleInfoEmpty) {
    //   toast.warning("채용 일정 모든 필드를 입력하셔는지 확인해 주세요!");
    // }

   
    if (!values.title) {
      toast.warning("재목 입력해 주세요!");
    } else if (isBasicInfoEmpty) {
      toast.warning("기본 정보 모든 필드를 입력하셔는지 확인해 주세요!");
    } else if (isCompanyInfoEmpty) {
      toast.warning("업체 정보 모든 필드를 입력하셔는지 확인해 주세요!");
    } else if (isRecruiterInfoEmpty) {
      toast.warning("채용 담당자 정보 모든 필드를 입력하셔는지 확인해 주세요!");
    } else if (!values.recruitmentDetail) {
      toast.warning("모집 요강 상세 영역 확인해 주세요!");
    } else {
      const newValues = {
        create: new Blob(
          [
            JSON.stringify({
              ...values,
              submissionDate: formatLocaleDate(values.submissionDate),
              interviewDate: formatLocaleDate(values.interviewDate),
              periodFrom: formatLocaleDate(values.periodFrom),
              periodTo: formatLocaleDate(values.periodTo),
            }),
          ],
          {
            type: "application/json",
          }
        ),
        companyId: isOldCompany ? values.companyId : 0,
        isSignedCompany: isOldCompany ? true : false,
        logoFile: images.img1,
        thumbnailFile: images.img2,
        file: file,
      };
      const createValue = {
        ...values,
        submissionDate: formatLocaleDate(values.submissionDate),
        interviewDate: formatLocaleDate(values.interviewDate),
        periodFrom: formatLocaleDate(values.periodFrom),
        periodTo: formatLocaleDate(values.periodTo),
        companyId: isOldCompany ? values.companyId : 0,
        isSignedCompany: isOldCompany ? true : false,
        logoFile: images.img1,
        thumbnailFile: images.img2,
        file: file,
      };

      setIsUploading(true);
      try {
        if (job) {
          await updateJobById(newValues, token);
        } else {
          await createJob(createValue, token);
        }
        navigate("/job-management/all-jobs");
      } catch (error) {
        console.log(error);
        toast.error(error.response?.data.message);
      }
      setIsUploading(false);
    }
  };

  const getJob = async () => {
    try {
      const { data } = await getJobById(id, token, user?.id);
      setJob(data);
      setImages({ img1: data.logo, img2: data.thumbnail });
      reset(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      const { data } = await getJobCategories(token);
      const newData = data
        .filter((cat) => cat.title)
        .map((cat) => ({ label: cat.title, value: cat.id }));
      setCategories([...categories, ...newData]);
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await getAllCustomers(query, token);
      setCompanies(data?.content);
    } catch (error) {
      console.log(error);
    }
  };

  const search = async (e) => {
    if (e && e.target.value) {
      const keyword = e.target.value;
      debounce(
        () => {
          return getOccupation(keyword);
        },
        "search-box",
        1000
      );
    }
  };

  const searchCompany = (e) => {
    if (e) {
      setQuery({
        companyName: e.target.value,
      });
    }
  };

  const handleRecruitmentCopy = async () => {
    try {
      console.log('job-------------> ' , JSON.stringify(job));
      await copyRecruitment(JSON.stringify(job), token);
      navigate("/job-management/all-jobs");
    } catch (err) {
      toast.warning("Failed to copy: ");
    }
  };

  const searchOccu = () => {
    setModal({
      ...modal,
      isOpen: true,
      keyword: watch("searchOccu"),
    });
  };

  const deleteById = async () => {
    try {
      await deleteJobById(modal.deleteID, token);
      setModal({ isOpen1: false });
      navigate("/job-management/all-jobs");
    } catch (error) {
      console.log(error);
    }
  };

  const deleteJobAction = async (id) => {
    setModal({ ...modal, isOpen1: true, deleteID: id });
  };

  useEffect(() => {
    if (modal.occupation) {
      setValue("occupationId", modal.occupation.id);
      setOccupation([modal.occupation]);
    }
  }, [modal.occupation]);

  useEffect(() => {
    getCategories();
    if (id) {
      getJob();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (isOldCompany) {
      getCustomers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOldCompany, query]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <BackButton text={id ? "채용 정보 상세" : "채용 등록"} />
        <LinkButton label="목록보기" link="/job-management/all-jobs" />
      </Box>
      <hr style={{ margin: "16px 0 30px 0" }} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "10px 20px",
          mb: "30px",
          backgroundColor: "#F9F8FE",
          borderRadius: "5px",
        }}
      >
        <Typography whiteSpace="nowrap" mr="14px">
          <b style={{ fontSize: "14px", color: "#7653DF" }}>모집 부문(제목)</b>
        </Typography>
        <FormInputText
          sx={{
            input: {
              backgroundColor: "#fff",
            },
          }}
          required
          control={control}
          name="title"
          placeholder="내용을 입력해 주세요"
        />
      </Box>
      <Typography whiteSpace="nowrap" mb="30px">
        <b style={{ fontSize: "16px" }}>1. 기본 정보</b>
      </Typography>
      <Grid container>
        <Grid container rowSpacing={1} alignItems="center" mb="12px">
          <Grid item xs={1} fontWeight="500">
            채용 형태
          </Grid>
          <Grid item xs={11} pl="18px">
            <FormInputRadio
              name="type"
              sx={{
                minHeight: "30px",
                marginRight: "38px",
                span: { fontWeight: 500 },
              }}
              control={control}
              options={jobType}
              row
            />
          </Grid>
        </Grid>
        <Grid item xs={7} pr="24px">
          <Grid container rowSpacing={1} alignItems="center">
            <Grid item xs={2} fontWeight="500">
              직종
            </Grid>
            <Grid item xs={10}>
              <Box display="flex" alignItems="center">
                <FormInputSearch
                  arrowHide
                  staticValue={
                    modal.occupation
                      ? modal.occupation.name
                      : job?.occupationName
                  }
                  control={control}
                  name="occupationId"
                  placeholder="내용을 입력해 주세요"
                  sx={{
                    width: "80%",
                    ".MuiOutlinedInput-root.MuiInputBase-formControl": {
                      padding: "0",
                    },
                  }}
                  setValue={(_, selected) => setValue("occupationId", selected)}
                  onChange={search}
                  options={occupation.map((occupation) => ({
                    label: occupation.name,
                    value: occupation.id,
                  }))}
                />
                <Button
                  onClick={() => searchOccu()}
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "12px",
                    marginLeft: "10px",
                    lineHeight: "14px",
                    padding: "8px 26px",
                    color: "#7653DF",
                    backgroundColor: "#EEEAFB",
                  }}
                >
                  검색
                </Button>
              </Box>
            </Grid>
            <Grid item xs={2} fontWeight="500">
              근무 부서
            </Grid>
            <Grid item xs={10}>
              <FormInputText
                control={control}
                name="workingDepartment"
                sx={{
                  width: "80%",
                }}
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
            <Grid item xs={2} fontWeight="500">
              채용 성별
            </Grid>
            <Grid item xs={10}>
              <FormInputDropdown
                control={control}
                name="gender"
                sx={{
                  width: "80%",
                }}
                options={genderIrrelevant}
              />
            </Grid>
            <Grid item xs={2} fontWeight="500">
              근무 지역
            </Grid>
            <Grid item xs={10}>
              <FormInputDropdown
                control={control}
                name="workingArea"
                sx={{
                  width: "80%",
                }}
                options={workAreas}
              />
            </Grid>
            <Grid item xs={2} fontWeight="500" mb="auto">
              급여 조건
            </Grid>
            <Grid item xs={10}>
              <FormInputTextArea
                style={{
                  width: "80%",
                  background: "#fff",
                  borderRadius: "8px",
                  border: "1px solid #E0E0E0",
                }}
                placeholder="내용을 입력해 주세요"
                minRows={3}
                control={control}
                name="salaryConditions"
                error={"false"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid container rowSpacing={1} alignItems="center">
            <Grid item xs={2} fontWeight="500">
              학력 사항
            </Grid>
            <Grid item xs={10}>
              <Box display="flex" alignItems="center">
                <FormInputDropdown
                  control={control}
                  name="eduHistoryFrom"
                  sx={{
                    maxWidth: "190px",
                    width: "100%",
                  }}
                  options={eduLevelIrrelevant}
                />
                <Typography
                  sx={{ whiteSpace: "nowrap" }}
                  ml="10px"
                  fontWeight={500}
                >
                  부터
                </Typography>
                <FormInputDropdown
                  control={control}
                  name="eduHistoryTo"
                  sx={{
                    marginLeft: "auto",
                    maxWidth: "190px",
                    width: "100%",
                  }}
                  options={eduLevelIrrelevant}
                />
                <Typography
                  sx={{ whiteSpace: "nowrap" }}
                  fontWeight={500}
                  ml="10px"
                >
                  까지
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={2} fontWeight="500">
              경력
            </Grid>
            <Grid item xs={10}>
              <FormInputDropdown
                control={control}
                name="career"
                sx={{
                  width: "100%",
                }}
                options={careerPeriod}
              />
            </Grid>
            {/* <Grid item xs={2} fontWeight="500">
              채용 연령
            </Grid>
            <Grid item xs={10}>
              <Box display="flex" alignItems="center">
                <FormInputDropdown
                  control={control}
                  name="ageFrom"
                  sx={{
                    maxWidth: "190px",
                    width: "100%",
                  }}
                  options={ages()}
                />
                <Typography
                  sx={{ whiteSpace: "nowrap" }}
                  ml="10px"
                  fontWeight={500}
                >
                  부터
                </Typography>
                <FormInputDropdown
                  control={control}
                  name="ageTo"
                  sx={{
                    marginLeft: "auto",
                    maxWidth: "190px",
                    width: "100%",
                  }}
                  options={ages()}
                />
                <Typography
                  sx={{ whiteSpace: "nowrap" }}
                  ml="10px"
                  fontWeight={500}
                >
                  까지
                </Typography>
              </Box>
            </Grid> */}
            <Grid item xs={2} fontWeight="500">
              모집 인원
            </Grid>
            <Grid item xs={10}>
              <FormInputText
                control={control}
                name="numberOfPeople"
                type="number"
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
            <Grid item xs={2} fontWeight="500" mb="auto">
              근무 시간
            </Grid>
            <Grid item xs={10}>
              <FormInputTextArea
                style={{
                  width: "100%",
                  background: "#fff",
                  borderRadius: "8px",
                  border: "1px solid #E0E0E0",
                }}
                placeholder="내용을 입력해 주세요"
                minRows={3}
                control={control}
                name="time"
                error={"false"}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <hr style={{ margin: "40px 0 30px 0" }} />
      <Typography whiteSpace="nowrap" mb="30px">
        <b style={{ fontSize: "16px" }}>2. 채용 일정</b>
      </Typography>
      <Grid container>
        <Grid item xs={7} pr="24px">
          <Grid container rowSpacing={1} alignItems="center">
            <Grid item xs={2} fontWeight="500">
              모집 기간
            </Grid>
            <Grid item xs={3}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CustomCheckbox
                  checked={watch("always")}
                  onChange={(e) => checked(e.target.checked, "always")}
                />
                <Typography sx={{ fontWeight: 500 }}>상시</Typography>
              </Box>
            </Grid>
            <Grid item xs={7}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ml: "-10px",
                }}
              >
                <FormInputDate
                  name="periodFrom"
                  control={control}
                  placeholder="YYYY/MM/DD"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: "127px",
                        background: "#fff",
                        ".MuiInputBase-root": {
                          paddingRight: "0px",
                        },
                        ".MuiIconButton-root": {
                          margin: "0px",
                        },
                        input: {
                          paddingRight: "0px!important",
                        },
                        "& .MuiSvgIcon-root": {
                          fill: "#987EE7 !important",
                        },
                      }}
                    />
                  )}
                  disabled={watch("always")}
                />
                <Typography
                  sx={{ mx: "11px", whiteSpace: "nowrap" }}
                  fontWeight={500}
                >
                  부터
                </Typography>
                <FormInputDate
                  name="periodTo"
                  control={control}
                  placeholder="YYYY/MM/DD"
                  disabled={watch("always")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: "127px",
                        ml: "auto",
                        background: "#fff",
                        ".MuiInputBase-root": {
                          paddingRight: "0px",
                        },
                        ".MuiIconButton-root": {
                          margin: "0px",
                        },
                        input: {
                          paddingRight: "0px!important",
                        },
                        "& .MuiSvgIcon-root": {
                          fill: "#987EE7 !important",
                        },
                      }}
                    />
                  )}
                />
                <Typography
                  fontWeight={500}
                  sx={{ ml: "11px", whiteSpace: "nowrap" }}
                >
                  까지
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} fontWeight="500">
              서류 제출일
            </Grid>
            <Grid item xs={3}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CustomCheckbox
                  checked={watch("submission")}
                  onChange={(e) => checked(e.target.checked, "submission")}
                />
                <Typography sx={{ fontWeight: 500 }}>제출일 있음</Typography>
              </Box>
            </Grid>
            <Grid item xs={7}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ml: "-10px",
                }}
              >
                <FormInputDate
                  name="submissionDate"
                  control={control}
                  disabled={!watch("submission")}
                  placeholder="YYYY/MM/DD"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: "127px",
                        background: "#fff",
                        ".MuiInputBase-root": {
                          paddingRight: "0px",
                        },
                        ".MuiIconButton-root": {
                          margin: "0px",
                        },
                        input: {
                          paddingRight: "0px!important",
                        },
                        "& .MuiSvgIcon-root": {
                          fill: "#987EE7 !important",
                        },
                      }}
                    />
                  )}
                />
                <Typography
                  fontWeight={500}
                  sx={{ ml: "11px", whiteSpace: "nowrap" }}
                >
                  까지
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid container rowSpacing={1} alignItems="center">
            <Grid item xs={12}>
              <CustomCheckbox
                disabled
                style={{
                  opacity: "0",
                  eventPointer: "none",
                }}
              />
            </Grid>
            <Grid item xs={2} fontWeight="500">
              면접 예정일
            </Grid>
            <Grid item xs={2}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CustomCheckbox
                  checked={watch("activation")}
                  onChange={(e) => checked(e.target.checked, "activation")}
                />
                <Typography sx={{ fontWeight: 500 }}>활성</Typography>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <FormInputDate
                name="interviewDate"
                control={control}
                disabled={!watch("activation")}
                placeholder="YYYY/MM/DD"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: "127px",
                      background: "#fff",
                      ".MuiInputBase-root": {
                        paddingRight: "0px",
                      },
                      ".MuiIconButton-root": {
                        margin: "0px",
                      },
                      input: {
                        paddingRight: "0px!important",
                      },
                      "& .MuiSvgIcon-root": {
                        fill: "#987EE7 !important",
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <hr style={{ margin: "40px 0 30px 0" }} />
      <Typography whiteSpace="nowrap" mb="30px">
        <b style={{ fontSize: "16px" }}>3. 업체 정보 </b>
      </Typography>
      <Grid container>
        <Grid item xs={7}>
          <FormControl>
            <Typography
              whiteSpace="nowrap"
              color="black"
              item
              xs={2}
              fontWeight="500"
            >
              업체 선택
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="yes"
              name="radio-buttons-group"
            >
              <FormControlLabel
                onClick={() => setIsOldCompany(true)}
                control={<Radio />}
                value="yes"
                label={
                  <Grid color="black" fontWeight="500">
                    저장된 회사 불러오기
                  </Grid>
                }
              />
              <FormControlLabel
                onClick={() => setIsOldCompany(false)}
                control={<Radio />}
                value="no"
                label={
                  <Grid color="black" fontWeight="500">
                    신규 작성
                  </Grid>
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={5} display="flex" alignItems="center">
          <Typography whiteSpace="nowrap" color="black" fontWeight="500">
            이력서 양식 업로드
          </Typography>
          <Button
            sx={{
              whiteSpace: "nowrap",
              fontSize: "12px",
              marginLeft: "10px",
              lineHeight: "15px",
              padding: "8px 26px",
              height: "28px",
              color: "#7653DF",
              position: "relative",
              backgroundColor: "#EEEAFB",
            }}
          >
            업로드
            <input
              type="file"
              onChange={(e) => handleFile(e.target.files[0])}
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                opacity: "0",
              }}
            />
          </Button>
          <FormInputText
            control={control}
            sx={{ backgroundColor: "white", width: "100%", marginLeft: "10px" }}
            staticValue={file ? file?.name || "" : job?.attachmentName || ""}
            name="file"
          />
        </Grid>
      </Grid>
      <Grid style={{ marginTop: 50 }} container>
        <Grid item xs={7}>
          <Grid container rowSpacing={1} alignItems="center">
            {isOldCompany ? (
              <Grid container rowSpacing={1} alignItems="center">
                <Grid item xs={2} fontWeight="500">
                  회사명
                </Grid>
                <Grid item xs={7}>
                  <FormInputSearch
                    staticValue={job?.companyName}
                    control={control}
                    name="companyId"
                    placeholder="내용을 입력해 주세요"
                    setValue={(_, selected) => {
                      setValue("isSignedCompany", isOldCompany);
                      setValue("companyId", selected ? selected.id : "");
                      setValue("companyName", selected ? selected.name : "");
                    }}
                    onChange={searchCompany}
                    options={companies?.map((com) => ({
                      label: com.name,
                      value: com,
                    }))}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container rowSpacing={1} alignItems="center">
                <Grid item xs={2} fontWeight="500">
                  회사명
                </Grid>
                <Grid item xs={7}>
                  <FormInputText
                    control={control}
                    name="companyName"
                    placeholder="내용을 입력해 주세요"
                  />
                </Grid>
              </Grid>
            )}

            <Grid item xs={2} fontWeight="500">
              홈페이지
            </Grid>
            <Grid item xs={7}>
              <FormInputText
                control={control}
                name="homePage"
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
            <Grid item xs={12} mt="10px">
              <Grid container rowSpacing={1} alignItems="center">
                <Grid item xs={3} fontWeight="500" pr="22px">
                  로고 업로드
                  {/* <Button
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "12px",
                      lineHeight: "15px",
                      padding: "8px 26px",
                      color: "#fff",
                      backgroundColor: "#8E66FF",
                    }}
                  >
                    업로드
                  </Button> */}
                  <label htmlFor="imageupload1" style={{ width: "100%" }}>
                    <Box
                      position="relative"
                      sx={{
                        marginTop: "8px",
                        "&:hover": { cursor: "pointer" },
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "150px",
                          borderRadius: "6px",
                          backgroundColor: "rgba(142, 102, 255, 0.03)",
                          border: "1px dashed #8E66FF",
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          overflow: "hidden",
                        }}
                      >
                        {images.img1 ? (
                          <>
                            <img
                              src={
                                typeof images.img1 === "string" && images.img1
                                  ? `${awsImagePath}/${images.img1}`
                                  : URL.createObjectURL(images.img1)
                              }
                              alt="uploader"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                            <Button
                              style={{
                                position: "absolute",
                                display: "flex",
                                minWidth: "unset",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#fff",
                                bottom: "7px",
                                right: "7px",
                                padding: "4px",
                              }}
                              onClick={deleteImage1}
                            >
                              <DeleteForeverIcon
                                sx={{ fontSize: "20px", color: "#000" }}
                              />
                            </Button>
                          </>
                        ) : (
                          <img src="/icons/upload.svg" alt="uploader" />
                        )}
                      </div>
                    </Box>
                    <input
                      type="file"
                      id="imageupload1"
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={handleUploadImage1}
                    />
                  </label>
                </Grid>
                <Grid pr="10px" item xs={5}>
                  <Box display="flex" alignItems="center">
                    <Typography>썸네일 업로드</Typography>
                  </Box>
                  <label htmlFor="imageupload2" style={{ width: "100%" }}>
                    <Box
                      position="relative"
                      sx={{
                        marginTop: "8px",
                        "&:hover": { cursor: "pointer" },
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "150px",
                          borderRadius: "6px",
                          backgroundColor: "rgba(142, 102, 255, 0.03)",
                          border: "1px dashed #8E66FF",
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          overflow: "hidden",
                        }}
                      >
                        {images.img2 ? (
                          <>
                            <img
                              src={
                                typeof images.img2 === "string" && images.img2
                                  ? `${awsImagePath}/${images.img2}`
                                  : URL.createObjectURL(images.img2)
                              }
                              alt="uploader"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                            <Button
                              style={{
                                position: "absolute",
                                display: "flex",
                                minWidth: "unset",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#fff",
                                bottom: "7px",
                                right: "7px",
                                padding: "4px",
                              }}
                              onClick={deleteImage2}
                            >
                              <DeleteForeverIcon
                                sx={{ fontSize: "20px", color: "#000" }}
                              />
                            </Button>
                          </>
                        ) : (
                          <img src="/icons/upload.svg" alt="uploader" />
                        )}
                      </div>
                    </Box>
                    <input
                      type="file"
                      id="imageupload2"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleUploadImage2}
                    />
                  </label>
                </Grid>
                <Grid sx={{ height: "100%" }} xs={4}>
                  <Typography fontSize={11}>
                    [이미지 업로드 안내] <br />
                    로고 : 380 * 380px 사이즈, 1:1 비율을 권장합니다. <br />
                    썸네일 : 760 * 380px 사이즈, 2:1 비율을 권장합니다. <br />
                    jpg/png 파일만 등록가능합니다.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid container rowSpacing={1} alignItems="center">
            <Grid item xs={2} fontWeight="500">
              회사 업종
            </Grid>
            <Grid item xs={10}>
              <FormInputText
                control={control}
                name="companyIndustry"
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
            <Grid item xs={2} fontWeight="500">
              업체 주소
            </Grid>
            <Grid item xs={10}>
              <Box display="flex" alignItems="center">
                <FormInputText
                  control={control}
                  name="businessAddress"
                  placeholder=""
                />
                <Button
                  onClick={handleClick}
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "12px",
                    marginLeft: "10px",
                    lineHeight: "15px",
                    padding: "8px 26px",
                    color: "#7653DF",
                    backgroundColor: "#EEEAFB",
                  }}
                >
                  검색
                </Button>
              </Box>
            </Grid>
            <Grid item xs={2} fontWeight="500" mb="auto">
              회사소개
            </Grid>
            <Grid item xs={10}>
              <FormInputTextArea
                style={{
                  width: "100%",
                  background: "#fff",
                  borderRadius: "8px",
                  border: "1px solid #E0E0E0",
                }}
                placeholder="내용을 입력해 주세요"
                minRows={6}
                control={control}
                name="aboutUs"
                error={"false"}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <hr style={{ margin: "40px 0 30px 0" }} />
      <Typography whiteSpace="nowrap" mb="30px">
        <b style={{ fontSize: "16px" }}>4. 채용 담당자 정보 </b>
      </Typography>
      <Grid container>
        <Grid item xs={7} pr="24px">
          <Grid container rowSpacing={1} alignItems="center">
            <Grid item xs={2} fontWeight="500">
              관리자
            </Grid>
            <Grid item xs={10}>
              <FormInputText
                control={control}
                name="managerName"
                placeholder="내용을 입력해 주세요"
                sx={{
                  width: "80%",
                }}
              />
            </Grid>
            <Grid item xs={2} fontWeight="500">
              문의전화
            </Grid>
            <Grid item xs={10}>
              <FormInputText
                control={control}
                name="managerInquiryCall"
                placeholder="내용을 입력해 주세요"
                sx={{
                  width: "80%",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid container rowSpacing={1} alignItems="center">
            <Grid item xs={2} fontWeight="500">
              이메일
            </Grid>
            <Grid item xs={10}>
              <FormInputText
                control={control}
                name="managerEmail"
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
            <Grid item xs={2} fontWeight="500">
              부서
            </Grid>
            <Grid item xs={10}>
              <FormInputText
                control={control}
                name="managerDepartment"
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <hr style={{ margin: "40px 0 30px 0" }} />
      <Typography whiteSpace="nowrap" mb="30px">
        <b style={{ fontSize: "16px" }}>5. 노출 설정 </b>
      </Typography>
      <Grid container>
        <Grid item xs={7} pr="24px">
          <Grid container rowSpacing={1} alignItems="center">
            <Grid item xs={2} fontWeight="500">
              위치 선택
            </Grid>
            <Grid item xs={10}>
              <FormInputDropdown
                control={control}
                name="jobDisplayPlace"
                sx={{
                  width: "80%",
                }}
                options={jobDisplayPlace}
              />
            </Grid>
            <Grid item xs={2} fontWeight="500">
              인사이트 키워드
            </Grid>
            <Grid item xs={10}>
              <FormInputDropdown
                control={control}
                name="jobCategoryId"
                sx={{
                  width: "80%",
                }}
                options={categories}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid container rowSpacing={1} alignItems="center">
            <Grid item xs={2} fontWeight="500">
              Hot 채용정보
            </Grid>
            <Grid item xs={10}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CustomCheckbox
                  name="isHotJobActive"
                  checked={watch("isHotJobActive")}
                  onChange={(_, selected) =>
                    setValue("isHotJobActive", selected)
                  }
                />
                <Typography sx={{ fontWeight: 500 }}>
                  Hot 채용 정보 활성
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <hr style={{ margin: "40px 0 30px 0" }} />
      <Typography whiteSpace="nowrap" mb="30px">
        <b style={{ fontSize: "16px" }}>6. 모집 요강 상세</b>
      </Typography>
      <Box
        sx={{
          border: "1px solid #E7E7E7",
          borderRadius: "5px",
          overflow: "hidden",
        }}
      >
        <FormInputCEditor
          setValue={setValue}
          control={control}
          data={
            job?.recruitmentDetail ||
            "<p>[㈜크릭앤리버엔터테인먼트는 방송스탭전문 에이전시입니다.</p><p>금번 당사에서 아래의 채용 인원을 모집하오니 많은 지원 바랍니다.]</p>"
          }
          sx={{
            ".ck-content": {
              height: "650px",
              border: "none",
              borderTop: "1px solid #E0E0E0",
            },
            ".ck-toolbar, .ck-focused": {
              border: "none",
              borderTop: "1px solid #E0E0E0",
            },
          }}
          name="recruitmentDetail"
        />
      </Box>
      <Box display="flex" alignItems="center" mt="40px" mb="20px">
        {id ? (
          <>
            <Button
              onClick={handleRecruitmentCopy}
              sx={{
                fontSize: "13px",
                width: "auto",
                height: "30px",
                mr: "6px",
                border: " 1px solid #7653DF",
                padding: "8px 26px",
                color: "#7653DF",
                backgroundColor: "#fff",
              }}
            >
              복사하기
            </Button>
            <Button
              onClick={() => deleteJobAction(id)}
              sx={{
                fontSize: "13px",
                width: "auto",
                height: "30px",
                padding: "8px 26px",
                color: "#fff",
                backgroundColor: "#7653DF",
              }}
            >
              마감하기
            </Button>
          </>
        ) : null}
        <Button
          onClick={() => navigate("/job-management/all-jobs")}
          sx={{
            fontSize: "13px",
            ml: "auto",
            mr: "6px",
            height: "30px",
            padding: "7px 26px",
            color: "#fff",
            background: "#98A6AD",
          }}
        >
          취소
        </Button>
        {isUploading ? (
          <Button>
            <Loader style={{ backgroundColor: "red" }} />
          </Button>
        ) : (
          <Button
            onClick={handleSubmit(submit)}
            sx={{
              fontSize: "13px",
              width: "auto",
              height: "30px",
              padding: "7px 46px",
              color: "#fff",
              background: "#7653DF",
            }}
          >
            저장하기
          </Button>
        )}
      </Box>
      {modal.isOpen && (
        <CustomModal
          modal={{ isOpen: modal.isOpen }}
          style={{ width: "90%", height: "90%", padding: "30px" }}
        >
          <CodeManager
            modal={modal}
            setModal={setModal}
            contentKey="occupation"
            apiUrl="occupation"
            modalKey="isOpen"
          />
        </CustomModal>
      )}
      {modal.isOpen1 && (
        <AcceptModal
          setModal={setModal}
          modal={{ ...modal, isOpen: modal.isOpen1 }}
          handleClick={deleteById}
          modalKey="isOpen1"
          title="작업을 삭제 하시겠습니까?"
        />
      )}
    </>
  );
}

export default CURecruitment;
