import React, { useRef, useState, useEffect } from "react";
import { Box, Button, Modal, Paper, Typography } from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import interactionPlugin from "@fullcalendar/interaction";
import { useNavigate } from "react-router-dom";
import AnimationPage from "../../../pages/AnimationPage";
import { copyOrders, getAllOrders } from "../../../api/order";
import { getAge } from "../../../utils/methods";

import "../style.css";

const headerContent = (e) => {
  const day = e.text.slice(0, 2);
  return (
    <div className="custom-date">
      <h1>{day}</h1>
    </div>
  );
};

const OrderStatus = () => {
  const navigate = useNavigate();
  const calendarRef = useRef();
  const [events, setEvents] = useState();
  const { token } = useSelector((state) => state.user.user);
  const [opens, setOpens] = useState({
    open: false,
    open1: false,
  });

  const handleClick = (e) => {
    navigate(`/job-management/order-status/${e.startStr}`);
  };

  const getAllEvents = async () => {
    try {
      const data = await getAllOrders(
        new Date(`02 ${calendarRef.current.getApi().view.title}`)
          .toISOString()
          .split("T")[0],
        token
      );
      const mergedData = [];
      if (data.content) {
        data.content.map((item) => {
          mergedData.push({
            id: item.id,
            start: getAge(item.displayDate),
            display: "background",
          });
          return mergedData;
        });
      }
      setEvents(mergedData);
      // setEvents(
      //   data.map((event) => ({
      //     start: event,
      //     end: event,
      //     display: "background",
      //   }))
      // );
    } catch (error) {
      console.log("error", error);
    }
  };

  const copyPrevOrders = async () => {
    try {
      const checkedData = [];
      events.map((data) => {
        return checkedData.push(data.id);
      });
      if (checkedData.length === 0) {
        toast.error("인원을 선택해 주세요!");
      } else {
        await copyOrders(checkedData, token);
        toast.success("Copied");
        setOpens({ ...opens, open: false });
        getAllEvents();
      }
    } catch (err) {
      toast.warning(err.response?.data.message);
      setOpens({ ...opens, open: false });
    }
  };

  useEffect(() => {
    getAllEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarRef]);

  return (
    <AnimationPage>
      <Box
        component={Paper}
        elevation={2}
        style={{
          boxShadow: "none",
        }}
      >
        <Box
          sx={{
            zIndex: 2,
          }}
        >
          <FullCalendar
            ref={calendarRef}
            plugins={[interactionPlugin, dayGridPlugin]}
            headerToolbar={{
              left: `prev`,
              center: `title`,
              right: "next copyButton",
            }}
            customButtons={{
              next: {
                click: () => {
                  calendarRef.current.getApi().next();
                  getAllEvents();
                },
              },
              prev: {
                click: () => {
                  calendarRef.current.getApi().prev();
                  getAllEvents();
                },
              },
              copyButton: {
                text: "전일 오더 복사",
                click: () => {
                  setOpens({ ...opens, open: true });
                },
              },
            }}
            key={events}
            initialView="dayGridMonth"
            editable={false}
            locale="en"
            firstDay={1}
            fixedWeekCount={false}
            eventBackgroundColor="#8E66FF"
            dayHeaderContent={headerContent}
            selectable={true}
            selectMirror={true}
            nowIndicator={true}
            events={events}
            eventContent={(e) => {
              const formattedDate = `${e.event.start.getFullYear()}-${(
                e.event.start.getMonth() + 1
              )
                .toString()
                .padStart(2, "0")}-${e.event.start
                .getDate()
                .toString()
                .padStart(2, "0")}`;
              return (
                <div
                  onClick={() =>
                    navigate(`/job-management/order-status/${formattedDate}`)
                  }
                >
                  {e.event.title}
                </div>
              );
            }}
            select={handleClick}
          />
        </Box>
      </Box>

      {/*first created modal*/}
      {opens.open && (
        <Modal open={opens.open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              padding: "84px 85px 60px",
              bgcolor: "#fff",
              borderRadius: "10px",
              border: "none",
              outline: "none",
            }}
          >
            <Box display="flex" justifyContent="center" flexDirection="column">
              <Typography
                sx={{
                  margin: "10px auto 80px auto",
                  fontSize: "18px",
                  fontWeight: 500,
                }}
              >
                오늘자 오더 현황을 생성하시겠습니까?
              </Typography>

              <Box display="flex" justifyContent="space-between" width="100%">
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => setOpens({ ...opens, open: false })}
                  sx={{
                    border: "1px solid #E9EDF9",
                    padding: "9px 80px",
                    background: "none",
                    color: "#212B36",
                    fontSize: "16px",
                    fontWeight: 500,
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#c5c5c5",
                      boxShadow: "none",
                    },
                  }}
                >
                  취소
                </Button>
                <Button
                  onClick={copyPrevOrders}
                  variant="contained"
                  size="large"
                  sx={{
                    ml: "20px",
                    padding: "9px 80px",
                    background: "#8E66FF",
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: 500,
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#c5c5c5",
                      boxShadow: "none",
                    },
                  }}
                >
                  확인
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </AnimationPage>
  );
};

export default OrderStatus;
