import * as XLSX from "xlsx";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export function getAge(bd) {
  if (bd && typeof bd === "string") {
    const today = new Date();
    const getBirthYear = parseInt(bd.substring(0, 4));
    const getBirthMonth = parseInt(bd.substring(4, 6));
    const getBirthDay = parseInt(bd.substring(6));
    var calAge = today.getFullYear() - getBirthYear;
    const calAgeMonth = today.getMonth() + 1 - getBirthMonth;
    const calAgeDay = today.getDate() - getBirthDay;
    if (calAgeMonth < 0 || (calAgeMonth === 0 && calAgeDay < 0)) {
      calAge = calAge - 1;
    }
    const formattedMonth =
      getBirthMonth < 10 ? `0${getBirthMonth}` : getBirthMonth;
    const formattedDay = getBirthDay < 10 ? `0${getBirthDay}` : getBirthDay;
    return `${calAge}-${formattedMonth}-${formattedDay}`;
  } else if (bd && Array.isArray(bd) && bd.length === 3) {
    const formattedMonth = bd[1] < 10 ? `0${bd[1]}` : bd[1];
    const formattedDay = bd[2] < 10 ? `0${bd[2]}` : bd[2];
    return `${bd[0]}-${formattedMonth}-${formattedDay}`;
  } else {
    return bd;
  }
}

export function exportDate(date) {
  if (date && typeof date === "string") {
    return date;
  } else if (date && Array.isArray(date) && date.length === 3) {
    const formattedMonth = date[1] < 10 ? `0${date[1]}` : date[1];
    const formattedDay = date[2] < 10 ? `0${date[2]}` : date[2];
    return `${date[0]}-${formattedMonth}-${formattedDay}`;
  }
}

export function formatLocaleDate(bd) {
  if (bd) {
    const date = new Date(bd);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
    return `${year}-${formattedMonth}-${formattedDay}`;
  } else {
    return bd;
  }
}

export function formatDateDot(bd) {
  if (bd && typeof bd === "string") {
    const today = new Date();
    const getBirthYear = parseInt(bd.substring(0, 4));
    const getBirthMonth = parseInt(bd.substring(4, 6));
    const getBirthDay = parseInt(bd.substring(6));
    var calAge = today.getFullYear() - getBirthYear;
    const calAgeMonth = today.getMonth() + 1 - getBirthMonth;
    const calAgeDay = today.getDate() - getBirthDay;
    if (calAgeMonth < 0 || (calAgeMonth === 0 && calAgeDay < 0)) {
      calAge = calAge - 1;
    }
    return calAge;
  } else if (bd && typeof bd === "object") {
    return `${bd[0] + "." + bd[1] + "." + bd[2]}`;
  } else {
    return bd;
  }
}

export function getBirthYear(bd) {
  if (bd && typeof bd === "string") {
    const getBirthYear = parseInt(bd.substring(0, 4));
    return getBirthYear;
  } else if (bd && typeof bd === "object") {
    return `${bd[0]}`;
  } else {
    return bd;
  }
}

export function addZero(num) {
  if (num) {
    return num < 10 ? `0${num}` : num;
  }
}
///////this date converter funtion convert MUI DatePicker data object to "dd/mm/yyyy" format
export const dateConverter = (selected) => {
  if (selected && !isNaN(selected) && selected.$d) {
    const date = selected.$d.toLocaleDateString("en-US").split("/");
    return `${date[2]}-${date[0].length === 1 ? "0" + date[0] : date[0]}-${
      date[1].length === 1 ? "0" + date[1] : date[1]
    }`;
  }
  return selected;
};

export function getTomorrow(date = new Date()) {
  const tomorrow = new Date(date.getTime());
  tomorrow.setDate(date.getDate() + 1);
  return tomorrow;
}

export function getPreviousDay(date = new Date()) {
  const previous = new Date(date.getTime());
  previous.setDate(date.getDate() - 1);
  return previous;
}

export const handleRoute = (route) => {
  var parts = route.split("/");
  const id = parts[parts.length - 1];
  const slug = parts[parts.length - 2];
  switch (route) {
    case "/talent/all-user":
      return (
        <>
          인재관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          구직자
        </>
      );
    case "/talent/number-contacts":
      return (
        <>
          인재관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          콘택트 인원
        </>
      );
    case "/talent/employee-waiting-list":
      return (
        <>
          인재관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          사원 대기자
        </>
      );
    case "/talent/employee":
      return (
        <>
          인재관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          사원
        </>
      );
    case "/talent/waiting-list":
      return (
        <>
          인재관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          퇴사 대기자
        </>
      );
    case `/send-to-pending/${id}`:
      return (
        <>
          인재관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          구직자{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          회원정보{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          사원 대기자로 보내기
        </>
      );
    case "/talent/deceased":
      return (
        <>
          인재관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          퇴사자
        </>
      );
    case "/job-management/order-status":
      return (
        <>
          채용 정보 관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          오더 현황
        </>
      );
    case `/job-management/order-status/${id}`:
      return (
        <>
          채용 정보 관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          오더 현황
        </>
      );
    case "/job-management/all-jobs":
      return (
        <>
          채용 정보 관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          전체 채용 정보
        </>
      );
    case "/job-management/reqirement-create":
      return (
        <>
          채용 정보 관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          채용 등록
        </>
      );
    case "/job-management/set-up-career":
      return (
        <>
          채용 정보 관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          커리어 인사이트 설정
        </>
      );
    case `/user-details/${id}`:
      return (
        <>
          인재관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          구직자{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          회원 정보
        </>
      );
    case `/user-create`:
      return (
        <>
          내부 관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          사원 관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          사원 임의 등록
        </>
      );
    case `/inter-create`:
      return (
        <>
          인재관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          구직자{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          회원가입
        </>
      );
    case `/talent/number-contacts/${id}`:
      return (
        <>
          인재관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          콘택트 인원{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          콘택트 인원 정보
        </>
      );
    case `/talent/employee-waiting-list/${id}`:
      return (
        <>
          인재관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          사원 대기자{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          사원 대기자 인원 정보
        </>
      );
    case `/talent/employee/${id}`:
      return (
        <>
          인재관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          사원{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          사원 인원 정보
        </>
      );
    case `/talent/waiting-list/${id}`:
      return (
        <>
          인재관리
          <NavigateNextIcon
            fontSize="small"
            sx={{ ml: "10px", mr: "8px" }}
          />{" "}
          퇴사 대기자
          <NavigateNextIcon
            fontSize="small"
            sx={{ ml: "10px", mr: "8px" }}
          />{" "}
          퇴사 대기자 인원 정보
        </>
      );
    case `/talent/deceased/${id}`:
      return (
        <>
          인재관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          퇴사자{" "}
          {/* <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          퇴사 대기자 인원 정보 */}
        </>
      );
    case "/talent/certificate-applicant":
      return (
        <>
          인재관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          제증명 신청자
        </>
      );
    case `/job-management/reqirement-update/${id}`:
      return (
        <>
          채용 정보 관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          전체 채용 정보{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />
          채용 정보 상세
        </>
      );
    case "/account-management/business-management":
      return (
        <>
          거래처 관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          업체 관리
        </>
      );
    case `/account-management/business-management/${id}`:
      return (
        <>
          거래처 관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          업체 관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />
          업체 상세
        </>
      );
    case "/account-management/business-manager":
      return (
        <>
          거래처 관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          파견 사업 관리 대장
        </>
      );
    case `/account-management/business-manager/${id}`:
      return (
        <>
          거래처 관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          파견 사업 관리 대장{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />
          파견 사업 관리 대장 상세
        </>
      );
    case "/account-management/business-management-ledger":
      return (
        <>
          거래처 관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          사용 사업 관리 대장
        </>
      );
    case `/account-management/business-management-ledger/${id}`:
      return (
        <>
          거래처 관리{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          사용 사업 관리 대장{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />
          사용 사업 관리 대장 상세
        </>
      );
    case "/admin-role/employee-list":
      return (
        <>
          Employee list{" "}
          <NavigateNextIcon fontSize="small" sx={{ ml: "10px", mr: "8px" }} />{" "}
          List
        </>
      );
    case "/post-notice/notification":
      return (
        <>
          게시/공지관리
          <NavigateNextIcon fontSize="small" />
          공지
        </>
      );
    case `/notification-details/${id}`:
      return (
        <>
          게시/공지관리
          <NavigateNextIcon fontSize="small" />
          공지
        </>
      );
    case `/post-notice/banner`:
      return (
        <>
          게시/공지관리
          <NavigateNextIcon fontSize="small" />
          배너
        </>
      );
    case `/banner-details/${id}`:
      return (
        <>
          게시/공지관리
          <NavigateNextIcon fontSize="small" />
          배너
        </>
      );
    case `/post-notice/popup`:
      return (
        <>
          게시/공지관리
          <NavigateNextIcon fontSize="small" />
          팝업
        </>
      );
    case `/popup-details/${id}`:
      return (
        <>
          게시/공지관리
          <NavigateNextIcon fontSize="small" />
          팝업
          <NavigateNextIcon fontSize="small" />
          팝업 상세
        </>
      );
    case `/sms-sent`:
      return "SMS 보내기";
    case `/sms-list`:
      return "SMS 보내기";
    case `/account-management/hh-request`:
      return (
        <>
          거래처 관리
          <NavigateNextIcon fontSize="small" />
          헤드헌팅 의뢰
        </>
      );
    case `/account-management/hh-request/${id}`:
      return (
        <>
          거래처 관리
          <NavigateNextIcon fontSize="small" />
          헤드헌팅 의뢰
        </>
      );
    case `/job-management/support-status/${id}`:
      return (
        <>
          채용 정보 관리
          <NavigateNextIcon fontSize="small" />
          전체 채용 정보
        </>
      );
    case `/payroll-management`:
      return (
        <>
          급여 관리
          <NavigateNextIcon fontSize="small" />
          사원 급여
        </>
      );
    case `/payroll-management/${id}`:
      return (
        <>
          급여 관리
          <NavigateNextIcon fontSize="small" />
          사원 급여
          <NavigateNextIcon fontSize="small" />
          월별 내역
        </>
      );
    case `/payroll-applicant/${slug}/${id}`:
      return (
        <>
          급여 관리
          <NavigateNextIcon fontSize="small" />
          사원 급여
          <NavigateNextIcon fontSize="small" />
          월별 내역
          <NavigateNextIcon fontSize="small" />
          업체별 내역
        </>
      );
    case `/job-management/order-create/${slug}`:
      return <>오더 현황</>;
    case `/job-management/order-details/${id}`:
      return <>오더 현황</>;
    case `/payroll/search`:
      return (
        <>
          급여 관리
          <NavigateNextIcon fontSize="small" />
          사원 급여
          <NavigateNextIcon fontSize="small" />
          사원 검색
        </>
      );
    case `/admin-role/statistic`:
      return <>대시보드</>;
    case "/home":
      return <></>;
    default:
      return <>대시보드</>;
  }
};

export const handleSection = (section) => {
  var parts = section.split("/");
  const id = parts[parts.length - 1];
  const slug = parts[parts.length - 2];
  switch (section) {
    case "/talent/all-user":
      return "전체회원리스트";
    case `/user-create`:
      return "회원가입";
    case "/talent/number-contacts":
      return "콘택트 인원";
    case "/talent/employee-waiting-list":
      return "사원 대기자";
    case `/talent/employee-waiting-list/${id}`:
      return "사원 대기자";
    case `/send-to-pending/${id}`:
      return "사원 대기자로 보내기";
    case "/talent/employee":
      return "사원";
    case `/talent/employee/${id}`:
      return "사원";
    case "/talent/waiting-list":
      return "퇴사 대기자";
    case `/talent/waiting-list/${id}`:
      return "퇴사 대기자";
    case "/talent/deceased":
      return "퇴사자";
    case `/talent/deceased/${id}`:
      return "퇴사자";
    case "/job-management/order-status":
      return "오더 현황";
    case `/job-management/order-status/${id}`:
      return "오더 현황";
    case "/job-management/all-jobs":
      return "전체 채용 정보";
    case "/job-management/reqirement-create":
      return "채용 등록";
    case `/job-management/reqirement-update/${id}`:
      return "전체 채용 정보";
    case "/job-management/set-up-career":
      return "커리어 인사이트 설정";
    case `/user-details/${id}`:
      return "구직자";
    case `/talent/number-contacts/${id}`:
      return "콘택트 인원";
    case "/talent/certificate-applicant":
      return "제증명 신청자";
    case "/account-management/business-management":
      return "업체 관리";
    case `/account-management/business-management/${id}`:
      return "업체 관리";
    case "/account-management/business-manager":
      return "파견 사업 관리 대장";
    case `/account-management/business-manager/${id}`:
      return "파견 사업 관리 대장";
    case "/account-management/business-management-ledger":
      return "사용 사업 관리 대장";
    case `/account-management/business-management-ledger/${id}`:
      return "사용 사업 관리 대장 ";
    case "/admin-role/employee-list":
      return "관리자 관리";
    case `/employee-details/${id}`:
      return "관리자 관리";
    case `/post-notice/notification`:
      return "공지";
    case `/notification-details/${id}`:
      return "공지";
    case `/post-notice/banner`:
      return "배너";
    case `/banner-details/${id}`:
      return "배너";
    case `/post-notice/popup`:
      return "팝업";
    case `/popup-details/${id}`:
      return "팝업";
    case `/sms-sent`:
      return "SMS 보내기";
    case `/sms-list`:
      return "SMS 보내기";
    case `/account-management/hh-request`:
      return "헤드헌팅 의뢰";
    case `/account-management/hh-request/${id}`:
      return "헤드헌팅 의뢰";
    case `/job-management/support-status/${id}`:
      return "전체 채용 정보";
    case `/payroll-management`:
      return "사원 급여";
    case `/payroll-management/${id}`:
      return "사원 급여";
    case `/payroll-applicant/${slug}/${id}`:
      return "사원 급여";
    case `/job-management/order-details/${id}`:
      return "오더 현황";
    case `/job-management/order-create/${slug}`:
      return "오더 현황";
    case `/payroll/search`:
      return "사원 급여";
    case `/admin-role/statistic`:
      return "통계";
    default:
      return "대시보드";
  }
};

////this date formatter will convert any type of date to "dd/MM/yyyy"
export const dateFormatter = (date) => {
  return new Date(date).toISOString().replace(/T.*$/, "");
};

export const getDateRange = (days) => {
  const today = new Date();
  const date = new Date().setDate(new Date().getDate() + days);
  return { from: dateFormatter(today), to: dateFormatter(date) };
};

/////this debounce function for call functions after user stoped typing to input
const tasks = {};

export function debounce(callBack, nameTask, duration = 1000) {
  // get task
  let task = tasks[nameTask];

  // remove timeout if it has
  task && clearTimeout(task);

  // set new task or update
  tasks[nameTask] = setTimeout(callBack, duration);
}

////this function for copy contents to clipboard which is inside of the element have this id
export const copyContent = async (id) => {
  let text = document.getElementById(id).innerHTML;
  try {
    await navigator.clipboard.writeText(text);
    alert("Copied! " + text);
  } catch (err) {
    alert("Failed to copy: ", err);
  }
};

export const checkJobDedline = (data) => {
  const today = new Date();
  const dedline = new Date(`${data.dedline}T23:59`);
  if (data.always) {
    return "진행중";
  }
  if (dedline < today) {
    return <font color="#2D9CDB">마감</font>;
  }
  return "진행중";
};

//english to korean translate fields
const fieldTranslations = {
  address: "거주지",
  addressDetails: "상세 주소",
  addressDetail: "상세 주소",
  applicantId: "신청자ID",
  contactPath: "접촉경로",
  email: "이메일",
  workerSalary: "근로자 급여" ,// "급여 은행",
  id: "아이디",
  name: "성명",
  gender: "성별",
  employeeNumber: "사원 번호",
  employmentType: "고용 유형",
  reason: "사유",
  status: "상태",
  eduLevel: "학력",
  manager: "관리자",
  phone: "휴대폰",
  memo: "메모",
  location: "거주지",
  school: "학력",
  phoneNumber: "휴대폰",
  jobRequestCount: "작업 요청 횟수",
  desiredOccupation: "희만하는 작업",
  religion: "종교",
  username: "유서 네임",
  statusOfHealth: "건강 상태",
  contactPeriod: "연락 기간",
  pentionApplication: "연급 신청",
  healthInsurance: "건강 보험",
  employmentInsurance: "고용 보험",
  accidentlnsurance: "사고보험",
  bankSalary: "급여은행",
  accountNumber: "계좌 번호",
  employee: "사원",
  // personnCharge: "개인 요금",
  reasonComment: "사유댓글",
  departmenId: "근무부서",
  customerlndustry: "거래처 업종 관리",
  contractPeriod: "계약 기간",
  detailedTasks: "세부 수행 업무",
  pensionApplication: "연급 신청",
  accidentInsurance: "사고보험",
  personInCharge: "개인 요금",
  customerIndustry: "거래처 업종 관리",
  type: "종류",
  fullName: "성명",
  registrationNumber: "주민번호",
  certificateNumber: "증명 번호",
  certificateUsage: "사용 용도",
  job: "직업",
  position: "위치",
  title: "모집 부문",
  workingDepartment: "근무부서",
  workingArea: "근무 지역",
  salaryConditions: "급여 조건",
  eduHistoryFrom: "학력 사항 부터",
  eduHistoryTo: "학력 사항 까지",
  career: "경력",
  numberOfPeople: "모집 인원",
  workingTime: "근무 시간",
  alwaysOpen: "모집 기간 상시",
  periodFrom: "기간 부터",
  periodTo: "기간 까지",
  submission: "제출",
  activation: "활성화",
  companyIndustry: "회사 업종",
  homePage: "홈페이지",
  businessAddress: "업체 주소",
  aboutUs: "회사소개",
  managerName: "관리자명",
  managerEmail: "관리자 이메일",
  managerInquiryCall: "문의 전화",
  managerDepartment: "관리자 부서",
  jobDisplayPlace: "채용 공고 위치",
  jobCategoryId: "인사이트 키워드",
  isHotJobActive: "Hot 채용 정보",
  recruitmentDetail: "모집 요강 상세",
  jobCategoryTitle: "인사이트 키워드 타이틀",
  
};

////////this function export data to excel file
export const exportToExcel = (data, name) => {
  console.log('<--------------- excel ---------------> ');
  console.log('excel data -------> ' , JSON.stringify(data));
  console.log('excel name -------> ' , JSON.stringify(name));
  // Translate field names
  const translatedData = data.map((item) => {
    const translatedItem = {};

    Object.keys(item).forEach((key) => {
      if (key === "social1" && item["social2"]) {
        const combinedSocial = `${item["social1"]}-${item["social2"]}`;
        translatedItem["주민번호"] = combinedSocial;
      } else if (key !== "social2") {
        // Exclude social2 from the export
        const translatedKey = fieldTranslations[key] || key;
        translatedItem[translatedKey] = item[key];
      }
    });

    if (item.birthday) {
      translatedItem["출생년도"] = exportDate(item.birthday);
    }
    if (item.joiningDate) {
      translatedItem["입사일"] = exportDate(item.joiningDate);
    }
    if (item.applicationDate) {
      translatedItem["신청일"] = exportDate(item.applicationDate);
    }
    if (item.submissionDate) {
      translatedItem["제출 일"] = exportDate(item.submissionDate);
    }
    if (item.joinedDate) {
      translatedItem["신청일자"] = exportDate(item.joinedDate);
    }
    if (item.insuranceDate) {
      translatedItem["처리일자"] = exportDate(item.insuranceDate);
    }
    if (item.quitDate) {
      translatedItem["퇴사 일"] = exportDate(item.quitDate);
    }
    if (item.createdDate) {
      translatedItem["생성일"] = exportDate(item.createdDate);
    }
    if (item.updatedDate) {
      translatedItem["업데이트 일"] = exportDate(item.updatedDate);
    }
    if (item.contactDate) {
      translatedItem["연락날짜"] = exportDate(item.contactDate);
    }
    if (item.dateFrom) {
      translatedItem["계약 기간 부터"] = exportDate(item.dateFrom);
    }
    if (item.dateUntil) {
      translatedItem["계약 기간 까지"] = exportDate(item.dateUntil);
    }
    if (item.registration) {
      translatedItem["등록 일"] = exportDate(item.registration);
    }
    if (item.companyName) {
      delete item.customerName;
      translatedItem["업체명"] = item.companyName;
    } else {
      delete item.companyName;
      translatedItem["업체명"] = item.customerName;
    }
    if (item.subCustomer) {
      translatedItem["사업장명"] = item.subCustomer.name;
    }
    if (item.occupation) {
      delete item.occupationName;
      translatedItem["직무명"] = item.occupation.name;
    }
    if (item.dispatchCode) {
      translatedItem["파견명"] = item.dispatchCode.name;
    }
    if (item.department) {
      translatedItem["부서명"] = item.department.name;
    }
    if (item.managementDepartment) {
      translatedItem["관리부서명"] = item.managementDepartment.name;
    }
    if(item.employee)
      translatedItem['사원'] = item.employee === true ? "예" : "아니요"
    // if(item.status) {
    //   translatedItem['상태'] = item.healthInsurance === true ? "예" : "아니요"
    // }
    
    translatedItem['건강 보험'] = item.healthInsurance === true ? "예" : "아니요"
    translatedItem['연락 기간'] = item.contactPeriod === true ? "예" : "아니요"
    translatedItem['고용 보험'] = item.employmentInsurance === true ? "예" : "아니요"
    translatedItem['고용 보험'] = item.employmentInsurance === true ? "예" : "아니요"
    translatedItem['계약 기간'] = item.contractPeriod  === true ? "예" : "아니요"
    

    delete translatedItem.department;
    delete translatedItem.dispatchCode;
    delete translatedItem.occupation;
    delete translatedItem.occupationCode;
    delete translatedItem.managementDepartment;
    delete translatedItem.managementDepartmentId;
    delete translatedItem.managementDepartmentld;
    delete translatedItem.social1;
    delete translatedItem.social2;
    delete translatedItem.birthday;

    delete translatedItem.joiningDate;
    delete translatedItem.applicationDate;
    delete translatedItem.submissionDate;
    delete translatedItem.joinedDate;
    delete translatedItem.insuranceDate;
    delete translatedItem.quitDate;
    delete translatedItem.createdDate;
    delete translatedItem.updatedDate;
    delete translatedItem.contactDate;
    delete translatedItem.dateFrom;
    delete translatedItem.dateUntil;
    delete translatedItem.registration;

    delete translatedItem.companyName;
    delete translatedItem.customerName;
    delete translatedItem.subCustomer;
    delete translatedItem.subCustomerId;
    delete translatedItem.customer;
    delete translatedItem.customerld;
    delete translatedItem.customerId;

    //delete must needed
    delete translatedItem.isChecked;
    delete translatedItem.profilePicture;
    delete translatedItem.ageFrom;
    delete translatedItem.ageTo;
    delete translatedItem.activation;
    delete translatedItem.always;
    delete translatedItem.submission;
    delete translatedItem.logo;
    delete translatedItem.thumbnail;
    delete translatedItem.attachmentPath;
    delete translatedItem.dispatchCodeId;
    delete translatedItem.occupationId;
    delete translatedItem.departmentId;

    return translatedItem;
  });

  // Auto-fit column widths
  const colWidths = [];
  Object.keys(translatedData[0]).forEach((key) => {
    colWidths.push({ wch: key?.length + 6 });
  });
  Object.values(translatedData[0]).forEach((key) => {
    console.log(key);
  });
  const ws = XLSX.utils.json_to_sheet(translatedData, {
    header: [
      "성명",
      "주민번호",
      "사원 번호",
      "휴대폰",
      "이메일",
      "업체명",
      "사업장명",
      "근무부서",
      "직무명",
      "세부 수행 업무",
      "파견명",
      "관리자",
      "관리부서명",
      // "사원 번호",
      "입사일",
      "계약 기간 부터",
      "계약 기간 까지",
      "근로자 급여", // "급여 은행",
      "계좌 번호",
      // "급여 은행",
      "거주지",
      "상세 주소",
    ],
  });
  ws["!cols"] = colWidths;

  const wb = XLSX.utils.book_new();
  const sheetName = "Sheet1"; // Replace with your desired sheet name

  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  XLSX.writeFile(wb, `${name}.xlsx`);
};

export const exportDataToExcel = async (data, name) => {
  exportToExcel(data, name);
};

export const formatArrayDate = (value) => {
  if (typeof value === "string") {
    return value;
  } else if (typeof value === "object" && value) {
    const year = value[0];
    const month = value[1];
    const date = value[2];
    const newStartDate = `${year || ""}-${month || ""}-${date || ""}`;
    return newStartDate;
  } else if (value === null) {
    return value;
  }
};

export const awsImagePath =
  // "https://mediadbglobaldev.s3.ap-northeast-2.amazonaws.com";
  "https://creeknriver-mediadbglobaldev.s3.ap-northeast-2.amazonaws.com";

export const formatJobStatus = (value) => {
  switch (value) {
    case "ACTIVE":
      return "진행중";
    case `EXPIRED`:
      return "마감";
    default:
      return "";
  }
};

export const dateFormatterWithTime = (inputDate) => {
  const date = new Date(inputDate);

  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "pm" : "am";

  return `${month}/${day} ${hours % 12 || 12}:${minutes}:${seconds} ${ampm}`;
};

export const formatKoreanPhoneNumber = (phoneNumber) => {
  const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

  if (/^010\d{7,8}$/.test(numericPhoneNumber)) {
    // Format the mobile number as "010-xxxx-xxxx"
    return numericPhoneNumber.replace(/(\d{3})(\d{3,4})(\d{4})/, "010-$2-$3");
  } else if (/^02\d{7,8}$/.test(numericPhoneNumber)) {
    // Format the landline number as "02-xxxx-xxxx"
    return numericPhoneNumber.replace(/(\d{2})(\d{3,4})(\d{4})/, "02-$2-$3");
  } else {
    return phoneNumber;
  }
};

export const salaryTableData = {
  first: [
    {
      item: "기본급",
      tax: "과세",
      rate: 209,
      note: "시급*월소정근로시간(209h or 226h)",
      fee: "",
      name: "mainSalary",
    },
    {
      item: "시간외수당",
      tax: "과세",
      rate: 48,
      note: "시급*시간외근로시간*1.5배",
      fee: "",
      name: "overtimePay",
    },
    {
      item: "연차수당",
      tax: "과세",
      rate: 10,
      note: "시급*연간15일*8h/12개월",
      fee: "",
      name: "annualLeaveAllowance",
    },
    {
      item: "식대1",
      tax: "비과세",
      name: "mealCostOne",
      rate: "",
      note: "월 최대 10만원까지 비과세",
      fee: "",
    },
    {
      item: "식대2",
      tax: "과세",
      name: "mealCostTwo",
      rate: "",
      note: "월 최대 10만원초과금액 과세",
      fee: "",
    },
    {
      item: "차량유지비(교통비)1",
      tax: "비과세",
      name: "transportationCostOne",
      rate: "",
      note: "월 최대 20만원까지 비과세",
      fee: "",
    },
    {
      item: "차량유지비(교통비)2",
      tax: "과세",
      name: "transportationCostTwo",
      rate: "",
      note: "월 최대 20만원초과금액 과세",
      fee: "",
    },
    {
      item: "기타수당1",
      tax: "과세",
      name: "otherAllowanceOne",
      rate: "",
      note: "개별입력(KBS 제수당)",
      fee: "",
    },
    {
      item: "기타수당2",
      tax: "과세",
      name: "otherAllowanceTwo",
      rate: "",
      note: "개별입력",
      fee: "",
    },
    {
      item: "휴일수당",
      tax: "과세",
      name: "holidayAllowance",
      rate: "",
      note: "개별입력",
      fee: "",
    },
    {
      item: "O/T수당",
      tax: "과세",
      name: "otAllowance",
      rate: "",
      note: "개별입력",
      fee: "",
    },
    {
      item: "특근수당",
      tax: "과세",
      name: "overTimeAllowanceOne",
      rate: "",
      note: "개별입력",
      fee: "",
    },
    {
      item: "대근수당",
      tax: "과세",
      name: "overTimeAllowanceTwo",
      rate: "",
      note: "개별입력",
      fee: "",
    },
    {
      item: "통신비/휴대폰보조금",
      name: "phoneExpenses",
      tax: "과세",
      rate: "",
      note: "개별입력",
      fee: "",
    },
    {
      item: "출연료",
      tax: "과세",
      name: "appearanceFee",
      rate: "",
      note: "개별입력",
      fee: "",
    },
    {
      item: "라이브수당",
      tax: "과세",
      name: "liveAllowance",
      rate: "",
      note: "개별입력",
      fee: "",
    },
    {
      item: "육아보육수당",
      name: "childCareAllowance",
      tax: "비과세",
      rate: "",
      note: "월 최대 10만원까지 비과세",
      fee: "",
    },
    {
      item: "연장근로수당",
      tax: "과세",
      name: "overTimePayTwo",
      rate: "",
      note: "개별입력",
      fee: "",
    },
    {
      item: "상여금",
      tax: "과세",
      rate: "",
      name: "bonus",
      note: "개별입력",
      fee: "",
    },
    {
      item: "인센티브",
      tax: "과세",
      rate: "",
      name: "incentive",
      note: "개별입력",
      fee: "",
    },
    {
      item: "급여조정금",
      name: "salaryAdjustmentFee",
      tax: "과세",
      rate: "",
      note: "수식설정(개인급여를 100원단위로 맞추기위한 조정금)",
      fee: "",
    },
  ],
  second: [
    {
      item: "퇴직적립금",
      tax: "",
      name: "retirementSavings",
      rate: 1,
      note: "(개인급여소계/12개월) 적용:1,미적용:0",
      fee: "",
    },
    {
      item: "퇴직적립금조정",
      tax: "",
      name: "retirementSavingsAdjustment",
      rate: "",
      note: "MBC,KBS등 식대 별도로 청구하는경우 퇴직적립금 금액 조정",
      fee: "",
    },
    {
      item: "연차수당(연말)",
      tax: "",
      name: "annualLeaveAllowanceYear",
      rate: "",
      note: "시급*연간15일*8h/12개월",
      fee: "",
    },
  ],
  third: [
    {
      item: "국민연금",
      tax: "",
      name: "nationalPension",
      rate: "4.50%",
      note: "개인급여(과세)의 4.50%, 1원단위 절사",
      fee: "",
    },
    {
      item: "건강보험",
      tax: "",
      name: "healthInsurance",
      rate: "3.23%",
      note: "개인급여(과세)의 3.23%, 1원단위 절사",
      fee: "",
    },
    {
      item: "노인장기요양보험",
      tax: "",
      name: "elderlyCareInsurance",
      rate: "8.51%",
      note: "건강보험료의 8.51%, 1원단위 절사",
      fee: "",
    },
    {
      item: "고용보험",
      tax: "",
      name: "employmentInsurance",
      rate: "1.50%",
      note: "과세급여의 1.50%",
      fee: "",
    },
    {
      item: "산재보험",
      tax: "",
      name: "industrialAccidentInsurance",
      rate: "1.00%",
      note: "과세급여의 1.00%",
      fee: "",
    },
    {
      item: "임금채권분담금",
      tax: "",
      name: "wageClaimContribution",
      rate: "0.063%",
      note: "과세급여의 0.063%",
      fee: "",
    },
    {
      item: "장애인고용분담금",
      tax: "",
      name: "contributionForDisabled",
      rate: "53,809",
      note: "2019년고시 기초부담금 1,048,000",
      fee: "",
    },
    {
      item: "사업소세",
      tax: "",
      name: "businessTax",
      rate: "0.50%",
      note: "개인급여(과세+비과세)의 0.5%",
      fee: "",
    },
    {
      item: "채용검진",
      tax: "",
      name: "recruitmentCheckUp",
      rate: "",
      note: "연간 25,000원/12개월 = 2,083원(미실시)",
      fee: "",
    },
    {
      item: "단체보험",
      name: "groupInsurance",
      tax: "",
      rate: "",
      note: "1인당 단체보험 평균 가입금액 2,000원(미가입)",
      fee: "",
    },
    {
      item: "복리후생비",
      name: "employeeBenefits",
      tax: "",
      rate: "10,000",
      note: "월간 1만원(명절선물/경조금/해외연수/기타이벤트 등)",
      fee: "",
    },
  ],
  fourth: [
    {
      item: "일반관리비",
      tax: "",
      name: "generalMaintenanceFee",
      rate: "20,000",
      note: "리크루팅/교육훈련비 등 월 20,000원",
      fee: "",
    },
    {
      item: "에이전트수수료",
      name: "agentFee",
      tax: "",
      rate: "",
      note: "에이전트 제반행정비용",
      fee: "",
    },
    {
      item: "일반관리비조정",
      name: "managementCostAdjustment",
      tax: "",
      rate: "",
      note: "",
      fee: "",
    },
    {
      item: "에이전트수수료조정",
      name: "agentFeeAdjustment",
      tax: "",
      rate: "",
      note: "",
      fee: "",
    },
    {
      item: "법정복리비조정",
      name: "legalWelfareCost",
      tax: "",
      rate: "",
      note: "",
      fee: "",
    },
  ],
};
