import React from "react";
import FormInputCEditor from "../../../components/form-components/FormInputCEditor";

export default function Tab5({ control, setValue, data }) {
  return (
    <FormInputCEditor
      setValue={setValue}
      control={control}
      data={data?.content}
      sx={{
        ".ck-content": {
          height: "500px",
          border: "none",
          borderTop: "1px solid #E0E0E0",
        },
        ".ck-toolbar, .ck-focused": {
          border: "none",
          borderTop: "1px solid #E0E0E0",
        },
      }}
      name="content"
    />
  );
}
