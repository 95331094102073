import React from "react";
import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  TableHead,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import { FormInputText } from "../../components/form-components/FormInputText";
import CustomCheckbox from "../../components/CustomCheckbox";
import { getPersonnelList, getUserConcults } from "../../api/user";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { order, pageSize, searchTypeContact } from "../../constants/constants";
import {
  exportDataToExcel,
  formatKoreanPhoneNumber,
} from "../../utils/methods";
import SmsSendModal from "../../components/modals/SmsSendModal";
import { toast } from "react-toastify";
import DataNotFound from "../../components/DataNotFound";
import Loader from "../../components/loader";

const initialQuery = {
  number: 1,
  size: 20,
  searchType: "",
  sort: "createdDate",
  order: "DESC",
  keyword: "",
};

function NumberOfContacts() {
  const { token } = useSelector((state) => state.user.user);
  const { handleSubmit, control, reset } = useForm({
    defaultValues: initialQuery,
  });
  const [query, setQuery] = useState(initialQuery);
  const [users, setUsers] = useState();
  const [usersData, setUsersData] = useState([]);
  const [smsModal, setSmsModal] = useState({ isOpen: false });
  const [isNoData, setIsNoData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePage = (_, value) => {
    setQuery({ ...query, number: value });
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    var tempUser = {};
    if (name === "allSelect") {
      tempUser = usersData?.map((user) => {
        return { ...user, isChecked: checked };
      });
    } else {
      tempUser = usersData?.map((user) =>
        user.name + user.id === name ? { ...user, isChecked: checked } : user
      );
    }
    setUsersData(tempUser);
  };

  const donwloadExcel = () => {
    const checkedData = [];
    usersData?.map((data) => {
      if (data.isChecked) {
        checkedData.push(data);
      }
      return data.isChecked;
    });

    if (checkedData.length === 0) {
      toast.warning("인원을 선택해 주세요!");
    } else {
      exportDataToExcel(checkedData, "콘택트 인원");
    }
  };

  async function userList() {
    try {
      const { data } = await getPersonnelList(query, token);
      if (data.totalElements > 0) {
        setIsNoData(false);
        setUsers(data);
      } else {
        setIsNoData(true);
        setUsers(data);
      }
    } catch (error) {
      console.log(error.response?.data.message);
    }
  }

  const submit = async (values) => {
    setQuery({ ...query, ...values });
  };

  const setInitial = () => {
    reset(initialQuery);
    setQuery(initialQuery);
  };

  const fetchMemo = async (userData) => {
    try {
      const { data } = await getUserConcults(
        `personnelConsultations/getByPersonnelId`,
        userData.id,
        token
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!users) return;

      const mapUserData = async (userData) => {
        const memo = await fetchMemo(userData);

        const lastMemoIndex = memo ? memo.length - 1 : -1;
        return {
          ...userData,
          memo: lastMemoIndex >= 0 ? memo[0].contents : "",
        };
      };

      try {
        setIsLoading(true);
        const promises =
          users.content?.map(mapUserData) || users?.map(mapUserData);
        const data = await Promise.all(promises);
        setUsersData(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching memo data:", error);
      }
    };

    fetchData();
  }, [users]);

  useEffect(() => {
    userList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const smsSend = () => {
    const checkedData = [];
    usersData?.map((data) => {
      if (data.isChecked) {
        checkedData.push(data);
      }
      return data.isChecked;
    });

    if (checkedData.length === 0) {
      toast.warning("인원을 선택해 주세요!");
    } else {
      setSmsModal({ isOpen: true, checkedUsers: checkedData });
    }
  };

  return (
    <Paper
      sx={{
        width: "100%",
        boxShadow: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          mb: "12px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography
          style={{
            minWidth: "95px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#5a5a5a",
          }}
        >
          검색
        </Typography>
        <FormInputDropdown
          control={control}
          name="searchType"
          sx={{ width: "111px", marginRight: "10px" }}
          options={searchTypeContact}
        />
        <FormInputText
          sx={{ width: "200px", marginRight: "6px" }}
          control={control}
          name="keyword"
          placeholder="내용을 입력해 주세요"
        />
        <Button
          onClick={handleSubmit(submit)}
          size="small"
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "7px 18px",
            color: "#7653DF",
            background: "#EEEAFB",
          }}
        >
          검색
        </Button>
        <Typography
          size="small"
          onClick={setInitial}
          sx={{
            textDecoration: "underline",
            fontSize: "14px",
            ml: "10px",
            color: "#6B7280",
            fontWeight: 500,
            whiteSpace: "nowrap",
            "&:hover": {
              color: "#8E66FF",
              cursor: "pointer",
            },
          }}
        >
          검색조건 초기화
        </Typography>
        <Link
          to="/talent/number-contacts/create"
          style={{ marginLeft: "auto" }}
        >
          <Button
            size="small"
            sx={{
              fontSize: "13px",
              width: "auto",
              height: "30px",
              padding: "8px 26px",
              color: "#FFF",
              backgroundColor: "#7653DF",
              whiteSpace: "nowrap",
            }}
          >
            회원 등록 <AddIcon />
          </Button>
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          mb: "10px",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex" alignItems="center">
          <Button
            sx={{
              fontSize: "12px",
              lineHeight: "15px",
              height: "30px",
              color: "#fff",
              padding: "7px 20px",
              marginRight: "6px",
              background: "#6B7280",
            }}
            onClick={smsSend}
          >
            SMS
          </Button>
          <Button
            onClick={() => donwloadExcel()}
            sx={{
              fontSize: "12px",
              lineHeight: "15px",
              height: "30px",
              padding: "7px 26px",
              whiteSpace: "nowrap",
              color: "#fff",
              background: "#6B7280",
              marginLeft: "auto",
            }}
          >
            엑셀 다운로드
          </Button>
          <CustomCheckbox
            name="allSelect"
            checked={!usersData?.some((user) => user.isChecked !== true)}
            onChange={handleChange}
          />
          <Typography style={{ minWidth: "95px", fontWeight: "500" }}>
            현재 해당되는 모든 회원 ( {users?.totalElements} 명) 선택
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormInputDropdown
            control={control}
            name="order"
            staticValue={query.order}
            sx={{ width: "75px", marginRight: "6px" }}
            onChange={(e) => setQuery({ ...query, order: e.target.value })}
            options={order}
          />
          <FormInputDropdown
            control={control}
            name="size"
            sx={{ width: "90px" }}
            options={pageSize}
            staticValue={query.size}
            onChange={(e) => setQuery({ ...query, size: e.target.value })}
          />
        </Box>
      </Box>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Loader style={{ backgroundColor: "red" }} />
        </Box>
      ) : isNoData ? (
        <DataNotFound title="검색된 콘택트 인원이 없습니다." />
      ) : (
        <Box>
          <TableContainer
            className="table"
            sx={{
              borderTop: "1px solid #98A6AD",
              height: `${window.innerHeight - 350}px`,
              position: "relative",
            }}
          >
            <Table>
              <TableHead
                sx={{
                  background: "#ebebeb",
                  position: "sticky",
                  top: -1,
                  zIndex: 10,
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                    align="center"
                  >
                    <CustomCheckbox
                      color="primary"
                      name="allSelect"
                      onChange={handleChange}
                      checked={
                        !usersData?.some((user) => user.isChecked !== true)
                      }
                    />
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    번호
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    성명
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    성별
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    출생년도
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    휴대폰
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    최종 출신 학교
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    메모
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    관리자
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    콘택트 일자
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersData?.map((user, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      sx={{
                        "&:hover": {
                          color: "#5a5a5a !important",
                          backgroundColor: "rgba(142, 102, 255, 0.22)",
                        },
                        ".css-1d46hnn-MuiTableCell-root": {
                          padding: "1px",
                        },
                        textDecoration: "none",
                        height: "40px",
                      }}
                    >
                      <TableCell
                        padding="checkbox"
                        sx={{
                          padding: "10px",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                        align="center"
                      >
                        <CustomCheckbox
                          name={user.name + user.id}
                          checked={user?.isChecked || false}
                          onChange={handleChange}
                        />
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/talent/number-contacts/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {query.size * (query.number - 1) + index + 1}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/talent/number-contacts/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.name}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/talent/number-contacts/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.gender}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/talent/number-contacts/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.birthday}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/talent/number-contacts/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.phoneNumber &&
                          formatKoreanPhoneNumber(user.phoneNumber)}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/talent/number-contacts/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.school}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/talent/number-contacts/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        <p
                          style={{
                            inlineSize: "480px",
                            overflowWrap: "break-word",
                          }}
                        >
                          {user.memo}
                        </p>
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/talent/number-contacts/${user.id}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.manager}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/talent/number-contacts/${user.id}`}
                        sx={{
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.contactDate}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
            <Pagination
              count={Math.ceil(users?.totalElements / query.size) || 0}
              onChange={handleChangePage}
              page={query.number}
              color="primary"
              variant="outlined"
              shape="rounded"
              sx={{
                m: "0 auto",
                "& .MuiPaginationItem-root.MuiPaginationItem-page, .MuiPaginationItem-previousNext":
                  { padding: "4px 20px !important" },
              }}
            />
          </Stack>
        </Box>
      )}

      <SmsSendModal
        setModal={setSmsModal}
        modal={{ ...smsModal, isOpen: smsModal.isOpen }}
        modalKey="isOpen"
      />
    </Paper>
  );
}

export default NumberOfContacts;
