import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { FormInputText } from "../../../components/form-components/FormInputText";
import { FormInputDropdown } from "../../../components/form-components/FormInputDropdown";
import { FormInputTextArea } from "../../../components/form-components/FormInputTextArea";
import { useNavigate, useParams } from "react-router-dom";
import { getHhuntingById, updateHhuntingById } from "../../../api/customers";

const options = [
  {
    label: "끌 안보임",
    value: "1",
  },
];

export default function Tab2() {
  const { id } = useParams();
  const [hhunting, setHhunting] = useState();
  const { handleSubmit, control, reset } = useForm({
    defaultValues: hhunting || "",
  });
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  async function hhuntingReq() {
    try {
      const { data } = await getHhuntingById(id, user.token);
      setHhunting(data);
      reset(data);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data.message);
    }
  }

  const submit = async (values) => {
    try {
      await updateHhuntingById(values, user.token);
      toast.success("완료 되었습니다.");
      navigate("/account-management/hh-request");
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data.message);
    }
  };

  useEffect(() => {
    hhuntingReq();
  }, [id]);

  return (
    <Paper sx={{ boxShadow: "none" }}>
      <Grid container>
        <Grid item xs={6} pr="17px">
          <Grid container rowSpacing={1} alignItems="center">
            <Grid item xs={2} fontWeight="500">
              담당자명 <font color="#A91515">*</font>
            </Grid>
            <Grid item xs={10}>
              <FormInputText
                control={control}
                name="personInCharge"
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
            <Grid item xs={2} fontWeight="500" pt="16px!important">
              직급 <font color="#A91515">*</font>
            </Grid>
            <Grid item xs={10} pt="16px!important">
              <FormInputText
                control={control}
                name="position"
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
            <Grid item xs={2} fontWeight="500" pt="16px!important">
              E-mail <font color="#A91515">*</font>
            </Grid>
            <Grid item xs={10} pt="16px!important">
              <FormInputText
                control={control}
                name="email"
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} pl="17px">
          <Grid container rowSpacing={1} alignItems="center">
            <Grid item xs={2} fontWeight="500">
              회사명 <font color="#A91515">*</font>
            </Grid>
            <Grid item xs={10}>
              <FormInputText
                control={control}
                name="companyName"
                placeholder=""
              />
            </Grid>
            <Grid item xs={2} fontWeight="500" pt="16px!important">
              전화번호(내선)
            </Grid>
            <Grid item xs={10} pt="16px!important">
              <Typography color="red" fontSize={12}>
                핸드폰 번호는 반드시 "-" 제외하고 입력하세요!
              </Typography>
              <FormInputText
                control={control}
                name="phoneNumber"
                type="tel"
                placeholder="전화 번호를 입력해 주세요"
                InputProps={{ maxLength: 11 }}
              />
            </Grid>
            <Grid item xs={2} fontWeight="500" pt="16px!important">
              홈페이지
            </Grid>
            <Grid item xs={10} pt="16px!important">
              <FormInputText
                control={control}
                name="webAddress"
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} fontWeight="500" pt="16px!important">
          구인 의뢰 내용 <font color="#A91515">*</font>
        </Grid>
        <Grid item xs={11} pt="16px!important">
          <FormInputTextArea
            style={{
              width: "100%",
              background: "#fff",
              borderRadius: "5px",
              border: "1px solid #E7E7E7",
            }}
            minRows={10}
            control={control}
            name="detail"
          />
        </Grid>
      </Grid>

      <hr style={{ margin: "30px 0 40px 0" }} />
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Button
          size="small"
          sx={{
            display: "block",
            fontSize: "12px",
            lineHeight: "15px",
            mr: "8px",
            padding: "7px 26px",
            color: "#fff",
            background: "#98A6AD",
          }}
        >
          취소
        </Button>
        <Button
          onClick={handleSubmit(submit)}
          size="small"
          sx={{
            fontSize: "12px",
            lineHeight: "15px",
            padding: "7px 50px",
            display: "block",
            color: "#fff",
            backgroundColor: "#7653DF",
          }}
        >
          저장
        </Button>
      </Box>
    </Paper>
  );
}
