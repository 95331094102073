import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import BackButton from "../../components/BackButton";
import LinkButton from "../../components/LinkButton";
import { FormInputText } from "../../components/form-components/FormInputText";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import { createUser } from "../../api/user";
import { eduLevel, gender, scriptUrl } from "../../constants/constants";
import { FormInputDate } from "../../components/form-components/FormInputDate";
import { formatLocaleDate } from "../../utils/methods";
import { getCoordinatesByAddress } from "../../api/nominatium";

const checkUser = {
  name: "성명을 입력하세",
  username: "아이디를 입력하세",
  address: "주소를 입력하세",
  addressDetails: "상세 주소를 입력해 주세요",
  birthday: "생년월을 입력해 주세요",
  eduLevel: "최종 학력을 입력해 주세요",
  email: "이메일을 입력해 주세요",
  gender: "성별을 선택해 주세요",
  phoneNumber: "전화 번호를 입력해 주세요",
  password: "비밀번호를 입력해 주세요",
  verifyPassword: "비밀번호 확인를 입력해 주세요",
};

function InterCreate() {
  const { state } = useLocation();
  const navigation = useNavigate();
  const { handleSubmit, control, setValue } = useForm();
  const [image, setImage] = useState();
  const [error, setError] = useState({});
  const open = useDaumPostcodePopup(scriptUrl);

  const handleUploadImage = (e) => {
    setImage(e.target.files[0]);
  };

  const submit = async (values) => {
    let checkField = true;
    for (const property in values) {
      if (values[property] === undefined || values[property] === "") {
        checkField = false;
        toast.warning(checkUser[property]);
        break;
      }
    }

    const userData = {
      ...values,
      profilePicture: "profilePicture",
      file: image,
      birthday: formatLocaleDate(values.birthday),
    };

    if (checkField) {
      if (userData.password !== userData.verifyPassword) {
        setError({
          password: true,
        });
      } else {
        try {
          await createUser(state.url, userData);
          navigation(
            state ? "/inter/employee-management" : "/talent/all-user"
          );
        } catch (error) {
          toast.error(error.response.data.message);
        }
      }
    }
  };

  const handleComplete = async (data) => {
    setValue(
      "address",
      `${data.userSelectedType === "R" ? data.roadAddress : data.jibunAddress}${
        data.buildingName ? `, ${data.buildingName}` : ""
      }`
    );
  };

  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  return (
    <div
      style={{
        background: "white",
        borderRadius: "5px",
        paddingTop: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <BackButton text="사원 임의 등록" />
        <LinkButton link="/inter/employee-management" label="목록으로" />
      </Box>
      <hr style={{ margin: "16px 0 20px 0" }} />
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            minWidth: "200px",
            maxWidth: "200px",
            marginTop: "50px",
            paddingRight: "12px",
          }}
        >
          <label htmlFor="imageupload" style={{ width: "100%" }}>
            <Box
              position="relative"
              sx={{
                "&:hover": { cursor: "pointer" },
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "240px",
                  background: "#D9D9D9",
                  borderRadius: "5px",
                  position: "relative",
                  overflow: "hidden",
                  marginBottom: "40px",
                }}
              >
                {image ? (
                  <img
                    src={URL.createObjectURL(image)}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt="uploader"
                  />
                ) : (
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#987EE7",
                      color: "#fff",
                      width: "50px",
                      height: "50px",
                      bottom: "7px",
                      right: "7px",
                      borderRadius: "50%",
                    }}
                  >
                    <PhotoCameraOutlinedIcon
                      sx={{ fontSize: "35px", color: "#fff" }}
                    />
                  </div>
                )}
              </div>
            </Box>
            <input
              type="file"
              id="imageupload"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleUploadImage}
            />
          </label>
        </Box>
        <Box
          sx={{
            width: "100%",
            pl: "20px",
            marginTop: "50px",
          }}
        >
          <Grid container>
            <Grid item xs={6}>
              <Grid container rowSpacing={1} alignItems="center">
                <Grid item mb="4px" xs={2} fontWeight="500">
                  이름(한글)
                </Grid>
                <Grid item mb="4px" xs={10}>
                  <FormInputText
                    control={control}
                    name="name"
                    placeholder="내용을 입력해 주세요"
                  />
                </Grid>
                <Grid item xs={2} mb="4px" fontWeight="500">
                  성별
                </Grid>
                <Grid item xs={4} mb="4px">
                  <FormInputDropdown
                    control={control}
                    name="gender"
                    sx={{
                      maxWidth: "180px",
                      width: "100%",
                    }}
                    options={gender}
                  />
                </Grid>
                <Grid item xs={2} mb="4px" fontWeight="500" textAlign="center">
                  최종 학력
                </Grid>
                <Grid item xs={4} mb="4px">
                  <FormInputDropdown
                    control={control}
                    name="eduLevel"
                    sx={{
                      maxWidth: "180px",
                      width: "100%",
                    }}
                    options={eduLevel}
                  />
                </Grid>
                <Grid item mb="4px" xs={2} fontWeight="500">
                  주소
                </Grid>
                <Grid item xs={10} mb="4px">
                  <Box display="flex" alignItems="center">
                    <FormInputText
                      control={control}
                      name="address"
                      placeholder=""
                    />
                    <Button
                      onClick={handleClick}
                      sx={{
                        whiteSpace: "nowrap",
                        fontSize: "12px",
                        marginLeft: "10px",
                        lineHeight: "15px",
                        padding: "8px 26px",
                        color: "#7653DF",
                        backgroundColor: "#EEEAFB",
                      }}
                    >
                      검색
                    </Button>
                  </Box>
                </Grid>
                <Grid item mb="4px" xs={2} />
                <Grid item mb="4px" xs={10}>
                  <FormInputText
                    control={control}
                    name="addressDetails"
                    placeholder="상세 주소를 입력해 주세요"
                  />
                </Grid>
                <Grid item xs={2} fontWeight="500">
                  비밀번호
                </Grid>
                <Grid item xs={10}>
                  <FormInputText
                    control={control}
                    name="password"
                    type="password"
                    placeholder="비밀번호"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} pl="35px">
              <Grid container rowSpacing={1} alignItems="center">
                <Grid item mb="4px" xs={2} fontWeight="500">
                  생년월일
                </Grid>
                <Grid item mb="4px" xs={10}>
                  <FormInputDate
                    name="birthday"
                    control={control}
                    placeholder="YYYY/MM/DD"
                    renderInput={(params) => (
                      <TextField
                        color="info"
                        size="small"
                        variant="outlined"
                        {...params}
                        sx={{
                          width: "100%",
                          background: "#fff",
                          ".MuiInputBase-root": {
                            paddingRight: "0px",
                          },
                          ".MuiIconButton-root": {
                            marginRight: "0px",
                          },
                          input: {
                            paddingRight: "0px!important",
                          },
                          "& .MuiSvgIcon-root": {
                            fill: "#987EE7 !important",
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item mb="4px" xs={2} fontWeight="500">
                  휴대폰
                </Grid>
                <Grid item mb="4px" xs={10}>
                  <Typography color="red" fontSize={12}>
                    핸드폰 번호는 반드시 "-" 제외하고 입력하세요!
                  </Typography>
                  <FormInputText
                    control={control}
                    name="phoneNumber"
                    placeholder="전화 번호를 입력해 주세요"
                    type="tel"
                    InputProps={{ maxLength: 11 }}
                  />
                </Grid>
                <Grid item mb="4px" xs={2} fontWeight="500">
                  이메일
                </Grid>
                <Grid item mb="4px" xs={10}>
                  <FormInputText
                    control={control}
                    type="email"
                    name="email"
                    placeholder="이매일을 입력해 주세요"
                  />
                </Grid>
                <Grid item mb="4px" xs={2} fontWeight="500">
                  아이디
                </Grid>
                <Grid item mb="4px" xs={10}>
                  <FormInputText
                    control={control}
                    name="username"
                    placeholder="hong123"
                  />
                </Grid>
                <Grid item xs={2} fontWeight="500">
                  비밀번호 확인
                </Grid>
                <Grid item xs={10}>
                  <FormInputText
                    control={control}
                    name="verifyPassword"
                    type="password"
                    placeholder="비밀번호"
                  />
                </Grid>
                <Grid item xs={2} fontWeight="500"></Grid>
                {error.password && (
                  <Grid
                    item
                    mb="4px"
                    sx={{ color: "#A91515" }}
                    fontWeight="500"
                  >
                    입력한 비밀번호와 일치하지 않습니다.
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <hr style={{ margin: "298px 0 40px 0" }} />
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Button
          size="small"
          sx={{
            display: "block",
            fontSize: "12px",
            lineHeight: "15px",
            mr: "8px",
            padding: "7px 26px",
            color: "#fff",
            background: "#98A6AD",
          }}
        >
          취소
        </Button>
        <Button
          size="small"
          onClick={handleSubmit(submit)}
          sx={{
            fontSize: "12px",
            lineHeight: "15px",
            padding: "7px 50px",
            display: "block",
            color: "#fff",
            backgroundColor: "#7653DF",
          }}
        >
          저장
        </Button>
      </Box>
    </div>
  );
}

export default InterCreate;
