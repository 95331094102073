import mainCaller from "./mainCaller";

export async function getUserSummary(data, token) {
  return await mainCaller(`dashboard/user-summary`, "POST", data, {
    "Content-type": "application/json",
    Authorization: `Bearer ${token}`,
  });
}
export async function getUserSummaryDetail(data, token) {
  return await mainCaller(`dashboard/user-summary/detail`, "POST", data, {
    "Content-type": "application/json",
    Authorization: `Bearer ${token}`,
  });
}
export async function getLogTime(data, token) {
  return await mainCaller(`dashboard/log-time`, "POST", data, {
    "Content-type": "application/json",
    Authorization: `Bearer ${token}`,
  });
}
export async function getLogDay(data, token) {
  return await mainCaller(`dashboard/log-day`, "POST", data, {
    "Content-type": "application/json",
    Authorization: `Bearer ${token}`,
  });
}
export async function getLogYear(data, token) {
  return await mainCaller(`dashboard/log-month`, "POST", data, {
    "Content-type": "application/json",
    Authorization: `Bearer ${token}`,
  });
}
