import React from "react";
import DispatchCode from "./DispatchCode";
import CustomerIndustry from "./CustomerIndustry";
import Occupation from "./Occupation";
import WorkDepartment from "./WorkDepartment";
import ManagementDepartment from "./ManagementDepartment";
import DepartmentDetail from "./DepartmentDetail";
import { useSelector } from "react-redux";

export default function CodeSwitcher({ selectedTab, setSelectedTab }) {
  const { token } = useSelector((state) => state.user.user);
  switch (selectedTab.tab) {
    case 1:
      return <DispatchCode token={token} />;
    case 2:
      return <CustomerIndustry token={token} />;
    case 3:
      return <Occupation token={token} />;
    case 4:
      return <WorkDepartment setSelectedTab={setSelectedTab} token={token} />;
    case 5:
      return <ManagementDepartment token={token} selectedTab={selectedTab} />;
    case 6:
      return (
        <DepartmentDetail
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
          token={token}
        />
      );
    default:
      return <DispatchCode token={token} />;
  }
}
