import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import { useForm } from "react-hook-form";
import { createPersonnelUser } from "../../../api/user";
import BackButton from "../../../components/BackButton";
import LinkButton from "../../../components/LinkButton";
import { FormInputText } from "../../../components/form-components/FormInputText";
import { FormInputDropdown } from "../../../components/form-components/FormInputDropdown";
import { eduLevel, gender } from "../../../constants/constants";
import { FormInputDate } from "../../../components/form-components/FormInputDate";
import { useSelector } from "react-redux";
import { dateFormatter } from "../../../utils/methods";

function UserCreate() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const { handleSubmit, control } = useForm();
  const [image, setImage] = useState();

  const handleUploadImage = (e) => {
    setImage(e.target.files[0]);
  };

  const submit = async (values) => {
    const userData = {
      ...values,
      birthday: dateFormatter(values.birthday),
      manager: user.username || user.name,
    };
    try {
      const { data } = await createPersonnelUser(userData, user.token);
      navigate("/talent/number-contacts");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        background: "white",
        borderRadius: "5px",
        paddingTop: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <BackButton text="콘택트 인원 정보" />
        <LinkButton link="/talent/number-contacts" label="목록보기" />
      </Box>
      <hr style={{ margin: "16px 0 20px 0" }} />
      <Box sx={{ display: "flex" }}>
        {/* <Box
          sx={{
            minWidth: "200px",
            maxWidth: "200px",
            marginTop: "50px",
            paddingRight: "12px",
          }}
        >
          <label htmlFor="imageupload" style={{ width: "100%" }}>
            <Box
              position="relative"
              sx={{
                "&:hover": { cursor: "pointer" },
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "240px",
                  background: "#D9D9D9",
                  borderRadius: "5px",
                  position: "relative",
                  overflow: "hidden",
                  marginBottom: "40px",
                }}
              >
                {image ? (
                  <img
                    src={URL.createObjectURL(image)}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt="uploader"
                  />
                ) : (
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#987EE7",
                      color: "#fff",
                      width: "50px",
                      height: "50px",
                      bottom: "7px",
                      right: "7px",
                      borderRadius: "50%",
                    }}
                  >
                    <PhotoCameraOutlinedIcon
                      sx={{ fontSize: "35px", color: "#fff" }}
                    />
                  </div>
                )}
              </div>
            </Box>
            <input
              type="file"
              id="imageupload"
              style={{ display: "none" }}
              onChange={handleUploadImage}
            />
          </label>
        </Box> */}

        <Box
          sx={{
            width: "100%",
            pl: "20px",
            marginTop: "50px",
          }}
        >
          <Typography mb="30px" fontSize="16px" fontWeight="700">
            개인 정보
          </Typography>
          <Grid container>
            <Grid item xs={6}>
              <Grid container rowSpacing={1} alignItems="center">
                <Grid item mb="4px" xs={2} fontWeight="500">
                  이름(한글)
                </Grid>
                <Grid item mb="4px" xs={10}>
                  <FormInputText
                    control={control}
                    name="name"
                    placeholder="내용을 입력해 주세요"
                  />
                </Grid>
                <Grid item xs={2} mb="4px" fontWeight="500">
                  성별
                </Grid>
                <Grid item xs={4} mb="4px">
                  <FormInputDropdown
                    control={control}
                    name="gender"
                    sx={{
                      maxWidth: "180px",
                      width: "100%",
                    }}
                    options={gender}
                  />
                </Grid>
                <Grid item xs={2} mb="4px" fontWeight="500" textAlign="center">
                  최종 학력
                </Grid>
                <Grid item xs={4} mb="4px">
                  <FormInputDropdown
                    control={control}
                    name="eduLevel"
                    sx={{
                      maxWidth: "180px",
                      width: "100%",
                    }}
                    options={eduLevel}
                  />
                </Grid>
                <Grid item mb="4px" xs={2} fontWeight="500">
                  최종출신학교
                </Grid>
                <Grid item xs={10} mb="4px">
                  <FormInputText
                    control={control}
                    name="school"
                    placeholder="내용을 입력해 주세요"
                  />
                </Grid>
                <Grid item xs={2} fontWeight="500">
                  콘택트 경로
                </Grid>
                <Grid item mb="4px" xs={10}>
                  <FormInputText
                    control={control}
                    name="contactPath"
                    placeholder="내용을 입력해 주세요"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} pl="35px">
              <Grid container rowSpacing={1} alignItems="center">
                <Grid item mb="4px" xs={2} fontWeight="500">
                  출생년도
                </Grid>
                <Grid item mb="4px" xs={10}>
                  <FormInputDate
                    name="birthday"
                    control={control}
                    placeholder="YYYY/MM/DD"
                    renderInput={(params) => (
                      <TextField
                        color="info"
                        size="small"
                        variant="outlined"
                        {...params}
                        sx={{
                          width: "100%",
                          background: "#fff",
                          ".MuiInputBase-root": {
                            paddingRight: "0px",
                          },
                          ".MuiIconButton-root": {
                            marginRight: "0px",
                          },
                          input: {
                            paddingRight: "0px!important",
                          },
                          "& .MuiSvgIcon-root": {
                            fill: "#987EE7 !important",
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item mb="4px" xs={2} fontWeight="500">
                  휴대폰
                </Grid>
                <Grid item mb="4px" xs={10}>
                  <Typography color="red" fontSize={12}>
                    핸드폰 번호는 반드시 "-" 제외하고 입력하세요!
                  </Typography>
                  <FormInputText
                    control={control}
                    name="phoneNumber"
                    placeholder="전화 번호를 입력해 주세요"
                  />
                </Grid>
                <Grid item mb="4px" xs={2} fontWeight="500">
                  전공 학과
                </Grid>
                <Grid item mb="4px" xs={10}>
                  <FormInputText
                    control={control}
                    name="department"
                    placeholder="내용을 입력해 주세요"
                  />
                </Grid>
                <Grid item mb="4px" xs={2} fontWeight="500">
                  소재지
                </Grid>
                <Grid item mb="4px" xs={10}>
                  <FormInputText
                    control={control}
                    name="location"
                    placeholder="내용을 입력해 주세요"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <hr style={{ margin: "298px 0 40px 0" }} />
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Button
          size="small"
          sx={{
            display: "block",
            fontSize: "12px",
            lineHeight: "15px",
            mr: "8px",
            padding: "7px 26px",
            color: "#fff",
            background: "#98A6AD",
          }}
        >
          취소
        </Button>
        <Button
          size="small"
          onClick={handleSubmit(submit)}
          sx={{
            fontSize: "12px",
            lineHeight: "15px",
            padding: "7px 50px",
            display: "block",
            color: "#fff",
            backgroundColor: "#7653DF",
          }}
        >
          저장
        </Button>
      </Box>
    </div>
  );
}

export default UserCreate;
