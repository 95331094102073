import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FormInputText } from "../form-components/FormInputText";
import { useForm } from "react-hook-form";
import { getCodeList } from "../../api/personalCodeManagement";
import { FormInputDropdown } from "../form-components/FormInputDropdown";
import { pageSize } from "../../constants/constants";
import { useSelector } from "react-redux";

const initialQuery = {
  number: 1,
  size: 60,
  order: "DESC",
  sort: "name",
  keyword: "",
};

export default function CodeManager({
  setModal,
  modal,
  modalKey,
  contentKey,
  apiUrl,
}) {
  const { control, handleSubmit, reset } = useForm({ defaultValues: modal });
  const ref = useRef(null);
  const { token } = useSelector((state) => state.user.user);
  const [users, setUsers] = useState();
  const [query, setQuery] = useState({
    ...initialQuery,
    keyword: modal.keyword,
  });
  const getList = async () => {
    try {
      const { data } = await getCodeList(apiUrl, query, token);
      setUsers(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = (_, value) => {
    setQuery({ ...query, number: value });
  };

  const submit = (value) => {
    setQuery({ ...query, ...value });
  };

  const setContent = (content) => {
    setModal((prevModal) => ({
      ...prevModal,
      [modalKey]: false,
      [contentKey]: content,
    }));
  };

  const setInitial = () => {
    reset(initialQuery);
    setQuery(initialQuery);
  };

  const close = () => {
    setModal((prevModal) => ({
      ...prevModal,
      [modalKey]: false,
    }));
  };

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <Box ref={ref} sx={{ width: "100%", maxHeight: "100%", minHeight: "100%" }}>
      <Button
        sx={{ display: "block", minWidth: "unset", marginLeft: "auto" }}
        onClick={close}
      >
        <img src="/icons/close.svg" alt="close" />
      </Button>
      <Box
        sx={{
          display: "flex",
          mb: "16px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography style={{ minWidth: "95px", fontWeight: "500" }}>
          검색
        </Typography>
        <FormInputText
          control={control}
          sx={{ width: "280px", marginRight: "6px" }}
          name="keyword"
          placeholder="내용을 입력해 주세요"
        />
        <Button
          onClick={handleSubmit(submit)}
          size="small"
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "7px 18px",
            color: "#7653DF",
            background: "#EEEAFB",
          }}
        >
          검색
        </Button>
        <Button
          onClick={setInitial}
          size="small"
          sx={{
            fontSize: "13px",
            color: "#6B7280",
            ml: "17px",
            "&:hover": {
              color: "#8E66FF",
            },
          }}
        >
          검색조건 초기화
        </Button>
      </Box>
      {/* <FormInputDropdown
        control={control}
        sx={{ width: "90px", display: "flex", margin: "0 0 10px auto" }}
        options={pageSize}
        staticValue={query.size}
        onChange={(e) => setQuery({ ...query, size: e.target.value })}
      /> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          maxHeight: ref?.current?.clientHeight - 150,
        }}
      >
        <TableContainer
          className="table"
          sx={{
            border: "1px solid #E7E7E7",
            borderTop: "1px solid #98A6AD",
          }}
        >
          <Table>
            <TableHead sx={{ background: "#fcfcfc" }}>
              <TableRow>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  번호
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  코드
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  이름
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.content?.slice(0, 20).map((user, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      sx={{
                        "&:hover": {
                          color: "#5a5a5a !important",
                          backgroundColor: "rgba(142, 102, 255, 0.22)",
                        },
                        ".css-1d46hnn-MuiTableCell-root": {
                          padding: "1px",
                        },
                        height: "40px",
                      }}
                    >
                      <TableCell
                        id={labelId}
                        onClick={() => setContent(user)}
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        onClick={() => setContent(user)}
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.code}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        onClick={() => setContent(user)}
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.name}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer
          className="table"
          sx={{
            marginX: "10px",
            border: "1px solid #E7E7E7",
            borderTop: "1px solid #98A6AD",
          }}
        >
          <Table>
            <TableHead sx={{ background: "#fcfcfc" }}>
              <TableRow>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  번호
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  코드
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  파견코드 명
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.content.length > 20 &&
                users.content.slice(20, 40).map((user, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      sx={{
                        "&:hover": {
                          color: "#5a5a5a !important",
                          backgroundColor: "rgba(142, 102, 255, 0.22)",
                        },
                        ".css-1d46hnn-MuiTableCell-root": {
                          padding: "1px",
                        },

                        height: "40px",
                      }}
                    >
                      <TableCell
                        id={labelId}
                        onClick={() => setContent(user)}
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        onClick={() => setContent(user)}
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.code}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        onClick={() => setContent(user)}
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.name}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer
          className="table"
          sx={{
            border: "1px solid #E7E7E7",
            borderTop: "1px solid #98A6AD",
          }}
        >
          <Table>
            <TableHead sx={{ background: "#fcfcfc" }}>
              <TableRow>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  번호
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  코드
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  align="center"
                >
                  파견코드 명
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.content.length > 40 &&
                users.content.slice(40).map((user, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      sx={{
                        "&:hover": {
                          color: "#5a5a5a !important",
                          backgroundColor: "rgba(142, 102, 255, 0.22)",
                        },
                        ".css-1d46hnn-MuiTableCell-root": {
                          padding: "1px",
                        },

                        height: "40px",
                      }}
                    >
                      <TableCell
                        id={labelId}
                        onClick={() => setContent(user)}
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        onClick={() => setContent(user)}
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.code}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        onClick={() => setContent(user)}
                        scope="row"
                        padding="none"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {user.name}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {[0, 1, 2].map((tableIndex) => (
          <TableContainer
            key={tableIndex}
            className="table"
            sx={{
              border: "1px solid #E7E7E7",
              borderTop: "1px solid #98A6AD",
              marginX: tableIndex !== 1 ? "10px" : 0,
            }}
          >
            <Table>
              <TableHead sx={{ background: "#fcfcfc" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    번호
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    코드
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    이름
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users &&
                  users.content
                    ?.filter((user, i) => i % 3 === tableIndex)
                    .map((user, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          role="checkbox"
                          key={index}
                          sx={{
                            "&:hover": {
                              color: "#5a5a5a !important",
                              backgroundColor: "rgba(142, 102, 255, 0.22)",
                            },
                            ".css-1d46hnn-MuiTableCell-root": {
                              padding: "1px",
                            },
                            height: "40px",
                          }}
                        >
                          <TableCell
                            id={labelId}
                            onClick={() => setContent(user)}
                            scope="row"
                            padding="none"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                            align="center"
                          >
                            {tableIndex + 1 + index * 3}
                          </TableCell>
                          <TableCell
                            id={labelId}
                            onClick={() => setContent(user)}
                            scope="row"
                            padding="none"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                            align="center"
                          >
                            {user.code}
                          </TableCell>
                          <TableCell
                            id={labelId}
                            onClick={() => setContent(user)}
                            scope="row"
                            padding="none"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                            align="center"
                          >
                            {user.name}
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        ))}
      </Box> */}
      {users && (
        <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
          <Pagination
            count={Math.ceil(users.totalElements / query.size)}
            onChange={handleChangePage}
            page={query.number}
            color="primary"
            variant="outlined"
            shape="rounded"
            sx={{
              width: "100%",
              mt: "20px",
              position: "absolute",
              bottom: "40px",
              left: "0",
              right: "0",
            }}
          />
        </Stack>
      )}
    </Box>
  );
}
