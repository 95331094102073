import React from "react";
import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  TableHead,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  employmentType,
  order,
  pageSize,
  searchType2,
} from "../../../constants/constants";
import { formatKoreanPhoneNumber, getAge } from "../../../utils/methods";
import { getAllEmployees } from "../../../api/user";
import { FormInputDropdown } from "../../../components/form-components/FormInputDropdown";
import { FormInputText } from "../../../components/form-components/FormInputText";
import CustomCheckbox from "../../../components/CustomCheckbox";
import SmsSendModal from "../../../components/modals/SmsSendModal";
import { toast } from "react-toastify";

const initialQuery = {
  number: 1,
  size: 20,
  order: "DESC",
  sort: "createdDate",
  employmentType: "",
  keyword: "",
  searchType: "",
};

export default function InterEmployeeManagement() {
  const { handleSubmit, control, reset } = useForm({
    defaultValues: initialQuery,
  });
  const { user } = useSelector((state) => state.user);
  const [query, setQuery] = useState(initialQuery);
  const [users, setUsers] = useState();
  const [smsModal, setSmsModal] = useState({ isOpen: false });

  const handleChangePage = (_, value) => {
    setQuery({ ...query, number: value });
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    var tempUser = {};
    if (name === "allSelect") {
      tempUser = users.content.map((user) => {
        return { ...user, isChecked: checked };
      });
    } else {
      tempUser = users.content.map((user) =>
        user.name + user.applicantId === name
          ? { ...user, isChecked: checked }
          : user
      );
    }
    setUsers({ ...users, content: tempUser });
  };

  async function userList() {
    try {
      const { data } = await getAllEmployees(query, user.token);
      setUsers(data);
    } catch (error) {
      console.log(error.response?.data.message);
    }
  }

  const smsSend = () => {
    const checkedData = [];
    users.content?.map((data) => {
      if (data.isChecked) {
        checkedData.push(data);
      }
      return data.isChecked;
    });

    if (checkedData.length === 0) {
      toast.warning("메시지 보내고 싶은 사원을 선택해 주세요!");
    } else {
      setSmsModal({ isOpen: true, checkedUsers: checkedData });
    }
  };

  const submit = async (values) => {
    setQuery({ ...query, ...values });
  };

  const setInitial = () => {
    reset(initialQuery);
    setQuery(initialQuery);
  };

  useEffect(() => {
    userList(query);
  }, [query]);

  return (
    <Paper
      sx={{
        width: "100%",
        boxShadow: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          mb: "12px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography
          style={{
            minWidth: "95px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#5a5a5a",
          }}
        >
          검색
        </Typography>
        <FormInputDropdown
          control={control}
          name="searchType"
          sx={{ width: "111px", marginRight: "10px" }}
          options={searchType2}
        />
        <FormInputText
          sx={{ width: "200px", marginRight: "6px" }}
          control={control}
          name="keyword"
          placeholder="내용을 입력해 주세요"
        />
        <Button
          size="small"
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "7px 18px",
            color: "#7653DF",
            background: "#EEEAFB",
          }}
        >
          검색
        </Button>
        <Link
          to="/inter-create"
          state={{ url: "employeeManagement/create" }}
          style={{ marginLeft: "auto" }}
        >
          <Button
            size="small"
            sx={{
              fontSize: "13px",
              width: "auto",
              height: "30px",
              padding: "8px 26px",
              color: "#FFF",
              backgroundColor: "#7653DF",
            }}
          >
            사원 임의 등록 <AddIcon />
          </Button>
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          mb: "12px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography
          style={{
            minWidth: "95px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#5a5a5a",
          }}
        >
          취업형태
        </Typography>
        <FormInputDropdown
          control={control}
          name="employmentType"
          options={employmentType}
          sx={{ width: "111px", marginRight: "10px" }}
        />
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          mb: "12px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography
          style={{
            minWidth: "95px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#5a5a5a",
          }}
        >
          사업장 별 검색
        </Typography>
        <Button
          size="small"
          sx={{
            fontSize: "13px",
            mr: "10px",
            height: "30px",
            padding: "7px 0",
            width: "111px",
            color: "#fff",
            background: "#987EE7",
          }}
        >
          사업장 선택
        </Button>
        <FormInputText
          sx={{ width: "200px", marginRight: "6px" }}
          control={control}
          name="keyword"
          placeholder="내용을 입력해 주세요"
        />
      </Box> */}
      <hr style={{ margin: "30px auto 20px auto", borderColor: "#E7E7E7" }} />
      <Box
        sx={{
          display: "flex",
          mb: "40px",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Button
          size="small"
          sx={{
            fontSize: "13px",
            width: "auto",
            mr: "30px",
            height: "30px",
            padding: "7px 30px",
            color: "#fff",
            background: "#987EE7",
          }}
          onClick={handleSubmit(submit)}
        >
          설정한 조건으로 검색
        </Button>
        <Box
          size="small"
          onClick={setInitial}
          sx={{
            textDecoration: "underline",
            fontSize: "14px",
            color: "#6B7280",
            fontWeight: 500,
            "&:hover": {
              color: "#8E66FF",
              cursor: "pointer",
            },
          }}
        >
          검색조건 초기화
        </Box>
      </Box>

      {users && (
        <>
          <Box
            sx={{
              display: "flex",
              mb: "15px",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                onClick={smsSend}
                size="small"
                sx={{
                  fontSize: "12px",
                  mr: "6px",
                  height: "30px",
                  padding: "7px 20px",
                  color: "#fff",
                  background: "#6B7280",
                }}
              >
                SMS
              </Button>
              <Button
                sx={{
                  fontSize: "12px",
                  mr: "12px",
                  height: "30px",
                  padding: "7px 20px",
                  color: "#fff",
                  background: "#6B7280",
                }}
              >
                엑셀 다운로드
              </Button>
              <CustomCheckbox
                name="allSelect"
                checked={
                  !users.content?.some((user) => user.isChecked !== true)
                }
                onChange={handleChange}
              />
              <Typography
                style={{
                  minWidth: "95px",
                  fontWeight: "500",
                  color: "#5A5A5A",
                }}
              >
                현재 해당되는 모든 회원 ( {users?.totalElements} 명) 선택
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormInputDropdown
                control={control}
                name="order"
                sx={{ width: "111px", marginRight: "10px" }}
                options={order}
                onChange={(e) => setQuery({ ...query, order: e.target.value })}
                staticValue={query.order}
              />
              <FormInputDropdown
                control={control}
                name="size"
                sx={{ width: "111px", marginRight: "10px" }}
                onChange={(e) => setQuery({ ...query, size: e.target.value })}
                options={pageSize}
                staticValue={query.size}
              />
            </Box>
          </Box>
          <TableContainer
            className="table"
            sx={{
              mt: "10px",
              borderTop: "1px solid #98a6ad",
            }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead sx={{ background: "#FCFCFC" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",

                      textAlign: "center",
                    }}
                    align="center"
                  >
                    <CustomCheckbox
                      color="primary"
                      name="allSelect"
                      checked={
                        !users.content?.some((user) => user.isChecked !== true)
                      }
                      onChange={handleChange}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    번호
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    성명
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    주민번호
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    연락처
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    업체명
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    입사일자
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    사원번호
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    취업형태
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    관리자
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.content?.map((user, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#EEEAFB",
                        },
                        ".css-1d46hnn-MuiTableCell-root": {
                          padding: "1px",
                        },
                        textDecoration: "none",
                        height: "40px",
                      }}
                    >
                      <TableCell
                        padding="checkbox"
                        sx={{
                          padding: "10px",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                        align="center"
                      >
                        <CustomCheckbox
                          name={user.name + user.applicantId}
                          checked={user?.isChecked || false}
                          onChange={handleChange}
                        />
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/user-details/${user.applicantId}`}
                        sx={{
                          textDecoration: "none",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {query.size * (query.number - 1) + index + 1}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/user-details/${user.applicantId}`}
                        sx={{
                          textDecoration: "none",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.name}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/user-details/${user.applicantId}`}
                        sx={{
                          textDecoration: "none",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.social1}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/user-details/${user.applicantId}`}
                        sx={{
                          textDecoration: "none",

                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.phoneNumber &&
                          formatKoreanPhoneNumber(user.phoneNumber)}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/user-details/${user.applicantId}`}
                        sx={{
                          textDecoration: "none",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.customerName}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/user-details/${user.applicantId}`}
                        sx={{
                          textDecoration: "none",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {getAge(user.joiningDate)}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/user-details/${user.applicantId}`}
                        sx={{
                          textDecoration: "none",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.employeeNumber}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/user-details/${user.applicantId}`}
                        sx={{
                          textDecoration: "none",

                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.employmentType}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/user-details/${user.applicantId}`}
                        sx={{
                          textDecoration: "none",

                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.manager}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
            <Pagination
              count={Math.ceil(users?.totalElements / query.size) || 0}
              onChange={handleChangePage}
              page={query.number}
              color="primary"
              variant="outlined"
              shape="rounded"
              sx={{
                m: "0 auto",
                "& .MuiPaginationItem-root.MuiPaginationItem-page, .MuiPaginationItem-previousNext":
                  { padding: "4px 20px !important" },
              }}
            />
          </Stack>
        </>
      )}
      <SmsSendModal
        setModal={setSmsModal}
        modal={{ ...smsModal, isOpen: smsModal.isOpen }}
        modalKey="isOpen"
      />
    </Paper>
  );
}
