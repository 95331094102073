import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { FormInputText } from "../../components/form-components/FormInputText";
import { FormInputDate } from "../../components/form-components/FormInputDate";
import BackButton from "../../components/BackButton";
import LinkButton from "../../components/LinkButton";
import {
  createCustomer,
  deleteCustomerById,
  getCustomerById,
  updateCustomerById,
} from "../../api/customers";
import { getCoordinatesByAddress } from "../../api/nominatium";
import { scriptUrl, situation } from "../../constants/constants";
import SubCompanyModal from "../../components/modals/SubCompanyCRUDModal";
import AcceptModal from "../../components/modals/AcceptModal";
import { formatKoreanPhoneNumber } from "../../utils/methods";

function BusinessDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const open = useDaumPostcodePopup(scriptUrl);
  const { token } = useSelector((state) => state.user.user);
  const { control, handleSubmit, reset, setValue } = useForm();
  const [customer, setCustomer] = useState();
  const [modal, setModal] = useState({
    isOpen: false,
    isOpen1: false,
    content: "",
  });

  const submit = async (values) => {
    delete values["accessType"];
    let checkField = true;

    for (const property in values) {
      // Skip the check for the "code" property
      if (property === "code") {
        continue;
      }

      if (values[property] === undefined || values[property] === "") {
        checkField = false;
        toast.warning("모든 영역을 작성해 주세요!");
        break;
      }
    }

    if (checkField) {
      try {
        if (id) {
          await updateCustomerById(values, token);
        } else {
          await createCustomer(values, token);
        }
        navigate("/account-management/business-management");
      } catch (error) {
        toast.error(error.response?.data.message);
      }
    }
  };

  const getCustomer = async () => {
    try {
      const { data } = await getCustomerById(id, token);
      setCustomer(data);
      reset(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleComplete = async (data) => {
    setValue(
      "address",
      `${data.userSelectedType === "R" ? data.roadAddress : data.jibunAddress}${
        data.buildingName ? `, ${data.buildingName}` : ""
      }`
    );
    setValue("zipCode", data.zonecode);
  };

  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  const deleteById = async () => {
    try {
      const res = await deleteCustomerById(modal.deleteID, token);
      if (res.statusCode === 200) {
        toast.success(res.message);
        navigate("/account-management/business-management");
      } else {
        toast.error(res.message);
      }
      setModal({ isOpen1: false });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCustomerAction = async (id) => {
    setModal({ ...modal, isOpen1: true, deleteID: id });
  };

  useEffect(() => {
    if (id) {
      getCustomer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <BackButton text="업체 관리" />
        <LinkButton
          label="목록보기"
          link="/account-management/business-management"
        />
      </Box>
      <hr style={{ margin: "16px 0 30px 0" }} />
      <Grid container mb="30px">
        <Grid item xs={6} pr="26px">
          <Grid container rowSpacing={1} alignItems="center">
            <Grid item xs={3} fontWeight="500">
              회사코드
            </Grid>
            <Grid item xs={9}>
              <FormInputText control={control} name="code" disabled={true} />
            </Grid>
            <Grid item xs={3} fontWeight="500">
              업체명
            </Grid>
            <Grid item xs={9}>
              <FormInputText
                control={control}
                name="name"
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
            <Grid item xs={3} fontWeight="500">
              사업자등록번호
            </Grid>
            <Grid item xs={4}>
              <FormInputText
                sx={{ maxWidth: "180px" }}
                control={control}
                name="regNumber"
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
            <Grid item xs={5}>
              <Typography color="red" fontSize={12}>
                사업자등록번호는 반드시 "-" 제외하고 입력하세요!
              </Typography>
            </Grid>
            <Grid item xs={3} fontWeight="500">
              업태
            </Grid>
            <Grid item xs={4}>
              <FormInputText
                sx={{ maxWidth: "180px" }}
                control={control}
                name="btype"
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
            <Grid item xs={1} fontWeight="500" textAlign="right">
              종목
            </Grid>
            <Grid item xs={4} textAlign="right">
              <FormInputText
                sx={{ maxWidth: "180px" }}
                control={control}
                name="event"
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
            <Grid item xs={3} fontWeight="500">
              우편번호
            </Grid>
            <Grid item xs={9}>
              <Box display="flex" alignItems="center">
                <FormInputText
                  control={control}
                  sx={{ maxWidth: "180px" }}
                  name="zipCode"
                  placeholder=""
                />
                <Button
                  onClick={handleClick}
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "12px",
                    marginLeft: "10px",
                    lineHeight: "15px",
                    padding: "8px 26px",
                    color: "#7653DF",
                    backgroundColor: "#EEEAFB",
                  }}
                >
                  검색
                </Button>
              </Box>
            </Grid>
            <Grid item xs={3} fontWeight="500">
              주소
            </Grid>
            <Grid item xs={9}>
              <FormInputText
                control={control}
                name="address"
                placeholder="상세 주소를 입력해 주세요"
              />
            </Grid>
            <Grid item xs={3} fontWeight="500">
              상세주소
            </Grid>
            <Grid item xs={9}>
              <FormInputText
                control={control}
                name="daddress"
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
            <Grid item xs={3} fontWeight="500">
              대표 이사
            </Grid>
            <Grid item xs={9}>
              <FormInputText
                sx={{ maxWidth: "180px" }}
                control={control}
                name="ceo"
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} pl="26px">
          <Grid container rowSpacing={1} alignItems="center">
            <Grid item xs={3} fontWeight="500">
              클라이언트 담당자
            </Grid>
            <Grid item xs={9}>
              <FormInputText
                sx={{ maxWidth: "180px" }}
                control={control}
                name="client"
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
            <Grid item xs={3} fontWeight="500">
              담당 부서
            </Grid>
            <Grid item xs={9}>
              <FormInputText
                control={control}
                name="department"
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
            <Grid item xs={3} fontWeight="500">
              관리자
            </Grid>
            <Grid item xs={9}>
              <Box display="flex" alignItems="center">
                <FormInputText
                  control={control}
                  sx={{ maxWidth: "180px" }}
                  name="manager"
                  placeholder=""
                />
                {/* <Button
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "12px",
                    marginLeft: "10px",
                    lineHeight: "15px",
                    padding: "8px 26px",
                    color: "#7653DF",
                    backgroundColor: "#EEEAFB",
                  }}
                >
                  검색
                </Button> */}
              </Box>
            </Grid>
            <Grid item xs={3} fontWeight="500">
              전화 번호
            </Grid>
            <Grid item xs={9}>
              <Typography color="red" fontSize={12}>
                핸드폰 번호는 반드시 "-" 제외하고 입력하세요!
              </Typography>
              <FormInputText
                sx={{ maxWidth: "180px" }}
                control={control}
                name="phone"
                placeholder="내용을 입력해 주세요"
                type="number"
              />
            </Grid>
            <Grid item xs={3} fontWeight="500">
              계약일
            </Grid>
            <Grid item xs={9}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "auto",
                }}
              >
                <FormInputDate
                  name="contractDate"
                  control={control}
                  placeholder="YYYY/MM/DD"
                  sx={{
                    ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
                      fontSize: "16px",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      color="info"
                      size="small"
                      variant="outlined"
                      {...params}
                      sx={{
                        width: "127px",
                        background: "#fff",
                        ".MuiInputBase-root": {
                          paddingRight: "0px",
                        },
                        ".MuiIconButton-root": {
                          marginRight: "0px",
                        },
                        input: {
                          paddingRight: "0px!important",
                        },
                        "& .MuiSvgIcon-root": {
                          fill: "#987EE7 !important",
                        },
                      }}
                    />
                  )}
                />
                <Typography
                  style={{ margin: "0 25px 0 10px", fontWeight: "500" }}
                >
                  부터
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3} fontWeight="500">
              계약 기간
            </Grid>
            <Grid item xs={9}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "auto",
                }}
              >
                <FormInputDate
                  name="termDateFrom"
                  control={control}
                  placeholder="YYYY/MM/DD"
                  sx={{
                    ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
                      fontSize: "16px",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      color="info"
                      size="small"
                      variant="outlined"
                      {...params}
                      sx={{
                        width: "127px",
                        background: "#fff",
                        ".MuiInputBase-root": {
                          paddingRight: "0px",
                        },
                        ".MuiIconButton-root": {
                          marginRight: "0px",
                        },
                        input: {
                          paddingRight: "0px!important",
                        },
                        "& .MuiSvgIcon-root": {
                          fill: "#987EE7 !important",
                        },
                      }}
                    />
                  )}
                />
                <Typography
                  style={{ margin: "0 25px 0 10px", fontWeight: "500" }}
                >
                  부터
                </Typography>
                <FormInputDate
                  name="termDateTo"
                  control={control}
                  placeholder="YYYY/MM/DD"
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      {...params}
                      sx={{
                        width: "127px",
                        background: "#fff",
                        paddingRight: "0px!important",
                        ".MuiInputBase-root": {
                          paddingRight: "0px",
                        },
                        ".MuiIconButton-root": {
                          marginRight: "0px",
                        },
                        input: {
                          paddingRight: "0px!important",
                        },
                        "& .MuiSvgIcon-root": {
                          fill: "#987EE7 !important",
                        },
                      }}
                    />
                  )}
                />
                <Typography
                  style={{ margin: "0 12px 0 10px", fontWeight: "500" }}
                >
                  까지
                </Typography>
              </Box>
            </Grid>
            {/* <Grid item xs={3} fontWeight="500">
              상태
            </Grid>
            <Grid item xs={9}>
              <FormInputDropdown
                control={control}
                name="situation"
                sx={{
                  width: "100%",
                }}
                options={situation}
              />
            </Grid> */}
            <Grid item xs={3} fontWeight="500">
              정산개시일/정산일수
            </Grid>
            <Grid item xs={9}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "auto",
                }}
              >
                <Typography
                  style={{
                    margin: "0 15px 0 0",
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                  }}
                >
                  매월
                </Typography>
                <FormInputText
                  control={control}
                  sx={{ maxWidth: "70px", minWidth: "70px" }}
                  name="month"
                  placeholder=""
                />
                <Typography
                  style={{ margin: "0 25px 0 6px", fontWeight: "500" }}
                >
                  일
                </Typography>
                <Typography
                  style={{
                    margin: "0 15px 0 auto",
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                  }}
                >
                  정산일수
                </Typography>
                <FormInputText
                  control={control}
                  sx={{ maxWidth: "70px", minWidth: "70px" }}
                  name="day"
                  placeholder=""
                />
                <Typography style={{ margin: "0 0 0 6px", fontWeight: "500" }}>
                  일
                </Typography>
              </Box>
            </Grid>
            <Typography
              style={{ margin: "10px 0 0 auto", whiteSpace: "nowrap" }}
            >
              (정산일수를 입력시 기준일수에 적용됩니다.)
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {id && (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: "10px",
            }}
          >
            <Typography sx={{ fontSize: "14px" }}>사업장 목록</Typography>

            <Button
              sx={{
                padding: "5px 25px",
                color: "#fff",
                backgroundColor: "#7653DF",
              }}
              onClick={() => setModal({ isOpen: true })}
            >
              사업장 등록 <AddIcon />
            </Button>
          </Box>
          <TableContainer
            className="table"
            sx={{
              borderTop: "1px solid #98A6AD",
            }}
          >
            <Table>
              <TableHead sx={{ background: "#fcfcfc" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                    align="center"
                  >
                    번호
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                    align="center"
                  >
                    현장 코드
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                    align="center"
                  >
                    현장명
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                    align="center"
                  >
                    주소
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                    align="center"
                  >
                    클라이언트담당자
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                    align="center"
                  >
                    관리 담당자
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                    align="center"
                  >
                    연락처
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                    align="center"
                  >
                    상태
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",

                      whiteSpace: "nowrap",
                    }}
                    align="center"
                  >
                    취업자 수
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customer &&
                  customer.subCustomers.map((user, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        role="checkbox"
                        key={index}
                        sx={{
                          "&:hover": {
                            color: "#5a5a5a !important",
                            backgroundColor: "rgba(142, 102, 255, 0.22)",
                          },
                          height: "40px",
                        }}
                      >
                        <TableCell
                          id={labelId}
                          onClick={() =>
                            setModal({ isOpen: true, content: user })
                          }
                          scope="row"
                          padding="none"
                          sx={{
                            padding: "10px",
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          {user.id}
                        </TableCell>
                        <TableCell
                          id={labelId}
                          onClick={() =>
                            setModal({ isOpen: true, content: user })
                          }
                          scope="row"
                          padding="none"
                          sx={{
                            padding: "10px",
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          {user.code}
                        </TableCell>
                        <TableCell
                          id={labelId}
                          onClick={() =>
                            setModal({ isOpen: true, content: user })
                          }
                          scope="row"
                          padding="none"
                          sx={{
                            padding: "10px",
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          {user.name}
                        </TableCell>
                        <TableCell
                          id={labelId}
                          onClick={() =>
                            setModal({ isOpen: true, content: user })
                          }
                          scope="row"
                          padding="none"
                          sx={{
                            padding: "10px",
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          {user.address}
                        </TableCell>
                        <TableCell
                          id={labelId}
                          onClick={() =>
                            setModal({ isOpen: true, content: user })
                          }
                          scope="row"
                          padding="none"
                          sx={{
                            padding: "10px",
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          {user.client}
                        </TableCell>
                        <TableCell
                          id={labelId}
                          onClick={() =>
                            setModal({ isOpen: true, content: user })
                          }
                          scope="row"
                          padding="none"
                          sx={{
                            padding: "10px",
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          {user.manager}
                        </TableCell>
                        <TableCell
                          id={labelId}
                          onClick={() =>
                            setModal({ isOpen: true, content: user })
                          }
                          scope="row"
                          padding="none"
                          sx={{
                            padding: "10px",
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          {user.phone == null ? '' :  formatKoreanPhoneNumber(user.phone)} 
                        </TableCell>
                        <TableCell
                          id={labelId}
                          onClick={() =>
                            setModal({ isOpen: true, content: user })
                          }
                          scope="row"
                          padding="none"
                          sx={{
                            padding: "10px",
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          {user.situation}
                        </TableCell>
                        <TableCell
                          id={labelId}
                          onClick={() =>
                            setModal({ isOpen: true, content: user })
                          }
                          scope="row"
                          padding="none"
                          sx={{
                            padding: "10px",
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          {user.numbOfEmployees ?? 0}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <hr style={{ margin: "40px 0" }} />
      <Box
        display="flex"
        alignItems="center"
        mt="25px"
        justifyContent="space-between"
      >
        {id ? (
          <Button
            onClick={() => deleteCustomerAction(id)}
            size="small"
            sx={{
              fontSize: "12px",
              mr: "6px",
              height: "30px",
              padding: "7px 20px",
              color: "#fff",
              background: "#6B7280",
            }}
          >
            삭제
          </Button>
        ) : (
          <div></div>
        )}
        <Box display="flex" alignItems="center">
          <Button
            onClick={() => navigate(-1)}
            size="small"
            sx={{
              fontSize: "13px",
              mr: "6px",
              height: "30px",
              padding: "7px 26px",
              color: "#fff",
              background: "#98A6AD",
            }}
          >
            취소
          </Button>
          <Button
            size="small"
            onClick={handleSubmit(submit)}
            sx={{
              fontSize: "13px",
              width: "auto",
              height: "30px",
              padding: "7px 46px",
              color: "#fff",
              background: "#7653DF",
            }}
          >
            저장하기
          </Button>
        </Box>
      </Box>
      {modal.isOpen && (
        <SubCompanyModal
          customer={customer}
          open={open}
          setCustomer={setCustomer}
          token={token}
          modal={modal}
          setModal={setModal}
        />
      )}
      {modal.isOpen1 && (
        <AcceptModal
          setModal={setModal}
          modal={{ ...modal, isOpen: modal.isOpen1 }}
          handleClick={deleteById}
          modalKey="isOpen1"
          title="작업을 삭제 하시겠습니까?"
        />
      )}
    </div>
  );
}

export default BusinessDetails;
