import React from "react";
import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  TableHead,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import CustomCheckbox from "../../components/CustomCheckbox";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { bannerStatus, pageSize } from "../../constants/constants";
import { FormInputText } from "../../components/form-components/FormInputText";
import { deleteBannerById, getAllBanner } from "../../api/banner";
import AcceptModal from "../../components/modals/AcceptModal";
import { ToastContainer, toast } from "react-toastify";

const initialQuery = {
  number: 1,
  size: 20,
  order: "DESC",
  sort: "createdDate",
  bannerTitle: "",
};

export default function Banner() {
  const { handleSubmit, control, reset } = useForm({
    defaultValues: initialQuery,
  });
  const { user } = useSelector((state) => state.user);
  const [query, setQuery] = useState(initialQuery);
  const [error, setError] = useState(false);
  const [users, setUsers] = useState();
  const [modal, setModal] = useState({
    isOpen: false,
  });

  const handleChangePage = (_, value) => {
    setQuery({ ...query, number: value });
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    var tempUser = {};
    if (name === "allSelect") {
      tempUser = users.content.map((user) => {
        return { ...user, isChecked: checked };
      });
    } else {
      tempUser = users.content.map((user) =>
        user.bannerTitle + user.id === name
          ? { ...user, isChecked: checked }
          : user
      );
    }
    setUsers({ ...users, content: tempUser });
  };

  async function userList() {
    try {
      const { data } = await getAllBanner(query, user.token);
      setUsers(data);
    } catch (error) {
      console.log(error.response?.data.message);
    }
  }

  const submit = async (values) => {
    const newQuery = {
      ...values,
      status: values.status ? values.status : null,
    };
    setQuery({ ...query, ...newQuery });
  };

  const setInitial = () => {
    reset(initialQuery);
    setQuery(initialQuery);
  };

  const positionSwitcher = (value) => {
    switch (value) {
      case 1:
        return "메인 01";
        break;
      case 2:
        return "메인 02";
        break;
      case 3:
        return "메인 03";
        break;
    }
  };

  const deleteById = () => {
    try {
      modal.deleteIds?.map(async (data) => {
        await deleteBannerById(data, user.token);
      });
      setModal({ isOpen: false });
      userList();
    } catch (error) {
      console.log(error);
      setModal({ isOpen: false });
    }
  };

  const deleteBanners = async () => {
    const checkedBanners = [];

    users?.content?.map((data) => {
      if (data.isChecked) {
        checkedBanners.push(data.id);
      }
      return data.isChecked;
    });

    if (checkedBanners.length === 0) {
      toast.warning("삭제 하고 싶은 배너를 선택해 주세요!");
    } else {
      setModal({ ...modal, isOpen: true, deleteIds: checkedBanners });
    }
  };

  useEffect(() => {
    if (error) {
      toast.warning("Notification!", {
        position: toast.POSITION.TOP_RIGHT,
        onClose: () => {
          setError(false);
        },
      });
    }
  }, [error]);

  useEffect(() => {
    userList();
  }, [query]);

  return (
    <Paper
      sx={{
        width: "100%",
        boxShadow: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          mb: "12px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography
          style={{
            minWidth: "95px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#5a5a5a",
          }}
        >
          검색
        </Typography>
        <FormInputText
          sx={{ width: "280px", marginRight: "6px" }}
          control={control}
          name="bannerTitle"
          placeholder="내용을 입력해 주세요"
        />
        <Link to="/banner-create" style={{ marginLeft: "auto" }}>
          <Button
            size="small"
            sx={{
              fontSize: "13px",
              width: "auto",
              height: "30px",
              padding: "8px 26px",
              color: "#FFF",
              backgroundColor: "#7653DF",
            }}
          >
            배너 등록 <AddIcon />
          </Button>
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography
          style={{
            minWidth: "95px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#5a5a5a",
          }}
        >
          상태
        </Typography>
        <FormInputDropdown
          control={control}
          name="status"
          sx={{ width: "111px", marginRight: "10px" }}
          options={bannerStatus}
        />
      </Box>
      <hr style={{ margin: "30px auto 20px auto", borderColor: "#E7E7E7" }} />
      <Box
        sx={{
          display: "flex",
          mb: "40px",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Button
          size="small"
          sx={{
            fontSize: "13px",
            width: "auto",
            mr: "30px",
            height: "30px",
            padding: "7px 30px",
            color: "#fff",
            background: "#987EE7",
          }}
          onClick={handleSubmit(submit)}
        >
          설정한 조건으로 검색
        </Button>
        <Box
          size="small"
          onClick={setInitial}
          sx={{
            textDecoration: "underline",
            fontSize: "14px",
            color: "#6B7280",
            fontWeight: 500,
            "&:hover": {
              color: "#8E66FF",
              cursor: "pointer",
            },
          }}
        >
          검색조건 초기화
        </Box>
      </Box>

      {users?.content && (
        <>
          <Box
            sx={{
              display: "flex",
              mb: "15px",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                onClick={deleteBanners}
                size="small"
                sx={{
                  fontSize: "12px",
                  mr: "6px",
                  height: "30px",
                  padding: "7px 20px",
                  color: "#fff",
                  background: "#6B7280",
                }}
              >
                삭제
              </Button>
              {/* <Button
                sx={{
                  fontSize: "12px",
                  lineHeight: "15px",
                  height: "30px",
                  padding: "7px 26px",
                  whiteSpace: "nowrap",
                  color: "#fff",
                  background: "#6B7280",
                  marginLeft: "auto",
                }}
              >
                엑셀 다운로드
              </Button> */}
              <CustomCheckbox
                name="allSelect"
                checked={
                  !users.content?.some((user) => user.isChecked !== true)
                }
                onChange={handleChange}
              />
              <Typography
                style={{
                  minWidth: "95px",
                  fontWeight: "500",
                  color: "#5A5A5A",
                }}
              >
                {/* 배너로 정정 희망( {users?.totalElements} 개) 선택 */}
                현재 해당되는 모든 배너 ( {users?.totalElements} 개) 선택
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormInputDropdown
                control={control}
                name="size"
                sx={{ width: "111px" }}
                onChange={(e) => setQuery({ ...query, size: e.target.value })}
                options={pageSize}
                staticValue={query.size}
              />
            </Box>
          </Box>
          <TableContainer
            className="table"
            sx={{
              mt: "10px",
              borderTop: "1px solid #98a6ad",
            }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead sx={{ background: "#FCFCFC" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                      textAlign: "center",
                    }}
                    align="center"
                  >
                    <CustomCheckbox
                      color="primary"
                      name="allSelect"
                      checked={
                        !users.content?.some((user) => user.isChecked !== true)
                      }
                      onChange={handleChange}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                      width: "100px",
                    }}
                    align="center"
                  >
                    번호
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    위치
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    제목
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    작성 일자
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      color: "#5A5A5A",
                      fontSize: "13px",
                    }}
                    align="center"
                  >
                    상태
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users?.content?.map((user, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#EEEAFB",
                        },
                        ".css-1d46hnn-MuiTableCell-root": {
                          padding: "1px",
                        },
                        textDecoration: "none",
                        height: "40px",
                      }}
                    >
                      <TableCell
                        padding="checkbox"
                        sx={{
                          padding: "10px",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                        align="center"
                      >
                        <CustomCheckbox
                          name={`${user.bannerTitle + user.id}`}
                          checked={user?.isChecked || false}
                          onChange={handleChange}
                        />
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/banner-details/${user.id}`}
                          style={{
                            textDecoration: "none",
                            padding: 10,
                            display: "block",
                            textAlign: "center",
                            width: "100px",
                          }}
                        >
                          {query.size * (query.number - 1) + 1 + index}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/banner-details/${user.id}`}
                          style={{
                            textDecoration: "none",
                            padding: 10,
                            display: "block",
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          {positionSwitcher(user.position)}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/banner-details/${user.id}`}
                          style={{
                            textDecoration: "none",
                            padding: 10,
                            display: "block",
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          {user.bannerTitle}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/banner-details/${user.id}`}
                          style={{
                            textDecoration: "none",
                            padding: 10,
                            display: "block",
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          {user.createdDate}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/banner-details/${user.id}`}
                          style={{
                            textDecoration: "none",
                            padding: 10,
                            display: "block",
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          {user.status}
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
            <Pagination
              count={Math.ceil(users.totalElements / query.size)}
              onChange={handleChangePage}
              page={query.number}
              color="primary"
              variant="outlined"
              shape="rounded"
              sx={{
                m: "0 auto",
                "& .css-1k2i9p6-MuiButtonBase-root-MuiPaginationItem-root": {
                  height: "20px",
                  minWidth: "20px",
                  backgroundColor: "#CCCCCC",
                  color: "#fff",
                },
              }}
            />
          </Stack>
        </>
      )}
      {modal.isOpen && (
        <AcceptModal
          handleClick={deleteById}
          modal={{ ...modal }}
          modalKey="isOpen"
          setModal={setModal}
          title="배너를 삭제 하시겠습니까?"
        />
      )}
      <ToastContainer />
    </Paper>
  );
}
