import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  TableHead,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { exportDataToExcel, getAge } from "../../utils/methods";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import { FormInputText } from "../../components/form-components/FormInputText";
import CustomCheckbox from "../../components/CustomCheckbox";
import { getAllCertificate, updateCertificate } from "../../api/certificate";
import { pageSize } from "../../constants/constants";
import SmsSendModal from "../../components/modals/SmsSendModal";

const searchOptions = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "재직증명",
    value: "재직증명",
  },
  {
    label: "경력증명",
    value: "경력증명",
  },
  {
    label: "퇴직증명",
    value: "퇴직증명",
  },
  {
    label: "경력확인",
    value: "경력확인",
  },
  {
    label: "퇴직금정산",
    value: "퇴직금정산",
  },
];

const handleOptions = [
  {
    label: "대기",
    value: "REQUESTED",
  },
  {
    label: "허가",
    value: "APPROVED",
  },
  {
    label: "불가",
    value: "DENIED",
  },
];

const initialQuery = {
  number: 1,
  size: 20,
  keyword: "",
  searchType: "",
  status: "",
};

function CertificateApplicant() {
  const methods = useForm();
  const { handleSubmit, control, reset } = methods;
  const { token } = useSelector((state) => state.user.user);
  const [list, setList] = useState();
  const [query, setQuery] = useState(initialQuery);
  const [userStatus, setUserStatus] = useState({
    id: null,
    status: "",
  });
  const [smsModal, setSmsModal] = useState({ isOpen: false });

  const getList = async () => {
    try {
      const { data } = await getAllCertificate(query, token);
      setList(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = (event, value) => {
    setQuery({ ...query, page: value });
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    var tempUser = {};
    if (name === "allSelect") {
      tempUser = list?.content.map((user) => {
        return { ...user, isChecked: checked };
      });
    } else {
      tempUser = list?.content.map((user) =>
        user.fullName + user.id === name
          ? { ...user, isChecked: checked }
          : user
      );
    }
    setList({ ...list, content: tempUser });
  };

  const handleChangeStatus = (userId, status) => {
    setUserStatus({
      id: userId,
      status: status,
    });
  };

  const submitSearch = async (values) => {
    setQuery({ ...query, ...values });
  };

  const download = () => {
    const checkedData = [];
    list?.content?.map((data) => {
      if (data.isChecked) {
        checkedData.push(data);
      }
      return data.isChecked;
    });

    if (checkedData.length === 0) {
      toast.warning("사원을 선택해 주세요!");
    } else {
      exportDataToExcel(checkedData, "제증명 신청자");
    }
  };

  const smsSend = () => {
    const checkedData = [];
    list?.content?.map((data) => {
      if (data.isChecked) {
        data.name = data.fullName;
        checkedData.push(data);
      }
      return data.isChecked;
    });

    if (checkedData.length === 0) {
      toast.warning("사원을 선택해 주세요!");
    } else {
      setSmsModal({ isOpen: true, checkedUsers: checkedData });
    }
  };

  const setInitial = () => {
    reset(initialQuery);
    setQuery(initialQuery);
  };

  const submit = async () => {
    try {
      const res = await updateCertificate(JSON.stringify(userStatus), token);
      if (res.statusCode === 200) {
        toast.success(res.message);
      }
    } catch (err) {
      console.log(err);
      toast.warning(err.response?.data.message);
    }
  };

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      sx={{
        width: "100%",

        boxShadow: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          mb: "6px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography style={{ minWidth: "95px", fontWeight: "500" }}>
          검색
        </Typography>
        <FormInputText
          sx={{ width: "180px", marginRight: "6px" }}
          control={control}
          name="keyword"
          placeholder="내용을 입력해 주세요"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          mb: "6px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography style={{ minWidth: "95px", fontWeight: "500" }}>
          구분
        </Typography>
        <FormInputDropdown
          control={control}
          name="searchType"
          sx={{ width: "180px", marginRight: "10px" }}
          options={searchOptions}
        />
      </Box>
      <hr style={{ margin: "30px auto 20px auto" }} />
      <Box
        sx={{
          display: "flex",
          mb: "40px",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Button
          onClick={handleSubmit(submitSearch)}
          size="small"
          sx={{
            fontSize: "13px",
            mr: "20px",
            height: "30px",
            padding: "7px 30px",
            color: "#fff",
            background: "#987EE7",
          }}
        >
          설정한 조건으로 검색
        </Button>
        <Box
          onClick={setInitial}
          component={Link}
          to="#"
          size="small"
          sx={{
            fontSize: "13px",
            color: "#6B7280",
            "&:hover": {
              color: "#8E66FF",
            },
          }}
        >
          검색조건 초기화
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          mb: "10px",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex" alignItems="center">
          <Button
            onClick={smsSend}
            sx={{
              fontSize: "12px",
              lineHeight: "15px",
              height: "30px",
              color: "#fff",
              padding: "7px 20px",
              marginRight: "6px",
              background: "#6B7280",
            }}
          >
            SMS
          </Button>
          <Button
            onClick={() => download()}
            sx={{
              fontSize: "12px",
              lineHeight: "15px",
              height: "30px",
              padding: "7px 26px",
              whiteSpace: "nowrap",
              color: "#fff",
              background: "#6B7280",
              marginLeft: "auto",
            }}
          >
            엑셀 다운로드
          </Button>
          <CustomCheckbox
            name="allSelect"
            checked={!list?.content?.some((user) => user.isChecked !== true)}
            onChange={handleChange}
          />
          <Typography style={{ minWidth: "95px", fontWeight: "500" }}>
            현재 해당되는 모든 회원 ( {list?.totalElements} 명) 선택
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormInputDropdown
            control={control}
            name="size"
            sx={{ width: "90px" }}
            onChange={(e) => setQuery({ ...query, size: e.target.value })}
            options={pageSize}
            staticValue={query.size}
          />
        </Box>
      </Box>
      <TableContainer
        className="table"
        sx={{
          borderTop: "1px solid #98A6AD",
          height: `${window.innerHeight - 470}px`,
          position: "relative",
        }}
      >
        <Table>
          <TableHead
            sx={{
              background: "#ebebeb",
              position: "sticky",
              top: 0,
              zIndex: 10,
            }}
          >
            <TableRow>
              <TableCell
                sx={{
                  minWidth: "70px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: 500,
                  textAlign: "center",
                }}
                align="center"
              >
                <CustomCheckbox
                  color="primary"
                  name="allSelect"
                  checked={
                    !list?.content?.some((user) => user.isChecked !== true)
                  }
                  onChange={handleChange}
                />
              </TableCell>
              <TableCell
                sx={{
                  minWidth: "70px",
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                align="center"
              >
                번호
              </TableCell>
              <TableCell
                sx={{
                  minWidth: "70px",
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                align="center"
              >
                성명
              </TableCell>
              <TableCell
                sx={{
                  minWidth: "70px",
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                align="center"
              >
                주민번호
              </TableCell>
              <TableCell
                sx={{
                  minWidth: "70px",
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                align="center"
              >
                연락처
              </TableCell>
              <TableCell
                sx={{
                  minWidth: "70px",
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  width: "280px",
                  fontWeight: 500,
                }}
                align="center"
              >
                업체명
              </TableCell>
              <TableCell
                sx={{
                  minWidth: "70px",
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                align="center"
              >
                신청일자
              </TableCell>
              <TableCell
                sx={{
                  minWidth: "70px",
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                align="center"
              >
                처리일자
              </TableCell>
              <TableCell
                sx={{
                  minWidth: "70px",
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                align="center"
              >
                발급(처리)번호
              </TableCell>
              <TableCell
                sx={{
                  minWidth: "70px",
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                align="center"
              >
                종류
              </TableCell>
              <TableCell
                sx={{
                  minWidth: "7px",
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
                align="center"
              >
                상태
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list && !list.empty
              ? list.content.map((user, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      key={index}
                      sx={{
                        "&:hover": {
                          color: "#5a5a5a !important",
                          backgroundColor: "rgba(142, 102, 255, 0.22)",
                        },
                        textDecoration: "none",
                        height: "40px",
                      }}
                    >
                      <TableCell
                        padding="checkbox"
                        sx={{
                          padding: "10px",
                        }}
                        align="center"
                      >
                        <CustomCheckbox
                          name={user.fullName + user.id}
                          checked={user?.isChecked || false}
                          onChange={handleChange}
                        />
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/user-details/${user.applicantId}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {query.size * (query.number - 1) + index + 1}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/user-details/${user.applicantId}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.fullName}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/user-details/${user.applicantId}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.registrationNumber}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/user-details/${user.applicantId}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                          minWidth: 150,
                        }}
                        align="center"
                      >
                        {user.address}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/user-details/${user.applicantId}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.companyName}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/user-details/${user.applicantId}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                          whiteSpace: "nowrap",
                        }}
                        align="center"
                      >
                        {getAge(user.joinedDate)}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/user-details/${user.applicantId}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                          whiteSpace: "nowrap",
                        }}
                        align="center"
                      >
                        {user.createdDate}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/user-details/${user.applicantId}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                        }}
                        align="center"
                      >
                        {user.registrationNumber}
                      </TableCell>
                      <TableCell
                        component={Link}
                        id={labelId}
                        scope="row"
                        padding="none"
                        to={`/user-details/${user.applicantId}`}
                        sx={{
                          textDecoration: "none",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "10px",
                          whiteSpace: "nowrap",
                        }}
                        align="center"
                      >
                        {user.type}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        scope="row"
                        padding="none"
                        width="max-content"
                        sx={{
                          padding: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "auto",
                          }}
                        >
                          <FormInputDropdown
                            control={control}
                            name={`status-${user.applicantId}`}
                            sx={{
                              width: "75px",
                              marginRight: "10px",
                              backgroundColor: "#fff",
                              borderRadius: "20px!important",
                              fieldset: {
                                color: "#6B7280!important",
                                top: "-7px",
                                borderRadius: "20px!important",
                              },
                            }}
                            options={handleOptions}
                            staticValue={user.status}
                            onChange={(e) =>
                              handleChangeStatus(user.id, e.target.value)
                            }
                          />
                          <Button
                            onClick={handleSubmit((values) =>
                              submit(user.id, values)
                            )}
                            sx={{
                              color: "#FFFFFF",
                              lineHeight: "18px",
                              borderRadius: "8px!important",
                              padding: "6px 14px",
                              backgroundColor: "#8E66FF",
                            }}
                          >
                            수정
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
        <Pagination
          count={Math.ceil(list?.totalElements / query.size) || 0}
          onChange={handleChangePage}
          page={query.number}
          color="primary"
          variant="outlined"
          shape="rounded"
          sx={{
            m: "0 auto",
            "& .MuiPaginationItem-root.MuiPaginationItem-page, .MuiPaginationItem-previousNext":
              { padding: "4px 20px !important" },
          }}
        />
      </Stack>
      <SmsSendModal
        setModal={setSmsModal}
        modal={{ ...smsModal, isOpen: smsModal.isOpen }}
        modalKey="isOpen"
      />
    </Paper>
  );
}

export default CertificateApplicant;
