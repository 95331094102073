import React from "react";
import { Box, Button, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { NavLink, useLocation } from "react-router-dom";

export default function UserChanger({ url = "user-details", user }) {
  const { state } = useLocation();
  return (
    <Box
      display="flex"
      alignItems="center"
      marginLeft="auto"
      marginRight="20px"
    >
      <Button
        component={NavLink}
        to={`/${url}/${user.previousId}`}
        state={state}
        sx={{
          padding: "0px",
          color: "#98A6AD",
          fontSize: "12px",
          minWidth: "unset",
          "&:hover": {
            backgroundColor: "transparent!important",
            color: "#5a5a5a",
          },
        }}
        disabled={!user.previousId}
      >
        {user.previousName}
        <KeyboardArrowLeftIcon />
      </Button>
      <Typography sx={{ color: "#5A5A5A", fontSize: "12px" }} variant="h6">
        {user.name}
      </Typography>
      <Button
        component={NavLink}
        state={state}
        to={`/${url}/${user.nextId}`}
        sx={{
          padding: "0px",
          color: "#98A6AD",
          fontSize: "12px",
          minWidth: "unset",
          "&:hover": {
            backgroundColor: "transparent!important",
            color: "#5a5a5a",
          },
        }}
        disabled={!user.nextId}
      >
        <KeyboardArrowRightIcon />
        {user.nextName}
      </Button>
    </Box>
  );
}
