import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  TableHead,
  TextField,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { order, pageSize } from "../../constants/constants";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import { FormInputText } from "../../components/form-components/FormInputText";
import CustomCheckbox from "../../components/CustomCheckbox";
import { FormInputDate } from "../../components/form-components/FormInputDate";
import { getAllJobs } from "../../api/recruitment";
import FilterButtons from "../../components/FilterButtons";
import {
  checkJobDedline,
  exportDataToExcel,
  getAge,
} from "../../utils/methods";
import SmsSendModal from "../../components/modals/SmsSendModal";
import Loader from "../../components/loader";

const searchOptions = [
  {
    label: "선택",
    value: "",
  },
  {
    label: "업체명",
    value: "companyName",
  },
  {
    label: "모집 부문",
    value: "title",
  },
  {
    label: "관리자",
    value: "managerName",
  },
];

const initialQuery = {
  number: 1,
  size: 20,
  order: "DESC",
  sort: "createdDate",
  dateTo: "",
  keyword: "",
  dateFrom: "",
  searchType: "",
};

function AllJobs() {
  const { user } = useSelector((state) => state.user);
  const [query, setQuery] = useState(initialQuery);
  const [jobs, setJobs] = useState();
  // eslint-disable-next-line no-unused-vars
  const { handleSubmit, control, reset, setValue, watch } = useForm({
    defaultValues: initialQuery,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [smsModal, setSmsModal] = useState({ isOpen: false });

  const handleChangePage = (_, value) => {
    setQuery({ ...query, number: value });
  };

  const donwloadExcel = () => {
    const checkedData = [];
    jobs?.content?.map((data) => {
      if (data.isChecked) {
        checkedData.push(data);
      }
      return data.isChecked;
    });

    if (checkedData.length === 0) {
      toast.warning("채용 벙보를 선택해 주세요!");
    } else {
      exportDataToExcel(checkedData, "전체 채용 정보");
    }
  };

  const smsSend = () => {
    const checkedData = [];
    jobs?.content?.map((data) => {
      if (data.isChecked) {
        checkedData.push(data);
      }
      return data.isChecked;
    });

    if (checkedData.length === 0) {
      toast.warning("채용 벙보를 선택해 주세요!");
    } else {
      setSmsModal({ isOpen: true, checkedUsers: checkedData, job: true });
    }
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    var tempUser = {};
    if (name === "allSelect") {
      tempUser = jobs.content.map((user) => {
        return { ...user, isChecked: checked };
      });
    } else {
      tempUser = jobs.content.map((user) =>
        user.title + user.id === name ? { ...user, isChecked: checked } : user
      );
    }
    setJobs({ ...jobs, content: tempUser });
  };

  async function userList() {
    try {
      setIsLoading(true);
      const { data } = await getAllJobs(query, user.token);
      setIsLoading(false);
      setJobs(data);
    } catch (error) {
      console.log(error.response?.data.message);
    }
  }

  const submit = async (values) => {
    const from = new Date(values.dateFrom);
    const to = new Date(values.dateTo);
    const dateFrom = values.dateFrom ? from.toISOString().split("T")[0] : "";
    const dateTo = values.dateTo ? to.toISOString().split("T")[0] : "";
    setQuery({
      ...query,
      ...values,
      dateFrom: dateFrom,
      dateTo: dateTo,
    });
  };

  const setInitial = () => {
    reset(initialQuery);
    setQuery(initialQuery);
  };

  const addressCopy = (item) => {
   
    try {
      const tempTextArea = document.createElement("textarea");
      tempTextArea.value = item.businessAddress;

      // Append the textarea to the DOM
      document.body.appendChild(tempTextArea);

      // Select the text in the textarea
      tempTextArea.select();

      // Execute the copy command
      document.execCommand("copy");

      // Remove the temporary textarea from the DOM
      document.body.removeChild(tempTextArea);

      if (item.businessAddress) {
        toast.success("Copied! " + item.businessAddress);
      } else {
        toast.warning("No address");
      }
    } catch (err) {
      console.error("Failed to copy:", err);
      alert("Failed to copy: " + err.message);
    }
  };

  useEffect(() => {
    userList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <Paper
      sx={{
        width: "100%",
        boxShadow: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          mb: "16px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography style={{ minWidth: "95px", fontWeight: 500 }}>
          검색
        </Typography>
        <FormInputDropdown
          control={control}
          name="searchType"
          sx={{ width: "127px", marginRight: "10px" }}
          options={searchOptions}
        />
        <FormInputText
          sx={{ width: "284px", marginRight: "6px" }}
          control={control}
          name="keyword"
          placeholder="내용을 입력해 주세요"
        />
        <Button
          size="small"
          onClick={handleSubmit(submit)}
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "7px 18px",
            color: "#7653DF",
            background: "#EEEAFB",
          }}
        >
          검색
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          mb: "16px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography style={{ minWidth: "95px", fontWeight: 500 }}>
          범위 선택
        </Typography>
        <FormInputDate
          name="dateFrom"
          control={control}
          placeholder="YYYY/MM/DD"
          sx={{
            ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
              fontSize: "16px",
            },
          }}
          renderInput={(params) => (
            <TextField
              color="info"
              size="small"
              variant="outlined"
              {...params}
              sx={{
                width: "127px",
                background: "#fff",
                ".MuiInputBase-root": {
                  paddingRight: "0px",
                },
                ".MuiIconButton-root": {
                  marginRight: "0px",
                },
                input: {
                  paddingRight: "0px!important",
                },
                "& .MuiSvgIcon-root": {
                  fill: "#987EE7 !important",
                },
              }}
            />
          )}
        />
        <Typography style={{ margin: "0 25px 0 10px" }}>부터</Typography>
        <FormInputDate
          name="dateTo"
          control={control}
          placeholder="YYYY/MM/DD"
          renderInput={(params) => (
            <TextField
              variant="outlined"
              {...params}
              sx={{
                width: "127px",
                background: "#fff",
                paddingRight: "0px!important",
                ".MuiInputBase-root": {
                  paddingRight: "0px",
                },
                ".MuiIconButton-root": {
                  marginRight: "0px",
                },
                input: {
                  paddingRight: "0px!important",
                },
                "& .MuiSvgIcon-root": {
                  fill: "#987EE7 !important",
                },
              }}
            />
          )}
        />
        <Typography style={{ margin: "0 12px 0 10px" }}>까지</Typography>
        <FilterButtons setValue={setValue} />
      </Box>
      <div style={{ margin: "30px auto 20px auto", borderColor: "#828282" }} />
      <Box
        sx={{
          display: "flex",
          mb: "48px",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Button
          size="small"
          onClick={handleSubmit(submit)}
          sx={{
            fontSize: "13px",
            width: "auto",
            mr: "20px",
            height: "30px",
            padding: "7px 46px",
            color: "#fff",
            background: "#987EE7",
          }}
        >
          설정한 조건으로 검색
        </Button>
        <Box
          onClick={setInitial}
          sx={{
            color: "#6B7280",
            textDecoration: "underline",
            cursor: "pointer",
            "&:hover": {
              color: "#8E66FF",
            },
          }}
        >
          검색조건 초기화
        </Box>
      </Box>
      {jobs && (
        <Box
          sx={{
            display: "flex",
            mb: "15px",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              onClick={smsSend}
              sx={{
                fontSize: "13px",
                mr: "8px",
                height: "30px",
                padding: "7px 26px",
                color: "#fff",
                background: "#6B7280",
              }}
            >
              SMS
            </Button>
            <Button
              onClick={() => donwloadExcel()}
              sx={{
                fontSize: "13px",
                mr: "8px",
                height: "30px",
                padding: "7px 26px",
                color: "#fff",
                background: "#6B7280",
              }}
            >
              엑셀 다운로드
            </Button>
            <CustomCheckbox
              name="allSelect"
              checked={!jobs.content?.some((user) => user.isChecked !== true)}
              onChange={handleChange}
            />
            <Typography style={{ minWidth: "95px", fontWeight: 500 }}>
              현재 해당되는 모든 회원 ( {jobs?.totalElements} 명) 선택
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormInputDropdown
              control={control}
              name="order"
              sx={{ width: "111px", marginRight: "10px" }}
              options={order}
              onChange={(e) => setQuery({ ...query, order: e.target.value })}
              staticValue={query.order}
            />
            <FormInputDropdown
              control={control}
              name="size"
              sx={{ width: "111px" }}
              onChange={(e) => setQuery({ ...query, size: e.target.value })}
              options={pageSize}
              staticValue={query.size}
            />
          </Box>
        </Box>
      )}
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Loader style={{ backgroundColor: "red" }} />
        </Box>
      ) : (
        <Box>
          <TableContainer
            className="table"
            sx={{
              borderTop: "1px solid #98A6AD",
              height: `${window.innerHeight - 450}px`,
              position: "relative",
            }}
          >
            <Table>
              <TableHead
                sx={{
                  background: "#ebebeb",
                  position: "sticky",
                  top: 0,
                  zIndex: 10,
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                    align="center"
                  >
                    <CustomCheckbox
                      color="primary"
                      name="allSelect"
                      checked={
                        !jobs?.content?.some((user) => user.isChecked !== true)
                      }
                      onChange={handleChange}
                    />
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    고유번호
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    업체명
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    모집 부문
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    모집 인원 수
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    관리자
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    마감일
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    진행 현황
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    align="center"
                  >
                    주소 복사
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobs
                  ? jobs.content.map((user, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          role="checkbox"
                          key={index}
                          sx={{
                            "&:hover": {
                              color: "#5a5a5a !important",
                              backgroundColor: "rgba(142, 102, 255, 0.22)",
                            },
                            textDecoration: "none",
                            height: "40px",
                          }}
                        >
                          <TableCell
                            padding="checkbox"
                            sx={{
                              padding: "10px",
                              fontWeight: 500,
                            }}
                            align="center"
                          >
                            <CustomCheckbox
                              name={user.title + user.id}
                              checked={user?.isChecked || false}
                              onChange={handleChange}
                            />
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/job-management/reqirement-update/${user.id}`}
                            sx={{
                              textDecoration: "none",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "10px",
                            }}
                            align="center"
                          >
                            {query.size * (query.number - 1) + index + 1}
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/job-management/reqirement-update/${user.id}`}
                            sx={{
                              textDecoration: "none",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "10px",
                            }}
                            align="center"
                          >
                            {user.companyName}
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/job-management/reqirement-update/${user.id}`}
                            sx={{
                              textDecoration: "none",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "10px",
                            }}
                            align="center"
                          >
                            {user.title}
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/job-management/support-status/${user.id}`}
                            sx={{
                              textDecoration: "none",
                              padding: "10px",
                              fontWeight: "500",
                            }}
                            align="center"
                          >
                            {user.numberOfPeople}
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/job-management/reqirement-update/${user.id}`}
                            sx={{
                              textDecoration: "none",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "10px",
                            }}
                            align="center"
                          >
                            {user.managerName}
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/job-management/reqirement-update/${user.id}`}
                            sx={{
                              textDecoration: "none",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "10px",
                            }}
                            align="center"
                          >
                            {getAge(user.periodTo)}
                          </TableCell>
                          <TableCell
                            component={Link}
                            id={labelId}
                            scope="row"
                            padding="none"
                            to={`/job-management/reqirement-update/${user.id}`}
                            sx={{
                              textDecoration: "none",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "10px",
                            }}
                            align="center"
                          >
                            {checkJobDedline({
                              always: user.always,
                              dedline: user.periodTo,
                            })}
                          </TableCell>
                          <TableCell
                            onClick={() => addressCopy(user)}
                            id="address-field"
                            scope="row"
                            padding="none"
                            sx={{
                              padding: "10px",
                              cursor: "pointer",
                              textDecoration: "underline",
                              color: "#2D9CDB",
                              "&:hover": {
                                color: "#8e66ff",
                              },
                            }}
                            align="center"
                          >
                            링크복사
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
            <Pagination
              count={Math.ceil(jobs?.totalElements / query.size) || 0}
              onChange={handleChangePage}
              page={query.number}
              color="primary"
              variant="outlined"
              shape="rounded"
              sx={{
                m: "0 auto",
                "& .MuiPaginationItem-root.MuiPaginationItem-page, .MuiPaginationItem-previousNext":
                  { padding: "4px 20px !important" },
              }}
            />
          </Stack>
        </Box>
      )}

      <SmsSendModal
        setModal={setSmsModal}
        modal={{ ...smsModal, isOpen: smsModal.isOpen }}
        modalKey="isOpen"
      />
    </Paper>
  );
}

export default AllJobs;
