import mainCaller from "./mainCaller";

export async function getAllCustomers(data, token) {
  return await mainCaller(`customers`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}
export async function getEmployessOfCustomer(data, token) {
  return await mainCaller(
    `careerStage/findAllByCustomerIdAndStatus`,
    "POST",
    data,
    {
      Authorization: `Bearer ${token}`,
    }
  );
}
export async function getSubCustomers(data, token) {
  return await mainCaller(`subCustomers`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function updateCustomerById(data, token) {
  return await mainCaller(`customers/update`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getCustomerById(id, token) {
  return await mainCaller(`customers/getById/${id}`, "GET", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function deleteCustomerById(id, token) {
  return await mainCaller(`customers/deleteById/${id}`, "DELETE", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function deleteChildrenById(id, token) {
  return await mainCaller(`subCustomers/deleteById/${id}`, "DELETE", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function createCustomer(data, token) {
  return await mainCaller(`customers/create`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function createChildren(data, token) {
  return await mainCaller(`subCustomers/create`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getChildrens(data, token) {
  return await mainCaller(`departments`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function updateChildren(data, token) {
  return await mainCaller(`subCustomers/update`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function customerSearch(keyword, token) {
  return await mainCaller(`customers/findByKeyword/${keyword}`, "GET", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function customersAllSearchNoPage(data, token) {
  return await mainCaller(`customers/getAllList`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

//Head Hunting api
export async function getAllHhunting(data, token) {
  return await mainCaller(`headhunting`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getHhuntingById(id, token) {
  return await mainCaller(`headhunting/getById/${id}`, "GET", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function updateHhuntingById(data, token) {
  return await mainCaller(`headhunting/update`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function deleteHhuntingById(id, token) {
  return await mainCaller(`headhunting/deleteById/${id}`, "DELETE", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getMemoByHHuntingId(id, token) {
  return await mainCaller(`memo/${id}`, "GET", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function createHhuntingMemo(data, token) {
  return await mainCaller(`memo/create`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function updateMemoByHhuntingId(data, token) {
  return await mainCaller(`memo/update`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}
