import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FormInputText } from "../../../components/form-components/FormInputText";
import { FormInputDropdown } from "../../../components/form-components/FormInputDropdown";
import { eduLevel, gender } from "../../../constants/constants";
import { FormInputDate } from "../../../components/form-components/FormInputDate";
import { formatLocaleDate } from "../../../utils/methods";
import { updatePersonnelById } from "../../../api/user";

export default function UserTab2({ user, token }) {
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm({ defaultValues: user });

  const submit = async (values) => {
    const newValues = {
      ...values,
      birthday: formatLocaleDate(values.birthday),
    };
    try {
      await updatePersonnelById(newValues, token);
      navigate("/talent/number-contacts");
    } catch (error) {
      console.log(error.response?.data.message);
    }
  };

  return (
    <Paper sx={{ boxShadow: "none" }}>
      <Box
        sx={{
          width: "100%",
          pl: "20px",
          marginTop: "50px",
        }}
      >
        <Typography mb="30px" fontSize="16px" fontWeight="700">
          개인 정보
        </Typography>
        <Grid container>
          <Grid item xs={6}>
            <Grid container rowSpacing={1} alignItems="center">
              <Grid item mb="4px" xs={2} fontWeight="500">
                이름(한글)
              </Grid>
              <Grid item mb="4px" xs={10}>
                <FormInputText
                  control={control}
                  name="name"
                  placeholder="내용을 입력해 주세요"
                />
              </Grid>
              <Grid item xs={2} mb="4px" fontWeight="500">
                성별
              </Grid>
              <Grid item xs={4} mb="4px">
                <FormInputDropdown
                  control={control}
                  name="gender"
                  sx={{
                    maxWidth: "180px",
                    width: "100%",
                  }}
                  options={gender}
                />
              </Grid>
              <Grid item xs={2} mb="4px" fontWeight="500" textAlign="center">
                최종 학력
              </Grid>
              <Grid item xs={4} mb="4px">
                <FormInputDropdown
                  control={control}
                  name="eduLevel"
                  sx={{
                    maxWidth: "180px",
                    width: "100%",
                  }}
                  options={eduLevel}
                />
              </Grid>
              <Grid item mb="4px" xs={2} fontWeight="500">
                최종출신학교
              </Grid>
              <Grid item xs={10} mb="4px">
                <FormInputText
                  control={control}
                  name="school"
                  placeholder="내용을 입력해주세요."
                />
              </Grid>
              <Grid item xs={2} fontWeight="500">
                콘택트 경로
              </Grid>
              <Grid item mb="4px" xs={10}>
                <FormInputText
                  control={control}
                  name="contactPath"
                  placeholder="내용을 입력해 주세요"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} pl="35px">
            <Grid container rowSpacing={1} alignItems="center">
              <Grid item mb="4px" xs={2} fontWeight="500">
                생년월일
              </Grid>
              <Grid item mb="4px" xs={10}>
                <FormInputDate
                  name="birthday"
                  control={control}
                  placeholder="YYYY/MM/DD"
                  renderInput={(params) => (
                    <TextField
                      color="info"
                      size="small"
                      variant="outlined"
                      {...params}
                      sx={{
                        width: "100%",
                        background: "#fff",
                        ".MuiInputBase-root": {
                          paddingRight: "0px",
                        },
                        ".MuiIconButton-root": {
                          marginRight: "0px",
                        },
                        input: {
                          paddingRight: "0px!important",
                        },
                        "& .MuiSvgIcon-root": {
                          fill: "#987EE7 !important",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item mb="4px" xs={2} fontWeight="500">
                휴대폰
              </Grid>
              <Grid item mb="4px" xs={10}>
                <Typography color="red" fontSize={12}>
                  핸드폰 번호는 반드시 "-" 제외하고 입력하세요!
                </Typography>
                <FormInputText
                  control={control}
                  name="phoneNumber"
                  placeholder="전화 번호를 입력해 주세요"
                />
              </Grid>
              <Grid item mb="4px" xs={2} fontWeight="500">
                전공 학과
              </Grid>
              <Grid item mb="4px" xs={10}>
                <FormInputText
                  control={control}
                  name="department"
                  placeholder="hong123@gmail.com"
                />
              </Grid>
              <Grid item mb="4px" xs={2} fontWeight="500">
                소재지
              </Grid>
              <Grid item mb="4px" xs={10}>
                <FormInputText
                  control={control}
                  name="location"
                  placeholder="hong123"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <hr style={{ margin: "298px 0 40px 0" }} />
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Button
          size="small"
          sx={{
            display: "block",
            fontSize: "12px",
            lineHeight: "15px",
            mr: "8px",
            padding: "7px 26px",
            color: "#fff",
            background: "#98A6AD",
          }}
        >
          취소
        </Button>
        <Button
          size="small"
          onClick={handleSubmit(submit)}
          sx={{
            fontSize: "12px",
            lineHeight: "15px",
            padding: "7px 50px",
            display: "block",
            color: "#fff",
            backgroundColor: "#7653DF",
          }}
        >
          저장
        </Button>
      </Box>
    </Paper>
  );
}
