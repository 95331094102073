import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import {
  Box,
  Typography,
  Button,
  Grid,
  Stack,
  Pagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
} from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import BackButton from "../../components/BackButton";
import LinkButton from "../../components/LinkButton";
import { useSelector } from "react-redux";
import { getCustomerById, getEmployessOfCustomer } from "../../api/customers";
import { pageSize, toDayCountry } from "../../constants/constants";
import { awsImagePath, formatDateDot } from "../../utils/methods";
import { toast } from "react-toastify";

const initialQuery = {
  number: 1,
  size: 20,
};

function ManagerDetails() {
  const { id } = useParams();
  const { state } = useLocation();
  const { token } = useSelector((state) => state.user.user);
  const [users, setUsers] = useState({ content: [] });
  const [query, setQuery] = useState(initialQuery);

  const handleChangePage = async (event, value) => {
    setQuery({ ...query, number: value });
    const employees = await userList({ ...query, number: value });
    setUsers({ ...users, ...employees });
  };

  const handleChangeSize = async (e) => {
    setQuery({ ...query, size: e.target.value });
    const employees = await userList({ ...query, size: e.target.value });
    setUsers({ ...users, ...employees });
  };

  async function userList(query) {
    try {
      const data = await getEmployessOfCustomer(
        {
          ...query,
          customerId: +id,
          status: state.tab ? "RETIRED" : "APPROVED",
        },
        token
      );
      return data.data;
    } catch (error) {
      console.log(error.response?.data.message);
    }
  }
  async function getCompany() {
    try {
      const { data } = await getCustomerById(id, token);
      const employees = await userList(query);
      setUsers({ ...employees, customer: data });
    } catch (error) {
      console.log(error.response?.data.message);
    }
  }

  // const downloadToExel = () => {
  //   if (users.costumer === undefined && users.content === undefined) {
  //     toast.warning("데이터가 없습니다!");
  //   } else {
  //     // Create a new workbook
  //     const workbook = XLSX.utils.book_new();

  //     // Add a worksheet for customer data
  //     const customerDataSheet = XLSX.utils.json_to_sheet([users.customer]);
  //     XLSX.utils.book_append_sheet(workbook, customerDataSheet, "Customer");

  //     // Add a worksheet for content array
  //     const contentArraySheet = XLSX.utils.json_to_sheet(users.content);
  //     XLSX.utils.book_append_sheet(workbook, contentArraySheet, "Users List");

  //     // Create a Blob containing the Excel file
  //     XLSX.writeFile(workbook, `data.xlsx`);
  //   }
  // };

  useEffect(() => {
    getCompany();
  }, []);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <BackButton text="파견 사업 관리대장 상세" />
        <LinkButton
          label="목록보기"
          link="/account-management/business-manager"
        />
      </Box>

      <hr className="no-print" style={{ margin: "16px auto 30px auto" }} />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
          파견 사업 관리대장 ({users?.customer?.name})
          <br />
          <span style={{ fontSize: "12px", fontWeight: 500 }}>
            {toDayCountry("ko-KR")}
          </span>
        </Typography>
        <Button
          onClick={() => window.print()}
          sx={{
            fontSize: "12px",
            width: "auto",
            height: "30px",
            padding: "7px 20px",
            color: "#fff",
            background: "#6B7280",
            ml: "auto",
            "@media print": {
              display: "none",
            },
          }}
        >
          문서 출력
        </Button>
        {/* <Button
          onClick={downloadToExel}
          sx={{
            marginLeft: "10px",
            fontSize: "12px",
            width: "auto",
            height: "30px",
            padding: "7px 20px",
            color: "#fff",
            background: "#6B7280",
            "@media print": {
              display: "none",
            },
          }}
        >
          엑셀 다운로드
        </Button> */}
      </Box>
      {users.customer && (
        <Box
          padding="30px"
          backgroundColor="#FCFCFC"
          borderRadius="5px"
          margin="20px 0 60px"
        >
          <Grid container alignItems={"center"}>
            <Grid container rowSpacing={1} sx={{ marginBottom: "20px" }}>
              <Grid item xs={2}>
                <Typography fontSize={14} lineHeight="19px" fontWeight={500}>
                  사용사업체명
                </Typography>
              </Grid>
              <Grid item xs={2} borderBottom={"1px solid #BDBDBD"}>
                <Typography fontSize={14} lineHeight="19px" fontWeight={500}>
                  {users.customer.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container rowSpacing={1} sx={{ marginBottom: "20px" }}>
              <Grid item xs={2}>
                <Typography fontSize={14} lineHeight="19px" fontWeight={500}>
                  소재지
                </Typography>
              </Grid>
              <Grid item xs={5} borderBottom={"1px solid #BDBDBD"}>
                <Typography fontSize={14} lineHeight="19px" fontWeight={500}>
                  {users.customer.address}
                </Typography>
              </Grid>
            </Grid>
            <Grid container rowSpacing={1} sx={{ marginBottom: "20px" }}>
              <Grid item xs={2}>
                <Typography fontSize={14} lineHeight="19px" fontWeight={500}>
                  사용사업주
                </Typography>
              </Grid>
              <Grid item xs={2} borderBottom={"1px solid #BDBDBD"}>
                <Typography fontSize={14} lineHeight="19px" fontWeight={500}>
                  {users.customer.ceo}
                </Typography>
              </Grid>
            </Grid>
            <Grid container rowSpacing={1} sx={{ marginBottom: "20px" }}>
              <Grid item xs={2}>
                <Typography fontSize={14} lineHeight="19px" fontWeight={500}>
                  사용사업관리책임자
                </Typography>
              </Grid>
              <Grid item xs={2} borderBottom={"1px solid #BDBDBD"}>
                <Typography fontSize={14} lineHeight="19px" fontWeight={500}>
                  {users.customer.client}
                </Typography>
              </Grid>
            </Grid>
            <Grid container rowSpacing={1} sx={{ marginBottom: "20px" }}>
              <Grid item xs={2}>
                <Typography fontSize={14} lineHeight="19px" fontWeight={500}>
                  전화번호
                </Typography>
              </Grid>
              <Grid item xs={2} borderBottom={"1px solid #BDBDBD"}>
                <Typography fontSize={14} lineHeight="19px" fontWeight={500}>
                  {users.customer.phone}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
      <Select
        sx={{ width: "90px", display: "flex", margin: "0 0 0 auto" }}
        onChange={handleChangeSize}
        value={query.size}
      >
        {pageSize?.map((option, i) => (
          <MenuItem key={i} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <TableContainer
        className="table"
        sx={{
          mt: "10px",
          borderTop: "1px solid #98A6AD",
        }}
      >
        <Table>
          <TableHead sx={{ background: "#fcfcfc" }}>
            <TableRow>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
                align="center"
              >
                번호
              </TableCell>

              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
                align="center"
              >
                사진
              </TableCell>

              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
                align="center"
              >
                성명
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
                align="center"
              >
                사업장 명칭
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
                align="center"
              >
                근무 부서
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
                align="center"
              >
                파견기간
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
                align="center"
              >
                업무내용
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users &&
              users.content?.map((user, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    role="checkbox"
                    key={index}
                    sx={{
                      "&:hover": {
                        color: "#5a5a5a !important",
                        backgroundColor: "rgba(142, 102, 255, 0.22)",
                      },
                      ".css-1d46hnn-MuiTableCell-root": {
                        padding: "1px",
                      },

                      height: "40px",
                    }}
                  >
                    <TableCell
                      component={Link}
                      id={labelId}
                      scope="row"
                      padding="none"
                      to={`/user-details/${user.applicantId}`}
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        padding: "10px",
                      }}
                      align="center"
                    >
                      {user.applicantId}
                    </TableCell>
                    <TableCell
                      component={Link}
                      id={labelId}
                      scope="row"
                      padding="none"
                      to={`/user-details/${user.applicantId}`}
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        padding: "10px",
                      }}
                      align="center"
                    >
                      <img
                        src={
                          `${awsImagePath}/${user.imageUrl}` ??
                          "/images/no-image.png"
                        }
                        style={{ borderRadius: "5px", width: 150 }}
                        alt="foto"
                      />
                    </TableCell>
                    <TableCell
                      component={Link}
                      id={labelId}
                      scope="row"
                      padding="none"
                      to={`/user-details/${user.applicantId}`}
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        padding: "10px",
                      }}
                      align="center"
                    >
                      {user.name}
                    </TableCell>
                    <TableCell
                      component={Link}
                      id={labelId}
                      scope="row"
                      padding="none"
                      to={`/user-details/${user.applicantId}`}
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        padding: "10px",
                      }}
                      align="center"
                    >
                      {user.customerName}
                    </TableCell>
                    <TableCell
                      component={Link}
                      id={labelId}
                      scope="row"
                      padding="none"
                      to={`/user-details/${user.applicantId}`}
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        padding: "10px",
                      }}
                      align="center"
                    >
                      {user.departmentName}
                    </TableCell>
                    <TableCell
                      component={Link}
                      id={labelId}
                      scope="row"
                      padding="none"
                      to={`/user-details/${user.applicantId}`}
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        padding: "10px",
                      }}
                      align="center"
                    >
                      {formatDateDot(user?.dateFrom)}~
                      {formatDateDot(user?.dateUntil)}
                    </TableCell>
                    <TableCell
                      component={Link}
                      id={labelId}
                      scope="row"
                      padding="none"
                      to={`/user-details/${user.applicantId}`}
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        padding: "10px",
                      }}
                      align="center"
                    >
                      {user.dispatchCodeName}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
        <Pagination
          count={Math.ceil(users?.totalElements / query.size) || 0}
          onChange={handleChangePage}
          page={query.number}
          color="primary"
          variant="outlined"
          shape="rounded"
          sx={{
            m: "0 auto",
            "& .css-1k2i9p6-MuiButtonBase-root-MuiPaginationItem-root": {
              height: "20px",
              minWidth: "20px",
              backgroundColor: "#CCCCCC",
              color: "#fff",
            },
          }}
        />
      </Stack>
    </div>
  );
}

export default ManagerDetails;
