import mainCaller from "./mainCaller";

export async function getAllPopup(data, token) {
  return await mainCaller(`popup`, "POST", data, {
    Authorization: `Bearer ${token}`,
  });
}

export async function getPopupById(id, token) {
  return await mainCaller(`popup/getById/${id}`, "GET", null, {
    Authorization: `Bearer ${token}`,
  });
}

export async function createPopup(data, token) {
  return await mainCaller(`popup/create`, "POST", data, {
    "Content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  });
}

export async function updatePopup(data, token) {
  return await mainCaller(`popup/update`, "POST", data, {
    "Content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  });
}

export async function deletePopupById(id, token) {
  return await mainCaller(`popup/deleteById/${id}`, "DELETE", null, {
    Authorization: `Bearer ${token}`,
  });
}
