import { Box, Modal } from "@mui/material";
import React from "react";

export default function CustomModal({ modal, children, style }) {
  return (
    <Modal open={modal.isOpen}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "#fff",
          borderRadius: "10px",
          border: "none",
          ...style,
        }}
      >
        {children}
      </Box>
    </Modal>
  );
}
