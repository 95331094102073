import React, { useEffect } from "react";
import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  TableHead,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useState } from "react";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import { FormInputText } from "../../components/form-components/FormInputText";
import { pageSize } from "../../constants/constants";
import { useSelector } from "react-redux";
import { getAllCustomers } from "../../api/customers";
import { formatKoreanPhoneNumber } from "../../utils/methods";

const initialQuery = {
  number: 1,
  size: 20,
  keyword: "",
  situation: "",
};

function Manager() {
  const { handleSubmit, control, reset } = useForm();
  const { user } = useSelector((state) => state.user);
  const [active, setActive] = useState(false);
  const [users, setUsers] = useState();
  const [query, setQuery] = useState(initialQuery);

  const handleChangePage = (event, value) => {
    setQuery({ ...query, number: value });
  };

  async function userList() {
    try {
      const { data } = await getAllCustomers(query, user.token);
      setUsers(data);
    } catch (error) {
      console.log(error.response?.data.message);
    }
  }

  const submit = async (values) => {
    setQuery({ ...query, ...values });
  };

  const setInitial = () => {
    reset({ ...query, keyword: "" });
    setQuery({ ...query, keyword: "" });
  };

  useEffect(() => {
    userList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <Paper
      sx={{
        width: "100%",
        boxShadow: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => {
            setActive(false);
            setQuery({ ...query, situation: "" });
          }}
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "7px 18px",
            color: `${active ? "#987EE7" : "#fff"}`,
            background: `${active ? "#EEEAFB" : "#987EE7"}`,
          }}
        >
          파견 사업관리대장 재직자
        </Button>
        <Button
          onClick={() => {
            setActive(true);
            setQuery({ ...query, situation: "입사포기" });
          }}
          sx={{
            marginLeft: "10px",
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "7px 18px",
            color: `${active ? "#fff" : "#987EE7"}`,
            background: `${active ? "#987EE7" : "#EEEAFB"}`,
          }}
        >
          파견 사업관리대장 퇴사자
        </Button>
      </Box>
      <hr style={{ margin: "6px auto 40px auto" }} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography style={{ minWidth: "80px", fontWeight: "500" }}>
          업체명
        </Typography>
        <FormInputText
          sx={{ width: "280px", marginRight: "6px" }}
          control={control}
          name="keyword"
          placeholder="내용을 입력해 주세요"
        />
        <Button
          onClick={handleSubmit(submit)}
          size="small"
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "7px 18px",
            color: "#7653DF",
            background: "#EEEAFB",
          }}
        >
          검색
        </Button>
        <Box
          onClick={setInitial}
          sx={{
            textDecoration: "underline",
            fontSize: "14px",
            marginLeft: "10px",
            color: "#6B7280",
            fontWeight: 500,
            "&:hover": {
              color: "#8E66FF",
              cursor: "pointer",
            },
          }}
        >
          검색조건 초기화
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          m: "20px 0 10px",
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormInputDropdown
            control={control}
            sx={{ width: "111px" }}
            options={pageSize}
            staticValue={query.size}
            onChange={(e) => setQuery({ ...query, size: e.target.value })}
          />
        </Box>
      </Box>
      <TableContainer className="table" sx={{ borderTop: "1px solid #98A6AD" }}>
        <Table>
          <TableHead sx={{ background: "#fcfcfc" }}>
            <TableRow>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontWeight: "500",
                  fontSize: "13px",
                }}
                align="center"
              >
                번호
              </TableCell>

              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontWeight: "500",
                  fontSize: "13px",
                }}
                align="center"
              >
                코드
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontWeight: "500",
                  fontSize: "13px",
                }}
                align="center"
              >
                사용 사업주 업체명
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontWeight: "500",
                  fontSize: "13px",
                }}
                align="center"
              >
                본사주소
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontWeight: "500",
                  fontSize: "13px",
                }}
                align="center"
              >
                대표이사
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontWeight: "500",
                  fontSize: "13px",
                }}
                align="center"
              >
                클라이언트 담당자
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontWeight: "500",
                  fontSize: "13px",
                }}
                align="center"
              >
                연락처
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontWeight: "500",
                  fontSize: "13px",
                }}
                align="center"
              >
                상태
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontWeight: "500",
                  fontSize: "13px",
                }}
                align="center"
              >
                사업장 수
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px",
                  whiteSpace: "nowrap",
                  fontWeight: "500",
                  fontSize: "13px",
                }}
                align="center"
              >
                취업자 수
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users &&
              users.content.map((user, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    key={index}
                    sx={{
                      "&:hover": {
                        color: "#5a5a5a !important",
                        backgroundColor: "rgba(142, 102, 255, 0.22)",
                      },
                      ".css-1d46hnn-MuiTableCell-root": {
                        padding: "1px",
                      },

                      height: "40px",
                    }}
                  >
                    <TableCell
                      component={Link}
                      id={labelId}
                      scope="row"
                      padding="none"
                      to={`/account-management/business-manager/${user.id}`}
                      state={{ tab: active }}
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                      align="center"
                    >
                      {user.id}
                    </TableCell>
                    <TableCell
                      component={Link}
                      id={labelId}
                      scope="row"
                      padding="none"
                      to={`/account-management/business-manager/${user.id}`}
                      state={{ tab: active }}
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                      align="center"
                    >
                      {user.code}
                    </TableCell>
                    <TableCell
                      component={Link}
                      id={labelId}
                      scope="row"
                      padding="none"
                      to={`/account-management/business-manager/${user.id}`}
                      state={{ tab: active }}
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                      align="center"
                    >
                      {user.name}
                    </TableCell>
                    <TableCell
                      component={Link}
                      id={labelId}
                      scope="row"
                      padding="none"
                      to={`/account-management/business-manager/${user.id}`}
                      state={{ tab: active }}
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                      align="center"
                    >
                      {user.address}
                    </TableCell>
                    <TableCell
                      component={Link}
                      id={labelId}
                      scope="row"
                      padding="none"
                      to={`/account-management/business-manager/${user.id}`}
                      state={{ tab: active }}
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                      align="center"
                    >
                      {user.ceo}
                    </TableCell>
                    <TableCell
                      component={Link}
                      id={labelId}
                      scope="row"
                      padding="none"
                      to={`/account-management/business-manager/${user.id}`}
                      state={{ tab: active }}
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                      align="center"
                    >
                      {user.client}
                    </TableCell>
                    <TableCell
                      component={Link}
                      id={labelId}
                      scope="row"
                      padding="none"
                      to={`/account-management/business-manager/${user.id}`}
                      state={{ tab: active }}
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                      align="center"
                    >
                      {user.phone && formatKoreanPhoneNumber(user.phone)}
                    </TableCell>
                    <TableCell
                      component={Link}
                      id={labelId}
                      scope="row"
                      padding="none"
                      to={`/account-management/business-manager/${user.id}`}
                      state={{ tab: active }}
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                      align="center"
                    >
                      {user.situation}
                    </TableCell>
                    <TableCell
                      component={Link}
                      id={labelId}
                      scope="row"
                      padding="none"
                      to={`/account-management/business-manager/${user.id}`}
                      state={{ tab: active }}
                      sx={{
                        padding: "10px",
                        textDecoration: "underline",
                        color: "#2D9CDB",
                      }}
                      align="center"
                    >
                      {user.numbOfBusiness}
                    </TableCell>
                    <TableCell
                      component={Link}
                      id={labelId}
                      scope="row"
                      padding="none"
                      to={`/account-management/business-manager/${user.id}`}
                      state={{ tab: active }}
                      sx={{
                        padding: "10px",
                        textDecoration: "underline",
                        color: "#2D9CDB",
                      }}
                      align="center"
                    >
                      {user.numbOfEmployees}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
        <Pagination
          count={Math.ceil(users?.totalElements / query.size) || 0}
          onChange={handleChangePage}
          page={query.number}
          color="primary"
          variant="outlined"
          shape="rounded"
          sx={{
            m: "0 auto",
            "& .css-1k2i9p6-MuiButtonBase-root-MuiPaginationItem-root": {
              height: "20px",
              minWidth: "20px",
              backgroundColor: "#CCCCCC",
              color: "#fff",
            },
          }}
        />
      </Stack>
    </Paper>
  );
}

export default Manager;
