import React, { useEffect } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState, useRef } from "react";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import { FormInputText } from "../../components/form-components/FormInputText";
import { FormInputTextArea } from "../../components/form-components/FormInputTextArea";
import { situation } from "../../constants/constants";
import { useSelector } from "react-redux";
import {
  concultsCreate,
  concultsDelete,
  concultsUpdate,
  getUserConcults,
} from "../../api/user";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Loader from "../../components/loader";
import AcceptModal from "../../components/modals/AcceptModal";

export default function UserConcultation({
  getUrl = "employeeConsultations/getByEmployeeId",
  createUrl = "employeeConsultations/create",
  updateUrl = "employeeConsultations/update",
  deleteUrl = "employeeConsultations/delete",
  user,
  token,
}) {
  const contentRef = useRef(null);
  const { user: userData } = useSelector((state) => state.user);
  const { handleSubmit, control, reset } = useForm();
  const [users, setUsers] = useState();
  const [clicked, setClicked] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState({ isOpen: false });

  const getConcultations = async () => {
    try {
      const { data } = await getUserConcults(getUrl, user.id, token);
      setUsers(data);
    } catch (error) {
      console.log(error);
    }
  };

  const submit = async (values) => {
    try {
      setIsLoading(true);
      if (values.id) {
        const { data } = await concultsUpdate(
          updateUrl,
          {
            ...values,
            employeeId: user.id,
            manager: userData.username || userData.name,
          },
          token
        );
        reset();
        setUsers(users.map((user) => (user.id === data.id ? data : user)));
      } else {
        const { data } = await concultsCreate(
          createUrl,
          {
            ...values,
            employeeId: user.id,
            manager: userData.username || userData.name,
          },
          token
        );
        reset();
        setUsers([...users, data]);
      }
      toast.success("Success");
      setIsLoading(false);
    } catch (error) {
      toast.error(error.response?.data.message);
      console.log(error);
    }
  };

  const deleteConculById = async (id) => {
    setModal({ ...modal, isOpen: true, deleteId: id });
  };

  const deleteById = async () => {
    try {
      const { data } = await concultsDelete(modal.deleteId, token);
      reset();
      setUsers(users.map((user) => (user.id === data.id ? data : user)));
      setModal({ isOpen: false });
    } catch (error) {
      console.log(error);
      setModal({ isOpen: false });
    }
  };

  const update = (user) => {
    reset(user);
  };

  // useEffect(() => {
  //   // Check if the content has more than three rows
  //   const contentElement = contentRef.current;
  //   if (contentElement) {
  //     const contentStyles = window.getComputedStyle(contentElement);
  //     const lineHeight = parseInt(contentStyles.lineHeight);
  //     const maxHeight = parseInt(contentStyles.maxHeight);
  //     const numberOfLines = maxHeight / lineHeight;

  //     if (numberOfLines > 3) {
  //       setClicked(true);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    getConcultations();
  }, [user.id]);

  return (
    <div>
      <Typography
        sx={{
          m: "40px 0 20px 0",
          fontSize: "16px",
          color: "#3A3A3A",
          fontWeight: 700,
        }}
      >
        상담 내역
      </Typography>
      <Table
        sx={{
          borderTop: "1px solid #98A6AD",
        }}
      >
        <TableHead sx={{ background: "#fcfcfc" }}>
          <TableRow>
            <TableCell
              sx={{
                whiteSpace: "nowrap",
                fontSize: "13px",
                fontWeight: "500",
              }}
              align="center"
            >
              일자
            </TableCell>
            <TableCell
              sx={{
                padding: "10px",
                fontWeight: "500",
                whiteSpace: "nowrap",
                fontSize: "13px",
              }}
              align="center"
            >
              관리자
            </TableCell>
            <TableCell
              sx={{
                padding: "10px",
                fontWeight: "500",
                whiteSpace: "nowrap",
                fontSize: "13px",
                width: "480px",
              }}
              align="center"
            >
              상담 내용
            </TableCell>
            {/* <TableCell
              sx={{
                padding: "10px",
                fontWeight: "500",
                whiteSpace: "nowrap",
                fontSize: "13px",
              }}
              align="center"
            >
              매칭처
            </TableCell> */}
            <TableCell
              sx={{
                padding: "10px",
                fontWeight: "500",
                whiteSpace: "nowrap",
                fontSize: "13px",
              }}
              align="center"
            >
              상태
            </TableCell>
            <TableCell
              sx={{
                padding: "10px",
                fontWeight: "500",
                whiteSpace: "nowrap",
                fontSize: "13px",
              }}
              align="center"
            >
              삭제
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users &&
            users.map((data, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  key={index}
                  onClick={() => update(data)}
                  onMouseEnter={() => setClicked(data.id)}
                  sx={{
                    ".css-1d46hnn-MuiTableCell-root": {
                      padding: "1px",
                    },
                    textDecoration: "none",
                    fontSize: "12px",
                    height: "40px",
                    cursor: "pointer",
                  }}
                >
                  <TableCell
                    id={labelId}
                    scope="row"
                    padding="none"
                    sx={{
                      textDecoration: "none",
                      fontSize: "12px",

                      padding: "10px",
                      fontWeight: "500",
                    }}
                    align="center"
                  >
                    {data.createdDate}
                  </TableCell>
                  <TableCell
                    id={labelId}
                    scope="row"
                    padding="none"
                    sx={{
                      textDecoration: "none",
                      fontSize: "12px",
                      width: "200px",
                      padding: "10px",
                      fontWeight: "500",
                    }}
                    align="center"
                  >
                    {data.manager}
                  </TableCell>
                  <TableCell
                    id={labelId}
                    scope="row"
                    padding="none"
                    sx={{
                      textDecoration: "none",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    <div
                      ref={contentRef}
                      style={{
                        maxHeight: clicked === data.id ? "none" : "54px",
                        overflow: "hidden",
                        transition: "max-height 0.3s ease",
                        textAlign: "center",
                        inlineSize: "480px",
                        padding: "10px",
                        overflowWrap: "break-word",
                      }}
                    >
                      {data.contents}
                    </div>
                  </TableCell>
                  {/* <TableCell
                    id={labelId}
                    scope="row"
                    padding="none"
                    sx={{
                      textDecoration: "none",
                      fontSize: "12px",

                      padding: "10px",
                      fontWeight: "500",
                    }}
                    align="center"
                  >
                    {data.destination}
                  </TableCell> */}
                  <TableCell
                    id={labelId}
                    scope="row"
                    padding="none"
                    sx={{
                      textDecoration: "none",
                      fontSize: "12px",
                      padding: "10px",
                      fontWeight: "500",
                      whiteSpace: "nowrap",
                    }}
                    align="center"
                  >
                    {data.situation}
                  </TableCell>
                  <TableCell
                    onClick={() => deleteConculById(data.id)}
                    id={labelId}
                    scope="row"
                    padding="none"
                    sx={{
                      padding: "10px",
                    }}
                    align="center"
                  >
                    <Button
                      size="small"
                      sx={{
                        fontSize: "12px",
                        height: "30px",
                        color: "#fff",
                        background: "#6B7280",
                      }}
                    >
                      삭제
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <Typography
        sx={{
          m: "40px 0 20px 0",
          fontSize: "16px",
          color: "#3A3A3A",
          fontWeight: 700,
        }}
      >
        상담 작성
      </Typography>
      <Box
        sx={{
          backgroundColor: "#FCFCFC",
          border: "1px solid #E7E7E7",
          padding: "30px",
          borderRadius: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            mb: "20px",
            alignItems: "center",
            width: "auto",
          }}
        >
          <Typography
            style={{ fontSize: "14px", fontWeight: "500", minWidth: "75px" }}
          >
            상태
          </Typography>
          <FormInputDropdown
            control={control}
            name="situation"
            sx={{
              width: "120px",
              marginRight: "100px",
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            options={situation}
          />
          {/* <Typography
            style={{ fontSize: "14px", fontWeight: "500", marginRight: "20px" }}
          >
            매칭처
          </Typography> */}
          {/* <FormInputText
            sx={{
              width: "200px",
              marginRight: "12px",
              background: "#fff",
              borderRadius: "8px",
            }}
            control={control}
            name="destination"
            placeholder="내용을 입력해 주세요"
          /> */}
          {isLoading ? (
            <Loader />
          ) : (
            <Button
              onClick={handleSubmit(submit)}
              size="small"
              sx={{
                fontSize: "13px",
                fontWeight: 700,
                height: "30px",
                padding: "7px 35px",
                marginLeft: "auto",
                color: "#fff",
                background: "#7653DF",
              }}
            >
              저장
            </Button>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "auto",
          }}
        >
          <Typography
            style={{ fontSize: "14px", fontWeight: "500", minWidth: "75px" }}
          >
            상담 내용
          </Typography>
          <FormInputTextArea
            control={control}
            minRows={3}
            name="contents"
            placeholder="내용을 입력해 주세요"
            style={{
              width: "100%",
              background: "#fff",
              borderRadius: "5px",
              border: "1px solid #E7E7E7",
            }}
          />
        </Box>
      </Box>
      {modal.isOpen && (
        <AcceptModal
          handleClick={deleteById}
          modal={{ ...modal }}
          modalKey="isOpen"
          setModal={setModal}
          title="선택한 항목을 삭제하시겠습니까?"
        />
      )}
    </div>
  );
}
