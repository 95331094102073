import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FormInputText } from "../form-components/FormInputText";
import { useForm } from "react-hook-form";
import { FormInputDate } from "../form-components/FormInputDate";
import {
  createEmployeeSalary,
  getEmployeeSalaryById,
  updateEmployeeSalary,
} from "../../api/user";
import { toast } from "react-toastify";
import { salaryTableData } from "../../utils/methods";

const salaryObj = {
  careerStageId: null,
  customerName: null,
  periodFrom: null,
  periodTo: null,
  hourlyRate: null,
  referenceString: null,
  indexNumber: null,
  mainSalary: 0,
  overtimePay: null,
  annualLeaveAllowance: null,
  mealCostOne: null,
  mealCostTwo: null,
  transportationCostOne: null,
  transportationCostTwo: null,
  otherAllowanceOne: null,
  otherAllowanceTwo: null,
  holidayAllowance: null,
  otAllowance: null,
  overTimeAllowanceOne: null,
  overTimeAllowanceTwo: null,
  phoneExpenses: null,
  appearanceFee: null,
  liveAllowance: null,
  childCareAllowance: null,
  overTimePayTwo: null,
  bonus: null,
  incentive: null,
  salaryAdjustmentFee: null,
  retirementSavings: null,
  retirementSavingsAdjustment: null,
  annualLeaveAllowanceYear: null,
  nationalPension: null,
  healthInsurance: null,
  elderlyCareInsurance: null,
  employmentInsurance: null,
  industrialAccidentInsurance: null,
  wageClaimContribution: null,
  contributionForDisabled: null,
  businessTax: null,
  recruitmentCheckUp: null,
  groupInsurance: null,
  employeeBenefits: null,
  generalMaintenanceFee: null,
  agentFee: null,
  managementCostAdjustment: null,
  agentFeeAdjustment: null,
  legalWelfareCost: null,
};

export default function UserSalaryAddModal({
  setModal,
  modal,
  modalKey,
  token,
}) {
  const { handleSubmit, control, reset } = useForm();
  const { user } = modal;
  const [maxHeight, setMaxHeight] = useState(0);
  const ref = useRef(null);
  const [salaryArray, setSalaryArray] = useState([
    salaryObj,
    salaryObj,
    salaryObj,
  ]);
  const [noData, setNoData] = useState(true);
  const [totalArray, setTotalArray] = useState({
    total1: 0,
    total2: 0,
    total3: 0,
    total4: 0,
    figure1: 0,
    figure2: 0,
    figure3: 0,
    figure4: 0,
    totalTax: 0,
    totalFirstArray: 0,
  });

  const allTotal =
    totalArray.total1 +
    totalArray.total2 +
    totalArray.total3 +
    totalArray.total4;

  const salaryArray1 = salaryTableData.first;
  const salaryArray2 = salaryTableData.second;
  const salaryArray3 = salaryTableData.third;
  const salaryArray4 = salaryTableData.fourth;

  const handleSum = useCallback(() => {
    const sum = (arr, index) => {
      let total = 0;
      arr.forEach((item) => {
        Object.keys(salaryArray[index]).forEach((key1) => {
          if (item.name === key1) {
            const value = salaryArray[index][key1];
            if (value !== null && !isNaN(parseFloat(value))) {
              total += parseFloat(value);
            }
          }
        });
      });
      return total;
    };

    const total1 = sum(salaryArray1, 0);
    const figure1 = sum(salaryArray1, 1);
    const total2 = sum(salaryArray2, 0);
    const figure2 = sum(salaryArray2, 1);
    const total3 = sum(salaryArray3, 0);
    const figure3 = sum(salaryArray3, 1);
    const total4 = sum(salaryArray4, 0);
    const figure4 = sum(salaryArray4, 1);

    console.log('total ----------> ' , total1);

    let totalTax = 0;
    let totalFirstArray = 0;

    salaryArray1.forEach((item1) => {
      if (item1.tax === "비과세") {
        const value1 = salaryArray[0][item1.name];
        if (value1 !== null && !isNaN(parseFloat(value1))) {
          totalTax += parseFloat(value1);
        }
      }
    });

    salaryArray1.forEach((item1) => {
      const value1 = salaryArray[0][item1.name];
      if (value1 !== null && !isNaN(parseFloat(value1))) {
        totalFirstArray += parseFloat(value1);
      }
    });

    setTotalArray({
      total1,
      total2,
      total3,
      total4,
      figure1,
      figure2,
      figure3,
      figure4,
      totalTax,
      totalFirstArray,
    });
  }, [salaryArray]);

  const getSalary = async () => {
    try {
      const res = await getEmployeeSalaryById(user.id, token);
      if (res.data.length > 0) {
        setSalaryArray(res.data);
        handleSum();
        setNoData(false);
      } else {
        setNoData(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChange = (index, key, value) => {
    const updatedSalaryArray = [...salaryArray];
    updatedSalaryArray[index] = { ...updatedSalaryArray[index], [key]: value };
    setSalaryArray(updatedSalaryArray);
  };

  const submit = async (values) => {
    const mergedData = salaryArray?.map((data, index) => {
      const objData = {
        ...data,
        ...values,
        careerStageId: user.id,
        indexNumber: index,
        totalSalary: allTotal,
        totalTax: totalArray.totalTax,
      };
      return objData;
    });
    try {
      if (noData) {
        const res = await createEmployeeSalary(
          JSON.stringify(mergedData),
          token
        );
        if (res) {
          toast.success("저장 완료되었습니다.");
        }
      } else {
        const res = await updateEmployeeSalary(
          JSON.stringify(mergedData),
          token
        );
        if (res) {
          toast.success("저장 완료되었습니다. ");
        }
      }
      close();
    } catch (err) {
      console.log(err);
    }
  };

  const close = () => {
    setModal((prevModal) => ({
      [modalKey]: false,
      user: "",
      closed: true,
    }));
  };

  useEffect(() => {
    const setMaxHeightAfterRender = () => {
      if (ref.current) {
        const newMaxHeight = ref.current.offsetHeight - 260;
        setMaxHeight(newMaxHeight > 0 ? newMaxHeight : 0);
      }
    };
    setMaxHeightAfterRender();
  }, []);

  useEffect(() => {
    getSalary();
  }, []);

  useEffect(() => {
    handleSum();
  }, [handleSum]);

  return (
    <Box ref={ref} sx={{ width: "100%", maxHeight: "100%", minHeight: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              mb: "12px",
              alignItems: "center",
              width: "auto",
            }}
          >
            <Typography
              style={{
                minWidth: "95px",
                fontSize: "14px",
                fontWeight: "500",
                color: "#5a5a5a",
              }}
            >
              근로자명
            </Typography>
            <FormInputText
              sx={{ width: "318px", marginRight: "6px" }}
              control={control}
              staticValue={user?.name}
              placeholder="내용을 입력해 주세요"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              mb: "12px",
              alignItems: "center",
              width: "auto",
            }}
          >
            <Typography
              style={{
                minWidth: "95px",
                fontSize: "14px",
                fontWeight: "500",
                color: "#5a5a5a",
              }}
            >
              거래처명
            </Typography>
            <FormInputText
              sx={{ width: "318px", marginRight: "6px" }}
              control={control}
              name="customerName"
              placeholder="내용을 입력해 주세요"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              mb: "12px",
              alignItems: "center",
              width: "auto",
            }}
          >
            <Typography
              style={{
                minWidth: "95px",
                fontSize: "14px",
                fontWeight: "500",
                color: "#5a5a5a",
              }}
            >
              적용기간
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "auto",
              }}
            >
              <FormInputDate
                name="periodFrom"
                control={control}
                placeholder="YYYY/MM/DD"
                sx={{
                  ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
                    fontSize: "16px",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    color="info"
                    size="small"
                    variant="outlined"
                    {...params}
                    sx={{
                      width: "127px",
                      background: "#fff",
                      ".MuiInputBase-root": {
                        paddingRight: "0px",
                      },
                      ".MuiIconButton-root": {
                        marginRight: "0px",
                      },
                      input: {
                        paddingRight: "0px!important",
                      },
                      "& .MuiSvgIcon-root": {
                        fill: "#987EE7 !important",
                      },
                    }}
                  />
                )}
              />
              <Typography style={{ margin: "0 10px 0 6px", fontWeight: "500" }}>
                부터
              </Typography>
              <FormInputDate
                name="periodTo"
                control={control}
                placeholder="YYYY/MM/DD"
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    sx={{
                      width: "127px",
                      background: "#fff",
                      paddingRight: "0px!important",
                      ".MuiInputBase-root": {
                        paddingRight: "0px",
                      },
                      ".MuiIconButton-root": {
                        marginRight: "0px",
                      },
                      input: {
                        paddingRight: "0px!important",
                      },
                      "& .MuiSvgIcon-root": {
                        fill: "#987EE7 !important",
                      },
                    }}
                  />
                )}
              />
              <Typography style={{ margin: "0 10px 0 6px", fontWeight: "500" }}>
                까지
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              mb: "12px",
              alignItems: "center",
              width: "auto",
            }}
          >
            <Typography
              style={{
                minWidth: "95px",
                fontSize: "14px",
                fontWeight: "500",
                color: "#5a5a5a",
              }}
            >
              시급
            </Typography>
            <FormInputText
              sx={{ width: "318px", marginRight: "6px" }}
              control={control}
              name="hourlyRate"
              placeholder="내용을 입력해 주세요"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              mb: "12px",
              alignItems: "center",
              width: "auto",
            }}
          >
            <Typography
              style={{
                minWidth: "95px",
                fontSize: "14px",
                fontWeight: "500",
                color: "#5a5a5a",
              }}
            >
              참고
            </Typography>
            <FormInputText
              sx={{ width: "318px", marginRight: "6px" }}
              control={control}
              name="referenceString"
              placeholder="참고 2019년도 최저시급 8,350원"
            />
          </Box>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
        >
          <Button
            sx={{
              background: "#987EE7",
              color: "#fff",
              fontSize: "14px",
              height: "30px",
              borderRadius: "5px",
              alignItems: "center",
              textAlign: "center",
              width: "156px",
              ":hover": { background: "#9775fa" },
            }}
            onClick={handleSum}
          >
            적용수치 전체 계산
          </Button>
          <Box sx={{ marginTop: "70px" }}>
            <TableRow sx={{ height: "40px" }}>
              <TableCell
                align="center"
                sx={{
                  width: "140px",
                  height: "40px",
                  padding: "10px",
                  backgroundColor: "#F1F1F1",
                  border: "1px solid #98A6AD",
                  color: "#1F2329",
                  fontWeight: "500",
                }}
              >
                과세급여합계
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  width: "200px",
                  height: "40px",
                  padding: "10px",
                  border: "1px solid #98A6AD",
                  borderLeft: "none",
                }}
              >
                {(
                  totalArray.totalFirstArray - totalArray.totalTax
                ).toLocaleString()}
              </TableCell>
            </TableRow>
            <TableRow sx={{ height: "40px" }}>
              <TableCell
                align="center"
                sx={{
                  width: "140px",
                  padding: "10px",
                  backgroundColor: "#F1F1F1",
                  border: "1px solid #98A6AD",
                  borderTop: "none",
                  color: "#1F2329",
                  fontWeight: "500",
                }}
              >
                비과세급여 합계
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  border: "1px solid #98A6AD",
                  borderTop: "none",
                  width: "200px",
                  borderLeft: "none",
                  padding: "10px",
                }}
              >
                {totalArray.totalTax.toLocaleString()}
              </TableCell>
            </TableRow>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          maxHeight: maxHeight,
          minHeight: "100%",
          overflow: "auto",
        }}
      >
        <TableContainer
          className="table"
          sx={{
            position: "relative",
            marginTop: "20px",
          }}
        >
          <Table>
            <TableHead
              sx={{
                background: "#ebebeb",
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                    fontWeight: 500,
                    backgroundColor: "#F1F1F1",
                    border: "1px solid #98A6AD",
                    width: "140px",
                  }}
                  align="center"
                >
                  분류
                </TableCell>

                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                    fontWeight: 500,
                    backgroundColor: "white",
                    border: "1px solid #98A6AD",
                    width: "180px",
                  }}
                  align="center"
                >
                  항목
                </TableCell>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                    fontWeight: 500,
                    maxWidth: "80px",
                    backgroundColor: "#F1F1F1",
                    border: "1px solid #98A6AD",
                    width: "140px",
                  }}
                  align="center"
                >
                  과세 여부
                </TableCell>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                    backgroundColor: "white",
                    fontWeight: 500,
                    border: "1px solid #98A6AD",
                    width: "180px",
                  }}
                  align="center"
                >
                  금액 (소수점이하 절사)
                </TableCell>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    backgroundColor: "#F1F1F1",
                    fontSize: "13px",
                    border: "1px solid #98A6AD",
                    fontWeight: 500,
                    width: "180px",
                  }}
                  align="center"
                >
                  적용수치 (관리자기입)
                </TableCell>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                    backgroundColor: "white",
                    fontWeight: 500,
                    width: "80px",
                    border: "1px solid #98A6AD",
                  }}
                  align="center"
                >
                  Default (요율)
                </TableCell>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    backgroundColor: "#F1F1F1",
                    fontSize: "13px",
                    fontWeight: 500,
                    border: "1px solid #98A6AD",
                    width: "340px",
                  }}
                  align="center"
                >
                  비고
                </TableCell>
                <TableCell
                  sx={{
                    paddingInline: "10px",
                    paddingBlock: 0,
                    backgroundColor: "white",
                    fontSize: "13px",
                    fontWeight: 500,
                    width: "80px",
                    border: "1px solid #98A6AD",
                  }}
                  align="center"
                >
                  총파견료 <br /> 대비비율
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {salaryArray1?.map((data, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{
                      ".css-1d46hnn-MuiTableCell-root": {
                        padding: "1px",
                      },
                      textDecoration: "none",
                      height: "40px",
                    }}
                  >
                    <TableCell
                      padding="checkbox"
                      sx={{
                        padding: "10px",
                        fontSize: "12px",
                        fontWeight: 500,
                        backgroundColor: "#F1F1F1",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      개인급여항목
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        border: "1px solid #98A6AD",
                        padding: "10px",
                      }}
                      align="center"
                    >
                      {data.item}
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        backgroundColor: "#F1F1F1",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      {data.tax}
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      <TextField
                        sx={{ backgroundColor: "white", width: "100%" }}
                        value={(salaryArray[0][data.name] || "")
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={(e) =>
                          handleInputChange(
                            0,
                            data.name,
                            e.target.value.replace(/,/g, "")
                          )
                        }
                      />
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        backgroundColor: "#F1F1F1",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      <TextField
                        sx={{ backgroundColor: "white", width: "100%" }}
                        value={(salaryArray[1][data.name] || "")
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={(e) =>
                          handleInputChange(
                            1,
                            data.name,
                            e.target.value.replace(/,/g, "")
                          )
                        }
                      />
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      {data.rate}
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        backgroundColor: "#F1F1F1",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      <TextField
                        sx={{ backgroundColor: "white", width: "100%" }}
                        value={salaryArray[2][data.name] || data.note}
                        onChange={(e) =>
                          handleInputChange(2, data.name, e.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      {salaryArray[0][data.name]
                        ? `${(
                            (salaryArray[0][data.name] / allTotal) *
                            100
                          ).toFixed(2)}%`
                        : 0}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>

            <TableHead>
              <TableRow sx={{ height: "40px" }}>
                <TableCell
                  colSpan={2}
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                    borderLeft: "1px solid #98A6AD",
                  }}
                  align="center"
                >
                  개인급여소계
                </TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                >
                  {totalArray.total1.toLocaleString() }
                </TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ marginTop: "30px" }}>
              {salaryArray2?.map((data, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{
                      ".css-1d46hnn-MuiTableCell-root": {
                        padding: "1px",
                      },
                      textDecoration: "none",
                      height: "40px",
                    }}
                  >
                    <TableCell
                      padding="checkbox"
                      sx={{
                        padding: "10px",
                        fontSize: "12px",
                        fontWeight: 500,
                        backgroundColor: "#F1F1F1",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      직접노무비항목
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        border: "1px solid #98A6AD",
                        padding: "10px",
                      }}
                      align="center"
                    >
                      {data.item}
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        backgroundColor: "#F1F1F1",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      {data.tax}
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      <TextField
                        sx={{ backgroundColor: "white", width: "100%" }}
                        value={(salaryArray[0][data.name] || "")
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={(e) =>
                          handleInputChange(
                            0,
                            data.name,
                            e.target.value.replace(/,/g, "")
                          )
                        }
                      />
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        backgroundColor: "#F1F1F1",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      <TextField
                        sx={{ backgroundColor: "white", width: "100%" }}
                        value={(salaryArray[1][data.name] || "")
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={(e) =>
                          handleInputChange(
                            1,
                            data.name,
                            e.target.value.replace(/,/g, "")
                          )
                        }
                      />
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      {data.rate}
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        backgroundColor: "#F1F1F1",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      <TextField
                        sx={{ backgroundColor: "white", width: "100%" }}
                        value={salaryArray[2][data.name] || data.note}
                        onChange={(e) =>
                          handleInputChange(2, data.name, e.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      {salaryArray[0][data.name]
                        ? (
                            (salaryArray[0][data.name] / allTotal) *
                            100
                          ).toFixed(2)
                        : 0}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableHead>
              <TableRow sx={{ height: "40px" }}>
                <TableCell
                  colSpan={2}
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                    borderLeft: "1px solid #98A6AD",
                  }}
                  align="center"
                >
                  직접노무비소계
                </TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                >
                  {totalArray.total2.toLocaleString()}
                </TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ marginTop: "30px" }}>
              {salaryArray3?.map((data, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{
                      ".css-1d46hnn-MuiTableCell-root": {
                        padding: "1px",
                      },
                      textDecoration: "none",
                      height: "40px",
                    }}
                  >
                    <TableCell
                      padding="checkbox"
                      sx={{
                        padding: "10px",
                        fontSize: "12px",
                        fontWeight: 500,
                        backgroundColor: "#F1F1F1",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      간접노무비항목
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        border: "1px solid #98A6AD",
                        padding: "10px",
                      }}
                      align="center"
                    >
                      {data.item}
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        backgroundColor: "#F1F1F1",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      {data.tax}
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      <TextField
                        sx={{ backgroundColor: "white", width: "100%" }}
                        value={(salaryArray[0][data.name] || "")
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={(e) =>
                          handleInputChange(
                            0,
                            data.name,
                            e.target.value.replace(/,/g, "")
                          )
                        }
                      />
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        backgroundColor: "#F1F1F1",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      <TextField
                        sx={{ backgroundColor: "white", width: "100%" }}
                        value={(salaryArray[1][data.name] || "")
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={(e) =>
                          handleInputChange(
                            1,
                            data.name,
                            e.target.value.replace(/,/g, "")
                          )
                        }
                      />
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      {data.rate}
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        backgroundColor: "#F1F1F1",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      <TextField
                        sx={{ backgroundColor: "white", width: "100%" }}
                        value={salaryArray[2][data.name] || data.note}
                        onChange={(e) =>
                          handleInputChange(2, data.name, e.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      {salaryArray[0][data.name]
                        ? (
                            (salaryArray[0][data.name] / allTotal) *
                            100
                          ).toFixed(2)
                        : 0}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableHead>
              <TableRow sx={{ height: "40px" }}>
                <TableCell
                  colSpan={2}
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                    borderLeft: "1px solid #98A6AD",
                  }}
                  align="center"
                >
                  간접노무비소계
                </TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                >
                  {totalArray.total3.toLocaleString()}
                </TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ marginTop: "30px" }}>
              {salaryArray4?.map((data, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{
                      ".css-1d46hnn-MuiTableCell-root": {
                        padding: "1px",
                      },
                      textDecoration: "none",
                      height: "40px",
                    }}
                  >
                    <TableCell
                      padding="checkbox"
                      sx={{
                        padding: "10px",
                        fontSize: "12px",
                        fontWeight: 500,
                        backgroundColor: "#F1F1F1",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      서비스요금항목
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        border: "1px solid #98A6AD",
                        padding: "10px",
                      }}
                      align="center"
                    >
                      {data.item}
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        backgroundColor: "#F1F1F1",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      {data.tax}
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      <TextField
                        sx={{ backgroundColor: "white", width: "100%" }}
                        value={(salaryArray[0][data.name] || "")
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={(e) =>
                          handleInputChange(
                            0,
                            data.name,
                            e.target.value.replace(/,/g, "")
                          )
                        }
                      />
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        backgroundColor: "#F1F1F1",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      <TextField
                        sx={{ backgroundColor: "white", width: "100%" }}
                        value={(salaryArray[1][data.name] || "")
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={(e) =>
                          handleInputChange(
                            1,
                            data.name,
                            e.target.value.replace(/,/g, "")
                          )
                        }
                      />
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      {data.rate}
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        backgroundColor: "#F1F1F1",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      <TextField
                        sx={{ backgroundColor: "white", width: "100%" }}
                        value={salaryArray[2][data.name] || data.note}
                        onChange={(e) =>
                          handleInputChange(2, data.name, e.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell
                      scope="row"
                      padding="none"
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        padding: "10px",
                        border: "1px solid #98A6AD",
                      }}
                      align="center"
                    >
                      {salaryArray[0][data.name]
                        ? (
                            (salaryArray[0][data.name] / allTotal) *
                            100
                          ).toFixed(2)
                        : 0}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableHead>
              <TableRow sx={{ height: "40px" }}>
                <TableCell
                  colSpan={2}
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                    borderLeft: "1px solid #98A6AD",
                  }}
                  align="center"
                >
                  서비스요금소개
                </TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                >
                  {totalArray.total4.toLocaleString()}
                </TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#EEEAFB",
                    borderRight: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ marginTop: "30px" }}>
              <TableRow
                sx={{
                  ".css-1d46hnn-MuiTableCell-root": {
                    padding: "1px",
                  },
                  textDecoration: "none",
                  height: "40px",
                }}
              >
                <TableCell
                  scope="row"
                  colSpan={2}
                  padding="none"
                  sx={{
                    textDecoration: "none",
                    fontSize: "12px",
                    border: "1px solid #98A6AD",
                    padding: "10px",
                    backgroundColor: "#F1F1F1",
                  }}
                  align="center"
                >
                  총파견료
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  sx={{
                    textDecoration: "none",
                    fontSize: "12px",
                    padding: "10px",
                    backgroundColor: "#F1F1F1",
                    border: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  sx={{
                    textDecoration: "none",
                    fontSize: "12px",
                    padding: "10px",
                    border: "1px solid #98A6AD",
                  }}
                  align="center"
                >
                  {allTotal.toLocaleString()}
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  sx={{
                    textDecoration: "none",
                    fontSize: "12px",
                    padding: "10px",
                    backgroundColor: "#F1F1F1",
                    border: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  sx={{
                    textDecoration: "none",
                    fontSize: "12px",
                    padding: "10px",
                    border: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  sx={{
                    textDecoration: "none",
                    fontSize: "12px",
                    padding: "10px",
                    backgroundColor: "#F1F1F1",
                    border: "1px solid #98A6AD",
                  }}
                  align="center"
                >
                  개인급여+직접&간접노무비소계+서비스요금합계
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  sx={{
                    textDecoration: "none",
                    fontSize: "12px",
                    padding: "10px",
                    border: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
              </TableRow>
              <TableRow
                sx={{
                  ".css-1d46hnn-MuiTableCell-root": {
                    padding: "1px",
                  },
                  textDecoration: "none",
                  height: "40px",
                }}
              >
                <TableCell
                  scope="row"
                  colSpan={2}
                  padding="none"
                  sx={{
                    textDecoration: "none",
                    fontSize: "12px",
                    border: "1px solid #98A6AD",
                    padding: "10px",
                    backgroundColor: "#F1F1F1",
                  }}
                  align="center"
                >
                  부가세
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  sx={{
                    textDecoration: "none",
                    fontSize: "12px",
                    padding: "10px",
                    backgroundColor: "#F1F1F1",
                    border: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  sx={{
                    textDecoration: "none",
                    fontSize: "12px",
                    padding: "10px",
                    border: "1px solid #98A6AD",
                  }}
                  align="center"
                >
                  {Math.floor(allTotal / 10).toLocaleString()}
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  sx={{
                    textDecoration: "none",
                    fontSize: "12px",
                    padding: "10px",
                    backgroundColor: "#F1F1F1",
                    border: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  sx={{
                    textDecoration: "none",
                    fontSize: "12px",
                    padding: "10px",
                    border: "1px solid #98A6AD",
                  }}
                  align="center"
                ></TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  sx={{
                    textDecoration: "none",
                    fontSize: "12px",
                    padding: "10px",
                    backgroundColor: "#F1F1F1",
                    border: "1px solid #98A6AD",
                  }}
                  align="center"
                >
                  총파견료의 10%
                </TableCell>
                <TableCell
                  scope="row"
                  padding="none"
                  sx={{
                    textDecoration: "none",
                    fontSize: "12px",
                    padding: "10px",
                    border: "1px solid #98A6AD",
                  }}
                  align="center"
                >
                  <p
                    style={{
                      inlineSize: "80px",
                    }}
                  ></p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        mt="20px"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          sx={{
            background: "#98A6AD",
            color: "#fff",
            fontSize: "14px",
            height: "30px",
            borderRadius: "5px",
            alignItems: "center",
            textAlign: "center",
            width: "83px",
            ":hover": { background: "#9775fa" },
          }}
          onClick={close}
        >
          취소
        </Button>
        <Button
          onClick={handleSubmit(submit)}
          sx={{
            background: "#7653DF",
            color: "#fff",
            fontSize: "14px",
            height: "30px",
            borderRadius: "5px",
            alignItems: "center",
            textAlign: "center",
            marginLeft: "6px",
            width: "123px",
          }}
        >
          저장
        </Button>
      </Box>
    </Box>
  );
}
