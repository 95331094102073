import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  TableHead,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import { FormInputText } from "../../components/form-components/FormInputText";
import CustomCheckbox from "../../components/CustomCheckbox";
import { getEmployeeList } from "../../api/user";
import { order, pageSize, searchType3 } from "../../constants/constants";
import { exportDataToExcel, getAge } from "../../utils/methods";
import SmsSendModal from "../../components/modals/SmsSendModal";
import DataNotFound from "../../components/DataNotFound";

const initialQuery = {
  number: 1,
  size: 20,
  order: "DESC",
  sort: "joiningDate",
  keyword: "",
  searchType: "",
  status: "PENDING",
};

function EmployeeWaitingList() {
  const { user } = useSelector((state) => state.user);
  const { handleSubmit, control, reset } = useForm({
    defaultValues: initialQuery,
  });
  const [query, setQuery] = useState(initialQuery);
  const [users, setUsers] = useState();
  const [smsModal, setSmsModal] = useState({ isOpen: false });
  const [isNoData, setIsNoData] = useState(false);

  const handleChangePage = (_, value) => {
    setQuery({ ...query, number: value });
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    var tempUser = {};
    if (name === "allSelect") {
      tempUser = users.content.map((user) => {
        return { ...user, isChecked: checked };
      });
    } else {
      tempUser = users.content.map((user) =>
        user.name + user.id === name ? { ...user, isChecked: checked } : user
      );
    }
    setUsers({ ...users, content: tempUser });
  };

  const donwloadExcel = () => {
    const checkedData = [];
    users?.content?.map((data) => {
      if (data.isChecked) {
        checkedData.push(data);
      }
      return data.isChecked;
    });

    if (checkedData.length === 0) {
      toast.warning("인원을 선택해 주세요!");
    } else {
      exportDataToExcel(checkedData, "사원 대기자");
    }
  };

  const smsSend = () => {
    const checkedData = [];
    users?.content?.map((data) => {
      if (data.isChecked) {
        checkedData.push(data);
      }
      return data.isChecked;
    });

    if (checkedData.length === 0) {
      toast.warning("인원을 선택해 주세요!");
    } else {
      setSmsModal({ isOpen: true, checkedUsers: checkedData });
    }
  };

  async function userList() {
    try {
      const { data } = await getEmployeeList(query, user.token);
      if (data.content.length > 0) {
        setIsNoData(false);
        setUsers(data);
      } else {
        setIsNoData(true);
      }
    } catch (error) {
      console.log(error.response?.data.message);
    }
  }

  const submit = async (values) => {
    setQuery({ ...query, ...values });
  };

  const setInitial = () => {
    reset(initialQuery);
    setQuery(initialQuery);
  };

  useEffect(() => {
    userList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <Paper
      sx={{
        width: "100%",
        boxShadow: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          mb: "12px",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Typography
          style={{
            minWidth: "95px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#5a5a5a",
          }}
        >
          검색
        </Typography>
        <FormInputDropdown
          control={control}
          name="searchType"
          sx={{ width: "111px", marginRight: "10px" }}
          options={searchType3}
        />
        <FormInputText
          sx={{ width: "200px", marginRight: "6px" }}
          control={control}
          name="keyword"
          placeholder="내용을 입력해 주세요"
        />
        <Button
          onClick={handleSubmit(submit)}
          size="small"
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "7px 18px",
            color: "#7653DF",
            background: "#EEEAFB",
          }}
        >
          검색
        </Button>
        <Box
          size="small"
          onClick={setInitial}
          sx={{
            textDecoration: "underline",
            fontSize: "14px",
            color: "#6B7280",
            ml: "10px",
            fontWeight: 500,
            "&:hover": {
              color: "#8E66FF",
              cursor: "pointer",
            },
          }}
        >
          검색조건 초기화
        </Box>
      </Box>
      <div style={{ margin: "30px auto 20px auto", borderColor: "#E7E7E7" }} />
      {isNoData ? (
        <DataNotFound title="검색된 사원 대기자가 없습니다!" />
      ) : (
        users && (
          <>
            <Box
              sx={{
                display: "flex",
                mb: "15px",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={smsSend}
                  size="small"
                  sx={{
                    fontSize: "12px",
                    mr: "6px",
                    height: "30px",
                    padding: "7px 20px",
                    color: "#fff",
                    background: "#6B7280",
                  }}
                >
                  SMS
                </Button>
                <Button
                  onClick={() => donwloadExcel()}
                  sx={{
                    fontSize: "12px",
                    mr: "12px",
                    height: "30px",
                    padding: "7px 20px",
                    color: "#fff",
                    background: "#6B7280",
                  }}
                >
                  엑셀 다운로드
                </Button>
                <CustomCheckbox
                  name="allSelect"
                  checked={
                    !users.content?.some((user) => user.isChecked !== true)
                  }
                  onChange={handleChange}
                />
                <Typography
                  style={{
                    minWidth: "95px",
                    fontWeight: "500",
                    color: "#5A5A5A",
                  }}
                >
                  현재 해당되는 모든 회원 ( {users?.totalElements} 명) 선택
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormInputDropdown
                  control={control}
                  name="order"
                  sx={{ width: "111px", marginRight: "10px" }}
                  onChange={(e) =>
                    setQuery({ ...query, order: e.target.value })
                  }
                  options={order}
                  staticValue={query.order}
                />
                <FormInputDropdown
                  control={control}
                  name="size"
                  sx={{ width: "111px" }}
                  onChange={(e) => setQuery({ ...query, size: e.target.value })}
                  options={pageSize}
                  staticValue={query.size}
                />
              </Box>
            </Box>
            <TableContainer
              className="table"
              sx={{
                mt: "10px",
                borderTop: "1px solid #98a6ad",
                height: `${window.innerHeight - 370}px`,
                position: "relative",
              }}
            >
              <Table size="small" aria-label="a dense table">
                <TableHead
                  sx={{
                    background: "#ebebeb",
                    position: "sticky",
                    top: -1,
                    zIndex: 10,
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        color: "#5A5A5A",
                        fontSize: "13px",
                        width: "",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      <CustomCheckbox
                        color="primary"
                        name="allSelect"
                        checked={
                          !users.content?.some(
                            (user) => user.isChecked !== true
                          )
                        }
                        onChange={handleChange}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#5A5A5A",
                        fontSize: "13px",
                        width: "73px",
                      }}
                      align="center"
                    >
                      번호
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#5A5A5A",
                        fontSize: "13px",
                        width: "52px",
                      }}
                      align="center"
                    >
                      성명
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 9px 10px 2px",
                        whiteSpace: "nowrap",
                        color: "#5A5A5A",
                        fontSize: "13px",
                        width: "23px",
                      }}
                      align="center"
                    >
                      주민번호
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#5A5A5A",
                        fontSize: "13px",
                      }}
                      align="center"
                    >
                      등록일자
                    </TableCell>

                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#5A5A5A",
                        fontSize: "13px",
                      }}
                      align="center"
                    >
                      입사일자
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#5A5A5A",
                        fontSize: "13px",
                      }}
                      align="center"
                    >
                      업체명
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#5A5A5A",
                        fontSize: "13px",
                      }}
                      align="center"
                    >
                      근로자 급여
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#5A5A5A",
                        fontSize: "13px",
                      }}
                      align="center"
                    >
                      관리자
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#5A5A5A",
                        fontSize: "13px",
                      }}
                      align="center"
                    >
                      세부 수행 업무
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        color: "#5A5A5A",
                        fontSize: "13px",
                      }}
                      align="center"
                    >
                      신청일자
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.content.map((user, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        role="checkbox"
                        key={index}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#EEEAFB",
                          },
                          ".css-1d46hnn-MuiTableCell-root": {
                            padding: "1px",
                          },
                          textDecoration: "none",
                          height: "40px",
                        }}
                      >
                        <TableCell
                          padding="checkbox"
                          sx={{
                            padding: "10px",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                          align="center"
                        >
                          <CustomCheckbox
                            name={user.name + user.id}
                            checked={user?.isChecked || false}
                            onChange={handleChange}
                          />
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/talent/employee-waiting-list/${user.applicantId}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                          }}
                          align="center"
                        >
                          {query.size * (query.number - 1) + index + 1}
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/talent/employee-waiting-list/${user.applicantId}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                          }}
                          align="center"
                        >
                          <span>{user.name}</span>
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/talent/employee-waiting-list/${user.applicantId}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                          }}
                          align="center"
                        >
                          <span>
                            {user.social1}
                            {user.social2 ? ` - ${user.social2}` : ""}
                          </span>
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/talent/employee-waiting-list/${user.applicantId}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                          }}
                          align="center"
                        >
                          {getAge(user.registration)}
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/talent/employee-waiting-list/${user.applicantId}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                          }}
                          align="center"
                        >
                          {getAge(user.joiningDate)}
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/talent/employee-waiting-list/${user.applicantId}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                          }}
                          align="center"
                        >
                          {user.customerName}
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/talent/employee-waiting-list/${user.applicantId}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                          }}
                          align="center"
                        >
                          {user.workerSalary}
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/talent/employee-waiting-list/${user.applicantId}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                          }}
                          align="center"
                        >
                          {user.personInCharge}
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/talent/employee-waiting-list/${user.applicantId}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                          }}
                          align="center"
                        >
                          {user.detailedTasks || ""}
                        </TableCell>
                        <TableCell
                          component={Link}
                          id={labelId}
                          scope="row"
                          padding="none"
                          to={`/talent/employee-waiting-list/${user.applicantId}`}
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                          }}
                          align="center"
                        >
                          {getAge(user.applicationDate)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
              <Pagination
                count={Math.ceil(users?.totalElements / query.size) || 0}
                onChange={handleChangePage}
                page={query.number}
                color="primary"
                variant="outlined"
                shape="rounded"
                sx={{
                  m: "0 auto",
                  "& .MuiPaginationItem-root.MuiPaginationItem-page, .MuiPaginationItem-previousNext":
                    { padding: "4px 20px !important" },
                }}
              />
            </Stack>
          </>
        )
      )}
      <SmsSendModal
        setModal={setSmsModal}
        modal={{ ...smsModal, isOpen: smsModal.isOpen }}
        modalKey="isOpen"
      />
    </Paper>
  );
}

export default EmployeeWaitingList;
