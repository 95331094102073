import {
  Box,
  Button,
  Grid,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import BackButton from "../../components/BackButton";
import { Link, useParams } from "react-router-dom";
import LinkButton from "../../components/LinkButton";
import AddIcon from "@mui/icons-material/Add";
import { FormInputText } from "../../components/form-components/FormInputText";
import { useForm } from "react-hook-form";
import { getAge } from "../../utils/methods";
import CustomCheckbox from "../../components/CustomCheckbox";
import { useState } from "react";
import { order, pageSize, sort, testUsers } from "../../constants/constants";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import { FormInputDate } from "../../components/form-components/FormInputDate";
import { FormInputTextArea } from "../../components/form-components/FormInputTextArea";
import FormInputCEditor from "../../components/form-components/FormInputCEditor";

const options = [
  {
    label: "정규직",
    value: "1",
  },
  {
    label: "파견직",
    value: "2",
  },
  {
    label: "도급직",
    value: "3",
  },
  {
    label: "위탁",
    value: "4",
  },
  {
    label: "아르바이트",
    value: "5",
  },
  {
    label: "프리랜서",
    value: "6",
  },
  {
    label: "계약직",
    value: "7",
  },
  {
    label: "기타",
    value: "8",
  },
];

export default function NoticeDetails() {
  const { id } = useParams();
  const { control, setValue } = useForm();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <BackButton text="공지 상세" />
        <LinkButton label="목록으로" link="/post-notice/notification" />
      </Box>
      <hr style={{ margin: "16px 0 30px 0" }} />
      <Grid container>
        <Grid item xs={6}>
          <Grid container rowSpacing={1} alignItems="center">
            <Grid item xs={2} fontWeight="500">
              상태
            </Grid>
            <Grid item xs={10}>
              <FormInputDropdown
                control={control}
                name="nimadir"
                sx={{
                  maxWidth: "180px",
                  width: "100%",
                }}
                options={options}
              />
            </Grid>

            <Grid item xs={2} fontWeight="500">
              제목
            </Grid>
            <Grid item xs={10}>
              <FormInputText
                control={control}
                name="adminCode"
                placeholder="내용을 입력해 주세요"
              />
            </Grid>
            <Grid item xs={2} fontWeight="500">
              파일 첨부
            </Grid>
            <Grid item xs={10}>
              <Box display="flex" alignItems="center">
                <FormInputText
                  control={control}
                  name="adminCode"
                  placeholder=""
                />
                <Button
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "12px",
                    marginLeft: "10px",
                    lineHeight: "15px",
                    padding: "8px 26px",
                    color: "#7653DF",
                    backgroundColor: "#EEEAFB",
                  }}
                >
                  업로드
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container rowSpacing={1} mt="0px">
        <Grid item xs={1} fontWeight="500">
          내용
        </Grid>
        <Grid item xs={10}>
          <FormInputCEditor
            setValue={setValue}
            control={control}
            sx={{
              borderRadius: "8px",
              ".ck.ck-toolbar": {
                background: "#fcfcfc",
              },
              ".ck-content": {
                height: "500px",
                borderRadius: "8px",
              },
            }}
            name="details"
          />
        </Grid>
      </Grid>
      <hr style={{ margin: "70px 0 20px" }} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        mt="25px"
      >
        <Button
          size="small"
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            marginRight: "auto",
            border: "1px solid #7653DF",
            padding: "8px 26px",
          }}
        >
          삭제
        </Button>
        <Button
          size="small"
          sx={{
            fontSize: "13px",
            mr: "8px",
            height: "30px",
            padding: "7px 26px",
            color: "#fff",
            background: "#98A6AD",
          }}
        >
          취소
        </Button>
        <Button
          size="small"
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "7px 46px",
            color: "#fff",
            background: "#7653DF",
          }}
        >
          저장
        </Button>
      </Box>
    </Box>
  );
}
