import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const DATE_FORMAT = "YYYY/MM/DD";
const DATE_FORMAT_MONTH = "YYYY/MM";
const DATE_FORMAT_YEAR = "YYYY";
const TIMEZONE = "Asia/Tashkent"; // Set your desired timezone here

export const FormInputDateSummery = ({
  name,
  renderInput,
  control,
  variant,
  disabled,
  defaultValue,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        disabled={disabled}
        render={({ field }) => {
          return (
            <DatePicker
              disabled={disabled}
              fullWidth
              inputFormat={DATE_FORMAT}
              variant={variant}
              autoOk
              defaultValue={defaultValue}
              renderInput={renderInput}
              value={field.value ? field.value : defaultValue}
              onChange={(date) => field.onChange(date ? date : "")}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export const DatePickerMonth = ({
  name,
  renderInput,
  control,
  variant,
  disabled,
  defaultValue,
  defaultSetValue,
  openTo,
  view,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        disabled={disabled}
        render={({ field }) => {
          return (
            <DatePicker
              openTo={openTo}
              view={view}
              disabled={disabled}
              fullWidth
              inputFormat={DATE_FORMAT_MONTH}
              variant={variant}
              autoOk
              renderInput={renderInput}
              value={
                field.value
                  ? dayjs(field.value).tz(TIMEZONE).toDate()
                  : defaultValue
              }
              onChange={(date) =>
                field.onChange(
                  date
                    ? dayjs(date).tz(TIMEZONE).add(10, "hour").toDate()
                    : defaultSetValue(date)
                )
              }
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export const DatePickerYear = ({
  name,
  renderInput,
  control,
  variant,
  disabled,
  defaultValue,
  defaultSetValue,
  openTo,
  view,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        disabled={disabled}
        render={({ field }) => {
          return (
            <DatePicker
              openTo={openTo}
              view={view}
              disabled={disabled}
              fullWidth
              inputFormat={DATE_FORMAT_YEAR}
              variant={variant}
              autoOk
              renderInput={renderInput}
              value={
                field.value
                  ? dayjs(field.value).tz(TIMEZONE).toDate()
                  : defaultValue
              }
              onChange={(date) =>
                field.onChange(
                  date
                    ? dayjs(date).tz(TIMEZONE).add(10, "hour").toDate()
                    : defaultSetValue(date)
                )
              }
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};
