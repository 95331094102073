import React, { useState, useEffect } from "react";
import { Box, Button, Tab, Tabs } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import TabSwitcher from "./TabSwitcher";
import BackButton from "../../../components/BackButton";
import LinkButton from "../../../components/LinkButton";
import { getHhuntingById } from "../../../api/customers";
import SmsSendModal from "../../../components/modals/SmsSendModal";

export default function HHRequestDetails() {
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState(2);
  const [data, setData] = useState();
  const [smsModal, setSmsModal] = useState({ isOpen: false });
  const { user } = useSelector((state) => state.user);

  const handleChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  async function hhuntingReq() {
    try {
      const { data } = await getHhuntingById(id, user.token);
      setData(data);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data.message);
    }
  }

  const smsSend = () => {
    const newData = {
      ...data,
      name: data.companyName,
    };
    setSmsModal({ isOpen: true, user: newData });
  };

  useEffect(() => {
    hhuntingReq();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Box
      sx={{
        width: "100%",
        pl: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <BackButton text="헤드헌팅 상시" />
        <LinkButton label="목록으로" link="/account-management/hh-request" />
      </Box>
      <hr style={{ margin: "16px 0 10px 0" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Tabs
          sx={{
            "& .MuiTab-root.MuiTab-textColorSecondary": {
              borderRadius: "5px",
              color: "#987EE7",
              background: "#EEEAFB",
              mr: "5px",
              minHeight: "30px !important",
              p: "7px 20px",
              fontWeight: "500",
              fontSize: "13px",
            },
            "& .MuiTabs-scroller": {
              alignItems: "flex-end",
              display: "flex",
            },
            "& .MuiTab-root.MuiTab-textColorSecondary.Mui-selected": {
              background: "#987EE7",
              color: "#fff",
            },
            "& .MuiTabs-indicator": {
              display: "none",
              color: "#E1E1E1",
            },
          }}
          value={selectedTab}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value={2} label="의뢰 정보" />
          <Tab value={1} label="상담 내역" />
        
        
        </Tabs>
        <Button
          onClick={smsSend}
          size="small"
          sx={{
            fontSize: "12px",
            mr: "6px",
            height: "30px",
            padding: "7px 20px",
            color: "#fff",
            background: "#6B7280",
          }}
        >
          SMS
        </Button>
      </Box>
      <hr
        style={{
          margin: "30px 0 40px 0",
        }}
      />
      <TabSwitcher selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <SmsSendModal
        setModal={setSmsModal}
        modal={{ ...smsModal, isOpen: smsModal.isOpen }}
        modalKey="isOpen"
      />
    </Box>
  );
}
