import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Modal, Typography, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { FormInputText } from "../form-components/FormInputText";
import { FormInputTextArea } from "../form-components/FormInputTextArea";
import { sendSms } from "../../api/employee";
import { toast } from "react-toastify";
import Loader from "../loader";

export default function SmsSendModal({
  modal,
  setModal,
  style = {},
  modalKey = "isOpen",
  //   handleClick = () => {},
}) {
  const close = () => {
    setModal((prevModal) => ({
      ...prevModal,
      [modalKey]: false,
      //////this is user status, for user status changing in UserDetail page
      status: "",
    }));
  };

  const { handleSubmit, control, reset } = useForm({});
  const { token } = useSelector((state) => state.user.user);
  const [isLoading, setIsLoading] = useState(false);

  const submit = async (values) => {
    const numbers = [];
    // eslint-disable-next-line array-callback-return
    if (modal.checkedUsers) {
      modal.checkedUsers.map((data) => {
        if (modal.job) {
          numbers.push({ value: data.managerInquiryCall?.replace(/-/g, "") });
        } else {
          numbers.push({ value: data.phoneNumber?.replace(/-/g, "") });
        }
      });
    } else if (modal.user) {
      numbers.push({ value: modal.user.phoneNumber?.replace(/-/g, "") });
    }
    const newValues = { ...values, phoneNumbers: numbers };
    if (!values.adminNumber) {
      toast.warning("발송 번호 입려해 주세요!");
    } else if (!values.content) {
      toast.warning("발송 내용 입력해 주세요!");
    } else {
      try {
        setIsLoading(true);
        const res = await sendSms(JSON.stringify(newValues), token);
        if (res) {
          toast.success(res.body.message);
          setIsLoading(false);
          close();
        }
      } catch (err) {
        toast.error(err.response?.data.message);
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal open={modal.isOpen}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          padding: "20px 40px 40px",
          width: 608,
          bgcolor: "#fff",
          boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.10)",
          borderRadius: "10px",
          border: "none",
          ...style,
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography
            sx={{
              fontSize: "18px",
              color: "#3A3A3A",
              fontWeight: "bold",
            }}
          >
            {"SMS 보내기"}
          </Typography>
          <Button sx={{ minWidth: "unset" }} onClick={close}>
            <img src="/icons/close.svg" alt="close" />
          </Button>
        </Box>

        <div
          style={{
            width: "100%",
            height: 1,
            backgroundColor: "#E7E7E7",
            marginTop: 30,
          }}
        />

        <Box marginTop={4} display="flex" justifyContent="space-between">
          <Grid color="#5A5A5A" fontSize={13} item xs={2} fontWeight="500">
            수신자
          </Grid>
          <FormInputText
            control={control}
            disabled={true}
            staticValue={
              modal.checkedUsers
                ? modal.checkedUsers?.map((data) => {
                    if (modal.job) {
                      return " " + data.title;
                    } else {
                      return " " + data.name;
                    }
                  })
                : modal.user?.name
            }
            sx={{ width: 400, height: 30 }}
          />
        </Box>
        <Box marginTop={2} display="flex" justifyContent="space-between">
          <Grid
            color="#5A5A5A"
            fontSize={13}
            item
            xs={2}
            sx={{ mt: "18px" }}
            fontWeight="500"
          >
            발송번호
          </Grid>
          <Grid item>
            <Typography align="start" color="red" fontSize={12}>
              핸드폰 번호는 반드시 "-" 제외하고 입력하세요!
            </Typography>
            <FormInputText
              control={control}
              name="adminNumber"
              sx={{ width: 400, height: 30 }}
              type="number"
            />
          </Grid>
        </Box>
        <Box marginTop={2} display="flex" justifyContent="space-between">
          <Grid color="#5A5A5A" fontSize={13} item xs={2} fontWeight="500">
            발송 내용
          </Grid>
          <FormInputTextArea
            control={control}
            name="content"
            style={{ width: 400, height: 120, fontSize: 13, fontWeight: 500 }}
            maxLength={90}
          />
        </Box>

        <div
          style={{
            width: "100%",
            height: 1,
            backgroundColor: "#E7E7E7",
            marginTop: 30,
          }}
        />

        <Box display="flex" justifyContent="end" mt="50px" gap="10px">
          <Button
            sx={{
              backgroundColor: "#98A6AD",
              color: "#FFFFFF",
              fontSize: "13px",
              borderRadius: "5px",
              width: "94px",
              height: "30px",
            }}
            onClick={close}
          >
            초기화
          </Button>
          {isLoading ? (
            <Box>
              <Loader style={{ backgroundColor: "red" }} />
            </Box>
          ) : (
            <Button
              sx={{
                backgroundColor: "#7653DF",
                color: "#fff",
                fontSize: "13px",
                borderRadius: "5px",
                width: "123px",
                height: "30px",
              }}
              onClick={handleSubmit(submit)}
            >
              발송
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
