import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import BaseOptionChart from "./chart/BaseOptionChart";
import merge from "lodash/merge";
import ReactApexChart from "react-apexcharts";
import { FormInputDateSummery } from "../form-components/FormInputDateSummery";
import { getLogTime } from "../../api/userSummary";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";

// ----------------------------------------------------------------------

const categories = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
];

const Hourly = () => {
  const { user } = useSelector((state) => state.user);
  const [logTime, setLogTime] = useState();

  const numLogs = [
    {
      name: "Time",
      data: [],
    },
  ];

  for (let i = 0; i < 24; i++) {
    numLogs[0].data.push(0);
  }

  logTime?.map((item) =>
    categories.forEach((category) => {
      if (category.includes(item.time_interval)) {
        let num = parseInt(item.time_interval);
        numLogs[0].data[num - 1] = item.num_logs;
      }
    })
  );

  const { handleSubmit, control } = useForm();

  const submit = async (values) => {
    const body = {
      startDate: dayjs(values.hour).format("YYYY-MM-DD 00:00:00"),
    };

    try {
      const result = await getLogTime(body, user.token);
      setLogTime(result);
    } catch (error) {
      toast.error(error.response?.data.message);
    }
  };

  const chartOptions = merge(BaseOptionChart(), {
    colors: ["#5A6ACF"],
    stroke: { show: false },
    chart: {
      type: "bar",
    },
    xaxis: {
      categories: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
      ],
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      y: {
        formatter: (val) => val,
      },
    },
  });

  return (
    <Stack sx={{ mt: 1.5 }}>
      <Stack sx={{ flexDirection: "row" }}>
        <FormInputDateSummery
          name="hour"
          control={control}
          sx={{
            ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
              fontSize: "16px",
            },
          }}
          renderInput={(params) => (
            <TextField
              SelectProps={{ native: true }}
              color="info"
              size="small"
              variant="outlined"
              {...params}
              sx={{
                width: "127px",
                background: "#fff",
                ".MuiInputBase-root": {
                  paddingRight: "0px",
                },
                ".MuiIconButton-root": {
                  marginRight: "0px",
                },
                input: {
                  paddingRight: "0px!important",
                },
                "& .MuiSvgIcon-root": {
                  fill: "#987EE7 !important",
                },
              }}
            />
          )}
        />
        <Button
          size="small"
          onClick={handleSubmit(submit)}
          sx={{
            fontSize: "13px",
            width: "auto",
            height: "30px",
            padding: "7px 18px",
            color: "#7653DF",
            background: "#EEEAFB",
            ml: 1,
          }}
        >
          검색
        </Button>
      </Stack>
      <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
        <ReactApexChart
          type="bar"
          series={numLogs}
          options={chartOptions}
          height={364}
        />
      </Box>

      <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
        <img src="/icons/ellipse.svg" alt="ellipse" width={9} height={9} />
        <Typography sx={{ ml: 1, fontSize: "12px", color: "#121212" }}>
          1 시간
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Hourly;
