//user
export const userTypes = {
  SET_USER: "SET_USER",
  REMOVE_USER: "REMOVE_USER",
};

export const authTypes = {
  SUCCESS_LOGIN: "SUCCESS_LOGIN",
  SUCCESS_SIGNUP: "SUCCESS_SIGNUP",
};
