import React from "react";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";


export default function TabSwitcher({ selectedTab, setSelectedTab }) {
  switch (selectedTab) {
    case 1:
      return <Tab1 />;
    case 2:
      return <Tab2 />;
    default:
      return <Tab1 />;
  }
}
