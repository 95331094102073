import { Button } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import {
  getEmployeeDataById,
  getUserById,
  getUserConcults,
  getUserJobAppls,
  getWorkHistoriesById,
} from "../../api/user";
import { exportDataToExcel, getAge } from "../../utils/methods";
import { getUserResumesById } from "../../api/resume";

const getUrl = "employeeConsultations/getByEmployeeId";

export default function UserDataExelDownloadSwitcher({ user, token, active }) {
  return (
    <Button
      onClick={() => functionSwitcher(active, user, token)}
      sx={{
        fontSize: "12px",
        lineHeight: "15px",
        height: "30px",
        padding: "7px 26px",
        whiteSpace: "nowrap",
        color: "#fff",
        background: "#6B7280",
        marginLeft: "auto",
      }}
    >
      엑셀 다운로드
    </Button>
  );
}

const functionSwitcher = (active, user, token) => {
  const download1 = async () => {
    try {
      const { data } = await getUserJobAppls(user.id, token);
      const { data: consultations } = await getUserConcults(
        getUrl,
        user.id,
        token
      );
      if (consultations.length === 0) {
        toast.warning("회원의 구직 신청 정보가 없습니다!");
      } else {
        exportDataToExcel(consultations, "상담 내역");
      }
      if (data.length === 0) {
        toast.warning("회원의 구직 신청 정보가 없습니다!");
      } else {
        exportDataToExcel(data, "구직 신청");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const download2 = async () => {
    try {
      const { data } = await getUserResumesById(user.id, token);
      if (data.length === 0) {
        toast.warning("저장된 이력서가 없습니다!");
      } else {
        exportDataToExcel(data, "이력서");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const download3 = async () => {
    const { nextId, nextName, previousId, previousName, ...filteredData } =
      user;
    const data = [];
    data.push({
      ...filteredData,
      birthday: getAge(filteredData.birthday),
    });
    exportDataToExcel(data, "계정 정보");
  };

  const download4 = async () => {
    try {
      const { data } = await getEmployeeDataById(user.id, token);
      const { data: userData } = await getUserById(user.id, undefined, token);
      const userDetail = {
        address: userData?.address,
        addressDetails: userData?.addressDetails,
        email: userData?.email,
      };

      const newData = dataConverter(data);

      const exportData = {
        ...newData,
        ...userDetail,
      };

      if (data.status === "PENDING") {
        exportDataToExcel([exportData], "사원대기자");
      } else if (data.status === "APPROVED") {
        exportDataToExcel([exportData], "사원");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const download5 = async () => {
    if (user.status === "PRE_RETIRED") {
      try {
        const { data } = await getEmployeeDataById(user.id, token);
        const { data: userData } = await getUserById(user.id, undefined, token);
        const userDetail = {
          address: userData?.address,
          addressDetails: userData?.addressDetails,
          email: userData?.email,
        };
        const newData = dataConverter(data);
        console.log(data);

        const exportData = {
          ...newData,
          ...userDetail,
        };
        exportDataToExcel([exportData], "퇴사대기자");
      } catch (error) {
        console.log(error);
      }
    }
    try {
      const { data } = await getWorkHistoriesById(user.id, token);
      const newData = data.map((data) => dataConverter(data));
      if (newData.length === 0) {
        toast.warning("회원의 퇴사 정보가 없습니다!");
      } else {
        exportDataToExcel(newData, "이력 정보");
      }
    } catch (error) {
      console.log(error);
    }
  };

  switch (active) {
    case 1:
      return download1();
    case 2:
      return download2();
    case 3:
      return download3();
    case 4:
      return download4();
    case 5:
      return download5();
    default:
      return "";
  }
};

const dataConverter = (data) => {
  const newData = Object.entries(data).reduce((acc, [key, value]) => {
    if (value !== null) {
      if (Array.isArray(value) && value.length === 3) {
        // Combine date arrays into a string
        acc[key] = value.join("-");
      } else if (typeof value === "object") {
        // Convert nested objects
        acc[key] = {};
        Object.entries(value).forEach(([nestedKey, nestedValue]) => {
          acc[key][nestedKey] =
            Array.isArray(nestedValue) && nestedValue.length === 3
              ? nestedValue.join("-")
              : nestedValue;
        });
      } else {
        acc[key] = value;
      }
    }
    return acc;
  }, {});

  return newData;
};
