import React from "react";
import { Link } from "react-router-dom";

export default function LinkButton({ link, label }) {
  return (
    <Link
      style={{
        color: "#6B7280",
        fontSize: "14px",
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
      }}
      to={link}
      className="no-print"
    >
      <img src="/icons/list.svg" style={{ marginRight: "2px" }} alt="list" />
      {label}
    </Link>
  );
}
