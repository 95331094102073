import React from "react";
import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  TableHead,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getAllSms } from "../../api/employee";
import { pageSize } from "../../constants/constants";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import { useForm } from "react-hook-form";
import {
  dateFormatterWithTime,
  formatKoreanPhoneNumber,
} from "../../utils/methods";
const initialQuery = {
  number: 1,
  size: 20,
  order: "DESC",
  sort: "createdDate",
  dateTo: "",
  keyword: "",
  dateFrom: "",
  searchType: "",
};
function SmsList() {
  const { user } = useSelector((state) => state.user);
  const [allData, setAllData] = useState();

  const [query, setQuery] = useState(initialQuery);

  const { control } = useForm({
    defaultValues: initialQuery,
  });

  useEffect(() => {
    userList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  async function userList() {
    try {
      const result = await getAllSms(query, user.token);
      setAllData(result.body);
    } catch (error) {
      console.log(error.response?.data.message);
    }
  }

  const handleChangePage = (_, value) => {
    setQuery({ ...query, number: value });
  };

  const onChange = async (e) => {
    console.log("e-> ", e.target.value);
    setQuery({ ...query, size: e.target.value });
  };
  return (
    <Paper
      sx={{
        width: "100%",
        boxShadow: "none",
      }}
    >
      <>
        <Box
          sx={{
            display: "flex",
            mb: "15px",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Link to="/sms-sent" style={{ marginLeft: "auto" }}>
            <Button
              size="small"
              sx={{
                fontSize: "13px",
                width: "auto",
                height: "30px",
                padding: "8px 26px",
                color: "#FFF",
                backgroundColor: "#7653DF",
              }}
            >
              메시지 작성 <AddIcon />
            </Button>
          </Link>
        </Box>
        <hr className="borderClass" />
        <Box
          sx={{
            display: "flex",
            mb: "15px",
            mt: "40px",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FormInputDropdown
            control={control}
            name="size"
            sx={{ width: "111px", marginLeft: "auto" }}
            options={pageSize}
            onChange={onChange}
            staticValue={query.size}
          />
        </Box>
        <TableContainer className="table">
          <Table
            sx={{
              "& .MuiTableCell-root": {
                border: "0.5px solid #b0aeae",
              },
            }}
          >
            <TableHead sx={{ background: "#fcfcfc" }}>
              <TableRow>
                <TableCell
                  sx={{
                    paddingLeft: "8px",
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                  align="center"
                >
                  발신일시
                </TableCell>

                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                    fontWeight: 500,
                  }}
                  align="center"
                >
                  발신번호
                </TableCell>

                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                    fontWeight: 500,
                  }}
                  align="center"
                >
                  수신번호
                </TableCell>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                    fontWeight: 500,
                  }}
                  align="center"
                >
                  내용
                </TableCell>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                    fontWeight: 500,
                  }}
                  align="center"
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allData
                ? allData.data.content.map((item, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:hover": {
                            color: "#5a5a5a !important",
                            backgroundColor: "rgba(142, 102, 255, 0.22)",
                          },
                          textDecoration: "none",
                          height: "40px",
                        }}
                      >
                        <TableCell
                          sx={{
                            padding: "10px",
                            fontWeight: 500,
                          }}
                          align="center"
                        >
                          {dateFormatterWithTime(item.createdDate)}
                        </TableCell>

                        <TableCell
                          scope="row"
                          padding="none"
                          sx={{
                            textDecoration: "none",
                            fontSize: "12px",
                            fontWeight: "500",
                            padding: "10px",
                          }}
                          align="center"
                        >
                          {formatKoreanPhoneNumber(item.callerId)}
                        </TableCell>
                        <TableCell
                          scope="row"
                          padding="none"
                          sx={{
                            textDecoration: "none",
                            fontSize: "12px",
                            fontWeight: "500",
                            padding: "10px",
                          }}
                          align="center"
                        >
                          {formatKoreanPhoneNumber(item.receivingId)}
                        </TableCell>
                        <TableCell
                          scope="row"
                          padding="none"
                          sx={{
                            textDecoration: "none",
                            padding: "10px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          {item.content}
                        </TableCell>
                        <TableCell
                          scope="row"
                          padding="none"
                          sx={{
                            textDecoration: "none",
                            fontSize: "12px",
                            fontWeight: "500",
                            padding: "10px",
                          }}
                          align="center"
                        >
                          {item.status ? "sent" : "not sent"}
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack spacing={1} sx={{ width: "100%", mt: "20px" }}>
          <Pagination
            count={Math.ceil(allData?.data.totalElements / query.size) || 0}
            onChange={handleChangePage}
            page={query.number}
            color="primary"
            variant="outlined"
            shape="rounded"
            sx={{
              m: "0 auto",
              "& .css-1k2i9p6-MuiButtonBase-root-MuiPaginationItem-root": {
                height: "20px",
                minWidth: "20px",
                backgroundColor: "#CCCCCC",
                color: "#fff",
              },
            }}
          />
        </Stack>
      </>
    </Paper>
  );
}

export default SmsList;
